import { useTareas } from './hooks/useTareas';
import './styles/estilos.scss';
import { useEffect } from 'react';
import GridProveedores from './components/GridProveedores';
import ModalSeguimiento from './components/ModalSeguimiento';

const SeguimientoProveedores = () => {

    const {
        modalActividadStatus,
        tabVisible,
        seguimientoProveedores,
        dataTareasEntidad,
        responsables,
        setModalActividadStatus,
        getSeguimientoProveedores,
        handleTareasEntidad,
        onEditTarea
    } = useTareas();

    useEffect(() => {
        getSeguimientoProveedores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <> 
        {
            seguimientoProveedores.loading ? 
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '100px' }}>
                    <div className="icon loading-icon loading-size-lg"></div>
                </div>
            :
            <div className="container-tareas">         
                <hr />
                <br />
                <GridProveedores
                    dataSeguimientoProveedores={seguimientoProveedores.data}
                    handleTareasEntidad={handleTareasEntidad}
                />
                {
                modalActividadStatus && (
                    <ModalSeguimiento
                        modalActividadEstatus={modalActividadStatus}
                        closePopup={() => setModalActividadStatus(!modalActividadStatus)}
                        tabVisible={tabVisible}
                        dataTareasEntidad={dataTareasEntidad}
                        onEditTarea={onEditTarea}
                        responsables={responsables.data}
                    />
                )
                }
            </div>
        }
        </>
    )
}

export default SeguimientoProveedores;
