import { IFiltersAnualClientes, IRemoteFiltersFacturacionAnual, IFiltersProfit, IRemoteFilterProfit } from "interfaces/IClientes";

export const RemoteFacturacionAnualAdapter = (filterFacturacionClientes: IFiltersAnualClientes): IRemoteFiltersFacturacionAnual => {
    return {
        Anio: filterFacturacionClientes.años,
        Clientes: filterFacturacionClientes.clientes,
        moneda: filterFacturacionClientes.moneda
    }
}
export const RemoteFilterProfit = (filter: IFiltersProfit): IRemoteFilterProfit => {
    return {
        anios: filter.años,
        cliente: filter.clientes,
        vendedor: filter.vendedores
    }
}