import { useCallback, useEffect, useState } from 'react';
import { IFilterChange, IFilterMultiple } from 'components/interfaces/Interfaces';
import { IGetCatalogos } from 'interfaces/IHome';
import { IFilterOpsTerrestres, IGetDataOperaciones } from 'interfaces/IOperaciones';
import { useAuth } from 'api/auth';
import { RemoteFilterOpsTerrestresAdapter } from 'adapters/Operaciones';
import { OperacionesService } from 'api/operaciones';
import { onExportingPivot } from 'utils/Functions';
import { ExportingEvent } from 'devextreme/ui/pivot_grid';

interface IUseOpsTerrestres {
  operacionesTerrestres: IGetDataOperaciones;
  catalogos: IGetCatalogos;
  handlerOnSelectChange: (e: IFilterChange) => void;
  handleFiltrado: () => Promise<void>;
  onExporting: (e: ExportingEvent) => void;
}

const defaultFilters: IFilterOpsTerrestres = {
  años: [],
  modosTransporte: [],
  operativo: [],
  transportista: []
}

export const useOpsTerrestres = (): IUseOpsTerrestres => {

  const { User } = useAuth();
  const [filters, setFilters] = useState<IFilterOpsTerrestres>(defaultFilters);
  const [catalogos, setCatalogos] = useState<IGetCatalogos>({ loading: false });
  const [operacionesTerrestres, setOperacionesTerrestres] = useState<IGetDataOperaciones>({ loading: false });

  const opsService = useCallback(() => {
    return OperacionesService(User)
  }, [User]);

  const onExporting = useCallback((e: ExportingEvent) => {
    return onExportingPivot(e, "OpsTerrestres", User.divisionSeleccionada!);
  }, [User]);

  const handleFiltrado = async (): Promise<void> => {
    setOperacionesTerrestres({ loading: true });
    const filtersAdapter: string = JSON.stringify(RemoteFilterOpsTerrestresAdapter(filters));
    const { result, data, error } = await opsService().getDataOpsTerrestres(filtersAdapter);
    if (!result) {
      setOperacionesTerrestres({ error: error, loading: false });
      return
    }
    setOperacionesTerrestres({ data: data, loading: false, });
  }

  const handlerOnSelectChange = (e: IFilterChange): void => {
    const { key, value } = e;
    setFilters(prev => {
      return {
        ...prev,
        [key]: value
      }
    });
  }

  /**GET CATALOGOS */
  useEffect(() => {
    const getCatalogos = async (): Promise<void> => {
      setCatalogos({ loading: true });
      const { result, data, error } = await opsService().getCatalogosOpsTerrestres();
      if (!result) {
        setCatalogos({ error: error, loading: false });
        return
      }
      const filtersCatalogos: IFilterMultiple[] = data;
      setCatalogos({ data: filtersCatalogos, loading: false });
    }
    getCatalogos();
  }, [opsService]);

  /**GET DATA INICIAL */
  useEffect(() => {
    const getInitialData = async (): Promise<void> => {
      setOperacionesTerrestres({ loading: true });
      const filtersAdapter: string = JSON.stringify(RemoteFilterOpsTerrestresAdapter(defaultFilters));
      const { result, data, error } = await opsService().getDataOpsTerrestres(filtersAdapter);
      if (!result) {
        setOperacionesTerrestres({ error: error, loading: false });
        return
      }
      setOperacionesTerrestres({ data: data, loading: false, });
    }
    getInitialData();
  }, [opsService]);

  return {
    operacionesTerrestres,
    catalogos,
    handleFiltrado,
    handlerOnSelectChange,
    onExporting
  }
}