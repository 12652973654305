import { useAuth } from "api/auth";
import { ITabs } from "functions/Tareas";
import { TareasService } from "api/tareas";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import {
  ITarea,
  IAddTarea,
  IEntidades,
  IUpdateTarea,
  IGetDataTareas,
  IGetDataEntidades,
  IGetTareasEntidad,
  IGetDataResponsables,
  IResponsablesFormated,
  ISeguimientoEntidades,
  IGetDataSeguimientoClientes,
  IGetDataSeguimientoProveedores,
} from "interfaces/ITareas";

interface IUseTareas {
  tarea: ITarea;
  tabVisible: ITabs;
  modalStatus: boolean;
  dataTareas: IGetDataTareas;
  modalActividadStatus: boolean;
  setModalStatus(e: boolean): void;
  entidadDefined: IGetDataEntidades;
  responsables: IGetDataResponsables;
  getResponsables: () => Promise<any>;
  dataTareasEntidad: IGetTareasEntidad;
  handlerOnSelectTab: (tab: ITabs) => void;
  setModalActividadStatus(e: boolean): void;
  deleteTarea: (id: number) => Promise<void>;
  getSeguimientoClientes: () => Promise<void>;
  getEntidades: (tipo: string) => Promise<any>;
  addTarea: (tarea: IAddTarea) => Promise<void>;
  getSeguimientoProveedores: () => Promise<void>;
  getTareasEntidad: (id: string) => Promise<void>;
  seguimientoClientes: IGetDataSeguimientoClientes;
  handleEditTarea: (data: ITarea) => Promise<void>;
  updateTarea: (tarea: IUpdateTarea) => Promise<void>;
  seguimientoProveedores: IGetDataSeguimientoProveedores;
  onEditTarea: (data: IUpdateTarea, action: string) => Promise<void>;
  handleTareasEntidad: (data: ISeguimientoEntidades) => Promise<void>;
}

const initialStateTarea: ITarea = {
  Tarea_Id: 0,
  Tarea_Nombre: "",
  Tarea_Estatus: "",
  Tarea_Urgencia: "",
  Entidad_Razon_Social: "",
  cMotion_Perfil_Colaborador_Nombre: "",
  cMotion_Perfil_Colaborador_Apellido_Paterno: "",
  cMotion_Perfil_Colaborador_Apellido_Materno: "",
  Estatus_Id: 0,
  Urgencia_Id: 0,
  Tarea_Nota: "",
  Entidad_Tipo: "",
  Tarea_Fecha_Termino: new Date(),
  Tarea_Fecha_Llamada: new Date(),
  Tarea_Fecha_Creacion: new Date(),
  Tarea_Fecha_Ultima_Actualizacion: new Date(),
};

const urgenciaItems = [
  { idUrgencia: 1, urgencia: "Baja" },
  { idUrgencia: 2, urgencia: "Media" },
  { idUrgencia: 3, urgencia: "Alta" },
];

const estatusItems = [
  { idEstatus: 1, estatus: "Pendiente" },
  { idEstatus: 2, estatus: "En proceso" },
  { idEstatus: 3, estatus: "Terminada" },
];

const defaultTab: ITabs = {
  text: "Editar",
};

export const useTareas = (): IUseTareas => {
  const { User } = useAuth();
  const [dataTareas, setDataTareas] = useState<IGetDataTareas>({
    loading: false,
    data: [],
  });
  const [responsables, setResponsables] = useState<IGetDataResponsables>({
    loading: false,
    data: [],
  });
  const [entidadDefined, setEntidadDefined] = useState<IGetDataEntidades>({
    loading: false,
    data: [],
  });
  const [seguimientoClientes, setSeguimientoClientes] =
    useState<IGetDataSeguimientoClientes>({ loading: false, data: [] });
  const [seguimientoProveedores, setSeguimientoProveedores] =
    useState<IGetDataSeguimientoProveedores>({ loading: false, data: [] });
  const [dataTareasEntidad, setDataTareasEntidad] = useState<IGetTareasEntidad>(
    { loading: false, data: [] }
  );
  const [modalStatus, setModalStatus] = useState(false);
  const [modalActividadStatus, setModalActividadStatus] = useState(false);
  const [tarea, setTarea] = useState<ITarea>({ ...initialStateTarea });
  const [tabVisible, setTabVisible] = useState<ITabs>(defaultTab);

  const tareasService = useMemo(() => {
    return TareasService(User);
  }, [User]);

  useLayoutEffect(() => {
    const getTareas = async (): Promise<void> => {
      setDataTareas({ loading: true });
      const { data } = await tareasService.getTareas();

      setDataTareas({ loading: false, data });
    };
    getTareas();
  }, [tareasService]);

  useLayoutEffect(() => {
    const getResponsables = async (): Promise<void> => {
      setResponsables({ loading: true });
      const { data } = await tareasService.getResponsables();
      let responsablesTransformados: IResponsablesFormated[];

      responsablesTransformados = data.map((responsable) => ({
        colaborador: `${responsable.cMotion_Perfil_Colaborador_Nombre} ${responsable.cMotion_Perfil_Colaborador_Apellido_Paterno} ${responsable.cMotion_Perfil_Colaborador_Apellido_Materno}`,
        guid: responsable.cMotion_Perfil_Colaborador_Guid,
        colaborador_id: responsable.colaborador_id,
      }));

      setResponsables({ loading: false, data: responsablesTransformados });
    };
    getResponsables();
  }, [tareasService]);

  const getEntidades = async (tipo: string): Promise<IEntidades[]> => {
    const { data } = await tareasService.getEntidades(tipo);
    setEntidadDefined({ loading: false, data });
    return data;
  };

  const getResponsables = async (): Promise<void> => {
    const { data } = await tareasService.getResponsables();
    let responsablesTransformados: IResponsablesFormated[];

    responsablesTransformados = data.map((responsable) => ({
      colaborador: `${responsable.cMotion_Perfil_Colaborador_Nombre} ${responsable.cMotion_Perfil_Colaborador_Apellido_Paterno} ${responsable.cMotion_Perfil_Colaborador_Apellido_Materno}`,
      guid: responsable.cMotion_Perfil_Colaborador_Guid,
      colaborador_id: responsable.colaborador_id,
    }));

    setResponsables({ loading: false, data: responsablesTransformados });
  };

  const getTareas = async (): Promise<void> => {
    setDataTareas({ loading: true });
    const { data } = await tareasService.getTareas();

    setDataTareas({ loading: false, data });
  };

  const addTarea = async (tarea: IAddTarea): Promise<void> => {
    await tareasService.addTarea(tarea);
    await getTareas();
  };

  const handleEditTarea = async (data: ITarea): Promise<void> => {
    const updatedData = { ...data };

    const urgenciaItem = urgenciaItems.find(
      (item) => item.urgencia === data.Tarea_Urgencia
    );
    const estatusItem = estatusItems.find(
      (item) => item.estatus === data.Tarea_Estatus
    );

    if (urgenciaItem) {
      updatedData.Urgencia_Id = urgenciaItem.idUrgencia;
    }

    if (estatusItem) {
      updatedData.Estatus_Id = estatusItem.idEstatus;
    }

    getResponsables();
    getEntidades(data.Entidad_Tipo);
    setModalStatus(true);
    setTarea(updatedData);
  };

  const handleTareasEntidad = async (
    data: ISeguimientoEntidades
  ): Promise<void> => {
    getTareasEntidad(data.Entidad_Guid);
    setModalActividadStatus(true);
  };

  const updateTarea = async (tarea: IUpdateTarea): Promise<void> => {
    await tareasService.updateTarea(tarea);
    await getTareas();
    setModalStatus(false);
  };

  const handlerOnSelectTab = useCallback((tab: ITabs): void => {
    setTabVisible(tab);
  }, []);

  const deleteTarea = async (id: number): Promise<void> => {
    await tareasService.deleteTarea(id);
    await getTareas();
  };

  const getSeguimientoClientes = async (): Promise<void> => {
    setSeguimientoClientes({ loading: true });
    const { data } = await tareasService.getSeguimientoClientes();
    setSeguimientoClientes({ loading: false, data });
  };

  const getSeguimientoProveedores = async (): Promise<void> => {
    setSeguimientoProveedores({ loading: true });
    const { data } = await tareasService.getSeguimientoProveedores();
    setSeguimientoProveedores({ loading: false, data });
  };

  const getTareasEntidad = async (id: string): Promise<void> => {
    setDataTareasEntidad({ loading: true });
    const { data } = await tareasService.getTareasEntidad(id);
    setDataTareasEntidad({ loading: false, data });
  };

  const onEditTarea = async (data: IUpdateTarea, action: string) => {
    switch (action) {
      case "update":
        await updateTarea(data);
        await getTareasEntidad(data.Entidad_Guid);
        break;
      case "remove":
        await deleteTarea(data.Tarea_Id);
        await getTareasEntidad(data.Entidad_Guid);
        break;
      default:
        return;
    }
  };

  return {
    dataTareas,
    responsables,
    modalStatus,
    modalActividadStatus,
    tarea,
    entidadDefined,
    tabVisible,
    seguimientoClientes,
    seguimientoProveedores,
    dataTareasEntidad,
    setModalStatus,
    getEntidades,
    getResponsables,
    addTarea,
    handleEditTarea,
    updateTarea,
    handlerOnSelectTab,
    deleteTarea,
    getSeguimientoClientes,
    getSeguimientoProveedores,
    getTareasEntidad,
    handleTareasEntidad,
    setModalActividadStatus,
    onEditTarea,
  };
};
