import Button from "components/Button";
import TableGrid, { IOnSaved } from "components/TableGrid";
import { Popup } from "devextreme-react";
import Loading from "pages/Loading";
import { useEffect, useState } from "react";
import { TagBox } from 'devextreme-react/tag-box';
import { ValueChangedEvent } from 'devextreme/ui/tag_box';
import { useClientesExentosDemoras } from "../hooks/useClientesExentosDemoras";
import notify from "devextreme/ui/notify";

const GridClientesDemoras = () => {
    const { 
        clientesExentosDemoras,
        catalogoClientes,
        getCatalogoClientes,
        getClientesExentosDemoras,
        insertClientesExentosDemoras,
        deleteClienteExentoDemora
    } = useClientesExentosDemoras();

    const [openModal, setOpenModal] = useState(false);
    const [selectedClients, setSelectedClients] = useState<string[]>([]);
    const MAX_CLIENTS = 5;

    useEffect(() => {
        getClientesExentosDemoras()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!catalogoClientes.loading && openModal) {
            setOpenModal(true);
        }
    }, [catalogoClientes.loading, openModal])

    const handleClientSelection = (e: ValueChangedEvent) => {
        if (e.value && Array.isArray(e.value)) {
            if (e.value.length <= MAX_CLIENTS) {
                setSelectedClients(e.value);
            } else {
                setSelectedClients(e.value.slice(0, MAX_CLIENTS));
                notify(`Se ha alcanzado el límite máximo de ${MAX_CLIENTS} clientes a insertar.`, 'warning', 3000);
            }
        }
    };

    const handleAddClients = async () => {
        if (selectedClients.length > 0) {
            await insertClientesExentosDemoras(selectedClients);
            await getClientesExentosDemoras();
            setOpenModal(false);
            setSelectedClients([]);
        }
    };

    const handleDeleteClient = async (event: IOnSaved[]) => {
        const entidad_guid = event[0].key.Entidad_Guid;
        await deleteClienteExentoDemora(entidad_guid);
        await getClientesExentosDemoras();
    }

    return (
        <>
        {clientesExentosDemoras.loading ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            padding: "300px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="icon loading-icon loading-size-lg"></div>
        </div>
      ) : (
        <div className='container-grid'>
            {
                clientesExentosDemoras.loading ?
                <Loading />
                : (
                <>
                    <Popup
                        visible={openModal}
                        onHiding={() => setOpenModal(false)}
                        title="Añadir clientes"
                        width={400}
                        height={200}
                        contentRender={() => (
                            <>
                                {catalogoClientes.loading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <div className="icon loading-icon loading-size-lg"></div>
                                    </div>
                                ) : (
                                    <>
                                        <div style={{ fontSize: '1em' }}>
                                            Selecciona hasta {MAX_CLIENTS} clientes. 
                                            Seleccionados: {selectedClients.length}/{MAX_CLIENTS}
                                        </div>
                                        <TagBox
                                            dataSource={catalogoClientes.data}
                                            displayExpr="razonSocial"
                                            valueExpr="entidadGuid"
                                            showSelectionControls={true}
                                            showDropDownButton={true}
                                            multiline={false}
                                            searchEnabled={true}
                                            placeholder="Selecciona clientes..."
                                            selectAllMode="allPages"
                                            maxDisplayedTags={5}
                                            value={selectedClients}
                                            onValueChanged={handleClientSelection}
                                        />
                                        <Button
                                            icon="fas fa-plus"
                                            text='Agregar Clientes'
                                            width={250}
                                            iconHover="Claro"
                                            style={{backgroundColor:"#10A800", marginTop: 20}}
                                            onClick={handleAddClients}
                                            disabled={selectedClients.length === 0}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    />
                    <TableGrid
                        dataSource={clientesExentosDemoras.data}
                        columns={[
                            { dataField: 'Entidad_Razon_Social', caption: 'Cliente', },
                            { dataField: 'Entidad_Guid', caption: 'GUID' },
                        ]}
                        enableHeaderFilter={true}
                        enableGroupPanel={true}
                        enableSearchPanel={true}
                        enableHoverState={true}
                        showRowLines={true}
                        enableColumnChooser={true}
                        enableEditing={{ modeEdit: 'cell', enableDelete:true, showIcons: true, onSavedChanges: (event) => {handleDeleteClient(event)} }}
                    />
                    <Button
                        disabled={clientesExentosDemoras.loading}
                        icon={`fas fa-${clientesExentosDemoras.loading ? 'spin' : 'sync-alt'}}`}
                        style={{ marginTop: 10}}
                        text={'Añadir Clientes'}
                        width={250}
                        iconHover="Claro"
                        onClick={() => {
                            getCatalogoClientes();
                            setOpenModal(true);
                        }}
                    />
                </>
                )
            }
        </div>
      )}
        </>
    )
}

export default GridClientesDemoras