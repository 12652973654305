/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FechaFilters, FilterSearchMultipleGrid} from 'components/Index'
import { useComisionesPendientes } from './hooks/useComisionesPendientes'
import './styles/estilos.scss'
import MenuFilters from 'components/MenuFilters';
import { GridComisionesPendientesVendedores } from './Index'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { URL_API_COMISIONES, URL_API_FIREBASE_APP } from 'consts/Services_URL';
import { useAuth } from 'api/auth';

const ComisionesPendientesVendedor = () => {
  const { User } = useAuth();
  const [comisiones, setComisiones] = useState<any[]>([]);
  const [vendedores, setVendedores] = useState<any[]>([]);
  const [vendedoresSelected, setVendedoresSelected] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  interface IComisionesPendientesVendedor {
    Tipo_Flete: string;
    Embarque_Personalizado_Fecha_Entrega: string;
    Fecha_Arribo: string;
    Termino_Pago: string;
  }

  const {
    filters,
    validateEditingComision,
    handlerOnChangeFecha,
    handlerOnSyncEmbarque
  } = useComisionesPendientes();

  const { fecha } = filters;

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const vendedores = await axios.get(`${URL_API_COMISIONES}catalogos/vendedor/subordinados?vendedor=${JSON.parse(localStorage.getItem('USER_STATE')!).nameMagaya!}`, {
        headers: {
          Authorization: `Bearer ${User.token!}`,
        }
      });
      vendedores.data.forEach((v: any) => v.selectionState = false);
      setVendedores(vendedores.data);
      setLoading(false);
    }
    fetchData();
  }, []);

  const handlerOnSelectVendedor = (vendedores: any) => {
    const vendedoresSelect = [];
    for(const v of vendedores.value){
        vendedoresSelect.push(v);
    }
    setVendedoresSelected(vendedoresSelect);
  }

  const aplyFilters = () => {
    setLoading(true);
    setComisiones([]);

    const fetchData = async () => {
      const data: any[] = [];

      for (const vendedor of vendedoresSelected) {
        const response = await axios.get(`${URL_API_FIREBASE_APP}/comisionesPendientesVendedor?vendedor=${vendedor}&mes=${fecha.mes}&anio=${fecha.año}`);
        const responseData = response.data.payload.map((item: IComisionesPendientesVendedor)  => {
          // Obtener fecha arribo
          const fechaArribo = new Date(item.Fecha_Arribo);
          // Obtener termino de pago en caso de que no sea un string vacio si no se asigna 0
          const terminoPago = item.Termino_Pago.trim() !== '' ? parseInt(item.Termino_Pago) : 0;
          const fechaTerminoPago = new Date(fechaArribo.getTime() + terminoPago * 24 * 60 * 60 * 1000);
          // Crea e inicializa la fecha de termino de pago de la comision
          let fechaTerminoPagoComision = new Date();
          // Obtener fecha de entrega de embarque personalizado
          const fechaEmbarquePersonalizadoEntrega = new Date(item.Embarque_Personalizado_Fecha_Entrega);
          if (item.Tipo_Flete === 'MARÍTIMO') {
            fechaTerminoPagoComision = new Date(fechaArribo.getTime() + terminoPago * 24 * 60 * 60 * 1000);
          }
          else if (item.Tipo_Flete === 'TERRESTRE') {
            fechaTerminoPagoComision = new Date(fechaEmbarquePersonalizadoEntrega.getTime() + terminoPago * 24 * 60 * 60 * 1000);
          }
          return {
              ...item,
              fecha_Termino_Pago: fechaTerminoPago.toISOString(),
              fecha_Termino_Pago_Comision: fechaTerminoPagoComision.toISOString()
          };
      });
        data.push(...responseData);
      }

      const porcentajes = [
        {min: 0, max: 2999.99, porcentaje: 0},
        {min: 3000, max: 4999.99, porcentaje: 0.05},
        {min: 5000, max: 7999.99, porcentaje: 0.07},
        {min: 8000, max: 1000000, porcentaje: 0.1},
      ];

      const total = data.reduce((acc: number, comision: any) => {
        const ganancia = comision.Venta_USD + comision.Compra_USD;
        return acc + ganancia;
      }, 0);

      const porcentaje = porcentajes.find((p) => total >= p.min && total <= p.max) || {porcentaje: 0};

      setComisiones(data.map((comision: any) => {
        const totalComision = (((comision.Venta_MXN + comision.Compra_MXN)) - comision.Puntos_LF_MXN) * porcentaje?.porcentaje!;
        return {
          ...comision,
          Porcentaje: porcentaje?.porcentaje,
          Total_Comision: totalComision
        }
      }));
      setLoading(false);
    }
    fetchData();
  }

  return (
    <div className="container-comisiones_modules">
      {
        loading ? 
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '100px'}}>
          <div className="icon loading-icon loading-size-lg"></div>
        </div>
         :
        <>
          <MenuFilters>
            <div className="container-filters">
              <FilterSearchMultipleGrid
                  items={vendedores}
                  onChange={handlerOnSelectVendedor}
                  keyFilter={"vendedorCotizacion"}
                  selectionState={vendedores.map((v) => v.selectionState)}
                  title={"Vendedores"}
              />

              <FechaFilters
                fecha={fecha}
                onValueChanged={handlerOnChangeFecha}
              />
            
            <Button
            text={loading ? `Buscando...` : "Buscar"}
            icon="fas fa-search-dollar"
            iconHover='Claro'
            onClick={aplyFilters}
            disabled={loading}

            />
          </div>
          </MenuFilters>
          <GridComisionesPendientesVendedores
            keyStateStoraged="ComisionesPendientesGrid"
            comisionesSource={comisiones}
            onStartEditing={validateEditingComision}
            onSyncClick={handlerOnSyncEmbarque}
            isSynchronizing={loading}
          />
        </>
      }
    </div>
  )
}


export default ComisionesPendientesVendedor