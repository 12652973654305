import { Select, FilterMultipleGrid, Button } from 'components/Index';
import { useFacturacionAnualProveedor } from './hooks/useFacturacionAnualProveedor';
import { _FieldFacturacionProveedores } from 'functions/Facturacion';
import PivotProveedores from './components/PivotProveedores';
import ErrorPage from 'pages/Error';
import MenuFilters from 'components/MenuFilters';
import './styles/estilos.scss';

const FacturacionAnualProveedor = () => {

  const {
    catalogos,
    facturas,
    handlerOnSelectChange,
    handleFiltrado,
    onExporting
  } = useFacturacionAnualProveedor();

  const { loading } = facturas;

  const showFilters = () => {
    const añosFactura = catalogos.años;
    const proveedores = catalogos.proveedores;
    const moneda = catalogos.moneda;

    return (
      <MenuFilters>
        {
          catalogos.loading ? (
            <p>Cargando...</p>
          ) : (
            <div className='container-filters'>
              <FilterMultipleGrid
                items={añosFactura?.items}
                onChange={handlerOnSelectChange}
                keyFilter={añosFactura?.key}
                selectionState={añosFactura?.selectionState}
                title={añosFactura?.title}
              />
              <FilterMultipleGrid
                items={proveedores?.items}
                onChange={handlerOnSelectChange}
                keyFilter={proveedores?.key}
                selectionState={proveedores?.selectionState}
                title={proveedores?.title}
              />
              <Select
                item={moneda}
                key={moneda?.key}
                onChangeSelect={handlerOnSelectChange}
              />
              <Button
                onClick={() => handleFiltrado()}
                text={facturas.loading ? "Filtrando..." : "Filtrar"}
                icon='fas fa-search'
                disabled={loading}
                className="btnFilter"
                width={"100%"}
                iconHover="Claro"
              />
            </div>
          )
        }
      </MenuFilters>
    )
  }

  if (facturas.error) {
    return <ErrorPage errorMessage={facturas.error} />
  }

  return (
    <div className='container-proveedoresModules'>
      {showFilters()}
      <PivotProveedores
        dataProveedores={facturas}
        fields={_FieldFacturacionProveedores}
        keyLocalStorage='pivotFacturacionProveedores'
        onExporting={onExporting}
      />
    </div >
  )
}

export default FacturacionAnualProveedor