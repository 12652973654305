import { useMemo, useState, createContext, ReactElement, useContext, useCallback, useEffect } from 'react';
import { useAuth } from 'api/auth';
import { Route, routes } from 'routes/routes';
import { DEFAULT_ROUTE, DEFAULT_PAGE_NAME } from 'consts/User';
import { KEY_ITEM_SESSION, KEY_SUBITEM_SESSION, StateMenu } from 'consts/Home';

interface IContextMenu {
  handlerCloseMenu: () => void;
  isOpenMenu: boolean | null;
  menuItems: Route[];
  subMenuItems: Route[];
  handlerItemsMenu: (route: Route, navigate: () => void) => void;
  handlerSubItemsMenu: (route: Route, navigate: () => void) => void;
  itemSelected: Route;
  subItemSelected?: Route | null;
  pageRendered: string;
}
export const MenuContext = createContext({} as IContextMenu);
const { Provider } = MenuContext;

interface IProps {
  children: ReactElement | ReactElement[];
}

let pageName: string = DEFAULT_PAGE_NAME;

export const MenuProvider = ({ children }: IProps) => {
  const { User } = useAuth();
  const [isOpenMenu, setIsOpenMenu] = useState<boolean | null>(null);
  const [subMenuItems, setSubMenuItems] = useState<Route[]>([]);
  const [itemSelected, setItemSelected] = useState<Route>(routes[0]);
  const [subItemSelected, setSubItemSelected] = useState<Route | null>();

  const menuItems: Route[] = useMemo(() => {
    const permisos: string[] = [...User.permisos || [], DEFAULT_ROUTE];
    let firstNavigation: Route[] = [];
    permisos.forEach(permiso => {
      const accessFirstRoutes: Route[] = routes
        .filter((route) => route.name === permiso)
        .map((route) => {
          let secondNavigation: Route[] = [];
          permisos.forEach(permiso => {
            const acessSecondRoutes: Route[] = (route.items?.filter((item) => item.name === permiso) || [])
              .map((subRoute) => {
                let thirdNavigation: Route[] = [];
                permisos.forEach(permiso => {
                  const acessThirdRoutes: Route[] = (subRoute.items?.filter((item) => item.name === permiso) || []);
                  thirdNavigation = ([...thirdNavigation, ...acessThirdRoutes]).sort((a, b) => a.id - b.id);
                })
                return {
                  ...subRoute,
                  items: thirdNavigation
                }
              });
            secondNavigation = ([...secondNavigation, ...acessSecondRoutes]).sort((a, b) => a.id - b.id);
          });
          return {
            ...route,
            items: secondNavigation
          }
        });
      firstNavigation = [...firstNavigation, ...accessFirstRoutes];
    });
    return firstNavigation.sort((a, b) => a.id - b.id);
  }, [User.permisos]);

  const handlerCloseMenu = useCallback(() => {
    isOpenMenu && setIsOpenMenu(null);   
  }, [isOpenMenu]);


  const saveRoutesOnSession = useCallback((route: Route, key: StateMenu) => {
    sessionStorage.setItem(key, JSON.stringify(route));
  }, []);

  const handlerItemsMenu = (route: Route, navigate: () => void) => {
    const historyItem: Route = itemSelected;
    setItemSelected(route);
    saveRoutesOnSession(route, "MENU_STATE");
    const { items } = route;
    setSubMenuItems(items || []);
    /**VERIFICARMOS LOS SUBITEMS */
    if (items?.length === 0) {
      setSubItemSelected(null);
      setIsOpenMenu(null);
      pageName = route?.pageName || "";
      navigate();
      return
    }
    if (isOpenMenu === null) {
      setIsOpenMenu(true);
      return
    }
    if (route.id === historyItem.id) {
      setIsOpenMenu(prev => !prev);
      return
    }
    !isOpenMenu && setIsOpenMenu(true);

  };

  const handlerSubItemsMenu = useCallback((route: Route, navigate: () => void) => {
    saveRoutesOnSession(route, "SUBMENU_STATE");
    setSubItemSelected(route);
    pageName = route?.pageName || "";
    navigate();
  }, [saveRoutesOnSession]);

  const values: IContextMenu = {
    handlerCloseMenu,
    isOpenMenu,
    menuItems,
    subMenuItems,
    handlerItemsMenu,
    handlerSubItemsMenu,
    itemSelected,
    subItemSelected,
    pageRendered: pageName
  }

  useEffect(() => {
    let hasChildrens: boolean = false;
    if (sessionStorage.getItem(KEY_ITEM_SESSION)) {
      const menuRoute: Route = JSON.parse(sessionStorage.getItem(KEY_ITEM_SESSION)!);
      setItemSelected(menuRoute);
      hasChildrens = menuRoute.items!.length > 0;
      /*VERIFICAS QUE TENGAS GUARDADA LAS SUBRUTAS */
      if (sessionStorage.getItem(KEY_SUBITEM_SESSION)) {
        const subMenuRoute: Route = JSON.parse(sessionStorage.getItem(KEY_SUBITEM_SESSION)!);
        /*VERIFICAS QUE LA RUTA PRINCIPAL TENGA SUBRUTAS */
        if (!hasChildrens) {
          pageName = menuRoute?.pageName || "";
          sessionStorage.removeItem(KEY_SUBITEM_SESSION);
          return
        }
        pageName = subMenuRoute?.pageName || "";
        setSubItemSelected(subMenuRoute);
      }
    }
  }, []);

  useEffect(() => {
    if (!User.isActive) {
      setItemSelected(routes[0]);
      setSubItemSelected(null);
      pageName = DEFAULT_PAGE_NAME;
    }
    setIsOpenMenu(null);
  }, [User.isActive]);

  return (
    <Provider
      value={values}
    >
      {children}
    </Provider>
  )

}

export const useMenu = () => {
  return useContext(MenuContext);
}