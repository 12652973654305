import GridEstrategias from './components/GridEstrategias'
import { useEstrategias } from './hooks/useEstrategias'

const EstrategiasComerciales = () => {

    const {
        configuracionEstrategias,
        catalogosEstrategias,
        onSavedEstrategias
    } = useEstrategias()
    return (
        <>
            <div className="container-configuracionModules">               
                <hr />
                <br />
                <GridEstrategias 
                    dataTable={configuracionEstrategias} 
                    catalogos={catalogosEstrategias}
                    onSaved={onSavedEstrategias}
                />
            </div>
        </>
    )
}

export default EstrategiasComerciales