import { _fieldsPivotOpsTerrestres } from 'api/operaciones';
import { Button } from 'components/Index';
import MenuFilters from 'components/MenuFilters';
import FiltersOperaciones from './components/FiltersOperaciones';
import PivotOperaciones from './components/PivotOperaciones';
import { useOpsTerrestres } from './hooks/useOpsTerrestres';
import './styles/estilos.scss';

const OpsTerrestres = () => {

  const {
    catalogos,
    operacionesTerrestres,
    handleFiltrado,
    handlerOnSelectChange,
    onExporting
  } = useOpsTerrestres();

  const { loading } = operacionesTerrestres;

  return (
    <div className='container-operaciones'>
      <MenuFilters>
        <FiltersOperaciones
          catalogos={catalogos}
          handlerOnSelectChange={handlerOnSelectChange}
          itemsByRow={1}
        >
          <Button
            onClick={() => handleFiltrado()}
            text={loading ? "Buscando..." : "Filtrar OPS"}
            icon='fas fa-search'
            disabled={loading}
            className="btnFilter"
            width={"100%"}
            iconHover="Claro"
          />
        </FiltersOperaciones>
      </MenuFilters>
      <PivotOperaciones
        dataOperaciones={operacionesTerrestres}
        fields={_fieldsPivotOpsTerrestres}
        keyLocalStorage='PivotOpsTerrestres'
        onExporting={onExporting}
      />
    </div>
  )
}

export default OpsTerrestres