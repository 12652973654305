import { useCallback, useState } from "react";
import { useMemo, useRef } from 'react';
import { useAuth } from "api/auth";
import { IGetDataDetalleEmbarque } from "interfaces/IClientes";
import { ClientesService } from "api/clientes";
import { ClientNotify, ClientStatus, ProfitSyncMessage } from 'consts/Clientes';
import notify from 'devextreme/ui/notify';

export interface IUseSyncEmbarque {
  detalleEmbarque?: IGetDataDetalleEmbarque;
  numeroEmbarque: string;
  estatus: ClientStatus;
  error: string;
  cleanData: () => void;
  handlerOnChangeEmbarque: (value: string) => void;
  onClickSearchEmbarque: () => Promise<void>;
  handlerOnSyncEmbarque: () => Promise<void>;
  messageSync: ProfitSyncMessage;
}

export const useSyncEmbarque = (): IUseSyncEmbarque => {
  const { User } = useAuth();
  const [detalleEmbarque, setDetalleEmbarque] = useState<IGetDataDetalleEmbarque>();
  const [estatus, setEstatus] = useState<ClientStatus>(ClientStatus.Stop);
  const [numeroEmbarque, setNumeroEmbarque] = useState<string>("");
  const [messageSync, setMessageSync] = useState<ProfitSyncMessage>(ProfitSyncMessage.Sync)
  const error = useRef<string>("");

  const clientesService = useMemo(() => {
    return ClientesService(User);
  }, [User]);

  const onClickSearchEmbarque = useCallback(async (): Promise<void> => {
    error.current = "";
    setEstatus(ClientStatus.Searching);
    try {
      if (!numeroEmbarque.trim()) {
        error.current = ClientNotify.EmptyNumberShipment;
        setEstatus(ClientStatus.Stop);
        return
      }
      const infoEmbarque = await clientesService.getInfoEmbarque(numeroEmbarque);
      if (!infoEmbarque.result) {
        error.current = ClientNotify.ErrorGetDataShipment;
      }
      if (infoEmbarque.result) {
        const { detalleEmbarque, facturacionCompra, facturacionVenta } = infoEmbarque.data!;
        setDetalleEmbarque({ detalleEmbarque, facturacionCompra, facturacionVenta });
      }
      setEstatus(ClientStatus.Stop);
    } catch (exception: any) {
      error.current = ClientNotify.ErrorGetDataShipment;
      setEstatus(ClientStatus.Stop);
    }

  }, [numeroEmbarque, clientesService]);

  const handlerOnChangeEmbarque = (value: string): void => {
    setNumeroEmbarque(value);
  }

  const cleanData = () => {
    setNumeroEmbarque("");
    setDetalleEmbarque({ facturacionCompra: [], facturacionVenta: [] });
  }

  const handlerOnSyncEmbarque = useCallback(async (): Promise<void> => {
    error.current = "";
    setEstatus(ClientStatus.Synchronizing);
    try {
      setMessageSync(ProfitSyncMessage.Synchronizing);
      const { embarque_Guid } = detalleEmbarque!.detalleEmbarque!;
      const wasSynchronizedShipment = await clientesService.syncComission(embarque_Guid);
      if (!wasSynchronizedShipment) {
        error.current = ClientNotify.ErrorSyncShipment;
      }
      if (wasSynchronizedShipment) {
        setMessageSync(ProfitSyncMessage.Updating);
        const wasUpdatedShipment = await clientesService.updateComission(embarque_Guid);
        if (!wasUpdatedShipment) {
          error.current = ClientNotify.ErrorUpdateShipment;
          return
        }
        notify(ClientNotify.SuccessSyncShipment, "success", 2000);
        onClickSearchEmbarque();
      }
      setMessageSync(ProfitSyncMessage.Sync);
      setEstatus(ClientStatus.Stop);
    } catch (err: any) {
      error.current = ClientNotify.ErrorSyncShipment;
      setMessageSync(ProfitSyncMessage.Sync);
      setEstatus(ClientStatus.Stop);
    }
  }, [clientesService, detalleEmbarque,onClickSearchEmbarque]);

  return {
    detalleEmbarque,
    handlerOnChangeEmbarque,
    onClickSearchEmbarque,
    estatus,
    error: error.current,
    numeroEmbarque,
    cleanData,
    handlerOnSyncEmbarque,
    messageSync
  }
}