import SWR from "swr";
import { fetcherWithToken, fetcher } from "utils/Fetcher";


export const useSWRWithToken = (url: string, refresh: number = 0, token: string) => {

  const { data, error, mutate } = SWR([url, token], fetcherWithToken, { refreshInterval: refresh })

  return {
    data,
    error,
    loading: !error && !data,
    mutate
  }
}

export const useSWR = (url: string, refresh: number) => {
  const { data, error, mutate } = SWR(url, fetcher, { refreshInterval: refresh })

  return {
    data,
    error,
    loading: !error && !data,
    mutate
  }
}