import { createContext, useState, ReactElement, useContext, useEffect } from 'react';
import { useAuth } from "api/auth";
import { URL_API_GENERAL } from "consts/Services_URL";
import { useSWRWithToken } from "hooks/useSWR";
import { IBudgetEstrategia } from "interfaces/IHome"

interface IContextBudgetEstrategia {
  estrategiasSelected?: IBudgetEstrategia | null;
  handlerOnSelectEstrategia: (estrategia: IBudgetEstrategia) => void;
  estrategias: IBudgetEstrategia[];
  loading: boolean;
}

export const BudgetEstrategiasContext = createContext({} as IContextBudgetEstrategia);
const { Provider } = BudgetEstrategiasContext;

interface IProps {
  children: ReactElement | ReactElement[];
}

export const BudgetProvider = ({ children }: IProps) => {
  const { User } = useAuth();
  const [estrategiasSelected, setEstrategiaSelected] = useState<IBudgetEstrategia | null>();
  const { data, loading } =
    useSWRWithToken(`${URL_API_GENERAL}budgetEstrategias?division=${User.divisionSeleccionada}`, 0, User.token!);


  const handlerOnSelectEstrategia = (estrategia: IBudgetEstrategia): void => {
    setEstrategiaSelected(estrategia);
  }

  useEffect(() => {
    setEstrategiaSelected(null);
  }, [User]);

  return (
    <Provider
      value={{
        estrategiasSelected,
        handlerOnSelectEstrategia,
        estrategias: data || [],
        loading
      }}
    >
      {children}
    </Provider>
  )

}

export const useBudgetEstrategias = () => {
  return useContext(BudgetEstrategiasContext);
}