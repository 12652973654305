import Button from 'components/Button'
import TextBox from 'components/TextBox'
import { Popup, ScrollView } from 'devextreme-react'
import { ToolbarItem } from 'devextreme-react/popover'
import { ajustarDecimales, formatoDecimales } from 'utils/Formatos';
import { useSyncEmbarque } from '../hooks/useSyncEmbarque';
import { ClientStatus } from 'consts/Clientes';
import DataGridFacturas from './DataGridFacturas'

const ModalDetalleOperacionProfit = ({ onCloseModal: closeModal, status }) => {

  const {
    detalleEmbarque,
    handlerOnChangeEmbarque,
    onClickSearchEmbarque,
    error,
    cleanData,
    estatus,
    numeroEmbarque,
    handlerOnSyncEmbarque,
    messageSync
  } = useSyncEmbarque();

  const onCloseModal = () => {
    cleanData();
    closeModal();
  }

  const optionsBtnClose = {
    text: "Cerrar",
    type: "danger",
    icon: "close",
    width: 200,
    onClick: onCloseModal
  }

  const onSyncDate = () => {

    if (ClientStatus.Synchronizing === estatus ||
      ClientStatus.Updating === estatus ||
      ClientStatus.Searching) {   
      handlerOnSyncEmbarque()
    }
  }

  const optionsBtnSincronizar = {
    text: messageSync,
    type: "success",
    stylingMode: 'outlined',
    icon: ClientStatus.Synchronizing === estatus || ClientStatus.Updating === estatus ? "loading-icon loading-size" : "fas fa-sync",
    width: 300,
    onClick: () => onSyncDate(),
    disabled: !detalleEmbarque?.detalleEmbarque
  }

  const renderTitle = () => {
    return (
      <h3 style={{ margin: "-0.2rem" }}>
        Detalle de operación
      </h3>
    )
  }

  return (
    <Popup
      visible={status}
      width={"80%"}
      height={"auto"}
      maxHeight={"90%"}
      dragEnabled={false}
      closeOnOutsideClick={false}
      showCloseButton={false}
      titleRender={renderTitle}
    >
      <ScrollView >
        <div className="container-clientesModules">
          <div className='container-detailFilter'>
            <TextBox
              className='container-input-detail'
              placeholder="Número de embarque"
              height={40}
              value={numeroEmbarque}
              onChange={(e) => handlerOnChangeEmbarque(e)}
              disabled={ClientStatus.Synchronizing === estatus || ClientStatus.Updating === estatus}
            />
            <Button
              text={estatus === ClientStatus.Searching ? "Buscando..." : "Buscar"}
              type='default'
              icon='fas fa-search'
              width={"15%"}
              iconHover={"Claro"}
              onClick={(e) => onClickSearchEmbarque(e)}
              disabled={ClientStatus.Synchronizing === estatus || ClientStatus.Updating === estatus}
            />
            <p className='errorMessage'>{error}</p>
          </div>
          <hr />
          <div className='infoEmbarque'>
            <div className='datos'>
              {ShowInforEmbarque(detalleEmbarque?.detalleEmbarque)}
            </div>
            <div className='profit'>
              {
                ShowProfit(detalleEmbarque?.facturacionCompra, detalleEmbarque?.facturacionVenta)
              }
            </div>
          </div>
          <hr />
        </div>
        <div>
          {
            detalleEmbarque?.detalleEmbarque && (
              <DataGridFacturas
                facturaVentas={detalleEmbarque?.facturacionVenta}
                facturaCompras={detalleEmbarque?.facturacionCompra}
              />
            )

          }
        </div>
      </ScrollView>
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={(optionsBtnSincronizar)}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={optionsBtnClose}
      />
    </Popup>
  )
}

const ShowInforEmbarque = (Informacion) => {
  /**SI NO HAY INFORMACION NO MOSTRAMOS NADA */
  if (!Informacion) { return }

  const { cliente, cotizacion_Vendedor, division_de_Empresa, embarque_Fecha_Creacion,
    embarque_Numero, fecha_Salida_PickUp, ruta } = Informacion;
  return (
    <>
      <p>Embarque: <span>{embarque_Numero}</span></p>
      <p>Fecha Salida : <span>{new Date(fecha_Salida_PickUp).toLocaleDateString()}</span></p>
      <p>Fecha Creación: <span>{new Date(embarque_Fecha_Creacion).toLocaleDateString()}</span></p>
      <p>Ruta : <span>{ruta}</span></p>
      <p>Cliente : <span>{cliente}</span></p>
      <p>Division : <span>{division_de_Empresa}</span></p>
      <p>Vendedor : <span>{cotizacion_Vendedor}</span></p>
    </>
  )
}

const ShowProfit = (FacturasCompra = [], FacturasVenta = []) => {
  const { ProfitUSD, ProfitMXN } = CalcularProfitEmbarque(FacturasVenta, FacturasCompra);
  return (
    <>
      <p>Profit USD: <span className={ProfitUSD <= 0 ? 'negativo' : 'positivo'}>$ {ajustarDecimales(ProfitUSD)}</span></p>
      <p>Profit MXN: <span className={ProfitMXN <= 0 ? 'negativo' : 'positivo'}>$ {ajustarDecimales(ProfitMXN)}</span></p>
    </>
  )
}

export const CalcularProfitEmbarque = (facturasVenta = [], facturasCompra = []) => {
  let totalCompraUSD = 0;
  let totalCompraMXN = 0;
  let totalVentaUSD = 0;
  let totalVentaMXN = 0;
  facturasVenta.forEach((factura) => {
    const { monto_MXN, monto_USD } = factura;
    totalVentaMXN += monto_MXN;
    totalVentaUSD += monto_USD;
  });
  facturasCompra.forEach((factura) => {
    const { monto_MXN, monto_USD } = factura;
    totalCompraMXN += monto_MXN;
    totalCompraUSD += monto_USD;
  });
  return { ProfitUSD: formatoDecimales((totalVentaUSD - totalCompraUSD), 2), ProfitMXN: formatoDecimales((totalVentaMXN - totalCompraMXN), 2) };
}

export default ModalDetalleOperacionProfit