import { useAuth } from 'api/auth';
import { columnsConfComisionesFijas } from 'api/configuracion';
import TableGrid, { IOnSaved } from 'components/TableGrid';
import { URL_API_CF } from 'consts/Services_URL';
import { useSWRWithToken } from 'hooks/useSWR';
import { IRemoteConfiguracionComisionesFijas } from 'interfaces/IConfiguracion';
import { IRemoteEmpleado } from 'interfaces/ITalentoHumano'
import { memo, useMemo } from 'react'

interface IProps {
  empleados: IRemoteEmpleado[];
  onSavedCFija: (data: IRemoteConfiguracionComisionesFijas, action: string) => void
}

const GridComisionFija = (Props: IProps) => {
  const {User} = useAuth();
  const {data: comisionesFijas=[]} = useSWRWithToken(`${URL_API_CF}ConfigComisiones/porcentajeFijo`,10, User.token!)

  const {
    empleados, 
    // comisionesFijas, 
    onSavedCFija
  } = Props

  const columsGrid = useMemo(() =>{
    return columnsConfComisionesFijas(empleados);
  },[empleados]);

  const onSaved = (e:IOnSaved[]) =>{
    if(e.length === 0){
        return
    }
    const {data, type, key} = e[0];
    switch(type){
        case 'insert':
          onSavedCFija(data, 'insert')
          break;
        case 'update':
          onSavedCFija(data, 'update')
          break;
        case 'remove':
          onSavedCFija(key, 'remove')
          break;
        default:
          return
    }
  }

  return (
    <div className='card-container'>
      <h2>Comisión Fija</h2>
      <TableGrid
          columns={columsGrid}
          dataSource={comisionesFijas}
          enableEditing={{
            modeEdit: "row", 
            showIcons: true,
            enableInsert: true,
            enableDelete: true,
            enableUpdating: true,
            onSavedChanges:onSaved
        }}
      />
    </div>
  )
}

export default memo(GridComisionFija)