import { Navigate, Route, Routes } from 'react-router-dom';
import { routes } from './routes'
import { useAuth } from 'api/auth';
import { useMemo } from 'react';
import NotFound from 'pages/NotFound';
import { DEFAULT_ROUTE } from 'consts/User';

const Navigation = () => {

  const { User } = useAuth();

  const routesUser = useMemo(() => {
    return routes.filter((route) => User.permisos?.includes(route.name) || route.name === DEFAULT_ROUTE);
  }, [User]);

  return (
    <Routes>
      {
        routesUser.map(({ path, Component }) => (
          <Route path={path} element={
            <Component />
          } key={path} />
        ))
      }
      <Route path='/' element={<Navigate to='/home' replace={true} />} />
      <Route path='/*' element={<NotFound />} />
    </Routes>
  )
}

export default Navigation