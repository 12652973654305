import { IRemoteDivisiones } from "interfaces/IConfiguracion"
import { IRemotePermisos } from "interfaces/IIt"

export const divisionAdapter = (division: IRemoteDivisiones) => {
  const divisionAdapt = {
    cMotion_Division_Id: division.divisionId,
    cMotion_Division_Nombre: division.nombreDivision,
    cMotion_Division_Razon_Social: division.razonSocial
  }
  return divisionAdapt
}

export const permisoAdapter = (permiso: IRemotePermisos) => {
  const divisionAdapt = {
    permisoId: permiso.permisoId,
    PermisoDescripcion: permiso.permisoDescripcion
  }
  return divisionAdapt
}