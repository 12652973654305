import { Button, DateBox } from "devextreme-react";
import moment from "moment";

const FormComparativoFilter = ({
  date,
  setDate,
}: any) => {
  return (
    <div className="container-filters">
      <form>
        <h2>Filtrar Datos</h2>
        <div className="label_input_task">
          <label htmlFor="Fecha_Log_Magaya">Fecha Log Magaya</label>
          <DateBox
            type="date"
            pickerType="calendar"
            name="Fecha_Log_Magaya"
            className="date_input_task"
            value={date}
            onValueChanged={(e) => setDate(moment(e.value).format("YYYY-MM-DD"))}
          />
        </div>
      </form>
    </div>
  );
};

export default FormComparativoFilter;
