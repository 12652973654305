import { useAuth } from "api/auth";
import { EmbarquesService } from "api/embarques";
import { IGetDataEmbarques, IGetDataDesgloseServicios, IDesgloseServiciosEmbarque } from "interfaces/IEmbarques"
import { useLayoutEffect, useMemo, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from "../../../config/firebase.config";
import pdfmake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { desgloseServiciosEmbarques } from "../../../utils/Pdf"

pdfmake.vfs = pdfFonts.pdfMake.vfs;

interface IUseEmbarques {
    dataEmbarques : IGetDataEmbarques;
    desgloseServicios: IGetDataDesgloseServicios;
    generatePdf: (guid: string) => Promise<void>;
}

export const useShipments = (): IUseEmbarques => {
    const [dataEmbarques, setDataEmbarques] = useState<IGetDataEmbarques>({ loading: false, data: [] });
    const [desgloseServicios, setDesgloseServicios] = useState<IGetDataDesgloseServicios>({ loading: false, data: [] });
    const {User} = useAuth();

    const embarquesService = useMemo(() => {
        return EmbarquesService(User);
    },[User])

    useLayoutEffect(() => {
        const getEmbarquesDCPendientes = async (): Promise<void> => {
            setDataEmbarques({ loading: true });
            const { data } = await embarquesService.getEmbarquesDocumentosContablesPendientes();

            setDataEmbarques({ loading: false, data });
        }
        getEmbarquesDCPendientes();
    },[embarquesService])

    const generatePdf = async (numero_factura: string): Promise<void> => {
        setDesgloseServicios({ loading: true });
        const data = await embarquesService.getDesgloseServiciosEmbarque(numero_factura) as unknown as IDesgloseServiciosEmbarque[];
        const pdfDefinition = await desgloseServiciosEmbarques((data as any).data) as any;
        const pdf = pdfmake.createPdf(pdfDefinition);
        pdf.getBase64(async (data) => {
            const base64ToUint8Array = (base64: string) => {
                const binaryString = window.atob(base64);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                return bytes;
            };
            
            const fileName = `pdf/DesgloseServicios_${numero_factura}.pdf`;
            const storageRef = ref(storage, fileName);
            
            const bytes = base64ToUint8Array(data);
            await uploadBytes(storageRef, bytes);
            
            const url = await getDownloadURL(storageRef);
            window.open(url, '_blank');
        });

        setDesgloseServicios({ loading: false, data: undefined });
    }
    return {
        dataEmbarques,
        desgloseServicios,
        generatePdf
    }
}