import GridSyncGuid from 'pages/talentoHumano/components/GridSyncGuid';

const Sinc = () => {

    return (
        <div className='container-modulo-th'>
            <div className='container-grid'>
                <GridSyncGuid/>
            </div>
            <div className='container-grid'>
                <GridSyncGuid isDeleted={true}/>
            </div>
        </div>
    )
}

export default Sinc