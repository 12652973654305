import { Meses } from "utils/Global";
import { IPeriodos } from 'interfaces/ITalentoHumano';
import moment from 'moment';

interface IFunctionPeriodos {
  addPeriodo: (fecha: Date) => void;
  getPeriodo: (fecha: Date) => IPeriodos;
  getAll: () => IPeriodos[];
}

interface IValidarMes {
  mes: number;
  año: number;
}

export const PeriodosService = () => {

  //FUNCION PARA GUARDAR LOS PERIODOS QUINCENALES
  const PeriodosQuincenales = (): IFunctionPeriodos => {
    const periodos: IPeriodos[] = [];

    const calcularRangoQuincenal = (fecha: Date): IPeriodos => {
      const dia: number = moment(fecha).date();
      const año: number = moment(fecha).year();
      const mes: number = moment(fecha).month();
      if (dia <= 15) {
        return { inicial: new Date(año, mes, 1), final: new Date(año, mes, 15) };
      }
      // ultimo dia del mes en curso
      const fechaSiguiente: Date = moment(fecha).add(1, 'months').date(0).toDate();
      return { inicial: new Date(año, mes, 16), final: new Date(año, mes, fechaSiguiente.getDate()) }
    }

    return {
      addPeriodo: (fecha: Date): void => {
        const { inicial, final } = calcularRangoQuincenal(fecha);
        const periodo = `${inicial.getDate()} ${Meses[inicial.getMonth()]} ${inicial.getUTCFullYear()} al ${final.getDate()} ${Meses[final.getMonth()]} ${final.getUTCFullYear()}`;
        periodos.push({ inicial, final, periodo });
      },
      getPeriodo: (fecha: Date): IPeriodos => {
        return calcularRangoQuincenal(fecha);
      },
      getAll: (): IPeriodos[] => periodos
    }
  }

  //FUNCION PARA GUARDAR LOS PERIODOS SEMANALES
  const PeridosSemanales = (diaPago: number): IFunctionPeriodos => {
    const periodos: IPeriodos[] = [];

    const calcularRangoSemanal = (diaPago: number, fecha: Date): IPeriodos => {
      const TOTAL_DIAS_SEMANAL: number = 6;
      const diaSemana: number = fecha.getDay();
      const diasFaltantesPago: number = diaPago - diaSemana;
      let diaComienzaSemana: number = 0;
      if (diasFaltantesPago < 0) {
        diaComienzaSemana = fecha.getDate() - ((diasFaltantesPago + 1) * -1);
      }
      if (diasFaltantesPago >= 0) {
        diaComienzaSemana = fecha.getDate() - (TOTAL_DIAS_SEMANAL - diasFaltantesPago);
      }
      const fechaSemanalInicial: Date = new Date(fecha.setDate(diaComienzaSemana));
      const fechaSemanaFinal: Date = new Date(fecha.setDate(fechaSemanalInicial.getDate() + TOTAL_DIAS_SEMANAL));
      return {
        inicial: new Date(fechaSemanalInicial.getUTCFullYear(), fechaSemanalInicial.getMonth(), fechaSemanalInicial.getDate()),
        final: new Date(fechaSemanaFinal.getUTCFullYear(), fechaSemanaFinal.getMonth(), fechaSemanaFinal.getDate())
      }
    }

    return {
      addPeriodo: (fecha: Date): void => {
        const { inicial, final } = calcularRangoSemanal(diaPago, fecha);
        const periodo = `${inicial.getDate()} ${Meses[inicial.getMonth()]} ${inicial.getUTCFullYear()} al ${final.getDate()} ${Meses[final.getMonth()]} ${final.getUTCFullYear()}`;
        periodos.push({ inicial, final, periodo });
      },
      getPeriodo: (fecha): IPeriodos => {
        return calcularRangoSemanal(diaPago, fecha);
      },
      getAll: (): IPeriodos[] => periodos
    }
  }

  //FUNCION PARA GUARDAR LOS PERIODOS MENSUALES
  const PeriodosMensuales = (): IPeriodos[] => {
    // obtener un año atrás
    console.log('PeriodosMensuales');
    const fechaActual: Date = new Date();
    const periodos: IPeriodos[] = [];

    for (let i = 0; i < 12; i++) {
      const { mes, año } = validarUltimoMes(fechaActual.getMonth() - i, fechaActual.getFullYear());
      const fechaInicial: Date = new Date(año, mes, 1);
      const fechaFinal: Date = new Date(año, mes, new Date(año, mes + 1, 0).getDate());
      periodos.push({
        inicial: fechaInicial,
        final: fechaFinal,
        periodo: `${Meses[fechaInicial.getMonth()]} ${fechaFinal.getUTCFullYear()}`
      });
    }

    return periodos;
  }

  //FUNCION PARA VALIDAR EL ULTIMO MES AÑO
  const validarUltimoMes = (mes: number, año: number): IValidarMes => {
    if (mes < 0) return { mes: mes + 12, año: año - 1 };
    if (mes > 11) return { mes: mes - 12, año: año + 1 };
    return { mes: mes, año: año };
  }

  return {
    getPeriodosQuincenales: (): IPeriodos[] => {
      const periodos = PeriodosQuincenales();
      console.log('PeriodosQuincenales');
      console.log(periodos);
      console.log(periodos.getAll());
      const fechaActual: Date = new Date();
      const fechaFinal: Date = new Date(new Date().setDate(fechaActual.getDate() + 16));
      const fechaInicial: Date = new Date(new Date().setDate(fechaActual.getDate() - 16));
      periodos.addPeriodo(fechaInicial);
      periodos.addPeriodo(fechaActual);
      periodos.addPeriodo(fechaFinal);
      return periodos.getAll();
    },
    getPeriodosSemanales: (DIA_PAGO_SEMANAL: number): IPeriodos[] => {
      const fechaActual: Date = new Date();
      const periodos = PeridosSemanales(DIA_PAGO_SEMANAL);
      const fechaFinal: Date = new Date(new Date().setDate(fechaActual.getDate() + 7));
      const fechaInicial: Date = new Date(new Date().setDate(fechaActual.getDate() - 7));
      periodos.addPeriodo(fechaInicial);
      periodos.addPeriodo(fechaActual);
      periodos.addPeriodo(fechaFinal);
      return periodos.getAll();
      /*let fechaInicio: Date = new Date(new Date().setDate(new Date().getDate() - 7));
      const fechaFin: Date = new Date(new Date().setDate(new Date().getDate() + 14));

      while (fechaInicio < fechaFin) {
        periodos.addPeriodo(fechaInicio);
        fechaInicio = new Date(fechaInicio.setDate(fechaInicio.getDate() + 7));
      }
      return periodos.getAll();*/

    },
    getPeriodosMensuales: (): IPeriodos[] => {
      return PeriodosMensuales();
    },
    getPeriodoAnual: (): IPeriodos[] => {
      const fechaActual = new Date();
      return [{
        inicial: new Date(fechaActual.getUTCFullYear(), 1, 1, 0, 0, 0),
        final: new Date(fechaActual.getUTCFullYear(), 12, 31, 0, 0, 0),
        periodo: `Año ${fechaActual.getUTCFullYear()}`
      }];
    },
    validarDiasDescanso: (fechaPago: Date): number => {
      const SABADO: number = 6;
      const DOMINGO: number = 0;
      const diaSemana: number = fechaPago.getUTCDay();
      if (diaSemana === SABADO) return fechaPago.getDate() - 1;
      if (diaSemana === DOMINGO) return fechaPago.getDate() - 2;
      return fechaPago.getDate();
    }
  }
}

