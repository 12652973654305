import FilterMultipleGrid from "components/FilterMultipleGrid";
import { TableGrid, Button } from "components/Index"
import { useEstadoCuenta } from './hooks/useEstadoCuenta';
import { columsEdoCuenta } from "api/clientes";
import MenuFilters from "components/MenuFilters";

const EstadoCuenta = () => {
  const {
    estadosCuenta,
    catalogos,
    handlerFiltrado,
    handlerOnSelectChange,
    onExporting
  }
   = useEstadoCuenta();

  const { loading } = estadosCuenta;

  const showFilter = (): JSX.Element => {
    const clientes = catalogos.clientes;
    return (
      <MenuFilters>
        {
          catalogos.loading ? (
            <p >Cargando...</p>
          ) : (
            <div className="container-filters">
              <FilterMultipleGrid
                items={clientes?.items}
                key={clientes?.key}
                keyFilter={clientes?.key}
                title={clientes?.title}
                selectionState={clientes?.selectionState}
                onChange={handlerOnSelectChange}
              />
              <Button
                onClick={() => handlerFiltrado()}
                text={estadosCuenta.loading ? "Filtrando..." : "Filtrar"}
                icon='fas fa-search'
                disabled={loading}
                className="btnFilter"
                width={"100%"}
                iconHover="Claro"
              />
            </div>
          )
        }
      </MenuFilters>

    )
  }

  return (
    <div className="container-clientesModules ">
      {showFilter()}
      {
        loading ? (<p>CARGANDO...</p>) :
          (<TableGrid
            dataSource={estadosCuenta?.data}
            columns={columsEdoCuenta}
            enableGroupPanel={true}
            enableColumnChooser={true}
            enableExport={{ enabled: true, onExporting: (e) => onExporting(e) }}
            enableHeaderFilter={true}
            enableSearchPanel={true}
            enableFilterRow={true}
            enableSessionStorage={{ enabled: true, keyStorage: 'gridEdoCuenta' }}
          />)
      }
    </div>
  )
}


export default EstadoCuenta