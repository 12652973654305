import { columsConfFormas } from 'api/configuracion'
import TableGrid from 'components/TableGrid'
import { IRemoteFormaPago } from 'interfaces/IConfiguracion'

interface IProps {
    formasPago : IRemoteFormaPago[],
  }

const GridFormaPago = (Props: IProps) => {

    const {formasPago} = Props;

    return (
        <div className='card-container'>
            <h2>Grid Forma Pago</h2>
            <TableGrid 
                showRowLines={true}
                columns={columsConfFormas}
                dataSource={formasPago}
            />
        </div>
    )
}

export default GridFormaPago