/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useAuth } from "api/auth";
import { ConfiguracionService } from "api/configuracion";
import { IBudgetData, ICatalogosBudget, IFiltersConfBudget } from "interfaces/IConfiguracion";
import { CFStatusBudget, CFNotificacionBudget, defaultBudget, defaultFilters } from "consts/Configuracion";
import notify from 'devextreme/ui/notify';
import { IFilterChange } from "components/interfaces/Interfaces";
import { createNewFilter } from 'utils/Functions';

interface IuseBudget {
    catalogosBudget?: ICatalogosBudget;
    budget: IBudgetData;
    handlerOnFilterChanged: (e: IFilterChange) => void;
    handlerOnSearchBudget: () => Promise<void>;
    handlerOnCompleteBudget: (complete: boolean) => void;
    handlerOnSaveBudget: () => Promise<void>;
    handlerOnChanges: () => void;
    handlerOnAddYearBudget: () => Promise<boolean>;
    statusBudget: CFStatusBudget;
    totalOps: number;
}
export let TITLE_TOTALES: string = "";


export const useBudget = (): IuseBudget => {
    const { User } = useAuth();
    const [catalogosBudget, setCatalogosBudget] = useState<ICatalogosBudget>();
    const [budget, setBudget] = useState<IBudgetData>(defaultBudget);
    const [filters, setFilters] = useState<IFiltersConfBudget>(defaultFilters);
    const [statusBudget, setStatusBudget] = useState<CFStatusBudget>(CFStatusBudget.Stop);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [totalOps, setTotalOps] = useState<number>(0);

    const configuracionService = useMemo(() => {
        return ConfiguracionService(User);
    }, [User]);

    const handlerOnFilterChanged = (e: IFilterChange): void => {
        const { key, value } = e;
        setFilters(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
    }

    const handlerOnSearchBudget = useCallback(async (): Promise<void> => {
        if (!filters.division.trim()) {
            notify(CFNotificacionBudget.MissingDivision, "warning", 2000);
            return
        }
        setStatusBudget(CFStatusBudget.Searching);
        setBudget(defaultBudget);
        /*ASIGNAMOS EL TITULO DE LA FILA TOTALES */
        TITLE_TOTALES = `TOTAL BUDGET ${filters.año}`;
        const dataBudgetGeneral = await configuracionService.getBudgetGeneral(filters);
        if (!dataBudgetGeneral.result) {
            notify(CFNotificacionBudget.ErrorGetBudgetGeneral, "error", 2000);
        }
        if (dataBudgetGeneral.result) {
            const dataBudgetSegmentacion = await configuracionService.getBudgetSegmentacion(filters);
            if (!dataBudgetSegmentacion.result) {
                notify(CFNotificacionBudget.ErrorGetBudgetSegmentacion, "error", 2000);
            }
            if (dataBudgetSegmentacion.result) {
                const { rowTotal, totalOps } = configuracionService.updateTotalesPorSegmentacion(dataBudgetGeneral.data, filters.año, TITLE_TOTALES);
                setBudget(
                    {
                        general: dataBudgetGeneral.data,
                        segmentaciones: [...dataBudgetSegmentacion.data, rowTotal]
                    });
                setTotalOps(totalOps);
            }
        }
        setStatusBudget(CFStatusBudget.Stop);
    }, [configuracionService, filters]);

    const handlerOnCompleteBudget = (complete: boolean): void => {
        setIsComplete(complete);
    }

    const handlerOnSaveBudget = useCallback(async (): Promise<void> => {
        if (!isComplete) {
            notify(CFNotificacionBudget.IsntComplete, "warning", 2000);
            return
        }
        const { general, segmentaciones } = budget;
        if (general.length === 0 || segmentaciones.length === 0) {
            notify(CFNotificacionBudget.EmptyData, "warning", 2000);
            return
        }
        setStatusBudget(CFStatusBudget.Saving);
        const BGResponse = await configuracionService.saveBudgetGeneral(general);
        if (!BGResponse) {
            notify(CFNotificacionBudget.ErrorSavingBudgetGeneral, "Error", 2000);
        }
        if (BGResponse) {
            const BSResponse = await configuracionService.saveBudgetSegmentacion(segmentaciones);
            if (!BSResponse) {
                notify(CFNotificacionBudget.ErrorSavingBudgetSegmentaciones, "error", 2000);
            }
            if (BSResponse) {
                notify(CFNotificacionBudget.SuccessSavingBudget, "success", 2000);
            }
        }
        setStatusBudget(CFStatusBudget.Stop);
    }, [isComplete, budget, configuracionService]);

    const handlerOnChanges = (): void => {
        const { rowTotal, totalOps } = configuracionService.updateTotalesPorSegmentacion(budget.general, filters.año, TITLE_TOTALES);
        const segmentaciones = budget.segmentaciones.map((segmentacion) => {
            if (segmentacion.titulo === TITLE_TOTALES) {
                return rowTotal;
            }
            return segmentacion;
        });
        setBudget(prev => {
            return {
                ...prev,
                segmentaciones
            }
        });
        setTotalOps(totalOps);
    }

    const getCatalogos = useCallback(async (): Promise<void> => {
        const { result, data } = await configuracionService.getCatalogosBudget()
        if (!result) {
            notify(CFNotificacionBudget.ErrorGetCatalogos, "error", 2000);
            return
        }
        const anios = createNewFilter(data!.anios, null, "año");
        setCatalogosBudget({ anios });
        setFilters(prev => {
            return {
                ...prev, division: data!.division
            }
        });
    }, [configuracionService]);
    
    const handlerOnAddYearBudget = useCallback(async (): Promise<boolean> => {
        setStatusBudget(CFStatusBudget.Saving);
        const success = await configuracionService.saveNewYearBudget();
        setTimeout(async () => {
            if (success) {
                notify("Year added successfully!", "success", 2000);
                setStatusBudget(CFStatusBudget.Stop);
            } else {
                notify("Failed to add year to budget", "error", 2000);
            }
            await getCatalogos();
        }, 3000);
        return success;
    }, [configuracionService]);
        
    useEffect(() => {
        getCatalogos();
        setBudget(defaultBudget);
    }, [configuracionService]);

    return {
        catalogosBudget,
        budget,
        handlerOnFilterChanged,
        handlerOnSearchBudget,
        handlerOnCompleteBudget,
        handlerOnSaveBudget,
        handlerOnAddYearBudget,
        statusBudget,
        handlerOnChanges,
        totalOps
    }
}