import { createContext, ReactElement, useCallback, useContext, useLayoutEffect, useState } from "react"
import { useAuth } from "api/auth";
import { IBoxOpcion } from "utils/Global";
import { MTIIcon, CrossIcon } from 'images/Index';
import { IUser } from 'interfaces/IUsuario';
import { AuthNotify } from "consts/User";

interface INavBarContext {
  openBoxOpciones: boolean;
  openPerfilOpciones: boolean;
  handlerOpenBoxOpciones: () => void;
  handlerOpenPerfilOpciones: () => void;
  handlerCloseNavBar: () => void;
  handlerOnLogOut: () => void;
  user: IUser;
  divisionesNotSelected: IBoxOpcion[];
}

const NavBarContext = createContext({} as INavBarContext);
const { Provider } = NavBarContext;

interface IProps {
  children: ReactElement | ReactElement[];
}

export const NavBarProvider = ({ children }: IProps) => {
  const { User, handlerChangeDivision, logOut } = useAuth();
  const [openBoxOpciones, setOpenBoxOpciones] = useState<boolean>(false);
  const [divisionesNotSelected, setDivisionesNotSelected] = useState<IBoxOpcion[]>([]);
  const [openPerfilOpciones, setOpenPerfilOpciones] = useState<boolean>(false);

  const handlerOpenBoxOpciones = (): void => {
    if (openPerfilOpciones && !openBoxOpciones) {
      setOpenPerfilOpciones(false);
    }
    setOpenBoxOpciones(prev => !prev);
  }

  const handlerOpenPerfilOpciones = (): void => {
    if (openBoxOpciones && !openPerfilOpciones) {
      setOpenBoxOpciones(false);
    }
    setOpenPerfilOpciones(prev => !prev);
  }

  const handlerCloseNavBar = useCallback((): void => {
    openBoxOpciones && setOpenBoxOpciones(false);
    openPerfilOpciones && setOpenPerfilOpciones(false);
  }, [openBoxOpciones, openPerfilOpciones])

  const handlerOnLogOut = (): void => {
    handlerCloseNavBar();
    logOut(AuthNotify.LogOut)
  }

  useLayoutEffect(() => {
    if (!User || !User.isActive) return

    const setDivisionColaborador = (): void => {
      const divisiones: string[] = User.divisiones!;
      const divisionSelected: string = User.divisionSeleccionada!;

      const divisionesUnselected: IBoxOpcion[] = divisiones
        .filter((division) => division !== divisionSelected)
        .map((division) => {
          return {
            name: division,
            className: division === "MTI" ? "img-MTI" : "img-CROSS",
            img: division === "MTI" ? MTIIcon : CrossIcon,
            onClick: handlerChangeDivision
          }
        });
      setDivisionesNotSelected(divisionesUnselected);
    }
    setDivisionColaborador();
  }, [User, handlerChangeDivision]);

  const values: INavBarContext = {
    openBoxOpciones,
    openPerfilOpciones,
    handlerOpenBoxOpciones,
    handlerOpenPerfilOpciones,
    handlerOnLogOut,
    user: User,
    divisionesNotSelected,
    handlerCloseNavBar
  }

  return (
    <Provider
      value={values}
    >
      {children}
    </Provider>
  )
}

export const useNavBar = (): INavBarContext => {
  return useContext(NavBarContext);
}