import { useEffect, useMemo, useRef, useState, useCallback, memo } from 'react';
import * as Echarts from 'echarts/core';
import {
  DatasetComponent,
  DatasetComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  TransformComponent
} from 'echarts/components';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { IDataLineRace, modoCalculo, typeValue } from './interfaces/IEcharts';
import { FunctionsCharts } from './Functions/functions';
import { LoadingPanel } from './Index';

Echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LineChart,
  CanvasRenderer,
  LabelLayout,
  UniversalTransition
]);

type EChartsOption = Echarts.ComposeOption<
  | DatasetComponentOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LineSeriesOption
>;

interface IProps {
  dataSource?: any[];
  legendsProp?: string;
  axisXProp?: string;
  valueProp?: string;
  width?: number | string;
  height?: number | string;
  title?: string;
  axisYText?: string;
  loading?: boolean;
  axisType?: typeValue;
  mode?: modoCalculo;
  legendType?: typeValue;
}

const LineRaceEchart = (Props: IProps) => {

  if (Props.loading) {
    return <LoadingPanel />
  }
  return <LineRace {...Props} />
}

const LineRace = (Props: IProps): JSX.Element => {

  const container = useRef(null);
  const isMounted = useRef(false);
  const [chart, setChart] = useState<Echarts.ECharts>();

  const {
    dataSource = [],
    legendsProp = "",
    axisXProp = "",
    width = "100%",
    height = 400,
    title = "",
    valueProp = "",
    axisYText = valueProp,
    axisType = "string",
    legendType = "string",
    mode = "count"
  }
    = Props;

  const legends: string[] = useMemo(() => {
    if (dataSource.length === 0) return [];
    const legendsKeys: any[] = dataSource.map((item) => {
      return {
        ...item,
        [legendsProp]: FunctionsCharts.ValueByType(item, legendType, legendsProp)
      }
    })
    return FunctionsCharts.KeysValues(legendsKeys, legendsProp, legendType);
  }, [dataSource, legendsProp, legendType]);

  const data: any[] = useMemo(() => {
    if (legends.length === 0) return [];
    const dataList: any[] = [];
    /*OBTENEMOS LAS AXIS X */
    const axisKeys: any[] = dataSource.map((item) => {
      return {
        ...item, [axisXProp]: FunctionsCharts.ValueByType(item, axisType, axisXProp)
      }
    })
    const axisX: string[] = FunctionsCharts.KeysValues(axisKeys, axisXProp, axisType)
    /**ASIGNAMOS LAS DIMENSIONES */
    dataList.push(Object.keys(dataSource[0]));
    /**RECORREMOS LAS LEGENDS */
    legends.forEach((legend) => {
      const filterDataLegend: any[] = dataSource.filter((item) =>
        FunctionsCharts.FilterData(item, legendType, legend, legendsProp));
      /*RECORREMOS NUESTRAS AXIS EN X */
      axisX.forEach((axis) => {
        const filterDataAxis: any[] = filterDataLegend.filter((item) =>
          FunctionsCharts.FilterData(item, axisType, axis, axisXProp));
        /*GUARDARMOS EL TOTAL */
        let total: number = 0;
        if (mode === "count") {
          total = filterDataAxis.length;
        }
        if (mode === "sum") {
          filterDataAxis.forEach((item) => {
            const totalItem: any = item[valueProp];
            total += typeof totalItem === "number" ? totalItem : 0;
          });
        }
        const firstElement: any = filterDataAxis.shift();
        if (!firstElement) return
        firstElement[valueProp] = total;
        firstElement[axisXProp] = FunctionsCharts.ValuesGroupedByType(firstElement, axisType, axisXProp);
        dataList.push(Object.values(firstElement));
      });
    });
    return dataList;
  }, [dataSource, mode, legendsProp, valueProp, axisType, axisXProp, legendType, legends]);

  const dataFilter: IDataLineRace = useMemo(() => {
    if (legends.length === 0) return { dataFilter: [], seriesOptions: [] };
    const dataList: DatasetComponentOption[] = [];
    const seriesOptions: LineSeriesOption[] = [];
    legends.forEach((legend: string) => {
      /*ASIGNAMOS LAS DATA */
      dataList.push({
        id: `dataset_${legend}`,
        fromDatasetId: 'dataset_raw',
        transform: {
          type: 'filter',
          config: {
            and: [{ dimension: legendsProp, '=': legend }]
          }
        }
      });
      /**ASIGNAMOS LAS SERIES */
      seriesOptions.push({
        type: 'line',
        datasetId: `dataset_${legend}`,
        showSymbol: true,
        name: legend,
        endLabel: {
          show: true,
          formatter: function (params: any) {
            const indexValue: number = params.dimensionNames
              .findIndex((dimension: string) => dimension === valueProp);
            return `${legend} : ${params.value[indexValue]}`
          }
        },
        labelLayout: {
          moveOverlap: 'shiftY'
        },
        emphasis: {
          focus: 'series'
        },
        encode: {
          x: axisXProp,
          y: valueProp,
          label: [legendsProp, valueProp],
          itemName: axisXProp,
          tooltip: [valueProp]
        }
      })
    });
    return {
      dataFilter: dataList,
      seriesOptions
    }
  }, [legends, legendsProp, axisXProp, valueProp]);

  const generateGraphic = useCallback(() => {
    if (!chart) return
    const optionsChart: EChartsOption = {
      animationDuration: 10000,
      dataset: [
        {
          id: 'dataset_raw',
          source: data
        },
        ...dataFilter.dataFilter
      ],
      title: {
        text: title
      },
      tooltip: {
        order: 'valueDesc',
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        nameLocation: 'middle'
      },
      yAxis: {
        name: axisYText
      },
      grid: {
        right: 140
      },
      series: dataFilter.seriesOptions
    };
    chart.clear();
    chart.setOption(optionsChart);
  }, [axisYText, chart, data, dataFilter, title]);

  generateGraphic();

  useEffect(() => {
    if (!isMounted.current) {
      const myChart = Echarts.init(container.current!);
      isMounted.current = true;
      setChart(myChart);
    }
  }, []);

  return (
    <div
      id='container-linerace'
      ref={container}
      style={
        {
          width: typeof width === "number" ? `${width}px` : width,
          height: typeof height === "number" ? `${height}px` : height
        }
      }
    >
    </div>
  )
}

export default memo(LineRaceEchart)