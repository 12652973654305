import TableGrid from 'components/TableGrid';
import { useCallback } from 'react'
import { ajustarDecimales } from 'utils/Formatos';
import { usePuntosLookingForward } from './hooks/usePuntosLookingForward';
import ErrorPage from 'pages/Error';
import { columnsPuntosLookingForward } from 'api/clientes';
import { useAuth } from 'api/auth';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { groupBy, onExportingGrid } from 'utils/Functions';

const PuntosLookingForward = () => {

  const { User } = useAuth();

  const onExporting = useCallback((e: ExportingEvent) => {
    return onExportingGrid(e, "PuntosLookingForward", User.divisionSeleccionada!);
  }, [User]);

  const {
    loading, data, error
  } = usePuntosLookingForward();

  const obtenerTotals = () => {
    const groupData = groupBy(data!, "grupo_LF")
    const groupdataArray = Object.values(groupData)
    const nuevaData = groupdataArray.map((item: any) => item[0])
    let TotalCanjeables = ajustarDecimales(nuevaData?.filter(item => item.aplica_Canje_Puntos).map(item => item.total_puntos).reduce((prev, next) => prev + next, 0))
    let totalNoCanjeables = ajustarDecimales(nuevaData?.filter(item => !item.aplica_Canje_Puntos).map(item => item.total_puntos).reduce((prev, next) => prev + next, 0))

    return (
      <div className="container-table">
        <div className='container-titles'>
          <h3>Total de puntos canjeables: <span>{TotalCanjeables}</span></h3>
          <h3>Total de puntos no canjeables: <span>{totalNoCanjeables}</span></h3>
        </div>
        <hr />
        <TableGrid
          dataSource={data}
          columns={columnsPuntosLookingForward}
          enableHeaderFilter={true}
          enableGroupPanel={true}
          enableFilterRow={true}
          enableExport={{ enabled: true, onExporting: (e) => onExporting(e) }}
          enableSearchPanel={true}
          enableSessionStorage={{ enabled: true, keyStorage: 'gridPuntosLookingForward' }}
          totalesColumns={[{ summaryType: "sum", displayFormat: "Total: {0}", valueFormat: ajustarDecimales, column: "total_puntos" }]}
        />
      </div>
    )
  }

  if (error) {
    return <ErrorPage errorMessage={error} />
  }

  return (
    <div className="container-clientesModules">     
      {
        error ? (error) : null
      }
      <hr />
      {
        loading ? (
          "Cargando..."
        )
          :
          (
            obtenerTotals()
          )
      }
    </div>
  )
}

export default PuntosLookingForward