
export const KEY_LOCALSTORAGE_USER = 'USER_STATE';

export const KEY_LOCALSTORAGE_DIVISION = 'DIVISION_STATE';

export enum AuthNotify {
  Inactivity = "Se cerro la sesión por inactividad",
  InvalidToken = "Tu sesión es invalida",
  LogOut = "Se cerro tu sesión",
  ErrorAuth = "Ocurrio un error en el servicio"
}

export const DEFAULT_ROUTE: string = process.env.REACT_APP_DEFAULT_ROUTE!;

export const HIDDEN_ROUTE: string = process.env.REACT_APP_HIDDEN_ROUTE!;

export const DEFAULT_PAGE_NAME: string = "dashboard";

export const BIENVENIDA: string = ", bienvenido a tu"