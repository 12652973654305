import { IndexTab, IndexTabSuperAdmin } from 'consts/IT'
import { ScrollView, Tabs } from 'devextreme-react'
import { Popup } from 'devextreme-react/popup'
import { tabsPerfil } from 'functions/It'
import { memo } from 'react'
import FormPerfilColaborador from './FormPerfilColaborador'

const ModalPerfilColaborador = ({
    asignarHistorico,
    catalogos,
    closePopup,
    colaborador,
    handlerOnSelectTab,
    jefes,
    onSavedPerfil,
    isEdition,
    setModoEdicion,
    setValorInicial,
    statusModal,
    rol,
    tabVisible,
    handleAllpermissionsSelect
}) => {

    const renderTitle = () => {
        return (
            <h3 style={{ margin: "-0.2rem" }}>
                Perfil de Colaborador
            </h3>
        )
    }

    return (
        <Popup
            visible={statusModal}
            width={"80%"}
            height={"auto"}
            maxHeight={"90%"}
            dragEnabled={false}
            closeOnOutsideClick={false}
            showCloseButton={false}
            titleRender={renderTitle}
        >
            <ScrollView >
                <>
                    <Tabs
                        visible={true}
                        height={"100%"}
                        selectedIndex={obtenerIndex(tabVisible, rol === "SuperAdmin")}
                        dataSource={tabsPerfil(rol === "SuperAdmin")}
                        onItemClick={(e) => handlerOnSelectTab(e.itemData)}
                    />
                    <FormPerfilColaborador
                        asignarHistorico={asignarHistorico}
                        onSavedPerfil={onSavedPerfil}
                        catalogos={catalogos}
                        colaborador={colaborador}
                        isEdition={isEdition}
                        setModoEdicion={setModoEdicion}
                        setValorInicial={setValorInicial}
                        closePopup={closePopup}
                        jefes={jefes}
                        formSuperAdmin={rol === "SuperAdmin"}
                        indexTab={obtenerIndex(tabVisible, rol === "SuperAdmin")}
                        handleAllpermissionsSelect={handleAllpermissionsSelect}
                    />
                    
                </>
            </ScrollView>
        </Popup>
    )
}

const obtenerIndex = (tabVisible, SuperAdmin) => {
    if (SuperAdmin) {
        return IndexTabSuperAdmin[tabVisible.text]
    }
    return IndexTab[tabVisible.text]
}

export default memo(ModalPerfilColaborador)