import { useEffect, useState } from "react";
import { DataGrid } from "devextreme-react";
import MenuFilters from "components/MenuFilters";
import { ColumsComparativo } from "functions/Compartivo";
import FormComparativoFilter from "./components/comparativo/FormComparativoFilter";
import HeaderComparativo from "./components/comparativo/HeaderComparativo";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "config/firebase.config";
import moment from "moment";
import axios from "axios";
import { URL_API_FIREBASE_APP } from "consts/Services_URL";

const Comparativo = () => {
  const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const [comparative, setComparative] = useState<any[]>([]);
  const [datacomparative, setDataComparative] = useState<any>({
    opsTotal: 0,
    opsSync: 0,
    errorPorcentage: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDoc(doc(db, "operations", date));
      const errors = await getDocs(collection(db, "errors"));
      const {operationsLog} = querySnapshot.data() as any;
      let data = {
        opsTotal: 0,
        opsSync: 0,
        errorPorcentage: 0,
      }
      data.opsTotal = operationsLog.length;

      const errorsArray = errors.docs.filter((error) => {
        const errorDate = moment(error.data().date).format("YYYY-MM-DD");
        return moment(errorDate).isSame(moment(date));
      }, []).map((error) => error.data());

      // quitar los guids repetidos
      const set = new Set();
      const errorsArrayNew = errorsArray.filter((error) => {
        const duplicate = set.has(error.GUID);
        set.add(error.GUID);
        return !duplicate;
      }, []);

      setComparative(errorsArrayNew);

      const errorsPorcentage = errorsArray.length / operationsLog.length;
      data.errorPorcentage = errorsPorcentage;

      const opsSync = operationsLog.length - errorsArrayNew.length;

      const { data: dataQueue } = await axios.get(`${URL_API_FIREBASE_APP}/getLengthQueue`);

      const today = moment().format("YYYY-MM-DD");

      if (today === date) {
        data.opsSync = opsSync - Number(dataQueue.payload.length);
      } else {
        data.opsSync = opsSync;
      }

      setDataComparative(data);
    }
    fetchData();
  }, [date]);

  return (
    <div className="container_comparativo">
      <HeaderComparativo
        opsTotal={datacomparative.opsTotal || 0}
        opsSync={datacomparative.opsSync || 0}
        errorPorcentage={datacomparative.errorPorcentage || 0}
      />
      <MenuFilters>
        <FormComparativoFilter
          date={date}
          setDate={setDate}
        />
      </MenuFilters>
      <DataGrid
        showBorders={true}
        columnMinWidth={40}
        showRowLines={true}
        columnAutoWidth={true}
        dataSource={comparative}
        allowColumnResizing={true}
        columnHidingEnabled={true}
        columnResizingMode="widget"
        columns={ColumsComparativo}
        noDataText="No hay información disponible"
        scrolling={{
          columnRenderingMode: "virtual",
        }}
      />
    </div>
  );
};

export default Comparativo;
