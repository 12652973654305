import BoxNominas from './components/Nomina/BoxNomina'
import { useNomina } from './hooks/useNomina'
import './styles/estilos.scss'

const Nomina = () => {

    const {
        configuracionNominaPorcentaje: NominaPorcentaje,
        configuracionNominaTipo: tipoNomina,
        configuracionFormaPago: formasPago,
        onSavedPorcentajes
    } = useNomina()

    return (
        <>
            <div className="container-configuracionModules">                             
                <hr />
                <BoxNominas 
                    configuracionNominaPorcentaje={NominaPorcentaje} 
                    configuracionNominaTipo={tipoNomina} 
                    formasPago={formasPago}
                    onSavedPorcentajes={onSavedPorcentajes} 
                />
            </div>
        </>
    )
}

export default Nomina