import { Meses } from 'utils/Global';
import { IOpsByTitle } from 'interfaces/IOperaciones';

interface IProps {
  loading?: boolean;
  error?: string | null;
  opsByMonth?: IOpsByTitle[];
}
const BudgetGrid = ({ error, loading, opsByMonth }: IProps) => {

  if (loading) {
    return <p>CARGANDO...</p>
  }

  const getValueOps = (ops: IOpsByTitle): string[] => {
    const value: string[] = Object.values(ops)[0];
    return value
  }

  return (
    <div className='scrollable-table container-budget-grid'>
      <table className="tableBudget">
        <thead>
          <tr>
            <th className='meses'>Mes</th>
            {Meses?.map((mes: string) => (
              <th className='meses' key={mes}>
                {mes}
              </th>
            ))}
            <th className='meses'>Totales</th>
          </tr>
        </thead>
        <tbody>
          {
            opsByMonth?.map((ops: IOpsByTitle, index) => (
              <tr key={index}>
                {
                  getValueOps(ops).map((value: string, index) => (
                    <td
                      key={`${index}${value}`}
                      style={{ 
                        fontWeight: index === 0 ? "bold" : "normal",
                        backgroundColor: index === 0 ? "var(--colorAzul)" : "#fff",
                        color : index === 0 ? "#fff" : "#959595"
                      }}
                    >{value}</td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default BudgetGrid