import { DateBox } from 'devextreme-react';
import { TableGrid, Button } from 'components/Index';
import { useState } from 'react'
import { useDeclaraciones } from './hooks/useDeclaraciones';
import ErrorPage from 'pages/Error';
import { columsDeclaracionAnual } from 'api/clientes';
import './styles/estilos.scss';
import MenuFilters from 'components/MenuFilters';

const Declaraciones = () => {

    const fecha = new Date();
    const [fechaInicial, setFechaInicial] = useState<Date>(fecha);
    const [fechaFinal, setFechaFinal] = useState<Date>(fecha);

    const {
        declaraciones,
        handleFiltrado,
        onExporting
    } = useDeclaraciones();

    if (declaraciones.error) {
        return <ErrorPage errorMessage={declaraciones.error} />
    }

    return (
        <div className="container-clientesModules">
            <MenuFilters>
                <div className="container-filters">
                    <div>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>Fecha Inicio</p>
                        <DateBox
                            type='date'
                            pickerType='calendar'                            
                            max={fechaFinal}
                            value={fechaInicial}
                            onValueChange={(e) => setFechaInicial(e)}
                        />
                    </div>
                    <div>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>Fecha Fin</p>
                        <DateBox
                            type='date'
                            pickerType='calendar'
                            min={fechaInicial}
                            max={Date.now()}
                            value={fechaFinal}
                            onValueChange={(e) => setFechaFinal(e)} />
                    </div>
                    <Button
                        onClick={() => handleFiltrado(fechaInicial, fechaFinal)}
                        text={declaraciones.loading ? "Filtrando..." : "Filtrar"}
                        icon='fas fa-search'
                        disabled={declaraciones.loading}
                        height={40}
                        iconHover="Claro"
                    />
                </div>
            </MenuFilters>
            <div className="container-table">
                {
                    declaraciones.loading ? (
                        "Cargando..."
                    )
                        :
                        (
                            <TableGrid
                                dataSource={declaraciones.data}
                                columns={columsDeclaracionAnual}
                                enableGroupPanel={true}
                                enableSearchPanel={true}
                                enableExport={{ enabled: true, onExporting: (e) => onExporting(e) }}
                                enableSessionStorage={{ enabled: true, keyStorage: 'gridDeclaracionClientes' }}
                            />
                        )
                }
            </div>
        </div>
    )
}

export default Declaraciones