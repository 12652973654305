import PivotTable from 'components/Pivot'
import { memo, useEffect } from "react";
import { ExportedEvent } from 'devextreme/ui/pivot_grid';
import { ProfitCliente } from 'api/clientes';


interface IProps{
    dataProfit: ProfitCliente[];
    fields: any[];
    keyLocalStorage?: string;
    onExporting?: (e: ExportedEvent) => void;
}

const PivotProfit = ({ dataProfit, fields, keyLocalStorage, onExporting }: IProps) => {
    
    useEffect(() =>{
        return () => {
            keyLocalStorage && localStorage.removeItem(keyLocalStorage);
        }
    },[keyLocalStorage])

    /**Agregar el loading y error */

    // if(loading){
    //     return <p>CARGANDO...</p>
    // }
    // if(error){
    //     return <ErrorPage errorMessage={error} />
    // }

    return(
        <PivotTable 
            data={dataProfit} 
            fieldPivot={fields} 
            keyPersistState={keyLocalStorage} 
            onExporting={onExporting}
        />
    )
}
export default memo(PivotProfit)