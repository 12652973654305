import { memo } from "react";
import { Button } from "components/Index";
import { DateBox } from "devextreme-react";
import { Autocomplete, TextField } from "@mui/material";
import { useTask } from "contexts/TaskContext";

const CreateTaskForm = () => {
  const {
    note,
    setNote,
    formData,
    entidades,
    tipoEntidad,
    fechaTermino,
    tiposEstatus,
    responsables,
    tiposEntidades,
    tiposUrgencias,
    setTipoEntidad,
    setFechaTermino,
    loadingEntidades,
    handleInputChange,
    handlerCreateTarea,
    estatusSeleccionado,
    urgenciaSeleccionada,
    setEntidadSeleccionada,
    setEstatusSeleccionado,
    setUrgenciaSeleccionada,
    setResponsableSeleccionado,
  } = useTask();

  return (
    <div className="container-filters">
      <form onSubmit={(e) => handlerCreateTarea(e)}>
        <h2>Crear Tarea</h2>
        <div className="label_input_task">
          <label htmlFor="tipoEntidad">Nombre de la Tarea</label>
          <input
            type="text"
            required={true}
            name="Tarea_Nombre"
            value={formData.Tarea_Nombre}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="select-container label_input_task">
          <label htmlFor="tipoEntidad">Tipo de Entidad</label>
          <select
            id="tipoEntidad"
            name="tipoEntidad"
            value={tipoEntidad}
            onChange={(e) => setTipoEntidad(e.target.value)}
          >
            {tiposEntidades.map((entidad) => (
              <option key={entidad.value} value={entidad.value}>
                {entidad.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="label_input_task">
          <label htmlFor="entidadAutocomplete">Entidad</label>
          <Autocomplete
            id="entidadAutocomplete"
            size="small"
            options={entidades}
            loading={loadingEntidades}
            disabled={entidades.length === 0}
            className="autocomplete_input_task"
            getOptionLabel={(option) => option.Entidad_Razon_Social}
            renderInput={(params: any) => <TextField {...params} />}
            renderOption={(props, option) => (
              <li {...props} key={option.Entidad_Guid}>
                {option.Entidad_Razon_Social}
              </li>
            )}
            onChange={(event, newValue) =>
              setEntidadSeleccionada(newValue ? newValue.Entidad_Guid : "")
            }
          />
        </div>
        <div className="label_input_task">
          <label htmlFor="responsableAutocomplete">
            Responsable de la tarea
          </label>
          <Autocomplete
            size="small"
            loading={loadingEntidades}
            options={responsables.data!}
            id="responsableAutocomplete"
            className="autocomplete_input_task"
            getOptionLabel={(option) => option.colaborador}
            renderInput={(params: any) => <TextField {...params} label="" />}
            renderOption={(props, option) => (
              <li {...props} key={option.guid}>
                {option.colaborador}
              </li>
            )}
            onChange={(event, newValue) =>
              setResponsableSeleccionado(newValue ? newValue.guid : "")
            }
          />
        </div>
        <div className="select-container label_input_task">
          <label htmlFor="entidad">Prioridad</label>
          <select
            id="urgencia"
            name="urgencia"
            value={urgenciaSeleccionada}
            onChange={(e) => setUrgenciaSeleccionada(e.target.value)}
          >
            {tiposUrgencias.map((urgencia) => (
              <option key={urgencia.value} value={urgencia.value}>
                {urgencia.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="select-container label_input_task">
          <label htmlFor="estatus">Estatus</label>
          <select
            id="estatus"
            name="estatus"
            value={estatusSeleccionado}
            onChange={(e) => setEstatusSeleccionado(e.target.value)}
          >
            {tiposEstatus.map((estatus) => (
              <option key={estatus.value} value={estatus.value}>
                {estatus.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="label_input_task">
          <label htmlFor="Tarea_Fecha_Termino">Fecha Termino</label>
          <DateBox
            type="date"
            value={fechaTermino}
            pickerType="calendar"
            name="Tarea_Fecha_Termino"
            className="date_input_task"
            onValueChanged={(e) => setFechaTermino(e.value)}
          />
        </div>
        <div className="label_input_task">
          <label htmlFor="note">Nota</label>
          <input
            type="text"
            name="note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <Button
          icon=""
          type="submit"
          width={"100%"}
          iconHover="Claro"
          text="CREAR TAREA"
          className="button_add_task"
        />
      </form>
    </div>
  );
};

export default memo(CreateTaskForm);
