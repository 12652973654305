import { GridFacturas, GridComisiones, TabComisiones } from 'consts/Comisiones';
import { IColumnProps } from 'devextreme-react/data-grid';
import { ajustarDecimales, formatoPorcentaje, formatoPorcentajeX100 } from 'utils/Formatos';

export const ColumsComisionesPendientesVendedor: IColumnProps[] = [
  {
    caption: "Aplica Comisión",
    dataField: "Genera_Comision",
    dataType: "boolean",
    width: 120,
    allowEditing: false
  },
  {
    caption: "Embarque",
    dataField: "Embarque",
    dataType: "string",
    alignment: "left",
  },
  {
    caption: "Cliente",
    dataField: "Cliente_Razon_Social",
    dataType: "string",
    alignment: "left",
  },
  {
    caption: "Fecha Arribo",
    dataField: "Fecha_Arribo",
    dataType: "date",
    alignment: "left",
    format: "dd/MM/yyyy"
  },
  {
    caption: "Fecha Creacion",
    dataField: "Embarque_Fecha_Creacion",
    dataType: "date",
    format: 'dd/MM/yyyy',
    alignment: "left",
  },
  {
    caption: "Fecha Pago",
    dataField: "fecha_Termino_Pago",
    dataType: "date",
    alignment: "left",
    format: "dd/MM/yyyy"
  },
  {
    caption: "Fecha Limite Pago Comision",
    dataField: "fecha_Termino_Pago_Comision",
    dataType: "date",
    format : "dd/MM/yyyy",
    width: 100
  },
  {
    caption: "Tipo Flete",
    dataField: "Tipo_Flete",
    alignment: "left",
    dataType: "string"
  },
  {
    caption: "Origen",
    dataField: "Origen",
    alignment: "left",
    dataType: "string"
  },
  {
    caption: "#Contenedores",
    dataField: "Embarque_Total_Piezas",
    alignment: "left",
    dataType: "number"
  },
  {
    caption: "Heredado",
    dataField: "Cte_Heredado",
    alignment: "center",
    dataType: "boolean",
    allowEditing: false
  },
  {
    caption: "Vendedor",
    dataField: "Vendedor",
    alignment: "left",
    dataType: "string"
  },
  {
    caption: "Todas las facturas pagadas",
    dataField: "isPaid",
    dataType: "boolean",
    alignment: "center",
    allowEditing: false
  },
  {
    caption: "Porcentaje",
    dataField: "Porcentaje",
    dataType: "number",
    alignment: "left",
    format: formatoPorcentajeX100,
  },
  {
    caption: "Total Comisión",
    dataField: "Total_Comision",
    dataType: "number",
    alignment: "left",
    format: ajustarDecimales,
  },
  {
    caption: "Estrategia",
    dataField: "Estrategia",
    dataType: "string",
    alignment: "left"
  }
];

export const ColumsComisionePendientes: IColumnProps[] = [
  {
    caption: "Aplica Comisión",
    dataField: "aplicaComision",
    dataType: "boolean",
    width: 120
  },
  {
    caption: "¿Pagar comisión?",
    dataField: "pagarComision",
    dataType: "boolean",
    allowEditing: true,
    width: 115
  },
  {
    caption: "Embarque",
    dataField: "embarque",
    dataType: "string",
    alignment: "left",
    width: 200
  },
  {
    caption: "Cliente",
    dataField: "cliente",
    dataType: "string",
    alignment: "left",
    width: 200
  },
  {
    caption: "Fecha Arribo",
    dataField: "fechaArribo",
    dataType: "date",
    alignment: "left",
    format: "dd/MM/yyyy"
  },
  {
    caption: "Fecha Creacion",
    dataField: "fechaCreacion",
    dataType: "date",
    format: 'dd/MM/yyyy',
    alignment: "left",
  },
  {
    caption: "Fecha Pago Comision",
    dataField: "fechaPago",
    dataType: "date",
    alignment: "left",
    format: "dd/MM/yyyy"
  },
  {
    caption: "Tipo Flete",
    dataField: "tipoFlete",
    alignment: "left",
    dataType: "string"
  },
  {
    caption: "Origen",
    dataField: "origen",
    alignment: "left",
    dataType: "string"
  },
  {
    caption: "Vendedor",
    dataField: "vendedor",
    alignment: "left",
    dataType: "string",
    groupIndex: 0
  },
  {
    caption: "Ganancia USD",
    dataField: "gananciaUSD",
    alignment: "left",
    dataType: "number",
    format: ajustarDecimales
  },
  {
    caption: "Ganancia MXN",
    dataField: "gananciaMXN",
    alignment: "left",
    dataType: "number",
    format: ajustarDecimales
  },
  {
    caption: "# Contenedores",
    dataField: "totalPiezas",
    alignment: "left",
    dataType: "number"
  },
  {
    caption: "Utilidad x Contenedor USD",
    dataField: "utilidadxContenedorUSD",
    alignment: "left",
    dataType: "number",
    format: ajustarDecimales
  },
  {
    caption: "Utilidad x Contenedor MXN",
    dataField: "utilidadxContenedorMXN",
    alignment: "left",
    dataType: "number",
    format: ajustarDecimales
  },
  {
    caption: "Heredado",
    dataField: "heredado",
    alignment: "center",
    dataType: "boolean"
  },
  {
    caption: "Genera Comision",
    dataField: "generaComision",
    dataType: "boolean",
    width: 115
  }
];

export const ColumsDetailsFacturas = (grid: GridFacturas): IColumnProps[] => {
  return [
    {
      caption: "No. Factura",
      dataField: "documento_Contable_Numero",
      dataType: "string"
    },
    {
      caption: "Razon Social",
      dataField: "entidad_Razon_Social",
      dataType: "string"
    },
    {
      caption: "Fecha Creacion",
      dataField: "documento_Contable_Fecha_Creacion",
      dataType: "date",
      format : "dd/MM/yyyy",
      width: 100
    },
    {
      caption: "Fecha Pago",
      dataField: "fecha_Termino_Pago",
      dataType: "date",
      format : "dd/MM/yyyy",
      width: 100
    },
    {
      dataField: "monto_MXN",
      dataType: "number",
      format: ajustarDecimales,
      width: 100
    },
    {
      dataField: "monto_USD",
      dataType: "number",
      format: ajustarDecimales,
      width: 100
    },
    {
      caption: "Puntos LF",
      dataField: "puntosLooking",
      format: ajustarDecimales,
      visible: grid === "Venta"
    },
    {
      caption: "Estatus",
      dataField: "documento_Contable_Estatus",
      width: 100,
      customizeText: ({ value }: { value: any }) => {
        const estatus = value === "Pagado" ? true : false;
        return `${estatus ? "✅" : "❌"} ${value}`;
      }
    }
  ]
}

export const ColumsComisionesPorPagar = (grid: GridComisiones): IColumnProps[] => {
  const colums: IColumnProps[] = [
    {
      caption: "Embarque",
      dataField: "embarque",
      dataType: "string",
      alignment: "left",
      width: 200,
      allowEditing: false
    },
    {
      caption: "Cliente",
      dataField: "cliente",
      dataType: "string",
      alignment: "left",
      width: 200,
      allowEditing: false
    },
    {
      caption: "Fecha Creacion",
      dataField: "fechaCreacion",
      dataType: "date",
      format: 'dd/MM/yyyy',
      alignment: "left",
      allowEditing: false
    },
    {
      caption: "Tipo Flete",
      dataField: "tipoFlete",
      alignment: "left",
      dataType: "string",
      allowEditing: false
    },
    {
      caption: "Origen",
      dataField: "origen",
      alignment: "left",
      dataType: "string",
      allowEditing: false
    },
    {
      caption: "Ganancia (USD)",
      dataField: "gananciaUSD",
      alignment: "left",
      dataType: "number",
      format: ajustarDecimales,
      allowEditing: false
    },
    {
      caption: "Ganancia (MXN)",
      dataField: "gananciaMXN",
      alignment: "left",
      dataType: "number",
      format: ajustarDecimales,
      allowEditing: false
    },
    {
      caption: "# CNTR",
      dataField: "totalPiezas",
      alignment: "left",
      dataType: "number",
      allowEditing: false
    },
    {
      caption: "UT por CNTR (USD)",
      dataField: "utilidadxContenedorUSD",
      alignment: "left",
      dataType: "number",
      format: ajustarDecimales,
      allowEditing: false
    },
    {
      caption: "UT por CNTR (MXN)",
      dataField: "utilidadxContenedorMXN",
      alignment: "left",
      dataType: "number",
      format: ajustarDecimales,
      allowEditing: false
    },
    {
      caption: "$ de Comisión",
      dataField: "totalComision",
      dataType: "number",
      alignment: "left",
      format: ajustarDecimales,
      allowEditing: grid === "Editing"
    },
    {
      caption: "% de Comisión",
      dataField: "porcentaje",
      dataType: "number",
      alignment: "left",
      format: formatoPorcentaje,
      allowEditing: grid === "Editing"
    },
    {
      caption: "Heredado",
      dataField: "heredado",
      alignment: "center",
      dataType: "boolean",
      allowEditing: false
    },
    {
      caption: "Estrategia",
      dataField: "estrategia",
      dataType: "string",
      alignment: "left",
      allowEditing: false
    },
    {
      caption: "Modo Calculado",
      dataField: "modoCalculo",
      dataType: "string",
      alignment: "left",
      allowEditing: false
    }
  ];
  return colums;

}

export const ColumsComisionesPagadas: IColumnProps[] = [
  {
    caption: "# Embarque",
    dataField: "embarque",
    alignment: "left",
    dataType: "string"
  },
  {
    caption: "Cliente",
    dataField: "cliente",
    alignment: "left",
    dataType: "string"
  },
  {
    caption: "Fecha Creacion",
    dataField: "fechaCreacion",
    alignment: "left",
    dataType: "date",
    format: "dd/MM/yyyy"
  },
  {
    caption: "Tipo Flete",
    dataField: "tipoFlete",
    alignment: "left",
    dataType: "string"
  },
  {
    caption: "Origen",
    dataField: "origen",
    alignment: "left",
    dataType: "string"
  },
  {
    caption: "# CNTR",
    dataField: "totalPiezas",
    alignment: "left",
    dataType: "number"
  },
  {
    caption: "Heredado",
    dataField: "heredado",
    alignment: "center",
    dataType: "boolean"
  },
  {
    caption: "Estrategia",
    dataField: "estrategia",
    dataType: "string",
    alignment: "left"
  },
  {
    caption: "$ de Comisión",
    dataField: "totalComision",
    dataType: "number",
    alignment: "left",
    format: ajustarDecimales,
  },
  {
    caption: "% de Comisión",
    dataField: "porcentaje",
    dataType: "number",
    alignment: "left",
    format: formatoPorcentaje
  },
  {
    caption: "Fecha Pago",
    dataField: "fechaPago",
    dataType: "date",
    format: "dd/MM/yyyy"
  }
]

export interface ITabs {
  text: TabComisiones;
}
export const tabsComisiones = (tieneSubordinados: boolean): ITabs[] => {
  const longtabs: ITabs[] = [
    { text: 'Vendedor' }
  ]
  if (tieneSubordinados) {
    longtabs.push({ text: 'Subordinados' });
  }
  return longtabs;
}