import { DEFAULT_ROUTE } from "consts/User";
import PerfilCliente from "pages/clientes/PerfilCliente";
import Profit from "pages/clientes/Profit";
import {
  ComisionesPagadas,
  ComisionesPendientes,
  CuentasPendientes,
  DeclaracionesClientes,
  DeclaracionProveedor,
  EstadoCuenta,
  FacturacionAnualClientes,
  FacturacionAnualProveedor,
  PagarComisiones,
  PuntosCanjeados,
  PuntosLookingForward,
  DesgloseServiciosEmbarques,
  Tareas,
  SeguimientoClientes,
  SeguimientoProveedores,
  Demoras,
} from "pages/Index";
import { lazy } from "react";
import { Route } from "../routes";
import FacturasPorEstatus from "pages/dashboards/FacturasPorEstatus";

const proveedoresModules = lazy(
  () =>
    import(
      /* webpackChunkName:"ProveedoresLayout" */ "routes/Administracion/ProveedoresModules"
    )
);
const clientesModules = lazy(
  () =>
    import(
      /* webpackChunkName:"ClientesLayout" */ "routes/Administracion/ClientesModules"
    )
);
const comisionesModules = lazy(
  () =>
    import(
      /* webpackChunkName :"ComisionesLayout" */ "routes/Administracion/ComisionesModules"
    )
);
const declaracionesModules = lazy(
  () =>
    import(
      /* webpackChunkName:"DeclaracionesLayout" */ "routes/Administracion/Declaraciones"
    )
);
const lookingForwardModules = lazy(
  () =>
    import(/* webpackChunkName :"LFLayout" */ "routes/Administracion/LFModules")
);
const dashboardsModules = lazy(
  () =>
    import(
      /* webpackChunkName :"DashboardsLayout" */ "routes/Administracion/DashboardModules"
    )
);
const seguimientoModules = lazy(
  () =>
    import(
      /* webpackChunkName :"SeguimientoLayout" */ "routes/Administracion/SeguimientoModules"
    )
);

export const subRoutesProveedores: Route[] = [
  {
    id: 2,
    to: "administracion/proveedores/facturacionAnual",
    path: "/facturacionAnual",
    Component: FacturacionAnualProveedor,
    name: DEFAULT_ROUTE,
    title: "Facturación Anual",
    icon: "fas fa-wallet",
    pageName: "Facturación Anual Proveedores",
  },
];

export const subRoutesClientes: Route[] = [
  {
    id: 1,
    to: "/administracion/clientes/facturacionAnual",
    path: "/facturacionAnual",
    Component: FacturacionAnualClientes,
    name: DEFAULT_ROUTE,
    title: "Facturación Anual",
    icon: "fas fa-wallet",
    pageName: "Facturación Anual Clientes",
  },
  {
    id: 2,
    to: "/administracion/clientes/estadoCuenta",
    path: "/estadoCuenta",
    Component: EstadoCuenta,
    name: DEFAULT_ROUTE,
    title: "Estado de Cuenta",
    icon: "fas fa-wallet",
    pageName: "Estado de Cuenta",
  },
  {
    id: 3,
    to: "/administracion/clientes/profit",
    path: "/profit",
    Component: Profit,
    name: "PROFIT CLIENTES",
    title: "Profit",
    icon: "fas fa-bullseye",
    pageName: "Profit",
  },
  {
    id: 4,
    to: "/administracion/clientes/perfilCliente",
    path: "/perfilCliente",
    Component: PerfilCliente,
    name: "PERFIL CLIENTES",
    title: "Perfil Cliente",
    icon: "fas fa-bullseye",
    pageName: "Perfil Cliente",
  },
];

export const subRoutesLookingForward: Route[] = [
  {
    id: 1,
    path: "/puntos",
    to: "/administracion/lookingForward/puntos",
    Component: PuntosLookingForward,
    name: DEFAULT_ROUTE,
    title: "Puntos",
    icon: "fas fa-gift",
    pageName: "Puntos Looking Forward",
  },
  {
    id: 2,
    path: "/puntosCanjeados",
    to: "/administracion/lookingForward/puntosCanjeados",
    Component: PuntosCanjeados,
    name: DEFAULT_ROUTE,
    title: "Puntos Canjeados",
    icon: "fas fa-hand-holding-usd",
    pageName: "Puntos Canjeados de Looking Forward",
  },
];

export const subRoutesDeclaraciones: Route[] = [
  {
    id: 1,
    path: "/clientes",
    to: "/administracion/declaraciones/clientes",
    Component: DeclaracionesClientes,
    name: "DECLARACIONES",
    title: "Clientes",
    icon: "fas fa-hand-holding-usd",
    pageName: "Declaraciones de Clientes",
  },
  {
    id: 2,
    path: "/proveedores",
    to: "/administracion/declaraciones/proveedores",
    Component: DeclaracionProveedor,
    name: "DECLARACIONES",
    title: "Proveedores",
    icon: "fas fa-hand-holding-usd",
    pageName: "Declaraciones de Proveedores",
  },
];

export const subRoutesComisiones: Route[] = [
  {
    id: 1,
    to: "/administracion/comisiones/comisionesPendientes",
    path: "/comisionesPendientes",
    Component: ComisionesPendientes,
    name: "COMISIONES",
    title: "Pendientes",
    icon: "fas fa-percent",
    pageName: "Comisiones Pendientes por pagar",
  },
  {
    id: 2,
    to: "/administracion/comisiones/pagarComisiones",
    path: "/pagarComisiones",
    Component: PagarComisiones,
    name: "COMISIONES",
    title: "Por pagar",
    icon: "fas fa-percent",
    pageName: "Comisiones A Pagar",
  },
  {
    id: 3,
    to: "/administracion/comisiones/comisionesPagadas",
    path: "/comisionesPagadas",
    Component: ComisionesPagadas,
    name: "COMISIONES",
    title: "Pagadas",
    icon: "fas fa-percent",
    pageName: "Comisiones Pagadas",
  },
];

export const subRoutesAdministracionDashboard: Route[] = [
  {
    id: 1,
    path: "/cuentasPendientes",
    to: "/administracion/dashboards/cuentasPendientes",
    Component: CuentasPendientes,
    icon: "fas fa-chart-line",
    name: DEFAULT_ROUTE,
    title: "CxC & PxP",
    pageName: "Cuentas por Pagar y por Cobrar",
  },
  {
    id: 2,
    path: "/facturasPorEstatus",
    to: "/administracion/dashboards/facturasPorEstatus",
    Component: FacturasPorEstatus,
    icon: "fas fa-chart-line",
    name: DEFAULT_ROUTE,
    title: "Validación de Facturas de Pago",
    pageName: "Validación de Facturas de Pago",
  },
  {
    id: 3,
    path: "/demoras",
    to: "/administracion/dashboards/demoras",
    Component: Demoras,
    icon: "fas fa-chart-line",
    name: DEFAULT_ROUTE,
    title: "Demoras",
    pageName: "Facturas por Demoras",
  },
];

export const subRoutesSeguimiento: Route[] = [
  {
    id: 1,
    path: "/tareas",
    to: "/administracion/seguimiento/tareas",
    Component: Tareas,
    icon: "fas fa-list-alt",
    name: DEFAULT_ROUTE,
    title: "Tareas",
    pageName: "Tareas",
  },
  {
    id: 2,
    path: "/clientes",
    to: "/administracion/seguimiento/clientes",
    Component: SeguimientoClientes,
    icon: "fas fa-handshake",
    name: DEFAULT_ROUTE,
    title: "Clientes",
    pageName: "Seguimiento de Clientes",
  },
  {
    id: 3,
    path: "/proveedores",
    to: "/administracion/seguimiento/proveedores",
    Component: SeguimientoProveedores,
    icon: "fas fa-id-badge",
    name: DEFAULT_ROUTE,
    title: "Proveedores",
    pageName: "Seguimiento de Proveedores",
  },
];

export const routesAdministracion: Route[] = [
  {
    id: 1,
    path: "/clientes/*",
    to: "/administracion/clientes/",
    Component: clientesModules,
    name: DEFAULT_ROUTE,
    icon: "fas fa-user-tie",
    title: "Clientes",
    items: subRoutesClientes,
  },
  {
    id: 2,
    path: "/proveedores/*",
    to: "/administracion/proveedores/",
    Component: proveedoresModules,
    name: DEFAULT_ROUTE,
    icon: "fas fa-id-badge",
    title: "Proveedores",
    items: subRoutesProveedores,
  },
  {
    id: 3,
    path: "/lookingForward/*",
    to: "/administracion/lookingForward/",
    Component: lookingForwardModules,
    name: DEFAULT_ROUTE,
    icon: "fas fa-donate",
    title: "Looking Forward",
    items: subRoutesLookingForward,
  },
  {
    id: 4,
    path: "/declaraciones/*",
    to: "/administracion/declaraciones/",
    Component: declaracionesModules,
    name: "DECLARACIONES",
    icon: "fas fa-hand-holding-usd",
    title: "Declaraciones",
    items: subRoutesDeclaraciones,
  },
  {
    id: 5,
    path: "/comisiones/*",
    to: "/administracion/comisiones/",
    Component: comisionesModules,
    name: "COMISIONES",
    title: "Comisiones",
    icon: "fas fa-percent",
    items: subRoutesComisiones,
  },
  {
    id: 6,
    path: "/dashboards/*",
    to: "/administracion/dashboards/",
    Component: dashboardsModules,
    name: DEFAULT_ROUTE,
    title: "Dashboards",
    icon: "fas fa-chart-line",
    items: subRoutesAdministracionDashboard,
  },
  {
    id: 7,
    path: "/seguimiento/*",
    to: "/administracion/seguimiento/",
    Component: seguimientoModules,
    name: DEFAULT_ROUTE,
    title: "Seguimiento",
    icon: "fas fa-comments",
    items: subRoutesSeguimiento,
  },
  {
    id: 8,
    path: "/embarquesCuentasPendientes",
    to: "/administracion/embarquesCuentasPendientes",
    Component: DesgloseServiciosEmbarques,
    icon: "fas fa-ship",
    name: DEFAULT_ROUTE,
    title: "Facturas sin desglose",
    pageName: "Facturas sin desglose",
  },
];
