import { OpsCanceladas, OpsTerrestres, FacturasPorEstatus, Demoras  } from 'pages/Index';
import { lazy } from 'react';
import { Route } from 'routes/routes';

const kpiSubModules = lazy(() => import(/* webpackChunkName:"KPILayout" */ 'routes/Operaciones/KPISubModules'));
const dashboardsModules = lazy(() => import(/* webpackChunkName :"DashboardsLayout" */ 'routes/Administracion/DashboardModules'));

export const subRoutesKPI: Route[] = [
  {
    id: 1,
    path: "/canceladas",
    to: "/operaciones/kpi/canceladas",
    Component: OpsCanceladas,
    icon: "fas fa-plane-slash",
    name: "OPERACIONES",
    title: "Ops Canceladas",
    pageName: "Operaciones Canceladas"
  },
  {
    id: 2,
    path: "/terrestres",
    to: "operaciones/kpi/terrestres",
    Component: OpsTerrestres,
    icon: "fas fa-truck-loading",
    name: "OPERACIONES",
    title: "Ops Terrestres",
    pageName: "Operaciones Terrestres"
  }
];

export const subRoutesOperacionDashboard: Route[] = [
  {
    id: 1,
    path: "/facturasPorEstatus",
    to: "/operaciones/dashboards/facturasPorEstatus",
    Component: FacturasPorEstatus,
    icon: "fas fa-chart-line",
    name: "OPERACIONES",
    title: "Validación de Facturas de Pago",
    pageName :"Validación de Facturas de Pago"
  },
  {
    id: 2,
    path: "/demoras",
    to: "/operaciones/dashboards/demoras",
    Component: Demoras,
    icon: "fas fa-chart-line",
    name: "OPERACIONES",
    title: "Demoras",
    pageName :"Facturas por Demoras"
  }
];

export const routesOperaciones: Route[] = [
  {
    id: 1,
    to: "/operaciones/kpi/",
    path: "/kpi/*",
    Component: kpiSubModules,
    icon: "fas fa-sort-amount-up",
    name: "OPERACIONES",
    title: "KPI'S",
    items: subRoutesKPI
  },
  {
    id: 2,
    path: "/dashboards/*",
    to: "/operaciones/dashboards/",
    Component: dashboardsModules,
    name: "OPERACIONES",
    title: "Dashboards",
    icon: "fas fa-chart-line",
    items: subRoutesOperacionDashboard
  },
]