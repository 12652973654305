import { memo, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { IDataPieChart, modoCalculo } from './interfaces/IEcharts';
import * as Echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  ToolboxComponentOption,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption
} from 'echarts/components';
import { PieChart, PieSeriesOption } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { groupBy } from 'utils/Functions';
import { LoadingPanel } from './Index';

Echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

export type EChartsOption = Echarts.ComposeOption<
  | TitleComponentOption
  | ToolboxComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | PieSeriesOption
>;

interface IProps {
  dataSource?: any[];
  displayProp?: string;
  valueProp?: string;
  width?: number | string;
  height?: number | string;
  seriesTitle?: string;
  title?: string;
  subText?: string;
  mode?: modoCalculo;
  loading?: boolean;
  colors?: Echarts.Color[];
}

const PieEchart = (Props: IProps): JSX.Element => {

  if (Props.loading) {
    return <LoadingPanel />
  }
  return <PieComponent {...Props} />
}

const PieComponent = (Props: IProps): JSX.Element => {

  const container = useRef(null);
  const isMounted = useRef(false);
  const [chart, setChart] = useState<Echarts.ECharts>();

  const {
    dataSource = [],
    width = "100%",
    height = 400,
    title = "",
    seriesTitle = "",
    subText = "",
    displayProp = "",
    valueProp = "",
    mode = "count",
    colors = []
  }
    = Props;

  const data = useMemo(() => {
    if (dataSource.length === 0) return [];
    const dataList: IDataPieChart[] = [];
    const dataGrouped: any = groupBy(dataSource, displayProp);
    const keysGrouped: string[] = Object.keys(dataGrouped)
      .filter((key: string) => key.trim());

    for (const key of keysGrouped) {
      const dataByKey: any[] = dataGrouped[key];
      let total: number = 0;
      if (mode === "count") {
        total = dataByKey.length;
      }
      if (mode === "sum") {
        dataByKey.forEach((item) => {
          const totalItem: any = item[valueProp];
          total += typeof totalItem === "number" ? totalItem : 0;
        });
      }
      dataList.push({
        value: total,
        name: key
      })
    }
    return dataList;
  }, [dataSource, displayProp, valueProp, mode]);

  const generateChart = useCallback(() => {
    if (!chart) return
    chart.clear();
    const optionsChart: EChartsOption = {
      title: {
        text: title,
        subtext: subText,
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        type: "scroll",
        orient: "horizontal",
        bottom: 10,
        left: 5,
        icon: "rect"
      },
      toolbox: {
        feature: {
          saveAsImage: {
            name: `Gráfico ${title}`,
            title: "Guardar como imagen"
          }
        }
      },
      series: [
        {
          name: seriesTitle,
          type: "pie",
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          color: colors,
          data: data
        }
      ]
    };
    chart.setOption(optionsChart);
  }, [chart, data, title, seriesTitle, subText, colors]);

  generateChart();

  useEffect(() => {
    if (!isMounted.current) {
      const myChart = Echarts.init(container.current!);
      isMounted.current = true;
      setChart(myChart);
    }
  }, []);

  return (
    <div
      id='container-pie'
      ref={container}
      style={
        {
          width: typeof width === "number" ? `${width}px` : width,
          height: typeof height === "number" ? `${height}px` : height
        }
      }
    >
    </div>
  )
}


export default memo(PieEchart)