import { DataGrid, Popup, ScrollView } from 'devextreme-react'
import { ToolbarItem } from 'devextreme-react/popup'
import { Column } from 'devextreme-react/data-grid'

const ModalHistoricoComisionPuesto = ({ status, setStatus, modalTitle, setDataModal, dataModal:{historial, historicoActual} }) => {
    const optionCerrar = {
        text: "cerrar",
        type: 'danger',
        stylingMode: 'outlined'
    }
    
    const closeModal = () => {
        setDataModal();
        setStatus(!status);
    }

    const marcarSueldoActual = ({data:{data}}) =>{
        const date = new Date(data.aplicaApartir);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return (
            <p style={{color:data.porcentajeAdicionalId === historicoActual.porcentajeAdicionalId && "green"}}>{date.toLocaleDateString("es-mx", options)}</p>
        )
    }

    const marcarPorcentaje = ({data:{data}}) => {
        return (
            <p>{`${data.porcentaje}%`}</p>
        )
    }

    return (
        <>
        <Popup
            width={"50%"}
            height={"auto"}
            maxHeight={"90%"}
            visible={status}
            showTitle={true}
            title={modalTitle}
            closeOnOutsideClick={false}
            showCloseButton={false}
        >
            <ToolbarItem    
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={optionCerrar}
                onClick={closeModal}
            />

            <ScrollView height='100%' width='100%'>

                <DataGrid
                    dataSource={historial}
                    key="idGridHistoricoComisionPuesto"
                    showBorders={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    noDataText={""}                       
                > 
                    <Column
                        caption="Porcentaje"
                        dataField="porcentaje"
                        alignment="left"      
                        cellComponent={marcarPorcentaje}                      
                    />

                    <Column
                        caption="Fecha que Aplico"
                        dataField="aplicaApartir"
                        dataType="date"
                        format= "dd/MM/yyyy"
                        alignment="left"
                        cellComponent={marcarSueldoActual}
                    /> 

                </DataGrid>
            </ScrollView> 
        </Popup>
        </>
    )
}

export default ModalHistoricoComisionPuesto