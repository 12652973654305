import { NominasPagadas, PagoNomina, Perfil } from "pages/Index";
import DirectorioCSM from "pages/talentoHumano/DirectorioCSM";
import { lazy } from "react";
import { Route } from "routes/routes";

const nominasModules = lazy(() => import(/* webpackChunkName :"NominasLayout" */ 'routes/TH/NominasModules'));

export const subRoutesNomina: Route[] = [ 
  {
    id: 1,
    path: "/pagar",
    to: "/th/nominas/pagar",
    icon: "fas fa-money-check-alt",
    Component: PagoNomina,
    name: "TH",
    title: "Pago Nómina",
    pageName: "Pago de Nómina"
  },
  {
    id: 2,
    path: "/pagadas",
    to: "/th/nominas/pagadas",
    icon: "fas fa-cash-register",
    Component: NominasPagadas,
    name: "TH",
    title: "Nóminas Pagadas",
    pageName: "Nóminas pagadas"
  }
];

export const talentoHumanoRoutes: Route[] = [
  {
    id: 1,
    path: "/directorioCSM",
    to: "/th/directorioCSM",
    Component: DirectorioCSM,
    name: "DIRECTORIO CSM",
    icon: "fas fa-address-book",
    title: "Directorio CSM"
  },
  {
    id: 2,
    path: "/nominas/*",
    to: "/th/nominas/",
    Component: nominasModules,
    name: "TH",
    icon: "fas fa-dollar-sign",
    title: "Nominas",
    items: subRoutesNomina
  },
  {
    id: 3,
    path: "/perfil",
    to: "/th/perfil",
    Component: Perfil,
    name: "TH",
    icon: "fas fa-id-card",
    title: "Perfil",
    pageName: "Perfil Colaborador"
  }
];