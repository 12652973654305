import { useDesglose } from './hooks/useDesglose';
import PivotOperaciones from './components/PivotOperaciones';
import FiltersOperaciones from './components/FiltersOperaciones';
import { _FieldDesgloce } from 'api/operaciones';
import { Button } from 'components/Index';
import './styles/estilos.scss';
import MenuFilters from 'components/MenuFilters';

const Desglose = () => {
  const {
    desglose,
    catalogos,
    handlerOnSelectChange,
    handleFiltrado,
    onExporting
  } = useDesglose();

  const { loading } = desglose;

  return (
    <div className='container-operaciones'>
      <MenuFilters>
        <FiltersOperaciones
          catalogos={catalogos}
          handlerOnSelectChange={handlerOnSelectChange}
          itemsByRow={1}
        >
          <Button
            onClick={() => handleFiltrado()}
            text={loading ? "Buscando..." : "Filtrar OPS"}
            icon='fas fa-search'
            disabled={loading}
            className="btnFilter"
            width={"100%"}
            iconHover="Claro"
          />
        </FiltersOperaciones>
      </MenuFilters>    
      <PivotOperaciones
        dataOperaciones={desglose}
        fields={_FieldDesgloce}
        keyLocalStorage='PivotDesglose'
        onExporting={onExporting}
      />
    </div>
  )
}

export default Desglose