import TableGrid from "components/TableGrid";
import { usePuntosCanjeados } from "./hooks/usePuntosCanjeados";
import './styles/estilos.scss';
import ErrorPage from 'pages/Error';
import { columnsPuntosCanjeados } from "api/clientes";
import { onExportingGrid } from 'utils/Functions';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { useCallback } from "react";
import { useAuth } from "api/auth";

const PuntosCanjeados = () => {

  const {User} = useAuth();

  const onExporting = useCallback((e: ExportingEvent) => {
    return onExportingGrid(e, "PuntosCanjeados", User.divisionSeleccionada!);
  }, [User]);

  const {
    loading, data, error
  } = usePuntosCanjeados();

  if (error) {
    return <ErrorPage errorMessage={error} />
  }

  return (
    <div className="container-clientesModules">  
      <div className="container-table">
        {
          loading ? (
            "Cargando..."
          )
          :
          (
            <TableGrid 
              dataSource={data} 
              enableFilterRow={true} 
              enableHeaderFilter={true} 
              columns={columnsPuntosCanjeados} 
              enableGroupPanel={true} 
              enableExport={{ enabled: true, onExporting: (e) => onExporting(e) }}
              enableSearchPanel={true} 
              enableSessionStorage={{ enabled: true, keyStorage: 'gridPuntosCanjeados' }}
            />
          )
        }
      </div>
    </div>
  )
}

export default PuntosCanjeados