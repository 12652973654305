import { memo, useRef } from 'react';
import { ColumsDocumentosAbiertosEmbarques } from 'functions/Embarques';
import PropTypes from 'prop-types';
import { Button } from 'components/Index';
import DataGrid, { Column, Pager } from 'devextreme-react/data-grid';
import { useShipments } from '../hooks/useShipments'

const pageSizes = [25, 50, 75, 100];

const GridEmbarques = (Props) => {

  const { generatePdf } = useShipments();

  const {
    dataEmbarques = [],
    isSynchronizing = false
  } = Props;

  const rowIndex = useRef(0);

  const handleOnClickPdf = ({ data }) => {
    const Synchronizing = isSynchronizing && rowIndex.current === data.rowIndex;
    return (
      <Button
        disabled={Synchronizing}
        width={75}
        type="button"
        iconHover='Claro'
        icon={Synchronizing ? "loading-icon loading-size" : 'fas fa-file-pdf'}
        onClick={() => generatePdf(data.data.Documento_Contable_Numero)}
      />
    )
  }
    
  return (
    <DataGrid
    dataSource={dataEmbarques}
    noDataText="No hay información disponible"
    showBorders={true}
    rowAlternationEnabled={true}
    columnAutoWidth={true}
    columnHidingEnabled={true}
    columnResizingMode="widget"
    columnMinWidth={40}
    scrolling={{
        columnRenderingMode: "virtual"
      }}
    headerFilter={{ visible: true, allowSearch: true }}
    searchPanel={{ visible: true, highlightCaseSensitive: false, placeholder: 'Buscar', width: 250 }}
    groupPanel={{ visible: true, emptyPanelText: 'Arrastra aqui 👇 la columna a agrupar' }}
    grouping={{ texts: { groupContinuesMessage: "Continua en la siguiente página" }, autoExpandAll: true }}
    showRowLines={true}
>
        {
        ColumsDocumentosAbiertosEmbarques.map((column, index) => (
          <Column {...column} key={index} />
        ))
      }
      <Column
        caption="PDF"
        cellComponent={handleOnClickPdf}
        width={90}
      />
      <Pager
        visible={true}
        allowedPageSizes={pageSizes}
        showPageSizeSelector={true}
      />
</DataGrid>
  )
}

GridEmbarques.propTypes = {
  dataEmbarques: PropTypes.array,
  keyStateStoraged: PropTypes.string,
  onStartEditing: PropTypes.func,
  isSynchronizing: PropTypes.bool
}

export default memo(GridEmbarques)