import { createContext, ReactElement, useRef, useEffect, useState, useCallback } from 'react';
import { IUser } from "interfaces/IUsuario";
import notify from "devextreme/ui/notify";
import { AuthenticationService } from "api/auth";
import { AuthNotify, KEY_LOCALSTORAGE_DIVISION } from "consts/User";

export interface IContext {
  User: IUser;
  loading?: boolean;
  onLogin: (user: string, password: string, navigate: () => void) => Promise<void>;
  handlerChangeDivision: (division: string) => void;
  handleSession?: () => void;
  logOut: (notificacion: AuthNotify) => void;
}

export const AuthContext = createContext({} as IContext);
const { Provider } = AuthContext;

interface IUserContext {
  children: ReactElement | ReactElement[];
}

let timeOutInactivity: NodeJS.Timer;
let timeOutRefresh: NodeJS.Timer;

export const AuthProvider = ({ children }: IUserContext) => {
  const userActive = AuthenticationService.checkUser().data;
  const [userState, setUserState] = useState<IUser>(userActive);
  const [loading, setLoading] = useState<boolean>(false);
  const expirationTimeInMinutes = useRef<number>(20);
  /**VERIFICAR ACCESOS */
  const onLogin = async (user: string, password: string, navigate: () => void): Promise<void> => {
    setLoading(true);
    const { data, isOk, error } = await AuthenticationService.signIn(user, password);
    if (!isOk) {
      notify(error, "error", 4000);
      setLoading(false);
      return
    }
    setUserState(data!);
    setLoading(false);
    navigate();
  }
  /**CERRAR SESION USUARIO */
  const logOut = (notificacion: AuthNotify): void => {
    const message = AuthenticationService.showNotificacion(notificacion);
    notify(message.message, message.type, 3500);
    clearInterval(timeOutInactivity);
    clearInterval(timeOutRefresh);
    sessionStorage.clear();
    localStorage.clear();
    setUserState({ isActive: false });
  }

  const handlerChangeDivision = useCallback((division: string): void => {
    const newUser: IUser = { ...userState, divisionSeleccionada: division };
    localStorage.setItem(KEY_LOCALSTORAGE_DIVISION, division);
    setUserState(newUser);
  }, [userState]);

  /**VERIFICAR INACTIVIDAD */
  useEffect(() => {

    if (!userState.isActive) return

    const checkMouseOver = (): void => {
      clearInterval(timeOutInactivity);
      expirationTimeInMinutes.current = 20;
      timeOutInactivity = setInterval(() => {
        expirationTimeInMinutes.current = expirationTimeInMinutes.current - 1;
        if (expirationTimeInMinutes.current === 0) {
          logOut(AuthNotify.Inactivity);
        }
      }, 60000);
    }
    document.addEventListener("mousemove", checkMouseOver);
    return () => document.removeEventListener('mousemove', checkMouseOver);
  }, [userState]);

  /**REFRESH TOKEN */
  useEffect(() => {

    if (!userState.isActive) return

    timeOutRefresh = setInterval(async () => {
      const response = await AuthenticationService.checkToken(userState.token!);
      if (!response.isOk) {
        logOut(AuthNotify.InvalidToken);
        return
      }
      const { expirationMinutes, user } = response.token!;
      if (expirationMinutes <= 5) {
        const { isOk, tokenRefresh } = await AuthenticationService.refreshToken(user, userState.token!);
        if (!isOk) {
          logOut(AuthNotify.ErrorAuth);
          return
        }
        AuthenticationService.saveNewToken(tokenRefresh!, (user: IUser) => {
          setUserState(user);
        });
      }
    }, 1000 * 60 * 60);
    return () => clearInterval(timeOutRefresh);
  }, [userState]);

  const values: IContext = { User: userState, loading, onLogin, handlerChangeDivision, logOut };

  return (
    <Provider
      value={values}
    >
      {children}
    </Provider>
  )

}
