import { 
    IRemoteConfiguracionNomina,  
    IGetDataConfiguracionNomina, 
    IGetDataConfiguracionNominaTipo,
    IGetDataConfiguracionFormaPago 
} from "interfaces/IConfiguracion"
import { useAuth } from "api/auth";
import { useGetDataWithAuth } from "hooks/useGetData";
import { URL_API_CF } from "consts/Services_URL";
import { useSWRWithToken } from "hooks/useSWR";
import { sendDataAsync } from "api/fetch";
import { AxiosRequestHeaders } from "axios";
import { useCallback } from "react";
import notify from "devextreme/ui/notify";

interface IUseConfNomina {
    configuracionNominaPorcentaje: IGetDataConfiguracionNomina;
    configuracionNominaTipo: IGetDataConfiguracionNominaTipo;
    onSavedPorcentajes:(data:IRemoteConfiguracionNomina, action: string) => void;
    configuracionFormaPago: IGetDataConfiguracionFormaPago;
}

interface INotify {
    type: string;
    message?: string;
}

export const useNomina = ():IUseConfNomina => {
    const {User} = useAuth();
    const headers: AxiosRequestHeaders = { Authorization: `Bearer ${User.token}` }

    const { data: formasPago } = useGetDataWithAuth(`${URL_API_CF}ConfigNomina/formasPago`, User.token);
    const { data: dataTipo } = useGetDataWithAuth(`${URL_API_CF}ConfigNomina/tipoNomina`, User.token);
    const {data: dataPorcentaje} = useSWRWithToken(`${URL_API_CF}ConfigNomina`, 10, User.token!);

    const showNotify = useCallback((Props:INotify) => {
        const {type,message=""} = Props;
        if (type === 'error') {
            notify({
                message: `Ocurrio un error: ${message}`
            }, "error", 4000);
        }
        if (type === 'success') {
            notify({
                message: `Se guardo correctamente`
            }, "success", 4000);
        }
    },[])

    const onSavedPorcentajes = async(data:IRemoteConfiguracionNomina, action: string) => {
        switch(action){
            case 'insert':
                const { result: resInsert, error: resErrorInsert } = await sendDataAsync(`${URL_API_CF}ConfigNomina`, data, "POST", headers)
                showNotify({ type: resInsert ? "success" : "error", message: resErrorInsert });
                break;
            case 'update':
                const {nominaId} = data
                const { result: resUpdate, error: resErrorUpdate } = await sendDataAsync(`${URL_API_CF}ConfigNomina/${nominaId}`, data, "PUT", headers)
                showNotify({ type: resUpdate ? "success" : "error", message: resErrorUpdate });
                break;
            case 'remove':
                const { result:resDelete, error: resErrorDelete } = await sendDataAsync(`${URL_API_CF}ConfigNomina/${data.nominaId}`, null, "DELETE", headers)
                showNotify({ type: resDelete ? "success" : "error", message: resErrorDelete });
                break;
            default:
                return
        }
    }

    const configuracionNominaPorcentaje:IGetDataConfiguracionNomina = {loading: false, data: dataPorcentaje}
    const configuracionNominaTipo:IGetDataConfiguracionNominaTipo = {loading: false, data: dataTipo}
    const configuracionFormaPago:IGetDataConfiguracionFormaPago = {loading: false, data: formasPago}

    return {
        configuracionNominaPorcentaje,
        configuracionNominaTipo,
        configuracionFormaPago,
        onSavedPorcentajes,
    }
}