import '../styles/estilos.scss'
import Button from 'components/Button';
import TableGrid, { IOnSaved } from 'components/TableGrid';
import { IGetTareasEntidad, columnsTareasEntidad, IUpdateTarea, IResponsablesFormated } from 'interfaces/ITareas';
import moment from 'moment';
import { useMemo } from 'react';

interface IProps {
    closePopup: () => boolean;
    onEditTarea: (data: IUpdateTarea, action: string) => Promise<void>;
    dataTareasEntidad: IGetTareasEntidad;
    responsables: IResponsablesFormated[];
}

const FormSeguimiento = (Props:IProps) => {
    const {
        closePopup,
        onEditTarea,
        dataTareasEntidad,
        responsables
    } = Props;

    const cancelPopup = () => {
        closePopup()
    }

    const columsGrid = useMemo(() =>{
        return columnsTareasEntidad(responsables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responsables]);

    const onSaved = (e:IOnSaved[]) =>{
        if(e.length === 0){
            return
        }
        const fechaActualizacion = moment().utc().format('YYYY-MM-DD 00:00:00')
        const {data, type, key} = e[0];
        
        switch(type){
            case 'update':
                const responsable = responsables.find((item) => item.colaborador_id === data.cMotion_Perfil_Colaborador_Id)
                
                const tareaUpdated = {
                    Tarea_Id: data.Tarea_Id,
                    Tarea_Nombre: data.Tarea_Nombre,
                    Tarea_Nota: data.Tarea_Nota,
                    Urgencia: data.Tarea_Urgencia,
                    Estatus: data.Tarea_Estatus,
                    Entidad_Guid: data.Entidad_Guid,
                    Tarea_Fecha_Actualizacion: fechaActualizacion,
                    Colaborador_Guid: responsable?.guid,
                }
              onEditTarea(tareaUpdated, 'update')
              break;
            case 'remove':
              onEditTarea(key, 'remove')
              break;
            default:
              return
        }
      }

    return (
        <>  
        <div style={{ marginBottom: "15px" }}>
            <TableGrid
                columns={columsGrid}
                dataSource={dataTareasEntidad.data}
                enableEditing={{
                    modeEdit: "row", 
                    showIcons: true,
                    enableDelete: true,
                    enableUpdating: true,
                    onSavedChanges: onSaved
                }}
            />
        </div>
        <div style={{display: "flex", justifyContent: "flex-end", marginTop: "15px"}}>
            <Button
                type='button'
                text='Cerrar'
                width={100}
                style={{ backgroundColor: "red"}}
                icon={'fas fa-window-close'}
                onClick={ () => cancelPopup() }
            />
        </div>
        </>
    )
}

export default FormSeguimiento