import { memo } from 'react';
import DataGrid, { Column, Pager } from 'devextreme-react/data-grid';
import { ColumsComisionesPendientesVendedor } from 'functions/Comisiones';
import PropTypes from 'prop-types';
import { onExportingGrid } from 'utils/Functions';

const pageSizes = [25, 50, 75, 100];

const GridComisionesPendientesVendedores = (Props) => {

  const {
    keyStateStoraged = "",
    comisionesSource = [],
  } = Props;

  return (
    <DataGrid
      dataSource={comisionesSource}
      key={"ComisionesPendientes"}
      onDisposing={() => sessionStorage.removeItem(keyStateStoraged.toLocaleUpperCase())}
      onExporting={(e) => onExportingGrid(e, "Comisiones Pendientes", "")}
      export={{ enabled: true }}
      allowColumnResizing={true}
      wordWrapEnabled={true}
      columnAutoWidth={true}
      noDataText="No hay información disponible"
      showBorders={true}
      loadPanel={{ enabled: comisionesSource.length > 0, text: "Cargando..." }}
      scrolling={{
        columnRenderingMode: "virtual"
      }}
      editing={{
        mode: "cell",
        allowUpdating: true
      }}
      paging={{ enabled: true }}
      stateStoring={{ enabled: true, type: 'sessionStorage', storageKey: keyStateStoraged.toLocaleUpperCase() }}
      headerFilter={{ visible: true, allowSearch: true }}
      searchPanel={{ visible: true, highlightCaseSensitive: false, placeholder: 'Buscar', width: 250 }}
      groupPanel={{ visible: true, emptyPanelText: 'Arrastra aqui 👇 la columna a agrupar' }}
      grouping={{ texts: { groupContinuesMessage: "Continua en la siguiente página" }, autoExpandAll: true }}
      columnChooser={{ enabled: true, title: 'Columnas a ocultar', emptyPanelText: 'Arrastra aqui 👇 las columnas a ocultar' }}
      showRowLines={true}
    >
      {
        ColumsComisionesPendientesVendedor.map((column, index) => (
          <Column {...column} key={index} />
        ))
      }
      <Pager
        visible={true}
        allowedPageSizes={pageSizes}
        showPageSizeSelector={true}
      />
    </DataGrid>
  )
}

GridComisionesPendientesVendedores.propTypes = {
  comisionesSource: PropTypes.array,
  keyStateStoraged: PropTypes.string,
  onStartEditing: PropTypes.func,
  isSynchronizing: PropTypes.bool,
  onSyncClick: function (props, propName, componentName) {
    const funct = props[propName];
    if (!funct || (typeof funct !== "function" && funct.length !== 1)) {
      return new Error(`${propName} debe ser una funcion con 1 argumento, ${componentName}`)
    }
  }
}

export default memo(GridComisionesPendientesVendedores)