import { useMemo } from 'react'
import { Select, Button, FilterMultipleGrid } from "components/Index";
import { Popup } from 'devextreme-react'
import { useNominaPagada } from 'api/nomina';
import { useReportAcumulado } from '../hooks/useReportAcumulado';
import Loading from 'pages/Loading';

const ModalAcumulado = () => {

  const { onShowModals, modals } = useNominaPagada();

  const btnCloseOptions = useMemo(() => {
    return {
      text: "CERRAR",
      icon: "close",
      type: "danger",
      width: 150,
      onClick: () => onShowModals("acumulado")
    }
  }, [onShowModals]);

  return (
    <Popup
      closeOnOutsideClick={false}
      title="Reporte Acumulado"
      showTitle={true}
      height={350}
      width={600}
      dragEnabled={false}
      showCloseButton={false}
      visible={modals.acumulado}
      toolbarItems={[{ widget: "dxButton", toolbar: "bottom", location: "after", options: btnCloseOptions }]}
      contentComponent={() => ContentAcumulado(modals.acumulado)}
    />
  )
}

const ContentAcumulado = (status: boolean) => {

  const {
    anios,
    meses,
    error,
    loading,
    reporteAcumulado,
    filters,
    handlerOnSearchAcumulado,
    handlerOnSelectChange,
    handlerOnDownloadAcumulado
  } = useReportAcumulado(status);

  const disabledDownload: boolean = reporteAcumulado.length === 0;

  return (
    <div className='container-modals'>
      <div className='filters-modals'>
        <FilterMultipleGrid
          title="Mes"
          width={200}
          items={meses?.items}
          keyFilter={meses?.key}
          displayFilter="nombreMes"
          onChange={handlerOnSelectChange}
        />
        <Select
          width={145}
          item={anios}
          display='anios'
          keyValue='anios'
          onChangeSelect={handlerOnSelectChange}
          showClearButton={false}
          value={filters.anio}
        />
        <Button
          text='Buscar nominas'
          icon='fas fa-search-dollar'
          width={160}
          className="btnSearch"
          onClick={() => handlerOnSearchAcumulado()}
        />
      </div>
      <br />
      <hr />
      <div className='generate-report'>
        <span className='error-report'>{error}</span>
        {
          loading ? (
            <Loading />
          ) : (
            <Button
              text='DESCARGAR REPORTE'
              icon='fas fa-file-excel'
              width={300}
              className={`btnGenerateReport ${disabledDownload && "disabled"}`}
              disabled={disabledDownload}
              onClick={() => handlerOnDownloadAcumulado()}
            />
          )
        }
      </div>
    </div>
  )
}


export default ModalAcumulado