import { memo, useMemo } from "react";
import { IComisionesPagadas, IComisionPorPagar } from "interfaces/IComisiones";
import { ajustarDecimales } from 'utils/Formatos';
import { useGetDataWithAuth } from "hooks/useGetData";
import { URL_API_TH } from "consts/Services_URL";
import { useAuth } from "api/auth";

interface ITotalComision {
  comisiones: IComisionPorPagar;
}

export const TotalComision = memo(({ comisiones }: ITotalComision) => {

  const totalComision: number = useMemo(() => {
    let totalAcumulado: number = 0;
    const { comisionesAPagar, comisionesSubordinados } = comisiones;
    comisionesAPagar.forEach((comision) => {
      totalAcumulado += comision.totalComision;
    });
    comisionesSubordinados.forEach((comision) => {
      totalAcumulado += comision.totalComision;
    });
    return totalAcumulado
  }, [comisiones]);

  return (
    <div className="container-total_comision">
      <p>Comisión a pagar MXN: <strong>$ {ajustarDecimales(totalComision)}</strong></p>
      <p>Porcentaje : <strong>{comisiones.porcentaje} %</strong></p>
    </div>
  )
})

interface ITotalComisionPagada {
  comisiones: IComisionesPagadas;
}
export const TotalComisionPagada = memo(({ comisiones }: ITotalComisionPagada) => {
  const { User } = useAuth();
  const { data: porcentajeAsimilado = 0 }
    = useGetDataWithAuth(`${URL_API_TH}catalogos/porcentajeAsimilado`, User.token);

  const totalComision: number = useMemo(() => {
    let totalAcumulado: number = 0;
    const { comisionesPagadas, comisionesSubordinados } = comisiones;
    comisionesPagadas.forEach((comision) => {
      totalAcumulado += comision.totalComision;
    });
    comisionesSubordinados.forEach((comision) => {
      totalAcumulado += comision.totalComision;
    });
    return totalAcumulado
  }, [comisiones]);

  return (
    <div className="container-total-comision-pagada">
      <div>
        <p>Porcentaje: <strong>{comisiones.porcentaje} %</strong></p>
      </div>
      <div>
        <p>Total (MXN): <strong>$ {ajustarDecimales(totalComision)}</strong></p>
        <p>Factura (- {porcentajeAsimilado}%) : <strong>$ {ajustarDecimales((totalComision * porcentajeAsimilado) / 100)}</strong></p>
        <p>Total a pagar (MXN) : <strong>$ {ajustarDecimales(totalComision - ((totalComision * porcentajeAsimilado) / 100))}</strong></p>
      </div>
    </div>
  )
})