import { useAuth } from 'api/auth';
import { Button, Switch } from 'devextreme-react';
import { Column, DataGrid, Export, GroupPanel, HeaderFilter, Pager, Paging, SearchPanel } from 'devextreme-react/data-grid';
import { memo, useCallback } from 'react'
import { onExportingGrid } from 'utils/Functions';
import ModalEliminarPerfilColaborador from './ModalEliminarPerfilColaborador';
import { useSWRWithToken } from 'hooks/useSWR';
import { URL_API_IT } from "consts/Services_URL";

const GridPerfilColaborador = ({
    modalDeleteStatus,
    MESSAGE_MODAL_DELETE,
    colaborador,
    setModalDeleteStatus,
    handleDelete,
    handleEdit,
    onSavedPerfil,
    setValorInicial
}) => {

    const { User } = useAuth();
    const perfilesColaboradores = useSWRWithToken(`${URL_API_IT}PerfilColaborador`, 10, User.token);

    const onExporting = useCallback((e) => {
        return onExportingGrid(e, "PerfilColaborador", User.divisionSeleccionada);
    }, [User]);

    const mostrarActivo = useCallback(({ data: { data } }) => {
        return (
            <div>
                <Switch
                    value={data.perfilActivo}
                    onValueChange={() => handleDelete(data)}
                    switchedOffText={'🔴'}
                    switchedOnText={'🟢'}
                />
            </div>
        )
    }, [handleDelete])

    const acciones = useCallback(({ data: { data } }) => {
        return (
            <div className="d-flex">
                <Button icon='edit' type='default' onClick={() => handleEdit(data)} />
            </div>
        )
    }, [handleEdit])

    const onSumbitDelete = useCallback(async (colaboradorReceived) => {
        onSavedPerfil(colaboradorReceived, "delete")
        setModalDeleteStatus(!modalDeleteStatus)
    }, [onSavedPerfil, modalDeleteStatus, setModalDeleteStatus])

    const cancelPopupDelete = useCallback(() => {
        setValorInicial()
        setModalDeleteStatus(!modalDeleteStatus)
    }, [modalDeleteStatus, setModalDeleteStatus, setValorInicial])

    return (
        <>
            <div className="card-container">
                <DataGrid
                    dataSource={perfilesColaboradores.data}
                    key="colaboradorId"
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    onExporting={onExporting}
                    noDataText={"No hay información"}
                >
                    <SearchPanel visible={true} highlightCaseSensitive={false} placeholder={global.inputSearch} />
                    <Paging enabled={true} defaultPageSize={20} />
                    <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} visible={true} />
                    <HeaderFilter visible={true} />
                    <GroupPanel
                        emptyPanelText='Arrastra aqui 👇 la columna a agrupar'
                        visible={true}
                    />
                    <Column
                        dataField='nombreColaborador'
                        dataType='string'
                        alignment='left'
                        caption='Nombre'
                    />
                    <Column
                        dataField="extencion"
                        dataType='string'
                        alignment='left'
                        caption='Extensión'
                    />
                    <Column
                        dataField="usuarioCMotion"
                        dataType='string'
                        alignment='left'
                        caption='Usuario cMotion'
                    />
                    <Column
                        dataField="oficinaNombre"
                        dataType='string'
                        alignment='left'
                        caption='Oficina'
                    />
                    <Column
                        dataField="departamentoNombre"
                        dataType='string'
                        alignment='left'
                        caption='Departamento'
                    />
                    <Column
                        dataField="puestoNombre"
                        dataType='string'
                        alignment='left'
                        caption='Puesto'
                    />
                    <Column
                        dataField="nombreJefe"
                        dataType='string'
                        alignment='left'
                        caption='Jefe'
                    />
                    <Column
                        dataField="email"
                        dataType='string'
                        alignment='left'
                        caption='Email'
                    />
                    <Column
                        caption='Activo'
                        alignment='center'
                        dataField='perfilActivo'
                        cellComponent={mostrarActivo}
                    />
                    <Column
                        caption='Acciones'
                        alignment='center'
                        cellComponent={acciones}
                    />
                    <Export enabled={true} />
                </DataGrid>
            </div>

            {
                modalDeleteStatus && (
                    <ModalEliminarPerfilColaborador
                        statusModal={modalDeleteStatus}
                        closePopup={() => cancelPopupDelete()}
                        message={MESSAGE_MODAL_DELETE}
                        onSumbitDelete={() => onSumbitDelete(colaborador)}
                    />
                )
            }
        </>
    )
}

const pageSizes = [10, 25, 50, 100, 200];

export default memo(GridPerfilColaborador)