import { memo, useMemo } from 'react'
import { Select } from "components/Index";
import { IMesFiltrado, IFechaFiltrado } from 'interfaces/IComisiones';
import { Años, Meses } from 'utils/Global';
import { createNewFilter } from 'utils/Functions';
import { IFilterData, IFilterChange } from 'components/interfaces/Interfaces';

interface IProps {
  fecha: IFechaFiltrado;
  onValueChanged?: (e: IFilterChange) => void;
}

const FechaFilters = ({ fecha, onValueChanged }: IProps) => {

  const meses: IFilterData = useMemo(() => {
    const arrayMeses: IMesFiltrado[] = Meses.map((mes: string, index) => {
      return {
        id: index + 1,
        mes
      }
    });
    return createNewFilter(arrayMeses, fecha.mes, "mes");
  }, [fecha]);

  const años: IFilterData = useMemo(() => {
    return createNewFilter(Años(), fecha.año, "año");
  }, [fecha]);

  const onFechaChanged = (e: IFilterChange): void => {
    onValueChanged && onValueChanged(e);
  }

  return (
    <>
      <Select
        title='Mes'
        width={"100%"}
        styleDropBox="outlined"
        item={meses}
        display="mes"
        keyValue='id'     
        showClearButton={false}
        value={fecha.mes}
        onChangeSelect={(e) => onFechaChanged(e)}
      />
      <Select
        title='Año'
        width={"100%"}
        styleDropBox="outlined"
        item={años}
        showClearButton={false}
        value={fecha.año}
        onChangeSelect={(e) => onFechaChanged(e)}
      />
    </>
  )
}

export default memo(FechaFilters)