import { Column, DataGrid, Summary, TotalItem } from 'devextreme-react/data-grid';
import { ajustarDecimales } from 'utils/Formatos';

const DataGridFacturas = ({ facturaVentas = [], facturaCompras = [] }) => {
  return (
    <div style={{ display: "flex", textAlign: "center" }}>
      <div className="card-container">
        <h2>Venta</h2>
        <DataGrid
          dataSource={facturaVentas}
          key="DetalleOperacionVentaProfit"
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          wordWrapEnabled={true}
        >
          <Column
            dataField="documento_Contable_Numero"
            caption="Embarque"
            dataType="text"
            alignment="left"
          />
          <Column
            dataField="entidad_Razon_Social"
            caption="Razón Social"
            dataType="string"
            alignment="left"
          />
          <Column
            dataField="documento_Contable_Fecha_Creacion"
            caption="Fecha"
            dataType="date"
            alignment="left"
            format="dd-MMM-yyyy"
          />
          <Column
            dataField="monto_MXN"
            caption="MXN"
            dataType="number"
            alignment="left"
            format={ajustarDecimales}
          />
          <Column
            dataField="monto_USD"
            caption="USD"
            dataType="number"
            alignment="left"
            format={ajustarDecimales}
          />
          <Column
            dataField="documento_Contable_Estatus"
            caption="Stat"
            customizeText={formatEstatusFacturas}
            alignment="center"
            width={60}
          />
          <Summary>
            <TotalItem
              column="Monto_MXN"
              summaryType="sum"
              displayFormat="{0}"
              valueFormat={ajustarDecimales}
            />
          </Summary>
        </DataGrid>
      </div>
      <div className="card-container">
        <h2>Compra</h2>
        <DataGrid
          dataSource={facturaCompras}
          key="DetalleOperacionCompraProfit"
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          wordWrapEnabled={true}
        >
          <Column
            dataField="documento_Contable_Numero"
            caption="Embarque"
            dataType="text"
            alignment="left"
          />
          <Column
            dataField="entidad_Razon_Social"
            caption="Razón Social"
            dataType="string"
            alignment="left"
          />
          <Column
            dataField="documento_Contable_Fecha_Creacion"
            caption="Fecha"
            dataType="date"
            alignment="left"
            format="dd-MMM-yyyy"
          />
          <Column
            dataField="monto_MXN"
            caption="MXN"
            dataType="number"
            alignment="left"
            format={ajustarDecimales}
          />
          <Column
            dataField="monto_USD"
            caption="USD"
            dataType="number"
            alignment="left"
            format={ajustarDecimales}
          />
          <Column
            dataField="documento_Contable_Estatus"
            caption="Stat"
            alignment="center"
            customizeText={formatEstatusFacturas}
            width={60}
          />
          <Summary>
            <TotalItem
              column="Monto_MXN"
              summaryType="sum"
              displayFormat="{0}"
              valueFormat={ajustarDecimales}
            />
          </Summary>
        </DataGrid>
      </div>
    </div>
  )
}

const formatEstatusFacturas = ({ value }) => {
  const estatus = value === "Pagado" ? true : false;  
  return `${estatus ? "✅" : "❌"}`;
}

export default DataGridFacturas