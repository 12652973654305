import { IColumnProps } from 'devextreme-react/data-grid';

export const ColumsDocumentosAbiertosEmbarques: IColumnProps[] = [
    {
        caption: "Guid",
        dataField: "Embarque_Guid",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Cliente",
        dataField: "Entidad_Razon_Social",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Numero de Embarque",
        dataField: "Embarque_Numero",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Numero de Factura",
        dataField: "Documento_Contable_Numero",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Metodo",
        dataField: "Embarque_Metodo",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Fecha de Creación",
        dataField: "Embarque_Fecha_Creacion",
        dataType: "date",
        alignment: "center",
        format: "dd/MM/yyyy"
    },
    {
        caption: "Fecha de Arribo",
        dataField: "Embarque_Fecha_Arribo",
        dataType: "date",
        alignment: "center",
        format: "dd/MM/yyyy"
    },
    {
        caption: "Fecha Pickup",
        dataField: "Embarque_Fecha_Estimada_Arribo",
        dataType: "date",
        alignment: "center",
        format: "dd/MM/yyyy"
    },
    {
        caption: "Estrategia",
        dataField: "Embarque_Estrategia_Cross",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Guid Documento Contable",
        dataField: "Documento_Contable_Guid",
        dataType: "string",
        alignment: "center",
    }
  ];