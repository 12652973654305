import { useAuth } from "api/auth";
import { TareasService } from "api/tareas";
import { IGetDataResponsables, IGetTareasEntidad, IResponsablesFormated, IUpdateTarea } from "interfaces/ITareas"
import { useMemo, useState } from "react";

interface IUseTareas {
    modalTareasStatus: boolean;
    dataTareasEntidad: IGetTareasEntidad;
    responsables: IGetDataResponsables;
    handleTareasPerfilCliente: (entidadGuid: string) => Promise<void>;
    getResponsables: () => Promise<any>;
    setModalTareasStatus(e: boolean): void;
    onEditTarea: (data: IUpdateTarea, action: string) => Promise<void>;
}

export const useTareas = (): IUseTareas => {
    const {User} = useAuth();
    const [dataTareasEntidad, setDataTareasEntidad] = useState<IGetTareasEntidad>({ loading: false, data: [] });
    const [responsables, setResponsables] = useState<IGetDataResponsables>({ loading: false, data: [] });
    const [modalTareasStatus, setModalTareasStatus] = useState(false);
    
    const tareasService = useMemo(() => {
        return TareasService(User);
    },[User])

    const handleTareasPerfilCliente = async (entidadGuid: string): Promise<void> => {
        getTareasEntidadGuid(entidadGuid);
        setModalTareasStatus(true);
    }

    const getTareasEntidadGuid = async (id: string): Promise<void> => {
        setDataTareasEntidad({ loading: true });
        const { data } = await tareasService.getTareasEntidad(id);
        setDataTareasEntidad({ loading: false, data });
    }

    const getResponsables = async(): Promise<void> => {
        const { data } = await tareasService.getResponsables();
        let responsablesTransformados: IResponsablesFormated[];

        responsablesTransformados = data.map(responsable => ({
            colaborador: `${responsable.cMotion_Perfil_Colaborador_Nombre} ${responsable.cMotion_Perfil_Colaborador_Apellido_Paterno} ${responsable.cMotion_Perfil_Colaborador_Apellido_Materno}`,
            guid: responsable.cMotion_Perfil_Colaborador_Guid,
            colaborador_id: responsable.colaborador_id
        }));

        setResponsables({loading: false, data: responsablesTransformados});
    }

    const updateTarea = async (tarea: IUpdateTarea): Promise<void> => {
        await tareasService.updateTarea(tarea);
    }

    const deleteTarea = async (id: number): Promise<void> => {
        await tareasService.deleteTarea(id);
    }

    const onEditTarea = async(data: IUpdateTarea, action: string) => {
        switch(action){
            case 'update':
                await updateTarea(data);
                await getTareasEntidadGuid(data.Entidad_Guid!);
                break;
            case 'remove':
                await deleteTarea(data.Tarea_Id);
                await getTareasEntidadGuid(data.Entidad_Guid!);
                break;
            default:
                return
        }
    }

    return {
        modalTareasStatus,
        dataTareasEntidad,
        responsables,
        handleTareasPerfilCliente,
        setModalTareasStatus,
        getResponsables,
        onEditTarea
    }
}