import { memo, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { ItemGrid } from './ItemGrid';
import { useNavigate } from 'react-router-dom';
import './styles/estilos.scss';
import { Route } from '../routes/routes';
import { useMenu } from 'contexts/MenuContext';

const Menu = () => {

  return (
    <Grid item>
      <ItemGrid>
        <ContentMenu />
        <ContentSubMenu />
      </ItemGrid>
    </Grid>
  )
}

const ContentMenu = () => {

  const navigate = useNavigate();
  const { menuItems, itemSelected, handlerItemsMenu } = useMenu();

  return (
    <div className='menu-container-main'>
      <div className='menu-logo'>
        <div
          className='logo'
          onClick={() => navigate("/home")}
        ></div>
      </div>
      <div className='menu-navigation'>
        {
          menuItems.map((navigation, index) => (
            <div
              className={`icon-container ${navigation.id === itemSelected.id && "icon-selected"}`}
              key={index}
              onClick={() => handlerItemsMenu(navigation, () => navigate(navigation.to))}
            >
              <span title={navigation.title}><i className={`icon ${navigation.icon}`}></i></span>
            </div>
          ))
        }
      </div>
    </div>
  )
}

const ContentSubMenu = () => {

  const { isOpenMenu, itemSelected } = useMenu();

  const statusMenu: string = useMemo(() =>
    isOpenMenu === null ? 'default-width-container ' :
      isOpenMenu ? 'openMenu' : 'closeMenu'
    , [isOpenMenu]);

  return (
    <div className={`menu-container-submenu ${statusMenu}`}>
      <div className='container-submenu'>
        <h2>{itemSelected.title}</h2>
        <hr />
        <OpcionesSubMenu />
      </div>
    </div>
  )
}

const OpcionesSubMenu = () => {

  const navigate = useNavigate();
  const { subMenuItems, subItemSelected, handlerSubItemsMenu } = useMenu();

  const ContentWithoutSubItems = (route: Route) => {
    const { to, path, icon, title } = route;
    return (
      <div
        className={`opcion ${subItemSelected?.to === to && "opcion-selected"}`}
        key={path}
        onClick={() => handlerSubItemsMenu(route, () => navigate(to))}
      >
        <i className={`icon ${icon}`}></i>
        <p>{title}</p>
      </div>
    )
  }
  const ContentWithSubItems = (route: Route) => {
    const { path, title, icon, items = [] } = route;

    const statusParentItem =
      items.find((route) => route.to === subItemSelected?.to) && "opcion-details-selected";

    return (
      <details
        key={path}
        className={`opcion-details ${statusParentItem}`}
      >
        <summary><i className={`icon ${icon}`}></i><span>{title}</span></summary>
        {
          items.map((subRoute) => (
            <div
              className={`sub-opcion ${subItemSelected?.to === subRoute.to && "sub-opcion-selected"}`}
              onClick={() => handlerSubItemsMenu(subRoute, () => navigate(subRoute.to))}
              key={subRoute.path}
            >
              <i className={`icon ${subRoute.icon}`}></i>
              <p>{subRoute.title}</p>
            </div>
          ))
        }
      </details>
    )
  }

  return (
    <div className='container-opciones'>
      {
        subMenuItems.map((subRoute) => (
          subRoute.items!.length === 0 ? (
            ContentWithoutSubItems(subRoute)
          ) : (
            ContentWithSubItems(subRoute)
          )
        ))
      }
    </div>
  )
}

export default memo(Menu)