import GridClientesDemoras from "./components/GridClientesDemoras";
const ConfiguracionClientesDemoras = () => {

  return (
    <>
      <div className='container-comisiones_modules'>
        <GridClientesDemoras/>
      </div>
    </>
  )
}


export default ConfiguracionClientesDemoras;