import { useNominaPagada } from 'api/nomina';
import { TableGrid } from 'components/Index';
import { IColumnProps } from 'devextreme-react/data-grid';
import Loading from 'pages/Loading';

interface IProps {
  columsGrid?: IColumnProps[];
}

const GridNominasPagadas = ({ columsGrid }: IProps) => {

  const { nominas, loading } = useNominaPagada();

  if (loading) {
    return <Loading />
  }

  return (
    <div className='container-grid'>
      <TableGrid
        dataSource={nominas}
        columns={columsGrid}
        enableHeaderFilter={true}
        enableGroupPanel={true}
        enableSearchPanel={true}
        enableHoverState={true}
        showRowLines={true}
        enableColumnChooser={true}
        enableSessionStorage={{ enabled: true, keyStorage: 'gridNominasPagadas' }}
        enableEditing={{ modeEdit: 'cell', showIcons: true, enableUpdating: true }}
      />
    </div>
  )
}

export default GridNominasPagadas