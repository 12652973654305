import axios, { AxiosRequestConfig } from "axios";
import { IFilterData, IFilterMultiple } from "components/interfaces/Interfaces";
import { URL_API_ADMINISTRACION } from "consts/Services_URL";
import { IRemoteAñosFacturadosProv, IRemoteMonedaProv, IRemoteProveedores } from "interfaces/IProveedores";
import { IUser } from "interfaces/IUsuario";
import { createNewFilter, createNewFilterMultiple } from "utils/Functions";
import { HttpErrorMessage } from "utils/ResponseAPI";
import { getDataAsync } from "./fetch";
import { IResponse } from "./interfaces/interfaces";
import { IColumnProps } from 'devextreme-react/data-grid';
import { ajustarDecimales } from "utils/Formatos";

interface IGetDataFacturacionProveedor {
    años: number[];
    moneda: string[];
    proveedores: string[];
}

interface IResponseFacturacionProveedores extends IResponse {
    data?: ICatalogosFacturacionproveedores
}

export interface ICatalogosFacturacionproveedores {
    años: IFilterMultiple;
    proveedores: IFilterMultiple;
    moneda: IFilterData;
}

export const ProveedoresService = (User: IUser) => {
    const { token, divisionSeleccionada: division } = User;
    const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } }

    return {
        getDataFacturacionProveedor: async (filters: string) : Promise<IResponse> =>{          
            const response = await getDataAsync(`${URL_API_ADMINISTRACION}facturacionAnualProveedores?filtros=${filters}&division=${division}`, headers)
            return response;
        },

        getDataDeclaracionProveedor: async (fechaInicial: string, fechaFinal: string): Promise<IResponse> => {
            const response = getDataAsync(`${URL_API_ADMINISTRACION}declaracionImpuestosProveedor?fechaInicio=${fechaInicial}&fechaFinal=${fechaFinal}`, headers);
            return response;
        },

        getCatalogosFacturacionAnual: async (): Promise<IResponseFacturacionProveedores> => {
            let response: IResponseFacturacionProveedores = { result: false };
            await Promise.all([
                axios.get(`${URL_API_ADMINISTRACION}catalogos/aniosFacturacion?division=${division}`, headers),
                axios.get(`${URL_API_ADMINISTRACION}Catalogos/proveedores?division=${division}`, headers),
                axios.get(`${URL_API_ADMINISTRACION}catalogos/monedas?division=${division}`, headers)
            ]).then(([añosFacturados, proveedores, moneda]) => {
                const añosFacturadosRemote: IRemoteAñosFacturadosProv[] = añosFacturados.data;
                const proveedorRemote: IRemoteProveedores[] = proveedores.data;
                const monedaRemote: IRemoteMonedaProv[] = moneda.data;

                const catalogos: IGetDataFacturacionProveedor = {
                    años: añosFacturadosRemote.map((año) => { return año.anio }),
                    proveedores: proveedorRemote.map((proveedor) => { return proveedor.razonSocial }),
                    moneda: monedaRemote.map((moneda) => { return moneda.claveMoneda })
                }
                const añosFilter: IFilterMultiple = createNewFilterMultiple(catalogos.años, 'años', []);
                const proveedorFilter: IFilterMultiple = createNewFilterMultiple(catalogos.proveedores, 'proveedores', []);
                const monedaFilter: IFilterData = createNewFilter(catalogos.moneda, "", "moneda")

                response = { ...response, data: { años: añosFilter, proveedores: proveedorFilter, moneda: monedaFilter }, result: true }
            }).catch((err) => {
                const error = JSON.parse(JSON.stringify(err));
                const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
                response = { ...response, error: messageError, estatus: error.status }
            })
            return response
        }
    }
}

export const columsDeclaracionAnualProveedores: IColumnProps[] = [
    {
        dataField: 'rfc',
        dataType: 'string',
        alignment: 'left',
        width: 150,
        caption: 'RFC',
    },
    {
        dataField: 'razon_Social',
        dataType: 'string',
        alignment: 'left',
        caption: 'Razón Social',
        width: 250,
    },
    {
        dataField: 'division',
        dataType: 'string',
        alignment: 'left',
        caption: 'Division'
    },
    {
        dataField: 'num_Doc',
        dataType: 'string',
        alignment: 'left',
        caption: '# Doc',
        width: 100,
    },
    {
        dataField: 'fecha_Documento',
        dataType: 'date',
        format: "dd/MM/yyyy",
        alignment: 'left',
        caption: 'Fecha Documento'
    },
    {
        dataField: 'total_Factura',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Total Factura'
    },
    {
        dataField: 'subtotal_IVA_16',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Subtotal IVA 16'
    },
    {
        dataField: 'subtotal_IVA_8',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Subtotal IVA 8'
    },
    {
        dataField: 'subtotal_IVA_0',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Subtotal IVA 0'
    },
    {
        dataField: 'ivA_No_Objeto',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Subtotal IVA no objeto'
    },
    {
        dataField: 'ivA_16',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'IVA 16'
    },
    {
        dataField: 'ivA_8',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'IVA 8'
    },
    {
        dataField: 'total_Ret_IVA',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Total Ret IVA'
    },
    {
        dataField: 'ivaO6',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'IVA06'
    },
    {
        dataField: 'ivarf',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'IVARF'
    },
    {
        dataField: 'ivarh',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'IVARH'
    },
    {
        dataField: 'ivarar',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'IVARAR'
    },
    {
        dataField: 'ivaresico',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'IVARESICO'
    },
    {
        dataField: 'isrrh',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'ISRRH'
    },
    {
        dataField: 'isrra',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'ISRRA'
    },
    {
        dataField: 'isrresico',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'ISRRESICO'
    },
    {
        dataField: 'cedrhon',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'CEDRHON'
    },
    {
        dataField: 'cedrarr',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'CEDRARR'
    },
    {
        dataField: 'cedresico',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'CEDRESICO'
    },
    {
        dataField: 'importe_Cobrado_Factura',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Importe Cobrado Factura'
    },
    {
        dataField: 'fecha_de_Pago',
        dataType: 'date',
        format: "dd/MM/yyyy",
        alignment: 'left',
        caption: 'Fecha de pago'
    },
    {
        dataField: 'tC_Factura',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'TC Factura'
    },
    {
        dataField: 'tC_Pago',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'TC Cobro'
    },
    {
        dataField: 'diferencia',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Diferencia'
    },
    {
        dataField: 'documento_Contable_Embarque_Numero',
        dataType: 'string',
        format: ajustarDecimales,
        alignment: 'left',
        caption: '#Embarque'
    },


]