import { useCallback, useEffect, useState, useMemo } from 'react';
import { RemoteFilterCanceladasAdapter } from 'adapters/Operaciones';
import { useAuth } from 'api/auth';
import { IFilterMultiple, IFilterChange, IFilterData } from 'components/interfaces/Interfaces';
import { OperacionesService } from 'api/operaciones';
import { IFilterOpsCanceladas, IGetDataOperaciones } from 'interfaces/IOperaciones';
import { ICatalogosOpsCanceladas } from 'api/operaciones';
import { ExportingEvent } from 'devextreme/ui/pivot_grid';
import { onExportingPivot } from 'utils/Functions';

interface IUseOpsCanceladas {
  operacionesCanceladas: IGetDataOperaciones;
  catalogos: IGetCatalogosCanceladas;
  handlerOnSelectChange: (e: IFilterChange) => void;
  handleFiltrado: () => Promise<void>;
  onExporting: (e: ExportingEvent) => void;
}
export interface IGetCatalogosCanceladas {
  años?: IFilterMultiple;
  estrategias?: IFilterData;
  motivos?: IFilterData
  error?: string | null;
  loading: boolean;
}
const defaultFilters: IFilterOpsCanceladas = {
  años: [],
  estrategiaOrigen: "",
  motivoCancelacion: ""
}

export const useOpsCanceladas = (): IUseOpsCanceladas => {
  const { User } = useAuth();
  const [filters, setFilters] = useState<IFilterOpsCanceladas>(defaultFilters);
  const [catalogos, setCatalogos] = useState<IGetCatalogosCanceladas>({ loading: false });
  const [operacionesCanceladas, setOperacionesCanceladas] = useState<IGetDataOperaciones>({ loading: false });

  const opsService = useMemo(() => {
    return OperacionesService(User);
  }, [User]);

  const onExporting = useCallback((e: ExportingEvent) => {
    return onExportingPivot(e, "OpsCanceladas", User.divisionSeleccionada!);
  }, [User]);

  const handleFiltrado = async (): Promise<void> => {
    setOperacionesCanceladas({ loading: true });
    const filtersAdapter: string = JSON.stringify(RemoteFilterCanceladasAdapter(filters));
    const { result, data, error } = await opsService.getDataOpsCanceladas(filtersAdapter);
    if (!result) {
      setOperacionesCanceladas({ error: error, loading: false });
      return
    }
    setOperacionesCanceladas({ data: data, loading: false, });
  }

  const handlerOnSelectChange = (e: IFilterChange): void => {
    const { key, value } = e;
    setFilters(prev => {
      return {
        ...prev,
        [key]: value
      }
    });
  }

  /**GET CATALOGOS */
  useEffect(() => {
    const getCatalogos = async (): Promise<void> => {
      setCatalogos({ loading: true });
      const { result, data, error } = await opsService.getCatalogosOpsCanceladas();
      if (!result) {
        setCatalogos({ error: error, loading: false });
        return
      }
      const filtersCatalogos: ICatalogosOpsCanceladas = data!;    
      setCatalogos({ años: filtersCatalogos.años, estrategias: filtersCatalogos.estrategias, motivos: filtersCatalogos.motivosCancelacion, loading: false });
    }
    getCatalogos();
  }, [opsService]);

  /**GET DATA INICIAL */
  useEffect(() => {
    const getInitialData = async (): Promise<void> => {
      setOperacionesCanceladas({ loading: true });
      const filtersAdapter: string = JSON.stringify(RemoteFilterCanceladasAdapter(defaultFilters));
      const { result, data, error } = await opsService.getDataOpsCanceladas(filtersAdapter);
      if (!result) {
        setOperacionesCanceladas({ error: error, loading: false });
        return
      }
      setOperacionesCanceladas({ data: data, loading: false, });
    }
    getInitialData();
  }, [opsService]);

  return {
    operacionesCanceladas,
    catalogos,
    handlerOnSelectChange,
    handleFiltrado,
    onExporting
  }
}