import { URL_API_FIREBASE_APP } from "consts/Services_URL";
import { SyncService } from "api/sync";
import Button from "components/Button"
import Select from "components/Select";
import TableGrid from "components/TableGrid"
import { Popup } from "devextreme-react";
import Loading from "pages/Loading";
import { useEffect, useState } from "react";
import notify from "devextreme/ui/notify";
import axios from "axios";
import { useAuth } from "api/auth";

const GridSyncGuid = ({
    isDeleted = false
}) => {
    const { User } = useAuth();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [tipo, setTipo] = useState(null);
    const [guids, setGuids] = useState('');
    const [keepAlive, setKeepAlive] = useState(false);

    const onHandlerSync = async () => {
        const syncData = data.map((item) => ({
            Tipo: item.reporte_Sync_Tipo,
            Guid: item.reporte_Sync_Guid,
            isDeleted,
        }));

        setLoading(true);

        try {
            await SyncService().syncAdd(syncData, User);
            notify('Todos los datos se agregaron a la cola', 'success', 2000);
            setLoading(false);
            setData([]);
        } catch (error) {
            console.log(error);
            notify('Error al sincronizar', 'error', 2000);
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchKeepAlive = async () => {
            try {
                const data = await axios.get(`${URL_API_FIREBASE_APP}/keepAlive`);

                if (data.status !== 200) {
                    setKeepAlive(false);
                    return;
                }
                setKeepAlive(true);
            } catch (error) {
                setKeepAlive(false);
            }
        }
        fetchKeepAlive();
    }, []);

    return (
        <div className='container-grid'>
            {
                loading ?
                <Loading />
                : (
                    <>
                    <Popup
                        visible={openModal}
                        onHiding={() => setOpenModal(false)}
                        title="Agregar GUID o Job Number"
                        width={300}
                        height={400}
                        contentRender={() => (
                            <>
                                <Select 
                                    title="Tipo"
                                    item={{
                                        key: 'Sync',
                                        title: 'Sync',
                                        items: 
                                        !isDeleted ? 
                                        [
                                            "Embarque",
                                            "Cliente",
                                            "Proveedor",
                                            "Facturas de Pago",
                                            "Facturas de Cobro",
                                            "Cheques",
                                            "Pagos",
                                            "Cotizaciones"
                                        ]
                                        : [
                                            "Facturas de Pago",
                                            "Facturas de Cobro",
                                            "Cheques",
                                            "Pagos",
                                            "Embarque"
                                        ]
                                    }}
                                    onChangeSelect={(e) => {
                                        setTipo(e.value);
                                    }}
                                />
                                <textarea 
                                    className="textarea"
                                    placeholder="GUIDs"
                                    style={{width: '100%', marginTop: 10, height: 200}}
                                    value={guids}
                                    onChange={(e) => setGuids(e.target.value)}
                                />
                                <Button
                                    disabled={loading}
                                    icon={`fas fa-${loading ? 'spin' : 'sync-alt'}}`}
                                    text='Agregar GUIDs'
                                    width={250}
                                    iconHover="Claro"
                                    style={{backgroundColor:"#10A800"}}
                                    onClick={() => {
                                        const guidsArray = guids.split('\n');

                                        if ( !tipo || !guidsArray.length ) return;

                                        for (let i = 0; i < guidsArray.length; i++) {
                                            for (let j = 0; j < guidsArray.length; j++) {
                                                
                                                if (guidsArray[i] === guidsArray[j] && i !== j) {
                                                    guidsArray.splice(j, 1);
                                                }
                                            }
                                        }

                                        for(let i = 0; i < guidsArray.length; i++) {
                                            for(let j = 0; j < data.length; j++) {
                                                if(guidsArray[i] === data[j].reporte_Sync_Guid) {
                                                    guidsArray.splice(i, 1);
                                                }
                                            }
                                        }


                                        setLoading(true);
                                        setTimeout(() => {
                                            setData([
                                                ...data,
                                                ...guidsArray.map((guid) => ({
                                                    reporte_Sync_Tipo: tipo,
                                                    reporte_Sync_Guid: guid,
                                                }))
                                            ]);
                                            setLoading(false);
                                            setOpenModal(false);
                                            setGuids('');
                                        }, 2000);
                                    }}
                                />
                            </>

                        )}
                    />
                    <TableGrid
                        dataSource={data}
                        columns={[
                            { dataField: 'reporte_Sync_Tipo', caption: 'Tipo', },
                            { dataField: 'reporte_Sync_Guid', caption: 'GUID' },
                        ]}
                        enableHeaderFilter={true}
                        enableGroupPanel={true}
                        enableSearchPanel={true}
                        enableHoverState={true}
                        showRowLines={true}
                        enableColumnChooser={true}
                        enableSessionStorage={{ enabled: true, keyStorage: 'gridSync' }}
                        enableEditing={{ modeEdit: 'cell', enableDelete:true, showIcons: true, enableUpdating: true, onSavedChanges: () => { } }}
                    />
                    <div className='container-buttons'>
                        <Button
                            disabled={loading}
                            icon={`fas fa-${loading ? 'spin' : 'sync-alt'}}`}
                            text='Agregar una lista de GUIDs o Job Numbers'
                            width={250}
                            iconHover="Claro"
                            onClick={() => setOpenModal(true)}
                        />
                        <Button
                            disabled={loading || !keepAlive}
                            icon={`fas fa-${loading ? 'spin' : 'sync-alt'}}`}
                            text={
                                !keepAlive ? 'Servicio no disponible' :
                                isDeleted ? 'Eliminar GUIDs o Job Numbers' : 'Sincronizar GUID o Job Number'}
                            width={250}
                            iconHover="Claro"
                            style={{backgroundColor: !keepAlive ? "#D33" : "#10A800"}}
                            onClick={onHandlerSync}
                        />
                    </div>
                    </>
                )
            }
        </div>
    )
}

export default GridSyncGuid