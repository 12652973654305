import { CSSProperties, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { MapaEstrategias } from 'images/Index';
import { FormatoSinDecimales, FormatText } from 'utils/Formatos';
import { useBudgetEstrategias } from 'contexts/EstrategiasContext';
import { IBudgetEstrategia } from 'interfaces/IHome';

interface IProps {
  className?: string;
  style?: CSSProperties
}

const BudgetEstrategia = ({ className, style }: IProps) => {

  const { loading } = useBudgetEstrategias();

  if (loading) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="text" width={"100%"} />
        <Skeleton variant="rectangular" width={"100%"} height={"100%"} animation='wave' />
      </Stack >
    )
  }

  return (
    <div className={className} style={style}>
      <div className='container-mapa'>
        <img alt='Mapa' src={MapaEstrategias} />
      </div>
      <ContentEstrategias />
    </div>
  )
}

interface IHoverClass {
  titles: string;
  porcentaje: string;
  barraPorcentaje: string;
}

const ContentEstrategias = () => {
  const {
    estrategias,
    estrategiasSelected,
    handlerOnSelectEstrategia
  } = useBudgetEstrategias();

  const hoverEstrategia: IHoverClass = useMemo(() => {
    let hoverClass: IHoverClass = { titles: "", porcentaje: "", barraPorcentaje: "" }
    if (!estrategiasSelected) return hoverClass;
    const { porcentajeCumplimiento } = estrategiasSelected.actual;
    hoverClass = { titles: "#15374C", porcentaje: "#15374C", barraPorcentaje: "#1786FF" }
    if (porcentajeCumplimiento <= 21) {
      return hoverClass;
    }
    if (porcentajeCumplimiento < 95 && porcentajeCumplimiento > 21) {
      return { ...hoverClass, porcentaje: "#fff" }
    }
    return { ...hoverClass, titles: "#fff", porcentaje: "#fff" }

  }, [estrategiasSelected]);

  const nombreEstrategia: string | undefined = estrategiasSelected?.estrategia;

  const BarEstrategia = (item: IBudgetEstrategia): JSX.Element => {
    const { estrategia, actual } = item;
    return (
      <div
        className='estrategia-container'
        key={estrategia}
        onClick={() => handlerOnSelectEstrategia(item)}
      >
        <div
          className='operaciones'
          style={{
            color: estrategia === nombreEstrategia ? hoverEstrategia.titles : ""
          }}
        >
          <p>{FormatoSinDecimales(actual.operaciones)} OPS</p>
          <p>{FormatText(estrategia)}</p>
        </div>
        <p
          className='porcentaje'
          style={{
            color: estrategia === nombreEstrategia ? hoverEstrategia.porcentaje : ""
          }}>{actual.porcentajeCumplimiento} <span>%</span></p>
        <span className='animacion-porcentaje' style={{
          width: !actual.porcentajeCumplimiento ? `${5}%` :
            actual.porcentajeCumplimiento > 100 ? `${110}%` :
              `${actual.porcentajeCumplimiento}%`,
          backgroundColor: estrategia === nombreEstrategia ? hoverEstrategia.barraPorcentaje : ""
        }}>

          <span className='first-span'></span>
          <span className='second-span'></span>
          <span className="third-span"
            style={{
              backgroundColor: estrategia === nombreEstrategia ? hoverEstrategia.barraPorcentaje : ""
            }}

          ></span>
        </span>
      </div>
    )
  }
  return (
    <div className='container-estrategias'>
      <p className='title'>Operaciones por <strong>estrategia</strong></p>
      {
        estrategias.map((estrategia) => (
          BarEstrategia(estrategia)
        ))
      }
    </div>
  )
}



export default BudgetEstrategia