import { useAuth } from "api/auth";
import {  IGetDataCatalogoClientes } from "interfaces/IClientes";
import { IGetDataClientexExentosDemoras } from "interfaces/IComisiones";
import { useCallback, useMemo, useState } from "react";
import { ComisionesService } from 'api/comisiones';
import notify from "devextreme/ui/notify";
import { ClientesService } from "api/clientes";

interface IUseClientesExentosDemoras {
  catalogoClientes: IGetDataCatalogoClientes;
  clientesExentosDemoras: IGetDataClientexExentosDemoras;
  getCatalogoClientes: () => Promise<void>;
  getClientesExentosDemoras: () => Promise<void>;
  insertClientesExentosDemoras: (entidad_guids: string[]) => Promise<void>;
  deleteClienteExentoDemora: (entidad_guid: string) => Promise<void>;
}

export const useClientesExentosDemoras = (): IUseClientesExentosDemoras => {
  const { User } = useAuth();
  const [ catalogoClientes, setCatalogoClientes ] = useState<IGetDataCatalogoClientes>({ loading: false, data: [] });
  const [ clientesExentosDemoras, setClientesExentosDemoras ] = useState<IGetDataClientexExentosDemoras>({ loading: false, data: [] });

    const opsClientes = useCallback(() => {
        return ClientesService(User);
    }, [User]);

    const comisionesService = useMemo(() => {
      return ComisionesService(User);
    }, [User]);


  const getCatalogoClientes = async (): Promise<void> => {
    setCatalogoClientes({ loading: true });
    const { result, data } = await opsClientes().getCatalogosClientes();
    if (!result) {
        setCatalogoClientes({ loading: false });
    }
    setCatalogoClientes({ data: data, loading: false });
  }

  const getClientesExentosDemoras = async (): Promise<void> => {
    setClientesExentosDemoras({ loading: true });
    const { result, data } = await comisionesService.getClientesExentosDemoras();
    if (!result) {
        setClientesExentosDemoras({ loading: false });
    }
    setClientesExentosDemoras({ data: data, loading: false });
  }

  const insertClientesExentosDemoras = async (entidad_guids: string[]): Promise<void> => {
    const { result } = await comisionesService.insertClientesExentosDemoras(entidad_guids);
    if (result) {
        notify('Clientes exentos de demoras agregados correctamente', 'success', 2000);
    } else {
      notify('Error al insertar clientes exentos de demoras', 'error', 2000);
    }
  }

  const deleteClienteExentoDemora = async (entidad_guid: string): Promise<void> => {
    const { result } = await comisionesService.deleteClienteExentoDemoras(entidad_guid);
    if (result) {
        notify('Cliente exento de demoras eliminado correctamente', 'success', 2000);
    } else {
      notify('Error al eliminar cliente exento de demoras', 'error', 2000);
    }
  }


  return {
    catalogoClientes,
    clientesExentosDemoras,
    getCatalogoClientes,
    getClientesExentosDemoras,
    insertClientesExentosDemoras,
    deleteClienteExentoDemora
  }
}