import { ajustarDecimales } from "utils/Formatos";

export const _FieldFacturacionClientes: any[] =[
    {
        caption: 'Numero de Embarque',
        width: 450,
        dataField: 'embarque_Numero',
        area: 'row',
        expanded: false
    },
    
    {
        caption: 'Movimiento',
        dataField: 'producto_Descripcion',
        width: 450,
        expanded: false,
        area: 'row'
    },
    
    {
        caption: 'Gasto originado',
        dataField: 'totalGasto',
        area: 'data',
        expanded: true,
        width: 100,
        format: ajustarDecimales,
        summaryType: 'sum',
    }

]