import { useAuth } from 'api/auth'
import { columnsConfPagoDivisiones } from 'api/configuracion'
import TableGrid, { IOnSaved } from 'components/TableGrid'
import { URL_API_CF } from 'consts/Services_URL'
import { useSWRWithToken } from 'hooks/useSWR'
import { IRemoteConfiguracionComisionesPago } from 'interfaces/IConfiguracion'
import { memo } from 'react'

interface IProps{
  onSavedDivision:(data: IRemoteConfiguracionComisionesPago, action: string) => void
}

const GridPagoDivisiones = (Props: IProps) => {
  const {User} = useAuth();
  const {data: pagoDivisiones=[]} = useSWRWithToken(`${URL_API_CF}ConfigComisiones/pagoDivision`,10, User.token!)

  const {
    onSavedDivision
  } = Props
  
  const onSaved = (e:IOnSaved[]) =>{
    if(e.length === 0){
        return
    }
    const {data, type, key} = e[0];
    switch(type){
        case 'insert':
          onSavedDivision(data, 'insert')
          break;
        case 'update':
          onSavedDivision(data, 'update')
          break;
        case 'remove':
          onSavedDivision(key, 'remove')
          break;
        default:
          return
    }
  }

  return (
    <div className='card-container'>
        <h2>Pago por Divisiones</h2>
        <TableGrid
          columns={columnsConfPagoDivisiones}
          dataSource={pagoDivisiones}
          enableSessionStorage={{ enabled: true, keyStorage: 'gridPagoPorDivisiones' }}
          enableEditing={{
            modeEdit: "row", 
            showIcons: true,
            enableInsert: true,
            enableUpdating: true,
            onSavedChanges:onSaved
        }}
      />
    </div>
  )
}

export default memo(GridPagoDivisiones)