import FiltersPagoNomina from './components/FiltersPagoNomina';
import GridPagoNomina from './components/GridPagoNomina';
import { NominaProvider } from 'contexts/NominaContext';
import './styles/estilos.scss';
import MenuFilters from 'components/MenuFilters';

const PagoNomina = () => {
  return (
    <NominaProvider>
      <div className='container-modulo-th'>
        <MenuFilters>
          <FiltersPagoNomina />
        </MenuFilters>    
        <GridPagoNomina />
      </div>
    </NominaProvider>
  )
}

export default PagoNomina