import {useMemo} from 'react'
import { columsConfiguracionPorcentajes } from 'api/configuracion'
import TableGrid, { IOnSaved } from 'components/TableGrid'
import {IRemoteConfiguracionNomina} from 'interfaces/IConfiguracion';
import { IRemoteFormaPago } from 'interfaces/IConfiguracion'

interface IProps {
  configuracionData : IRemoteConfiguracionNomina[],
  onSavedPorcentajes: (data:IRemoteConfiguracionNomina, action: string) => void;
  formasPago: IRemoteFormaPago[];
}

const GridPorcentajes = (Props:IProps) => {

  const {
    configuracionData, 
    formasPago,
    onSavedPorcentajes
  } = Props;

  const columsGrid = useMemo(() =>{
    console.log(formasPago)
    return columsConfiguracionPorcentajes(formasPago);
  },[formasPago]);

  const onSaved = (e:IOnSaved[]) =>{
    if(e.length === 0){
        return
    }
    const {data, type, key} = e[0];
    switch(type){
        case 'insert':
          onSavedPorcentajes(data, 'insert')
          break;
        case 'update':
          onSavedPorcentajes(data, 'update')
          break;
        case 'remove':
          onSavedPorcentajes(key, 'remove')
          break;
        default:
          return
    }
  }

  return (
    <div className='card-container'>
      <h2>Porcentajes de pago</h2>
      <TableGrid
        columns={columsGrid}
        dataSource={configuracionData}
        enableEditing={{
          modeEdit: "row", 
          showIcons: true,
          enableDelete: true,
          enableInsert: true,
          enableUpdating: true,
          onSavedChanges:onSaved
        }}
      />
    </div>
  )
}

export default GridPorcentajes