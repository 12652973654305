import { useContext, FormEvent } from "react";
import { Button, TextBox, AuthContext } from "./Index";
import { LogoCmotion } from "images/Index";
import { useNavigate } from "react-router-dom";
import Carousel from "pages/login/components/carousel";
import "./styles/estilos.scss";

const LoginForm = () => {
  const navigate = useNavigate();
  const { onLogin, loading } = useContext(AuthContext);

  const handlerLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData: FormData = new FormData(e.currentTarget);
    const usuario: string = formData.get("usuario")!.toString();
    const password: string = formData.get("password")!.toString();
    await onLogin(usuario, password, () => navigate("/home"));
  };

  return (
    <div className="container">
      <div className="form-container">
        <div className="formulario">
          <img src={LogoCmotion} alt="C-MOTION" className="logo" />
          <h1 className="form-title">Bienvenido :)</h1>
          <p className="form-info" style={{ marginTop: "1rem" }}>
            Para mantener conectado con nosotros, inicia sesión con tu
            información personal por correo electronico y contraseña.
          </p>
          <form onSubmit={(e) => handlerLogin(e)}>
            <TextBox
              height={40}
              name="usuario"
              required={true}
              placeholder="Usuario"
              icon="fas fa-fingerprint"
              style={{ marginBottom: "15px", width: "95%" }}
            />
            <TextBox
              height={40}
              name="password"
              type="password"
              required={true}
              showPassword={true}
              placeholder="Contraseña"
              style={{ width: "95%" }}
            />
            <Button
              width={"95%"}
              type="submit"
              iconHover="Claro"
              disabled={loading}
              style={{ marginTop: "15px" }}
              icon={`${loading ? "loading" : "fas fa-sign-in-alt"}`}
              text={loading ? "Verificando accesos" : "Iniciar sesión"}
            />
          </form>
          <p className="form-footer">
            Let's get There, <b>Together.</b>
          </p>
        </div>
      </div>
      <Carousel />
    </div>
  );
};

export default LoginForm;
