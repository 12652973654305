import { memo, useMemo } from 'react';
import { FormatText } from 'utils/Formatos';
import { useNavBar } from '../contexts/NavBarContext';
import BoxOpciones from './BoxOpciones';
import { ColaboradorIcon, IconBox } from 'images/Index';
import './styles/estilos.scss';
import { useMenu } from 'contexts/MenuContext';
import { DEFAULT_PAGE_NAME, BIENVENIDA } from '../consts/User';

const NavBar = () => {

  const {
    openBoxOpciones,
    openPerfilOpciones,
    handlerOpenPerfilOpciones,
    handlerOpenBoxOpciones,
    handlerOnLogOut,
    divisionesNotSelected,
    user
  } = useNavBar();

  const { pageRendered } = useMenu();

  const classBoxOpciones = useMemo(() => {
    return openBoxOpciones ? "show-box-opciones" : "hidden-box-opciones"
  }, [openBoxOpciones]);

  const classPerfilOpciones = useMemo(() => {
    return openPerfilOpciones ? "show-box-opciones" : "hidden-box-opciones"
  }, [openPerfilOpciones]);

  return (
    <div className='navbar-container'
    >
      <p className='navbar-welcome'>
        {
          pageRendered === DEFAULT_PAGE_NAME &&
          (
            <span><span style={{ color: "black" }}>Hola</span> {FormatText(user.name || "Usuario")}</span>
          )
        }
        {
          pageRendered === DEFAULT_PAGE_NAME ?
            `${BIENVENIDA} ${pageRendered}` : 
            pageRendered
        } de <span>{user.divisionSeleccionada}.</span>
      </p>
      <img
        alt='Menu'
        src={IconBox}
        className="box-menu"
        onClick={handlerOpenBoxOpciones}
      />
      <img
        alt='Perfil'
        src={ColaboradorIcon}
        className="navbar-perfil"
        onClick={handlerOpenPerfilOpciones}
      />
      <div
        className={`box-menu-opciones ${classBoxOpciones}`}
      >
        <BoxOpciones divisiones={divisionesNotSelected} />
      </div>
      <div
        className={`perfil-menu-opciones ${classPerfilOpciones}`}
      >
        <div className='list-container'>
          <div className='item' onClick={() => handlerOnLogOut()}>
            <i className='fas fa-sign-out-alt'></i>
            <p >Cerrar Sesión</p>
          </div>
        </div>
      </div>
    </div >
  )
}

export default memo(NavBar)