import { IFiltersConfBudget, IBudgetData } from "interfaces/IConfiguracion"

export enum CFNotificacionBudget {
  MissingDivision = "Falta seleccionar la division",
  ErrorGetCatalogos = "Ocurrio un error al tratar de obtener los catalogos",
  ErrorGetBudgetGeneral = "Ocurrio un error al obtener los datos del budget general",
  ErrorGetBudgetSegmentacion = "Ocurrio un error al obtener los datos del budget por segmentacion",
  IsntComplete = "El budget aún no ha sido completado",
  EmptyData = "No hay datos que guardar",
  ErrorSavingBudgetGeneral = "Error al guardar el budget general",
  ErrorSavingBudgetSegmentaciones = "Error al guardar el budget por segmentaciones",
  SuccessSavingBudget = "Se guardaron los datos del budget correctamente"
}

export const defaultBudget: IBudgetData = {
  general: [],
  segmentaciones: []
}
export const defaultFilters: IFiltersConfBudget = {
  año: new Date().getUTCFullYear(),
  division: "",
}

export enum CFStatusBudget {
  Searching = 1,
  Saving = 2,
  Stop = 3
}