import BoxComisiones from './components/comisiones/BoxComisiones'
import { useComisiones } from './hooks/useComisiones'

const Comisiones = () => {

    const {
        catalogosComisionPresupuesto: Presupuesto,
        historialComisionPuesto,
        MESSAGE_MODAL_DELETE,
        modalDeleteStatus,
        handleDelete,
        onSavedFijos,
        onSavedPagoDivision,
        onSavedRangos,
        onSavedPuesto,
        handleDeleteStatus,
        handleOnSetHistorialComisionPuesto
    } = useComisiones()

    return (
        <>
            <div className="container-configuracionModules">                
                <hr />
                <BoxComisiones
                    catalogosComisionPresupuesto={Presupuesto}
                    MESSAGE_MODAL_DELETE={MESSAGE_MODAL_DELETE}
                    modalDeleteStatus={modalDeleteStatus}
                    historialComisionPuesto={historialComisionPuesto}
                    handleDelete={handleDelete}
                    onSavedFijos={onSavedFijos}
                    onSavedPagoDivision={onSavedPagoDivision}
                    onSavedRango={onSavedRangos}
                    onSavedPuesto={onSavedPuesto}
                    handleDeleteStatus={handleDeleteStatus}
                    setHistorialComisionPuesto={handleOnSetHistorialComisionPuesto}
                />
            </div>
        </>
    )
}

export default Comisiones