import { AereoIcon, MaritimoIcon, TerrestreIcon } from 'images/Index';

interface IIconsModos {
  [key: string]: string;
}
export const ICONS_MODOSTRANSPORTE: IIconsModos = {
  MARITIMO: MaritimoIcon,
  AEREO: AereoIcon,
  TERRESTRE: TerrestreIcon
}

export const KEY_ITEM_SESSION = "MENU_STATE";
export const KEY_SUBITEM_SESSION = "SUBMENU_STATE";

export type StateMenu = typeof KEY_ITEM_SESSION | typeof KEY_SUBITEM_SESSION;