import FilterConfig from './components/budget/FilterConfig';
import { TableGrid } from 'components/Index';
import { useBudget, TITLE_TOTALES } from './hooks/useBudget';
import { formatoDecimales } from 'utils/Formatos';
import { IBudget } from 'interfaces/IConfiguracion';
import { GridConfiguracionBudget } from 'api/configuracion';
import MenuFilters from 'components/MenuFilters';

const Budget = () => {
    const {
        catalogosBudget,
        budget,
        handlerOnFilterChanged,
        handlerOnSearchBudget,
        handlerOnCompleteBudget,
        handlerOnSaveBudget,
        handlerOnChanges,
        handlerOnAddYearBudget,
        statusBudget,
        totalOps
    } = useBudget();

    const { general, segmentaciones } = budget;
    const { generateColumnsGridBudget, generateTotalsSummaryBudget } = GridConfiguracionBudget();

    return (
        <div className="container-configuracion-budget">
            <MenuFilters>
                <FilterConfig
                    catalogosBudget={catalogosBudget}
                    handleOnSaveBudget={handlerOnSaveBudget}
                    handlerOnFilterChanged={handlerOnFilterChanged}
                    handlerOnSearchBudget={handlerOnSearchBudget}
                    handlerOnAddYearBudget={handlerOnAddYearBudget}
                    statusBudget={statusBudget}
                />
            </MenuFilters>
            <TableGrid
                dataSource={general}
                columns={generateColumnsGridBudget({ withPercentaje: false, titleTotals: TITLE_TOTALES })}
                enablePager={false}
                enablePaging={false}
                enableEditing={{
                    modeEdit: "cell",
                    enableUpdating: true,
                    onSavedChanges: () => handlerOnChanges()
                }}
            />
            <br />
            <TableGrid
                visible={segmentaciones.length > 0}
                dataSource={segmentaciones}
                columns={generateColumnsGridBudget({ withPercentaje: true, titleTotals: TITLE_TOTALES, budget: budget.segmentaciones })}
                enablePager={false}
                enablePaging={false}
                enableEditing={{
                    modeEdit: "cell",
                    enableUpdating: true
                }}
                calcultateCustomSummary={(options: any) => calculateTotalColumn(options, handlerOnCompleteBudget, totalOps)}
                totalesColumns={generateTotalsSummaryBudget(budget.segmentaciones, TITLE_TOTALES)}
            />
        </div>
    )
}

const calculateTotalColumn = (options: any, onSetComplete: (complete: boolean) => void, totalOps: number) => {
    if (options.name !== "Porcentaje" && options.name !== "Total") {
        const mes: string = options.name;
        if (options.summaryProcess === 'start') {
            options.totalValue = 0;
        }
        if (options.summaryProcess === 'calculate') {
            if (options.value) {
                const budget: IBudget = options.value;
                console.log(!budget.meses![mes.toLocaleLowerCase()]);
                if (!!budget.meses![mes.toLocaleLowerCase()]) {
                    const { operaciones } = budget.meses![mes.toLocaleLowerCase()];
                    options.totalValue += budget.titulo !== TITLE_TOTALES ? operaciones : 0;
                }
            }
        }
    }
    if (options.name === "Total") {
        if (options.summaryProcess === 'start') {
            options.totalValue = 0;
        }
        if (options.summaryProcess === 'calculate') {
            if (options.value) {
                const ops: number = options.value;
                options.totalValue += ops !== totalOps ? ops : 0;
            }
        }

        if (options.summaryProcess === "finalize") {
            console.log(options.totalValue);
            if (options.totalValue === totalOps) {
                onSetComplete(true);
            }
            if (options.totalValue !== totalOps) {
                onSetComplete(false);
            }
        }
    }

    if (options.name === "Porcentaje") {
        if (options.summaryProcess === 'start') {
            options.totalValue = 0;
            options.contador = 0;
            options.acumulado = 0;
        }
        if (options.summaryProcess === 'calculate') {
            if (options.value) {
                const porcentaje: number = parseFloat(options.value.split('%')[0]!);
                porcentaje === 100 && options.contador++;
                options.acumulado += porcentaje !== 100 ? porcentaje : 0;
            }
        }
        if (options.summaryProcess === "finalize") {
            options.totalValue = options.contador > 1 ?
                formatoDecimales(options.acumulado + 100, 2) :
                formatoDecimales(options.acumulado, 2);
            /**VALIDAMOS QUE EL BUDGET A GUARDAR ESTE COMPLETO */
            /*if (options.totalValue >= 99.90 && options.totalValue <= 101) {
                onSetComplete(true);
            }
            if (options.totalValue < 99.90) {
                onSetComplete(false);
            }*/
        }
    }
}

export default Budget