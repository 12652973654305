import { useMenuFilter } from 'hooks/MenuFilters';
import { memo, ReactElement, useMemo } from 'react';
import '../styles/estilos.scss';

interface IProps {
  openWithClick?: boolean;
  children?: ReactElement | ReactElement[];
  style?: React.CSSProperties | undefined;
}

const MenuFilters = ({ children, openWithClick = false, style }: IProps) => {

  const { isOpenMenu, handlerStatusMenu, handlerCloseMenuFilter } = useMenuFilter();

  const statusMenu: string = useMemo(() =>
    isOpenMenu === null ? 'default-width-menu-filters' :
      isOpenMenu ? 'openMenuFilters' : 'closeMenuFilters'
    , [isOpenMenu]);

  const handlerOnClickLeave = (e: any) => {
    if (openWithClick) {
      return
    }
    const widthClicked: number = window.innerWidth - e.pageX;
    const widthLeave: number = 16 * 24;
    const diff: number = widthClicked - widthLeave;      
    if (-50 < diff && diff < 50) {
      handlerCloseMenuFilter();
    }
  }

  return (
    <div style={style} className={`container-menu-filters ${statusMenu}`} onMouseLeave={(e) => handlerOnClickLeave(e)}>
      <span className='icon-select-menu' onClick={() => handlerStatusMenu()}>
        <i className='fas fa-list-alt'></i>
      </span>
      <div className='box-filters' >
        {children}
      </div>
    </div>
  )
}

export default memo(MenuFilters)