/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Card, TextField } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { usePerfilCliente } from "./hooks/usePerfilCliente"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import MenuFilters from "components/MenuFilters";
import FiltersOperaciones from "pages/operaciones/components/FiltersOperaciones";
import { useDesglose } from "pages/operaciones/hooks/useDesglose";
import Button from "components/Button";
import logo from "images/IconCmotion.svg";
import ReactStoreIndicator from 'react-score-indicator';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Popup } from 'devextreme-react'
import TableGrid, { IOnSaved } from "components/TableGrid";
import { columnsOperacionesPerfilCliente, columsEdoCuenta } from "api/clientes";
import { onExportingGrid } from 'utils/Functions';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { useAuth } from "api/auth";
import PivotTable from 'components/Pivot'
import { _FieldFacturacionClientes } from "functions/Perfil";
import { useTareas } from "./hooks/useTareas";
import { columnsTareasEntidad } from "interfaces/ITareas";
import moment from "moment";
import { useTask } from "contexts/TaskContext";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
  
export const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
      },
    },
};

const PerfilCliente = () => {

    const { 
        data, 
        dateFin, 
        dateInicio, 
        cliente,
        catalogoClientes, 
        loading: loadingPerfil,
        handleFiltrado, 
        setDateInicio, 
        setDateFin, 
        setCliente, 
        handlerOnSelectChange,
        getCatalogoClientes
    } = usePerfilCliente()

    const {
        catalogos,
    } = useDesglose();

    const { 
        dataTareasEntidad,
        modalTareasStatus,
        responsables,
        handleTareasPerfilCliente,
        setModalTareasStatus,
        getResponsables,
        onEditTarea
    } = useTareas();

    const { entidadGuid, razonSocial ,setEntidadGuid } = useTask();

    const [saldos, setSaldos] = useState<any>(null)
    const [detalle, setDetalle] = useState<any>(null)
    const [rangosFacturas, setRangosFacturas] = useState<any>(null)
    const [gastosOriginados, setGastosOriginados] = useState<any>(null)
    const [dataRangos, setDataRangos] = useState<any>(null)
    const [dataGastosOriginados, setDataGastosOriginados] = useState<any>(null)
    const [operaciones, setOperaciones] = useState<any>(null)
    const [dataOperaciones, setDataOperaciones] = useState<any>(null)
    const [rentabilidad, setRentabilidad] = useState<any>(null)
    const [operacionesCliente, setOperacionesCliente] = useState<any>(null)
    const [estadosCuenta, setEstadosCuenta] = useState<any>(null)
    const [rentabilidadDatos, setRentabilidadDatos] = useState<any>(null)
    const [desglose, setDesglose] = useState<any>({
        data: []
    })
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [openModalSaldos, setOpenModalSaldos] = useState<boolean>(false)
    const [openModalRentabilidad, setOpenModalRentabilidad] = useState<boolean>(false)
    const [nombreCliente, setNombreCliente] = useState<string | null>(null)
    const { User } = useAuth();

    const ShowFilter = (): JSX.Element => {
        const clientes = catalogoClientes.data!
        console.log(estadosCuenta)
        return (
            <MenuFilters>
                {
                    !catalogoClientes.loading ? (
                        <div className="container-filters">
                            <div
                                style={{
                                    padding: "0 25px"
                                }}
                            >
                                <Autocomplete
                                    style={{ width: '100%', backgroundColor: 'white' }}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={clientes}
                                    sx={{ width: 300 }}
                                    getOptionLabel={(option: any) => option.razonSocial}
                                    renderInput={(params: any) => <TextField {...params} label="Clientes" />}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.entidadGuid}>
                                            {option.razonSocial}
                                        </li>
                                    )
                                    }
                                    onChange={(event, value) => {
                                        setCliente(value ? value.entidadGuid : "");
                                        setNombreCliente(value ? value.razonSocial : "");
                                    }}
                                />
                            </div>
                            <FiltersOperaciones
                                catalogos={{
                                    data: catalogos.data?.filter((item: any) => item.key !== 'años'),
                                    loading: catalogos.loading,
                                }}
                                handlerOnSelectChange={handlerOnSelectChange}
                                itemsByRow={1}
                            >
                                <TextField
                                    label="Fecha Inicio"
                                    type="date"
                                    value={`${dateInicio.getFullYear()}-${(dateInicio.getMonth() + 1).toString().padStart(2, "0")}-${dateInicio.getDate().toString().padStart(2, '0')}`}
                                    onChange={(e: any) => {
                                        const date = new Date(e.target.value)
                                        date.setDate(date.getDate() + 1)
                                        setDateInicio(date)
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{
                                        width: '100%',
                                        backgroundColor: 'white'
                                    }}
                                />
                                <TextField
                                    label="Fecha Fin"
                                    type="date"
                                    value={`${dateFin.getFullYear()}-${(dateFin.getMonth() + 1).toString().padStart(2, "0")}-${dateFin.getDate().toString().padStart(2, '0')}`}
                                    onChange={(e: any) => {
                                        const date = new Date(e.target.value)
                                        date.setDate(date.getDate() + 1)
                                        setDateFin(date)
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{
                                        width: '100%',
                                        backgroundColor: 'white'
                                    }}
                                />
                                <Button
                                    onClick={handleFiltrado}
                                    text={loadingPerfil ? "Buscando..." : "Filtrar OPS"}
                                    icon='fas fa-search'
                                    disabled={loadingPerfil}
                                    className="btnFilter"
                                    width={"100%"}
                                    iconHover="Claro"
                                />
                            </FiltersOperaciones>
                        </div>
                    ) : (
                        <p>Cargando...</p>
                    )
                }
            </MenuFilters>
        )
      }

    useEffect(() => {
        console.log(data)
        if ((data as any)?.data) {
            setSaldos((data as any).data[0][0])
            setDetalle((data as any).data[1][0])
            setRangosFacturas((data as any).data[2])
            setGastosOriginados((data as any).data[3])
            setDesglose({ data: (data as any).data[4] })
            setRentabilidadDatos((data as any).data[5])
            setEstadosCuenta((data as any).data[6])
            console.log((data as any).data[6])
            setRentabilidad(() => {
                const venta = (data as any).data[0][0].totalFacturado;
                let gasto = 0;
                (data as any).data[3].forEach((item: any) => {
                    gasto += item.cantidadUSD;
                })
                if (venta === 0 && gasto > 0) {
                    return -100;
                }

                if (venta === 0 && gasto === 0) {
                    return 0;
                }
                
                return ((venta - gasto) / venta) * 100;
            })
        }
    }, [data])

    useEffect(() => {
        if (rangosFacturas) {
            const data: any = {}
            data.labels = []
            data.datasets = []
            for (const key in rangosFacturas) {
                data.labels.push(rangosFacturas[key].rangoVencimiento)
                
            }
            data.datasets = [
                {
                    label: 'Facturado',
                    data: rangosFacturas.map((item: any) => item.cantidadFacturadaUSD),
                    backgroundColor: '#baf095',
                    borderColor: '#baf095',
                    borderWidth: 1,
                }
            ]
            setDataRangos(data)
        }
    }, [rangosFacturas])

    useEffect(() => {
        if (gastosOriginados) {
            const data: any = {}
            data.labels = []
            data.datasets = []
            for (const key in gastosOriginados) {
                data.labels.push(gastosOriginados[key].cargoOriginado)
            }
            data.datasets = [
                {
                    label: 'Gastos',
                    data: gastosOriginados.map((item: any) => item.cantidadUSD),
                    backgroundColor: '#57bdca',
                    borderColor: '#57bdca',
                    borderWidth: 1,
                }
            ]
            setDataGastosOriginados(data)
        }
    }, [gastosOriginados])  

    useEffect(() => {
        if (cliente) {
            if (desglose?.data) {
                const paises: any[] = [];
                const counterCountries: any = {}
                const operacionesClienteAux = desglose.data.filter((item: any) => item.entidad_Guid.includes(cliente) && new Date(item.fecha_Pick_Up) >= dateInicio && new Date(item.fecha_Pick_Up) <= dateFin)
                setOperacionesCliente(operacionesClienteAux)
                desglose.data.forEach((item: any) => {
                    const date = new Date(item.fecha_Pick_Up)
                    if (date >= dateInicio && date <= dateFin) {
                        if (item.entidad_Guid.includes(cliente) && !paises.includes(item.pais_Origen)) {
                            paises.push(item.pais_Origen)
                            counterCountries[item.pais_Origen] = 1
                        } else if (item.entidad_Guid.includes(cliente) && paises.includes(item.pais_Origen)) {
                            counterCountries[item.pais_Origen] += 1
                        }
                    }
                })
                setOperaciones(counterCountries)
            }
        }
    }, [desglose, cliente])

    useEffect(() => {
        if (operaciones) {
            const data: any = {}
            data.labels = []
            data.datasets = []
            for (const key in operaciones) {
                data.labels.push(key)
            }
            data.datasets = [
                {
                    label: 'Operaciones',
                    data: Object.values(operaciones),
                    backgroundColor: '#0a2c4f',
                    borderColor: '#0a2c4f',
                    borderWidth: 1,
                }
            ]
            setDataOperaciones(data)
        }
    }, [operaciones])

    useEffect(() => {
        getResponsables();
        getCatalogoClientes();
    }, []);

    useEffect(() => {
    if (entidadGuid) {
      setCliente(entidadGuid);
      handleFiltrado();
      setTimeout (() => {
        setEntidadGuid(null);
      } , 4000);
    }
  }, [entidadGuid, setCliente, handleFiltrado, setEntidadGuid]);

    let responsablesData = responsables.data
    const columsGrid = useMemo(() =>{
        return columnsTareasEntidad(responsablesData!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responsables]);

    const onSavedTarea = (e:IOnSaved[]) =>{
        if(e.length === 0){
            return
        }
        const fechaActualizacion = moment().utc().format('YYYY-MM-DD 00:00:00')
        const {data, type, key} = e[0];
        console.log(data)
        
        switch(type){
            case 'update':
                const responsable = responsablesData!.find((item) => item.colaborador_id === data.cMotion_Perfil_Colaborador_Id)
                
                const tareaUpdated = {
                    Tarea_Id: data.Tarea_Id,
                    Tarea_Nombre: data.Tarea_Nombre,
                    Tarea_Nota: data.Tarea_Nota,
                    Urgencia: data.Tarea_Urgencia,
                    Estatus: data.Tarea_Estatus,
                    Entidad_Guid: data.Entidad_Guid,
                    Tarea_Fecha_Actualizacion: fechaActualizacion,
                    Entidad_Razon_Social: data.Entidad_Razon_Social,
                    Colaborador_Guid: responsable?.guid,
                }
              onEditTarea(tareaUpdated, 'update')
              break;
            case 'remove':
              onEditTarea(key, 'remove')
              break;
            default:
              return
        }
      }

    const onExporting = useCallback((e: ExportingEvent) => {
        return onExportingGrid(e, "EstadoDeCuenta", User.divisionSeleccionada!);
    }, [User]);

    return (
        <div className='container-clientesModules'>
            <Popup
                visible={openModal}
                width={"80%"}
                height={"auto"}
                maxHeight={"90%"}
                dragEnabled={false}
                closeOnOutsideClick={true}
                onVisibleChange={(e) => setOpenModal(e)}
                showCloseButton={false}
                titleRender={() => {
                    return (
                        <div className="container-title">
                            <h4 style={{ margin: 0 }}>Detalle de operaciones</h4>
                        </div>
                    )
                }}
                contentRender={() => {
                    return (
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                overflowY: 'auto',
                            }}
                        >
                            <TableGrid
                                dataSource={operacionesCliente}
                                columns={columnsOperacionesPerfilCliente}
                                enableGroupPanel={true}
                                enableColumnChooser={true}
                                enableExport={{ enabled: true, onExporting: (e) => onExporting(e) }}
                                enableHeaderFilter={true}
                                enableSearchPanel={true}
                                enableFilterRow={true}
                                enablePaging={true}
                                height={"100%"}
                                enableSessionStorage={{ enabled: true, keyStorage: 'gridEdoCuenta' }}
                            />
                        </div>
                    )
                }}
            />
            <Popup
                visible={openModalSaldos}
                width={"80%"}
                height={"auto"}
                maxHeight={"90%"}
                dragEnabled={false}
                closeOnOutsideClick={true}
                onVisibleChange={(e) => setOpenModalSaldos(e)}
                showCloseButton={false}
                titleRender={() => {
                    return (
                        <div className="container-title">
                            <h4 style={{ margin: 0 }}>Detalle de Saldos</h4>
                        </div>
                    )
                }}
                contentRender={() => {
                    return (
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                overflowY: 'auto',
                            }}
                        >
                            <TableGrid
                                dataSource={estadosCuenta.filter((item: any) => item.entidad_Guid.includes(cliente) && new Date(item.fecha_Documento) >= dateInicio && new Date(item.fecha_Documento) <= dateFin)}
                                columns={columsEdoCuenta}
                                enableGroupPanel={true}
                                enableColumnChooser={true}
                                enableExport={{ enabled: true, onExporting: (e) => onExporting(e) }}
                                enableHeaderFilter={true}
                                enableSearchPanel={true}
                                enableFilterRow={true}
                                enableSessionStorage={{ enabled: true, keyStorage: 'gridEdoCuenta' }}
                            />
                        </div>
                    )
                }}
            />
            <Popup
                visible={openModalRentabilidad}
                width={"80%"}
                height={"auto"}
                maxHeight={"90%"}
                dragEnabled={false}
                closeOnOutsideClick={true}
                onVisibleChange={(e) => setOpenModalRentabilidad(e)}
                showCloseButton={false}
                titleRender={() => {
                    return (
                        <div className="container-title">
                            <h4 style={{ margin: 0 }}>Detalle de Rentabilidad</h4>
                        </div>
                    )
                }}
                contentRender={() => {
                    return (
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                overflowY: 'auto',
                            }}
                        >
                            <PivotTable
                                data={rentabilidadDatos}
                                fieldPivot={_FieldFacturacionClientes} 
                                onExporting={onExporting}
                            />
                        </div>
                    )
                }}
            />
            {/* Modal Tareas */}
            <Popup
                visible={modalTareasStatus}
                width={"80%"}
                height={"auto"}
                maxHeight={"90%"}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={false}
                titleRender={() => {
                    return (
                        <div className="container-title">
                            <h4 style={{ margin: 0 }}>Tareas</h4>
                        </div>
                    )
                }}
                contentRender={() => {
                    return (
                        <>  
                        <div style={{ marginBottom: "15px" }}>
                            <TableGrid
                                dataSource={dataTareasEntidad.data}
                                columns={columsGrid}
                                height={"100%"}
                                key="idGridSeguimiento"
                                showRowLines={true}
                                enableHeaderFilter={true}
                                enablePaging={true}
                                enableSearchPanel={true}
                                enableEditing={{
                                    modeEdit: "row", 
                                    showIcons: true,
                                    enableDelete: true,
                                    enableUpdating: true,
                                    onSavedChanges: onSavedTarea
                                }}
                            />
                        </div>
                        <div style={{display: "flex", justifyContent: "flex-end", marginTop: "15px"}}>
                            <Button
                                type='button'
                                text='Cerrar'
                                width={100}
                                style={{ backgroundColor: "red"}}
                                icon={'fas fa-window-close'}
                                onClick={ () => setModalTareasStatus(false) }
                            />
                        </div>
                        </>
                    )
                }}
            />
            {
                ShowFilter()
            }
            {
                !saldos && !loadingPerfil ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '80vh',
                            width: '100%',
                            flexDirection: 'column'
                        }}
                    >
                        <div
                            style={{
                                background: `url(${logo})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                height: '200px',
                                width: '200px'
                            }}
                        >
                        </div>
                        <h3>
                            {
                                catalogoClientes.loading ? (
                                    'CARGANDO CLIENTES ...'
                                ) : (
                                    'SELECCIONE UN CLIENTE'
                                )
                            }
                        </h3>
                    </div>
                ) : loadingPerfil ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '80vh',
                            width: '100%',
                            flexDirection: 'column'
                        }}
                    >
                        <div
                            style={{
                                background: `url(${logo})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                height: '200px',
                                width: '200px'
                            }}
                        >
                        </div>
                        <h3>CARGANDO ...</h3>
                    </div>
                ) : (
                    <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '1rem',
                        }}
                    >
                        <div 
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem'
                            }}
                        >
                            {/* titulo nombre cliente */}
                            <h2 style={{ textAlign: "center" }}>{nombreCliente || razonSocial}</h2>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                        >
                            <i onClick={() => setOpenModalSaldos(true)} style={{ cursor: "pointer" }} className="bi bi-info-circle-fill"></i>
                            <i onClick={() => handleTareasPerfilCliente(cliente!)} style={{ cursor: "pointer" }} className="bi bi-clipboard-check-fill"></i>
                            <Card
                                style={{
                                    width: '180px',
                                    height: '120px',
                                    padding: '0.5rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(104,179,214,0.2)',
                                }}
                            >
                                <h4 style={{margin: 5}}>Total Facturado</h4>
                                <span></span>
                                <p style={{margin: 5, fontWeight: "bold"}}>{saldos?.totalFacturado ? saldos?.totalFacturado.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "$0.00"} USD</p>
                                <span></span>
                                <span></span>
                            </Card>
                            <Card
                                style={{
                                    width: '180px',
                                    height: '120px',
                                    padding: '0.5rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(104,179,214,0.2)',
                                }}
                            >
                                <h4 style={{margin: 5}}>Porcentaje Cartera Vencida</h4>
                                <p style={{margin: 5, fontWeight: "bold"}}>{saldos?.porcentajeCarteraVencida as number}%</p>
                                <span></span>
                                <span></span>
                            </Card>
                            <Card
                                style={{
                                    width: '180px',
                                    height: '120px',
                                    padding: '0.5rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(104,179,214,0.2)',
                                }}
                            >
                                <h4 style={{margin: 5}}>Total vencido</h4>
                                <span></span>
                                <p style={{margin: 5, fontWeight: "bold"}}>{saldos?.totalVencido ? saldos?.totalVencido.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "$0.00" } USD</p>
                                <span></span>
                                <span></span>
                            </Card>
                            <Card
                                style={{
                                    width: '180px',
                                    height: '120px',
                                    padding: '0.5rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(104,179,214,0.2)',
                                }}
                            >
                                <h4 style={{margin: 5}}>Saldo Vigente</h4>
                                <span></span>
                                <p style={{margin: 5, fontWeight: "bold"}}>{saldos?.saldoVigente ? saldos?.saldoVigente.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "$0.00"} USD</p>
                                <span></span>
                                <span></span>
                            </Card>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "1rem",
                        }}
                    >
                        <Card
                            style={{
                                height: "300px",
                            }}
                        >
                            <div>
                                <h3
                                    style={{
                                        backgroundColor: "gray",
                                        color: "white",
                                        margin: 0
                                    }}
                                >Detalle cliente</h3>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "1rem",
                                        flexDirection: "column",
                                    }}
                                >
                                    <p>Dias de credito: <span style={{ fontWeight: "bold" }}>{detalle?.diasCredito | 0} dias</span></p>
                                    <p>Promedio de pago: <span style={{ fontWeight: "bold" }}>{detalle?.diasPromedioPago | 0} dias</span></p>
                                    <p>Profit promedio: <span style={{ fontWeight: "bold" }}>{detalle?.profitPromedioOperacion ? detalle?.profitPromedioOperacion.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "$0.00"} USD</span></p>
                                    <p>Total operaciones: <span style={{ fontWeight: "bold" }}>{detalle?.totalOperaciones ? detalle?.totalOperaciones.toLocaleString() : 0}</span></p>
                                </div>
                            </div>
                        </Card>
                        {
                            dataOperaciones && (
                                <Card
                                    style={{
                                        height: "300px",
                                    }}
                                >
                                    <h3
                                        style={{
                                            backgroundColor: "gray",
                                            color: "white",
                                            margin: 0
                                        }}
                                    >Principales origenes de carga <i onClick={() => setOpenModal(true)} style={{ cursor: "pointer" }} className="bi bi-info-circle-fill"></i></h3>
                                    <Bar 
                                        style={{
                                            width: "100%",
                                            margin: "0 15px",
                                        }}
                                        options={options} data={{
                                        labels: dataOperaciones.labels,
                                        datasets: dataOperaciones.datasets
                                    }} />
                                </Card>
                            )
                        }
                        <Card>
                            <h3
                                style={{
                                    backgroundColor: "gray",
                                    color: "white",
                                    margin: 0
                                }}
                            >Rentabilidad <i onClick={() => setOpenModalRentabilidad(true)} style={{ cursor: "pointer" }} className="bi bi-info-circle-fill"></i></h3>
                            {
                                rentabilidad !== null && (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            height: "100%",
                                        }}
                                    >
                                        <ReactStoreIndicator
                                            value={rentabilidad.toFixed(0)}
                                            maxValue={100}
                                            lineWidth={30}
                                        />
                                    </div>
                                )
                            }
                        </Card>
                        {
                            dataRangos && (
                                <Card
                                    style={{
                                        height: "300px",
                                    }}
                                >
                                    <h3
                                        style={{
                                            backgroundColor: "gray",
                                            color: "white",
                                            margin: 0
                                        }}
                                    >Detalle vencimiento facturación</h3>
                                    <Bar
                                        style={{
                                            width: "100%",
                                            margin: "0 15px",
                                        }}
                                        options={options} data={{
                                        labels: dataRangos.labels,
                                        datasets: dataRangos.datasets
                                    }} />
                                </Card>
                            )
                        }
                        {
                            dataGastosOriginados && (
                                <Card
                                    style={{
                                        height: "300px",
                                    }}
                                >
                                    <h3
                                        style={{
                                            backgroundColor: "gray",
                                            color: "white",
                                            margin: 0
                                        }}
                                    >Gastos originados</h3>
                                    <Bar
                                        style={{
                                            width: "100%",
                                            margin: "0 15px",
                                        }}
                                        options={options} data={{
                                        labels: dataGastosOriginados.labels,
                                        datasets: dataGastosOriginados.datasets
                                    }} />
                                </Card>
                            )
                        }
                    </div>
                    </>
                )
            }
        </div>
    )
}

export default PerfilCliente