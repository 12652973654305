export const ajustarDecimales = (value: number): string => {
  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  return new Intl.NumberFormat('es-MX', options).format(value);
}
export const formatoPorcentaje = (value: number): string => {
  return `${value} %`;
}
export const formatoPorcentajeX100 = (value: number): string => {
  return `${(value * 100).toFixed(2)} %`;
}
export const formatoBoolean = (value: boolean): string => {
  return value ? "SI" : "NO";
}
export const formatoDecimales = (value: number, decimales: number): number => {
  const axu_value = Math.pow(10, decimales);
  return (Math.trunc(value * axu_value) / axu_value);
}
export const FormatText = (text: string): string => {
  const arrayWords: string[] = text.split(" ");
  let newText: string = "";
  arrayWords.forEach((word) => {
    const arrayLetters: string[] = word.split("");
    arrayLetters.forEach((letter, index) => {
      if (index === 0) {
        newText += letter.toUpperCase();
        return
      }
      newText += letter.toLowerCase();
    });
    newText += " ";
  });
  return newText;
}

export const FormatoSinDecimales = (value: number): string => {
  return new Intl.NumberFormat('es-MX').format(value);
}
export const FormatoDecimalesPorcentaje = (value: number): string => {
  return `${formatoDecimales(value, 2)} %`;
}