import { useMemo, useState, useCallback } from 'react';
import { IEstrategias, IFilterComisionesPendientes, IComisiones } from 'interfaces/IComisiones';
import { IFilterChange, IFilterData } from 'components/interfaces/Interfaces';
import { useSWRWithToken } from "hooks/useSWR";
import { useAuth } from "api/auth";
import { URL_API_COMISIONES } from "consts/Services_URL";
import { createNewFilter } from 'utils/Functions';
import { ComisionesService } from "api/comisiones";
import { EditingStartEvent } from 'devextreme/ui/data_grid';
import notify from 'devextreme/ui/notify';
import { CSNotificacion, CSStatus } from 'consts/Comisiones';

interface IUseComisionesPendientes {
  filters: IFilterComisionesPendientes;
  estrategias: IFilterData;
  estatus: CSStatus;
  comisiones: IComisiones[];
  isAllSelected: boolean;
  confirmation: boolean;
  handlerOnChangeFecha: (e: IFilterChange) => void;
  handlerOnChangeEstrategia: (e: IFilterChange) => void;
  handlerOnSearchComisiones: () => Promise<void>;
  handlerOnSelectAllComisiones: (select: boolean) => void;
  validateEditingComision: (e: EditingStartEvent<any, any>) => void;
  handlerSendComisiones: () => Promise<void>;
  handlerConfirmation: () => void;
  handlerOnSyncEmbarque: (comision: IComisiones) => Promise<void>;
}

const fechaActual: Date = new Date();

const defaultFilters: IFilterComisionesPendientes = {
  estrategia: "",
  fecha: {
    mes: fechaActual.getMonth() + 1,
    año: fechaActual.getUTCFullYear()
  }
}

export const useComisionesPendientes = (): IUseComisionesPendientes => {
  const { User } = useAuth();
  const { data = [] }: { data: IEstrategias[] } = useSWRWithToken(`${URL_API_COMISIONES}catalogos/estrategias?division=${User.divisionSeleccionada!}`, 0, User.token!);
  const [estatus, setEstatus] = useState<CSStatus>(CSStatus.Stop);
  const [filters, setFilters] = useState<IFilterComisionesPendientes>(defaultFilters);
  const [comisiones, setComisiones] = useState<IComisiones[]>([])
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const comisionesService = useMemo(() => {
    return ComisionesService(User);
  }, [User]);

  const estrategias: IFilterData = useMemo(() => {
    return createNewFilter(
      data.map((estrategia) => { return estrategia.estrategia_Origen }),
      "",
      "estrategia");
  }, [data]);

  const isAllSelected: boolean = useMemo(() => {
    if (comisiones.length === 0) return false;
    const validComisiones = comisiones.filter((comision) => comision.aplicaComision);
    if (validComisiones.length === 0) {
      return false;
    }
    return validComisiones.every((comision) => comision.pagarComision);
  }, [comisiones]);

  /*Asignar el mes y el año a los filtros */
  const handlerOnChangeFecha = (e: IFilterChange): void => {
    const { key, value } = e;
    setFilters(prev => {
      return {
        ...prev,
        fecha: {
          ...prev.fecha,
          [key]: value
        }
      }
    });
  }
  /*Asignar las divsion seleccionada a los filtros */
  const handlerOnChangeEstrategia = (e: IFilterChange): void => {
    setFilters(prev => {
      return {
        ...prev,
        estrategia: e.value
      }
    })
  }
  /*Obtener las comisiones apartir de los filtros seleccionados */
  const handlerOnSearchComisiones = useCallback(async (): Promise<void> => {
    if (!filters.estrategia.trim()) {
      notify("No se ha seleccionado la estrategia", "warning", 3000);
      return
    }
    setComisiones([]);
    setEstatus(CSStatus.Searching);
    const { result, data: comisiones = [] } = await comisionesService.getComisionesPendientes(filters);
    if (!result) {
      notify(CSNotificacion.ErrorGetComissionsPending, "error", 4000);
    }
    if (result) {
      setComisiones(comisiones);
    }
    setEstatus(CSStatus.Stop);
  }, [comisionesService, filters]);
  /*Seleccionar todas las comisiones que aplican para pagar*/
  const handlerOnSelectAllComisiones = useCallback((select: boolean): void => {
    const allComisionesSelected: IComisiones[] = comisiones
      .map((comision) => {
        if (comision.aplicaComision) {
          return {
            ...comision,
            pagarComision: select
          }
        }
        return comision
      });
    setComisiones(allComisionesSelected);
  }, [comisiones]);

  /*Habilitar checkPagoComision*/
  const validateEditingComision = (e: EditingStartEvent<any, any>): void => {
    const { aplicaComision, vendedor } = e.data;
    if (vendedor === "AGENTE") {
      e.cancel = false;
    } else {
      e.cancel = aplicaComision ? false : true;
    }
  }
  
  /*Mandar a pagar las comisiones seleccionadas */
  const handlerSendComisiones = useCallback(async (): Promise<void> => {
    setEstatus(CSStatus.Sending);
    const { result, error } = await comisionesService.sendToPayComisionesPendientes(comisiones);
    if (!result) {
      notify(error, "error", 4000);
    }
    if (result) {
      notify(CSNotificacion.OKSendComissionsPending, "success", 4000);
    }
    setEstatus(CSStatus.Stop);
    setConfirmation(false);
  }, [comisiones, comisionesService]);

  /*Sincronizar embarque */
  const handlerOnSyncEmbarque = useCallback(async (comision: IComisiones): Promise<void> => {
    setEstatus(CSStatus.Synchronizing);
    const { embarqueGuid } = comision;
    const wasSynchronized = await comisionesService.syncComission(embarqueGuid);
    !wasSynchronized && notify(CSNotificacion.ErrorSyncShipment, "error", 2000);
    /*ACTUALIZAMOS LOS DATOS DEL EMBARQUE */
    if (wasSynchronized) {
      const wasUpdated = await comisionesService.updateComission(embarqueGuid);
      !wasUpdated && notify(CSNotificacion.ErrorUpdateShipment, "error", 2000);
      if (wasUpdated) {
        notify(CSNotificacion.SuccessSyncShipment, "success", 2000);
        await handlerOnSearchComisiones();
      }
    }
    setEstatus(CSStatus.Stop);
  }, [comisionesService, handlerOnSearchComisiones]);

  const handlerConfirmation = (): void => {
    setConfirmation(prev => !prev);
  }



  return {
    filters,
    estrategias,
    estatus,
    handlerOnChangeFecha,
    handlerOnSearchComisiones,
    handlerOnChangeEstrategia,
    comisiones,
    isAllSelected,
    handlerOnSelectAllComisiones,
    validateEditingComision,
    handlerSendComisiones,
    confirmation,
    handlerConfirmation,
    handlerOnSyncEmbarque
  }
}