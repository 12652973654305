import { memo } from 'react';
import { ColumsProveedores } from 'functions/Tareas';
import PropTypes from 'prop-types';
import DataGrid, { Column, Pager } from 'devextreme-react/data-grid';
import Button from 'components/Button';

const pageSizes = [25, 50, 75, 100];

const GridProveedores = ({ dataSeguimientoProveedores, handleTareasEntidad }) => {

  const handleOnClickDetail = ({ data }) => {
    return (
      <Button
        width={75}
        type="button"
        iconHover='Claro'
        icon={'fas fa-list-alt'}
        onClick={() => handleTareasEntidad(data.data)}
      />
    )
  }
    
  return (
    <DataGrid
      dataSource={dataSeguimientoProveedores}
      noDataText="No hay tareas registradas"
      showBorders={true}
      allowColumnResizing={true}
      rowAlternationEnabled={true}
      columnAutoWidth={true}
      columnHidingEnabled={true}
      columnResizingMode="widget"
      columnMinWidth={40}
      scrolling={{
          columnRenderingMode: "virtual"
        }}
      paging={{ enabled: true }}
      headerFilter={{ visible: true, allowSearch: true }}
      searchPanel={{ visible: true, highlightCaseSensitive: false, placeholder: 'Buscar', width: 250 }}
      groupPanel={{ visible: true, emptyPanelText: 'Arrastra aqui 👇 la columna a agrupar' }}
      grouping={{ texts: { groupContinuesMessage: "Continua en la siguiente página" }, autoExpandAll: true }}
      showRowLines={true}
>
        {
        ColumsProveedores.map((column, index) => (
          <Column {...column} key={index} />
        ))
      }
      <Column
        caption="Tareas"
        cellComponent={handleOnClickDetail}
        width={90}
      />
      <Pager
        visible={true}
        allowedPageSizes={pageSizes}
        showPageSizeSelector={true}
      />
</DataGrid>
  )
}

GridProveedores.propTypes = {
  dataTareas: PropTypes.array,
  keyStateStoraged: PropTypes.string,
  onStartEditing: PropTypes.func,
  isSynchronizing: PropTypes.bool
}

export default memo(GridProveedores)