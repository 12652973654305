import Navigation from 'routes/Navigation';
import { useAuth } from 'api/auth';
import Menu from 'components/Menu';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ItemGrid } from 'components/ItemGrid';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginForm from 'pages/login/LoginForm';
import NavBar from 'components/NavBar';
import { useNavBar } from 'contexts/NavBarContext';
import { useMenu } from 'contexts/MenuContext';
import 'devextreme/dist/css/dx.light.css';
import './components/styles/estilos.scss';

function App() {

  const { User } = useAuth();
  const { handlerCloseMenu } = useMenu();
  const { handlerCloseNavBar } = useNavBar();

  const handlerOnClose = (): void => {
    handlerCloseMenu();
    handlerCloseNavBar();    
  }

  if (!User.isActive) {
    return (
      <Routes>
        <Route path='login' element={<LoginForm />} />
        <Route path='/*' element={<Navigate to='/login' replace={true} />} />
      </Routes>
    )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container >
        <Menu />
        <NavBar />
        <Grid item md={11.5} sm={11.5}>
          <ItemGrid
            sx={{ userSelect: 'none' }}
            className="container-navigation"
            onClick={handlerOnClose}
          >
            <Navigation />
          </ItemGrid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default App;
