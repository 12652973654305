import { useMemo } from 'react';
import { Select, Button } from "components/Index";
import { useGetDataWithAuth } from 'hooks/useGetData';
import { URL_API_TH } from 'consts/Services_URL';
import { useNomina } from 'api/nomina';
import { useAuth } from 'api/auth';
import { createNewFilter } from 'utils/Functions';
import { KEY_PERIODO, KEY_TIPO_PAGO, SKIP_TIPO_COMISIONES } from 'consts/TH';
import { IRemoteTipoPago } from 'interfaces/ITalentoHumano';

const FiltersPagoNomina = () => {
  /**GET TIPOS PAGO */
  const { User } = useAuth();
  const { onSelectFilterChange, periodos, filtros, onHandlerSearchNomina } = useNomina();
  const { data, loading } = useGetDataWithAuth(`${URL_API_TH}catalogos/tipoNominaPago`, User.token);

  const tiposPago = useMemo(() => {
    if (!data) return
    return createNewFilter(
      data.filter((tipo: IRemoteTipoPago) => tipo.cMotion_Tipo_Nomina_Descripcion !== SKIP_TIPO_COMISIONES),
      filtros.tipoPago, KEY_TIPO_PAGO);
  }, [data, filtros]);
  
  return (
    <div className='container-filters'>
      {loading ? (
        <p>Cargando Tipos</p>
      ) : (
        <Select
          title='Tipo de pago'
          item={tiposPago}
          display={"cMotion_Tipo_Nomina_Descripcion"}
          onChangeSelect={onSelectFilterChange}
          showClearButton={false}
          width={"100%"}
          value={filtros.tipoPago}
        />
      )
      }
      <Select
        title='Periodo'
        width={"100%"}
        item={periodos}
        display={KEY_PERIODO}
        onChangeSelect={onSelectFilterChange}
        showClearButton={false}
        value={filtros.periodo}
      />
      <Button
        text='Buscar colaboradores'
        icon='fas fa-search-dollar'
        className='button-filter'
        width={"100%"}
        iconHover="Claro"
        onClick={() => onHandlerSearchNomina()}
      />
    </div>
  )
}

export default FiltersPagoNomina