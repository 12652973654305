import { useAuth } from 'api/auth'
import { getDataAsync } from 'api/fetch'
import { URL_API_IT } from 'consts/Services_URL'
import { DataGrid} from 'devextreme-react'
import { Button } from 'components/Index';
import { Column, SearchPanel } from 'devextreme-react/data-grid'
import { useCallback, useEffect, useState } from 'react'
import { ajustarDecimales } from 'utils/Formatos'
import Error from 'pages/Error'

const ModalHistorico = ({ data: coolaborador, asignarHistorico}) => {
    const { User } = useAuth()
    const [historialEmpleado, setHistorialEmpleado] = useState({ historicoActual: 0, empleado: null, historial: [] })

    const ShowHistoricoEmpleado = useCallback(async (coolaborador) => {
        const headers = { headers: { Authorization: `Bearer ${User.token}` } }
        const { nombreEmpleado, colaboradorId = 0 } = coolaborador
        if(colaboradorId !== 0) {
            const { data, result } = await getDataAsync(`${URL_API_IT}historicoSueldo/${colaboradorId}`, headers)
            if (!result) {
                return <Error>{result}</Error>
            }
            else {
                setHistorialEmpleado({ empleado: nombreEmpleado, historial: data.historico, historicoActual: data.fechaActual });
            }
        }
    }, [User])

    const marcarSueldoActual = ({data:{data}}) =>{
        const date = new Date(data.aplicaDesde)
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        return (
            <p style={{color:data.historicoId === historialEmpleado.historicoActual && "green"}}>{date.toLocaleDateString("es-mx", options)}</p>
        )
    }

    const marcarSueldoVigente = ({data:{data}}) =>{
        if(data.aplicaHasta)
        {
            const date = new Date(data.aplicaHasta)
            const options = { year: 'numeric', month: 'long', day: 'numeric' }
            return (
                <p style={{color:data.historicoId === historialEmpleado.historicoActual && "green"}}>{date.toLocaleDateString("es-mx", options)}</p>
            )
        }
        else{
            return (
                <p style={{color:data.historicoId === historialEmpleado.historicoActual && "green"}}>Sin Registro</p>
            )
        }
    }

    const actionsTemplate = ({ data: { data } }) => {
        return (
            <div style={{ display: "flex", gap: "10px" }}>
                <Button 
                    icon='fas fa-edit' 
                    onClick={() => asignarHistorico(data)}
                    iconHover="Claro"
                    />
            </div>
        )
    }

    useEffect(()  => {
        ShowHistoricoEmpleado(coolaborador)
    },[ShowHistoricoEmpleado, coolaborador]);

    if(historialEmpleado.historial.length > 0)
    {
        return (
            <>
                <DataGrid
                    dataSource={historialEmpleado.historial}
                    key="idGridHistorico"
                    showBorders={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    noDataText={""}                       
                >
                    <SearchPanel visible={false} highlightCaseSensitive={false} placeholder="Buscar"/>                        

                    <Column
                        caption="Sueldo Bruto"
                        dataField="sueldoBruto"
                        format={ajustarDecimales}
                        alignment="left"                            
                    />

                    <Column
                        caption="Sobrepasa IMSS"
                        dataField="sobrepasaIMSS"
                        format={ajustarDecimales}
                        alignment="left"                            
                    />                        

                    <Column
                        caption="Forma de pago"
                        dataField="formaPago"
                        alignment="left"                            
                    />

                    <Column
                        caption="Comisiona"
                        dataField="comisiona"
                        alignment="center"                            
                    />

                    <Column
                        caption="Fecha que Aplico"
                        dataField="aplicaDesde"
                        dataType="date"
                        format= "dd/MM/yyyy"
                        alignment="left"
                        cellComponent={marcarSueldoActual}
                    />
                    
                    <Column
                        caption="Expira"
                        dataField="aplicaHasta"
                        dataType="date"
                        format= "dd/MM/yyyy"
                        alignment="left"
                        cellComponent={marcarSueldoVigente}
                    />
                    <Column
                        caption="Acciones"
                        width={110}
                        cellComponent={actionsTemplate}
                    />
                </DataGrid>
            </>
        )
    }
}

export default ModalHistorico