import { IGetDataClientes } from "interfaces/IClientes";
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { useAuth } from "api/auth";
import { useCallback, useState } from "react";
import { onExportingGrid } from "utils/Functions";
import { ProveedoresService } from "api/proveedores";
import moment from "moment";

interface IUseDeclaracionClientes {
    declaraciones: IGetDataClientes;
    handleFiltrado: (fechaInicial: Date,fechaFinal: Date) => Promise<void>;
    onExporting: (e: ExportingEvent) => void;
}

export const useDeclaraciones = ():IUseDeclaracionClientes => {
    const {User} = useAuth();
    const [declaraciones, setDeclaraciones] = useState<IGetDataClientes>({ loading: false})

    const onExporting = useCallback((e: ExportingEvent) => {
        return onExportingGrid(e, "DeclaracionImpuestosProveedor", User.divisionSeleccionada!);
    }, [User]);

    const handleFiltrado = async (fechaInicial:Date, fechaFinal:Date) : Promise<void> => {
        setDeclaraciones({loading:true})
        const formattedDateInicio = moment(fechaInicial).format('DD-MM-YYYY');
        const formattedDateFin = moment(fechaFinal).format('DD-MM-YYYY');
        const { data, error } = await ProveedoresService(User).getDataDeclaracionProveedor(formattedDateInicio,formattedDateFin);
        if(error){
            setDeclaraciones({ error:error, loading:false})
            return 
        }
        setDeclaraciones({ data:data, loading:false})
    }

    return{
        declaraciones,
        handleFiltrado,
        onExporting
    };
}