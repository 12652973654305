import { memo, useState, useMemo, ReactElement, useCallback } from 'react';
import { DropDownBox, DataGrid } from 'devextreme-react';
import { IFilterMultiple, IFilterChange, IFilterProps, typeStyleDropBox } from './interfaces/Interfaces';
import { FilterMultipleDropBox } from 'utils/Functions';
import './styles/estilos.scss';

interface IProps {
  items?: IFilterMultiple[];
  onItemsSelected?: (e: IFilterChange) => void;
  itemsByRow?: number;
  children?: ReactElement | ReactElement[];
  styleDropBox?: typeStyleDropBox;
}

const FiltersMultipleGrid = ({ items, onItemsSelected, itemsByRow = 4, styleDropBox, children }: IProps) => {

  const handlerOnChange = useCallback((e: IFilterChange) => {
    onItemsSelected && onItemsSelected(e);
  }, [onItemsSelected]);

  return (
    <div
      className='filters-multiple-container'
      style={{ gridTemplateColumns: `repeat(${itemsByRow},1fr)` }}
    >
      {
        items?.map((item, index) => (
          <Filter
            keyFilter={item.key}
            selectionState={item.selectionState}
            items={item.items}
            key={index}
            title={item.title}
            onChange={handlerOnChange}
            styleDropBox={styleDropBox}
          />
        ))
      }
      {children}
    </div>
  )
}

const Filter = memo(({ keyFilter, selectionState, items, onChange, title, styleDropBox }: IFilterProps) => {

  const [selectionsItems, setSelectionsItems] = useState<any[]>(selectionState || []);

  const dataSource = useMemo(() => {
    return FilterMultipleDropBox(items || [], keyFilter || "");
  }, [items, keyFilter]);

  const handlerDataGridChanged = useCallback((e: any): void => {
    const rowsSelected: string[] = (e.selectedRowKeys.length && e.selectedRowKeys) || [];
    setSelectionsItems(rowsSelected);
    onChange && onChange({ key: keyFilter || "", value: rowsSelected });
  }, [keyFilter, onChange])

  const handlerDropChanged = useCallback((e: any): void => {
    const rowsSelected: string[] = e.value || [];
    setSelectionsItems(rowsSelected);
    onChange && onChange({ key: keyFilter || "", value: rowsSelected })
  }, [keyFilter, onChange]);

  const Grid = (() => {
    return (
      <DataGrid
        key='key'
        noDataText='No hay información'
        dataSource={dataSource}
        selection={{ mode: 'multiple' }}
        scrolling={{ mode: 'standard' }}
        pager={{ allowedPageSizes: "auto", visible: true }}
        paging={{ enabled: true, pageSize: 10 }}        
        columns={[{ dataField: keyFilter, caption: title }]}
        hoverStateEnabled={true}
        selectedRowKeys={selectionsItems}        
        onSelectionChanged={handlerDataGridChanged}
        loadPanel={{ enabled: false }}
      />
    )
  })

  return (
    <div className='select-item'>
      <div className="item-label title-filter">{title}</div>
      <div className="item-value">
        <DropDownBox
          value={selectionsItems}
          valueExpr={keyFilter}
          deferRendering={false}
          displayExpr={keyFilter}
          placeholder="--Selecciona--"
          showClearButton={true}
          dataSource={dataSource}
          onValueChanged={handlerDropChanged}
          contentRender={() => Grid()}
          stylingMode={styleDropBox}
        />
      </div>
    </div>
  )
})

export default memo(FiltersMultipleGrid)