import DataGrid, { Column, Editing, Lookup, Pager, Paging, RequiredRule } from 'devextreme-react/data-grid';
import { memo, useState } from 'react'
import { formatoPorcentaje } from 'utils/Formatos';
import { Button } from 'devextreme-react/button';
import ModalHistoricoComisionPuesto from './ModalHistoricoComisionPuesto';
import { Switch } from 'devextreme-react';
import ModalConfirmation from 'components/ModalConfirmation';
import { getDataAsync } from 'api/fetch';
import { useAuth } from 'api/auth';
import { URL_API_CF } from 'consts/Services_URL';
import Error from 'pages/Error';
import { useSWRWithToken } from 'hooks/useSWR';

const inicialHistorialData = {
  historicoActual: {},  historial: []
}

const GridComisionPuesto = ({
  catalogos, 
  handleDelete,
  modalDeleteStatus,
  onSavedPuesto,
  handleDeleteStatus,
  MESSAGE_MODAL_DELETE,
  historialComisionPuesto,
  setHistorialComisionPuesto,
  modalPuestoTitle,
  setModalPuestoTitle

}) => {
  const {User} = useAuth();
  const {data: comisionesPuesto=[]} = useSWRWithToken(`${URL_API_CF}ConfigComisiones/porcentajePuesto`,10, User.token)
  const [modal, setModal] = useState(historialComisionPuesto.historial.length > 0);
  const headers = { headers: { Authorization: `Bearer ${User.token}` } }

  const ShowHistoricoComisionPuesto = async(dataComisionPuesto) => {
    const { puestoId, oficinaId, departamentoId } = dataComisionPuesto
    const nombrePuesto = catalogos.puestos?.find(catPuesto => catPuesto.puestoId === puestoId)?.nombrePuesto
    const nombreOficina = catalogos.oficinas?.find(catOficina => catOficina.oficinaId === oficinaId)?.nombreOficina
    const nombreDepartamento = catalogos.departamentos?.find(catDepartamento => catDepartamento.departamentoId === departamentoId)?.nombreDepartamento
    setModalPuestoTitle(`${nombrePuesto}🔹${nombreDepartamento}🔹${nombreOficina}`)
    const {data, result} = await getDataAsync(`${URL_API_CF}ConfigComisiones/porcentajePuestoHistorico?puesto=${puestoId}&oficina=${oficinaId}&departamento=${departamentoId}`, headers)
    if(!result) {
        return <Error>{result}</Error>
    }
    else{
      setHistorialComisionPuesto({ historial: data, historicoActual: dataComisionPuesto })
    }
  }

  const mostrarActivo = ({ data: {data} }) => {
    return (
      <div>
          <Switch 
              value={data.porcentajeActivo} 
              onValueChange={ () => handleChangeStatus(data) }
              switchedOffText={'🔴'} 
              switchedOnText={'🟢'}
          />
      </div>
    )
  }

  const handleChangeStatus = (data) => {
    setHistorialComisionPuesto({...historialComisionPuesto, historicoActual: data})
    handleDelete(data)
  }

  const actionsTemplate = ({data:{data}}) => {
    return (
        <div className="d-flex">
            <Button icon='fas fa-history' type='normal' onClick={() => ShowHistoricoComisionPuesto(data)} />
        </div>
    )
  }

  const onSaving = async(e) =>{
    const {changes} = e;
    if(changes.length === 0){
        return
    }
    const {data, type, key} = changes[0];
    switch(type){
      case 'insert': 
        onSavedPuesto(data, 'insert')
        break;
      case 'update':
        const data1 = Object.assign([], data);
        let newData = key;
        await data1.map(item => newData = {...newData, item})
        onSavedPuesto(newData, 'update')
        break;
      default:
        return
    }
  }

  const onSumbitDelete = async () => {
    const {historicoActual} = historialComisionPuesto
    const {porcentajeActivo} = historicoActual
    const newStatus = {...historicoActual, porcentajeActivo: !porcentajeActivo}
    onSavedPuesto(newStatus, 'status')
    handleDeleteStatus()
  }

  return (
    <>
      <div className='card-container'>
        <h2>Comision Puesto</h2>
        <DataGrid 
          dataSource={comisionesPuesto}
          key="DataGridHistoricoSueldo"
          allowColumnReordering={true}
          columnAutoWidth={true}
          noDataText="No se encuentran datos"
          onSaving={onSaving}
          stateStoring={{ enabled: true, type: 'sessionStorage', storageKey: 'GRIDCOMISIONPUESTO' }}
        >
          <Paging enabled={true} defaultPageSize={20} />
          <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} visible={true} />
          
          <Editing
            mode="row"
            allowAdding={true}
            allowDeleting={false}
            allowUpdating={true}
            useIcons={true}
          />
          <Column 
            caption= "Puesto"
            dataField= "puestoId"
            alignment= "left"
          >
            <RequiredRule />
            <Lookup
                dataSource={catalogos?.puestos}
                displayExpr="nombrePuesto"
                valueExpr="puestoId"
            />
          </Column>
          <Column
            caption= "Oficina"
            dataField= "oficinaId"
            alignment= "center"
          >
            <RequiredRule />
            <Lookup
                dataSource={catalogos?.oficinas}
                displayExpr="nombreOficina"
                valueExpr="oficinaId"
            />
          </Column>
          <Column
            caption= "Departamento"
            dataField= "departamentoId"
            alignment= "center"
            >
            <RequiredRule />
            <Lookup
                dataSource={catalogos?.departamentos}
                displayExpr="nombreDepartamento"
                valueExpr="departamentoId"
            />
          </Column>
          <Column
            caption= "% Porcentaje"
            dataField= "porcentaje"
            dataType= "number"
            format= {formatoPorcentaje}
            >
            <RequiredRule />
          </Column>
          <Column
            caption= "Aplica a partir de"
            dataType= "date"
            format= "dd/MMM/yyyy"
            dataField= "aplicaApartir"
            >
            <RequiredRule />
          </Column>
          <Column
            caption="Activo"
            dataField="porcentajeActivo"
            alignment= "center"
            width={110}
            cellComponent={mostrarActivo}
          />
          <Column
            caption="Acciones"
            alignment= "center"
            width={110}
            cellComponent={actionsTemplate}
          />
        </DataGrid>
      </div>
      <ModalHistoricoComisionPuesto 
        status={modal} 
        dataModal={historialComisionPuesto} 
        setDataModal={() => setHistorialComisionPuesto(inicialHistorialData)} 
        setStatus={setModal} 
        modalTitle={modalPuestoTitle}
      />
      <ModalConfirmation 
        status={modalDeleteStatus} 
        message={MESSAGE_MODAL_DELETE} 
        close={() => handleDeleteStatus()}
        confirmation={() => onSumbitDelete()}
      />
    </>
  )
}

const pageSizes = [10, 25, 50, 100, 200];

export default memo(GridComisionPuesto)