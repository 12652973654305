const HeaderComparativo = ({
  opsTotal,
  opsSync,
  errorPorcentage,
}: any) => {
  return (
    <div className="header_comparativo">
      <p className="text_progress_bar">
        <span className="number_sync">{opsSync}</span>/
        <span className="number_day">{opsTotal}</span>{" "}
        <span className="ops_text">OPS</span> <b>{
          errorPorcentage.toFixed(2)
          }%</b>{" "}
        <span>de error</span>
      </p>
    </div>
  );
};

export default HeaderComparativo;
