import { PivotGridDataSourceField } from "devextreme/ui/pivot_grid/data_source";
import { ajustarDecimales, FormatoDecimalesPorcentaje } from 'utils/Formatos';

export const _FieldsPivotProfit: PivotGridDataSourceField[] = [
    {
        caption: 'Clientes',
        dataField: 'entidad_Razon_Social',
        width: 150,
        expanded: true,
        area: 'row'
    },
    {
        dataField: "fechaPickUpArribo",
        dataType: "date",
        expanded: true,
        area: "column",
        visible: true,
    },
    {
        groupName: "fechaPickUpArribo",
        groupInterval: "quarter",
        expanded: false,
        visible: false
    },
    {
        groupName: "fechaPickUpArribo",
        groupInterval: "month",
        visible: true
    },
    {
        caption: "Venta USD",
        dataField: "ventaUSD",
        summaryType: "sum",
        format: ajustarDecimales
    },
    {
        caption: "Compra USD",
        dataField: "compraUSD",
        summaryType: "sum",
        format: ajustarDecimales
    },
    {
        caption: "Puntos LF USD",
        dataField: "puntos_LF_USD",
        summaryType: "sum",
        format: ajustarDecimales
    },
    {
        caption: "Profit x CNTR",
        dataField: "profitPorCntr",
        summaryType: "sum",
        format: ajustarDecimales
    },
    {
        caption: "PROFIT USD",
        dataField: "profitUSD",
        area: "data",
        summaryType: "sum",
        format: ajustarDecimales
    },
    {
        caption: "% Profit",
        dataField: "porcentaje_Profit",
        area: "data",
        format: FormatoDecimalesPorcentaje
    },
    {
        caption: "Piezas",
        dataField: "embarque_Total_Piezas",
        area: "data",
        dataType: "number",
        summaryType: "sum"
    }
]