import { URL_API_CF, URL_API_FIREBASE_APP } from "consts/Services_URL";
import { IColumnProps } from "devextreme-react/data-grid";
import { IRemoteEmpleado } from "interfaces/ITalentoHumano";
import {
  ajustarDecimales,
  formatoDecimales,
  formatoPorcentaje,
} from "utils/Formatos";
import { IUser } from "interfaces/IUsuario";
import axios, { AxiosRequestConfig } from "axios";
import { IResponse } from "./interfaces/interfaces";
import { HttpErrorMessage } from "utils/ResponseAPI";
import {
  IRemoteDepartamentos,
  IRemoteAnios,
  IRemoteEstrategias,
  IRemoteFormaPago,
  IRemoteOficinas,
  IRemotePuesto,
  IRemoteVendedores,
  IFiltersConfBudget,
  IBudget,
} from "interfaces/IConfiguracion";
import { getDataAsync, sendDataAsync, sendRequestAsync } from "./fetch";
import { Meses } from "utils/Global";
import { SummaryTotalItem } from "devextreme/ui/data_grid";
import { db } from "config/firebase.config";
import { collection, doc, getDoc } from "firebase/firestore";

interface ICatalogosResponse extends IResponse {
  data?: {
    anios: IRemoteAnios[];
    division: string;
  };
}
interface IBudgetResponse extends IResponse {
  data: IBudget[];
}

interface IColumsGrid {
  withPercentaje: boolean;
  titleTotals: string;
  budget?: IBudget[];
}
interface ITotalBudget {
  rowTotal: IBudget;
  totalOps: number;
}
/**
 * FUNCIONES PARA CONFIGURACION
 * @param User
 * @returns
 */
export const ConfiguracionService = (User: IUser) => {
  const { token, divisionSeleccionada } = User;
  const headers: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };

  /**PUBLIC METHODS */
  return {
    getCatalogosFacturacionAnual: async (): Promise<IResponse> => {
      let response: IResponseCatalogosConfComisionPuesto = { result: false };
      await Promise.all([
        axios.get(`${URL_API_CF}catalogos/vendedores`, headers),
        axios.get(`${URL_API_CF}catalogos/puestos`, headers),
        axios.get(`${URL_API_CF}catalogos/oficinas`, headers),
        axios.get(`${URL_API_CF}catalogos/departamentos`, headers),
      ])
        .then(([vendedores, puestos, oficinas, departamentos]) => {
          const vendedoresRemote: IRemoteVendedores[] = vendedores.data;
          const puestosRemote: IRemotePuesto[] = puestos.data;
          const oficinasRemote: IRemoteOficinas[] = oficinas.data;
          const departamentosRemote: IRemoteDepartamentos[] =
            departamentos.data;

          response = {
            ...response,
            data: {
              departamentos: departamentosRemote,
              oficinas: oficinasRemote,
              puestos: puestosRemote,
              vendedores: vendedoresRemote,
            },
            result: true,
          };
        })
        .catch((err) => {
          const error = JSON.parse(JSON.stringify(err));
          const messageError = error.status
            ? HttpErrorMessage[error.status]
            : error.message;
          response = {
            ...response,
            error: messageError,
            estatus: error.status,
          };
        });
      return response;
    },
    getCatalogosEstrategias: async (): Promise<IResponse> => {
      let response: IResponseCatalogosEstrategias = { result: false };
      await Promise.all([
        axios.get(
          `${URL_API_CF}catalogos/estrategias?division=CROSSMOTION`,
          headers
        ),
        axios.get(`${URL_API_CF}catalogos/estrategias?division=MTI`, headers),
      ])
        .then(([segCross, segMTI]) => {
          const segCrossRemote: IRemoteEstrategias[] = segCross.data;
          const segMTIRemote: IRemoteEstrategias[] = segMTI.data;
          response = {
            ...response,
            data: {
              segmentacionCross: segCrossRemote,
              segmentacionMTI: segMTIRemote,
            },
            result: true,
          };
        })
        .catch((err) => {
          const error = JSON.parse(JSON.stringify(err));
          const messageError = error.status
            ? HttpErrorMessage[error.status]
            : error.message;
          response = {
            ...response,
            error: messageError,
            estatus: error.status,
          };
        });

      return response;
    },
    getCatalogosBudget: async (): Promise<ICatalogosResponse> => {
      const aniosResponse = await getDataAsync(
        `${URL_API_CF}catalogos/aniosBudget?division=${divisionSeleccionada}`,
        headers
      );
      if (!aniosResponse.result) {
        return { result: false };
      }
      const anios: IRemoteAnios[] = aniosResponse.data!;
      return {
        result: true,
        data: { anios, division: User.divisionSeleccionada! },
      };
    },
    getBudgetGeneral: async (
      filters: IFiltersConfBudget
    ): Promise<IBudgetResponse> => {
      const { año, division } = filters;
      const response = await getDataAsync(
        `${URL_API_CF}configBudget/general?division=${division}&anio=${año}`,
        headers
      );
      return response as IBudgetResponse;
    },
    getBudgetSegmentacion: async (
      filters: IFiltersConfBudget
    ): Promise<IBudgetResponse> => {
      const { año, division } = filters;
      const response = await getDataAsync(
        `${URL_API_CF}configBudget/segmentaciones?division=${division}&anio=${año}`,
        headers
      );
      return response as IBudgetResponse;
    },
    getLogs: async (): Promise<{
      data: any[];
      porcentaje: number;
    }> => {
      const actualDate = new Date();
      const anio = actualDate.getFullYear();
      const mes = actualDate.getMonth() + 1;
      const dia = actualDate.getDate();
      const ref = doc(
        collection(db, "operations"),
        `${anio}-${mes < 10 ? `0${mes}` : mes}-${dia - 1}`
      );
      const data = await getDoc(ref);
      const response = await getDataAsync(
        `
                ${URL_API_FIREBASE_APP}/getReportsSync?dateStart=${anio}-${mes}-${
          dia - 1
        }&dateEnd=${anio}-${mes}-${dia}
            `,
        headers
      );
      const porcentaje =
        Number(((response.data.payload.data.length * 100) / data.data()!.operationsLog.length >
        100
          ? 100
          : (response.data.payload.data.length * 100) / data.data()!.operationsLog.length).toFixed(0));
      return {
        data: response.data.payload.data,
        porcentaje,
      };
    },
    getImages: async (): Promise<IResponse> => {
      const response = await getDataAsync(
        `${URL_API_FIREBASE_APP}/getImages`,
        headers
      );
      return response.data.payload;
    },
    saveBudgetGeneral: async (budget: IBudget[]): Promise<boolean> => {
      const budgetGeneral: IBudget = budget.at(0)!;
      const response = await sendDataAsync(
        `${URL_API_CF}configBudget/general`,
        budgetGeneral,
        "POST",
        headers.headers
      );
      return response.result;
    },
    saveBudgetSegmentacion: async (budget: IBudget[]): Promise<boolean> => {
      const response = await sendDataAsync(
        `${URL_API_CF}configBudget/segmentaciones`,
        budget,
        "POST",
        headers.headers
      );
      return response.result;
    },
    saveNewYearBudget: async (): Promise<boolean> => {
      const response = await sendRequestAsync(
        `${URL_API_CF}configBudget/addYear`,
        "POST",
        headers.headers
      );
      return response.result;
    },
    saveBannerImage: async (index: number, url: string): Promise<boolean> => {
      const response = await sendDataAsync(
        `${URL_API_FIREBASE_APP}/updateImage?index=${index}&url=${url}`,
        {},
        "PUT",
        headers.headers
      );
      return response.result;
    },
    updateTotalesPorSegmentacion: (
      budget: IBudget[],
      año: number,
      titulo: string
    ): ITotalBudget => {
      const budgetGeneral: IBudget = budget.at(0)!;
      /*GENERAMOS LA FILA DONDE IRAN LOS TOTALES DE CADA MES PARA
            EL BUDGET POR SEGMENTACION A PARTIR DE LOS DATOS DEL BUDGET GENERAL */
      let rowTotal: IBudget = {
        titulo: titulo,
        division: "",
        anio: año,
      };
      let totalOps: number = 0;
      /*RECORREMOS CADA MES PARA ASIGNAR EL TOTAL */
      Meses.forEach((mes, index) => {
        const keyMes: string = mes.toLocaleLowerCase();
        totalOps += budgetGeneral.meses![keyMes].operaciones;
        rowTotal = {
          ...rowTotal,
          meses: {
            ...rowTotal.meses,
            [keyMes]: {
              id: index,
              mes: index,
              operaciones: budgetGeneral.meses![keyMes].operaciones,
              fecha: new Date(año, index + 1, 1),
            },
          },
        };
      });
      return { rowTotal, totalOps };
    },
    removeImageBanner: async (index: number): Promise<boolean> => {
      const response = await sendDataAsync(
        `${URL_API_FIREBASE_APP}/deleteImage?index=${index}`,
        {},
        "DELETE",
        headers.headers
      );
      return response.result;
    },
  };
};
export const GridConfiguracionBudget = () => {
  return {
    generateTotalsSummaryBudget: (
      budget: IBudget[],
      titleTotals: string
    ): SummaryTotalItem[] => {
      const totalesRow = budget.find((row) => row.titulo === titleTotals);
      const totales: SummaryTotalItem[] = Meses.map((mes: string) => {
        const keyMes: string = mes.toLocaleLowerCase();
        return {
          name: keyMes,
          summaryType: "custom",
          showInColumn: `meses.${keyMes}.operaciones`,
          customizeText: (data: any) => {
            const operaciones: number = totalesRow
              ? totalesRow.meses![keyMes].operaciones
              : 0;
            const { value: celdaValue }: { value: number } = data;
            let emoji: string = "";
            if (celdaValue < operaciones) {
              emoji = "➖";
            }
            if (celdaValue === operaciones) {
              emoji = "🟢";
            }
            if (celdaValue > operaciones) {
              emoji = "🚫";
            }
            return `${emoji}${celdaValue}`;
          },
        };
      });
      totales.push(
        {
          name: "Total",
          column: "Total",
          summaryType: "custom",
          showInColumn: "Total",
          displayFormat: "{0}",
        },
        {
          name: "Porcentaje",
          column: "%",
          summaryType: "custom",
          showInColumn: "%",
          displayFormat: "{0} %",
        }
      );
      return totales;
    },
    generateColumnsGridBudget: (Props: IColumsGrid): IColumnProps[] => {
      const { withPercentaje = false, budget = [], titleTotals } = Props;
      /**OBTENER EL TOTAL POR FILA */
      const calculateTotalSegmentacion = (budget: IBudget): number => {
        let totalOperaciones: number = 0;
        Meses.forEach((mes: string) => {
          const keyMes: string = mes.toLocaleLowerCase();
          totalOperaciones += budget.meses![keyMes]?.operaciones || 0;
        });
        return totalOperaciones;
      };

      const columns: IColumnProps[] = [
        {
          caption: "Titulo",
          dataField: "titulo",
          dataType: "string",
          alignment: "left",
          allowEditing: false,
        },
        {
          caption: "División",
          dataField: "division",
          dataType: "string",
          alignment: "left",
          allowEditing: false,
          width: 150,
        },
        {
          caption: "Enero",
          dataField: "meses.enero.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 75,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Febrero",
          dataField: "meses.febrero.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 75,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Marzo",
          dataField: "meses.marzo.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 75,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Abril",
          dataField: "meses.abril.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 75,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Mayo",
          dataField: "meses.mayo.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 75,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Junio",
          dataField: "meses.junio.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 75,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Julio",
          dataField: "meses.julio.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 75,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Agosto",
          dataField: "meses.agosto.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 75,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Septiembre",
          dataField: "meses.septiembre.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 90,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Octubre",
          dataField: "meses.octubre.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 75,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Noviembre",
          dataField: "meses.noviembre.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 85,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Diciembre",
          dataField: "meses.diciembre.operaciones",
          dataType: "number",
          alignment: "left",
          allowEditing: true,
          width: 85,
          validationRules: [
            {
              type: "required",
              message: "Requerido",
            },
            {
              type: "pattern",
              pattern: /^[0-9]+$/,
            },
          ],
        },
        {
          caption: "Total",
          alignment: "left",
          width: 80,
          allowEditing: false,
          calculateCellValue: calculateTotalSegmentacion,
        },
      ];
      if (withPercentaje) {
        columns.push({
          caption: "%",
          width: 90,
          alignment: "left",
          calculateCellValue: (rowBudget: IBudget): string => {
            if (budget.length === 0) return "0 %";
            const totalesRow = budget.find((row) => row.titulo === titleTotals);
            if (!totalesRow) return "0 %";
            const totalGeneral: number = calculateTotalSegmentacion(totalesRow);
            if (totalGeneral === 0) return "0 %";
            const totalSegmentacion: number =
              calculateTotalSegmentacion(rowBudget);
            const porcentaje: number = (totalSegmentacion * 100) / totalGeneral;
            return `${formatoDecimales(porcentaje, 2)} %`;
          },
        });
      }
      return columns;
    },
  };
};

interface ITiposValor {
  id: number;
  name: string;
}

interface ICalculoComision {
  id: string;
  metodo: string;
}

const tiposValor: ITiposValor[] = [
  {
    id: 1,
    name: "Porcentaje",
  },
  {
    id: 2,
    name: "Fijo",
  },
];

export const calculoComision: ICalculoComision[] = [
  {
    id: "Embarque",
    metodo: "Embarque",
  },
  {
    id: "Promedio",
    metodo: "Promedio",
  },
];

interface IResponseCatalogosConfComisionPuesto extends IResponse {
  data?: IGetCatalogosConfComisionPuesto;
}

export interface IGetCatalogosConfComisionPuesto {
  vendedores?: IRemoteVendedores[];
  puestos?: IRemotePuesto[];
  oficinas?: IRemoteOficinas[];
  departamentos?: IRemoteDepartamentos[];
}

interface IResponseCatalogosEstrategias extends IResponse {
  data?: IGetCatalogosEstrategias;
}

export interface IGetCatalogosEstrategias {
  segmentacionCross?: IRemoteEstrategias[];
  segmentacionMTI?: IRemoteEstrategias[];
}

export const columsConfiguracionPorcentajes = (
  formasPago: IRemoteFormaPago[]
): IColumnProps[] => {
  const columns: IColumnProps[] = [
    {
      caption: "Concepto Nomina",
      dataType: "string",
      dataField: "nominaConcepto",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
    },
    {
      caption: "Valor Nomina",
      dataType: "number",
      dataField: "nominaValor",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
    },
    {
      caption: "Tipo valor",
      dataType: "string",
      dataField: "tipoValor",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
      lookup: {
        dataSource: tiposValor,
        displayExpr: "name",
        valueExpr: "name",
      },
    },
    {
      caption: "Aplica a partir de",
      dataType: "date",
      format: "dd/MMM/yyyy",
      dataField: "aplicaApartir",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
    },
    {
      caption: "Forma de pago",
      dataType: "string",
      dataField: "formaPagoId",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
      lookup: {
        dataSource: formasPago,
        displayExpr: "formaPago",
        valueExpr: "pagoNominaId",
      },
    },
  ];
  return columns;
};

export const columsConfTipos: IColumnProps[] = [
  {
    caption: "Tipo",
    dataType: "string",
    dataField: "nominaDescripcion",
  },
  {
    caption: "Días",
    dataType: "number",
    dataField: "dias",
  },
];

export const columsConfFormas: IColumnProps[] = [
  {
    caption: "Forma de Pago",
    dataType: "string",
    dataField: "formaPago",
  },
];

export const columnsConfPagoDivisiones: IColumnProps[] = [
  {
    caption: "Division / Estratégica",
    dataType: "string",
    dataField: "divisionNombre",
    validationRules: [
      {
        type: "required",
        message: "Requerido",
      },
    ],
  },
  {
    caption: "Division / Estratégica",
    dataType: "string",
    dataField: "calculoComision",
    validationRules: [
      {
        type: "required",
        message: "Requerido",
      },
    ],
    lookup: {
      dataSource: calculoComision,
      displayExpr: "metodo",
      valueExpr: "metodo",
    },
  },
];

export const columnsConfRangosComisiones: IColumnProps[] = [
  {
    caption: "Minimo",
    dataField: "rangoMin",
    dataType: "number",
    format: ajustarDecimales,
    validationRules: [
      {
        type: "required",
        message: "Requerido",
      },
    ],
  },
  {
    caption: "Máximo",
    dataField: "rangoMax",
    dataType: "number",
    format: ajustarDecimales,
    validationRules: [
      {
        type: "required",
        message: "Requerido",
      },
    ],
  },
  {
    caption: "% Porcentaje",
    dataField: "rangoPorcentaje",
    dataType: "number",
    validationRules: [
      {
        type: "required",
        message: "Requerido",
      },
    ],
    format: formatoPorcentaje,
  },
];

export const columnsConfComisionesFijas = (
  empleados: IRemoteEmpleado[]
): IColumnProps[] => {
  const columns: IColumnProps[] = [
    {
      caption: "Vendedor",
      dataField: "porcentajeVendedor",
      alignment: "center",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
      lookup: {
        dataSource: empleados,
        displayExpr: "cotizacion_Vendedor",
        valueExpr: "cotizacion_Vendedor",
      },
    },
    {
      caption: "% Porcentaje",
      dataField: "porcentajeCantidad",
      dataType: "number",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
      format: formatoPorcentaje,
    },
  ];
  return columns;
};

export const columnsConfEstrategias = (
  segCross: IRemoteEstrategias[],
  segMTI: IRemoteEstrategias[]
): IColumnProps[] => {
  const columns: IColumnProps[] = [
    {
      caption: "Puerto Ciudad",
      dataType: "string",
      dataField: "puertoCiudad",
      alignment: "left",
      allowEditing: false,
    },
    {
      caption: "País",
      dataType: "string",
      dataField: "pais",
      alignment: "left",
      allowEditing: false,
    },
    {
      caption: "Segmentacion CROSSMOTION",
      dataField: "segmentacionCross",
      alignment: "center",
      lookup: {
        dataSource: segCross,
        displayExpr: "estrategia_Origen",
        valueExpr: "estrategia_Origen",
      },
    },
    {
      caption: "Segmentacion MTI",
      dataField: "segmentacionMTI",
      alignment: "center",
      lookup: {
        dataSource: segMTI,
        displayExpr: "estrategia_Origen",
        valueExpr: "estrategia_Origen",
      },
    },
  ];
  return columns;
};

export const columnsConfComisionesPuesto = (
  puestos: IRemotePuesto[],
  oficinas: IRemoteOficinas[],
  departamentos: IRemoteDepartamentos[]
): IColumnProps[] => {
  const columns: IColumnProps[] = [
    {
      caption: "Puesto",
      dataField: "puesto",
      alignment: "center",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
      lookup: {
        dataSource: puestos,
        displayExpr: "nombrePuesto",
        valueExpr: "puestoId",
      },
    },
    {
      caption: "Oficina",
      dataField: "oficina",
      alignment: "center",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
      lookup: {
        dataSource: oficinas,
        displayExpr: "nombreOficina",
        valueExpr: "oficinaId",
      },
    },
    {
      caption: "Departamento",
      dataField: "departamento",
      alignment: "center",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
      lookup: {
        dataSource: departamentos,
        displayExpr: "nombreDepartamento",
        valueExpr: "departamentoId",
      },
    },
    {
      caption: "% Porcentaje",
      dataField: "porcentaje",
      dataType: "number",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
      format: formatoPorcentaje,
    },
    {
      caption: "Aplica a partir de",
      dataType: "date",
      format: "dd/MMM/yyyy",
      dataField: "Aplica_Apartir",
      validationRules: [
        {
          type: "required",
          message: "Requerido",
        },
      ],
    },
  ];

  return columns;
};
