interface IHttpMessage {
  [key: string]: string;
}

export const HttpErrorMessage: IHttpMessage = {
  '500': 'Error en el servidor',
  '404': 'Recurso no encontrado',
  '200': 'Solicitud Exitosa',
  '201': 'Creación de recurso exitosa',
  '400': 'Petición incorrecta',
  '401': 'No esta autorizado para ver el contenido',
  '405': 'Metodo no permitido',
  '502': 'Respuesta invalida',
  '503': 'Servicio no disponible',
  '504': 'Tiempo de resupuesta agotado'
}
