export type TabPerfil = "Editar" | "Actividades";

export enum IndexTab {
    Editar = 0,
    Actividades = 1
}

export enum IndexTabSuperAdmin {
    Editar = 0,
    Actividades = 1
}