import { URL_API_FIREBASE_APP } from "consts/Services_URL";
import { AxiosRequestConfig } from "axios";
import { IUser } from "interfaces/IUsuario";
import { ITareas, IResponsables, IEntidades, IAddTarea, IUpdateTarea, ISeguimientoEntidades, ISeguimientoTareas } from 'interfaces/ITareas';
import { getDataAsync, sendDataAsync } from './fetch';
import { IResponse } from './interfaces/interfaces';

interface IResponseTareas extends IResponse {
    data: ITareas[];
}

interface IResponseResponsables extends IResponse {
    data: IResponsables[];
}

interface IResponseEntidades extends IResponse {
    data: IEntidades[];
}

interface IResponseSeguimientoClientes extends IResponse {
    data: ISeguimientoEntidades[];
}

interface IResponseSeguimientoProveedores extends IResponse {
    data: ISeguimientoEntidades[];
}

interface IResponseTareasEntidad extends IResponse {
    data: ISeguimientoTareas[];
}

export const TareasService = (User: IUser) => {
    const { token } = User;
    const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } };

    return {
        getTareas: async (): Promise<IResponseTareas> => {
            const response = await getDataAsync(`${URL_API_FIREBASE_APP}/getTareas`, headers);
            return {data:response.data.payload} as IResponseTareas;
        },
        getEntidades: async (tipo: string): Promise<IResponseEntidades> => {
            const response = await getDataAsync(`${URL_API_FIREBASE_APP}/getEntidades?tipo=${tipo}`, headers);
            return {data: response.data.payload} as IResponseEntidades;
        },
        getResponsables: async (): Promise<IResponseResponsables> => {
            const response = await getDataAsync(`${URL_API_FIREBASE_APP}/getColaboradores`, headers);
            return {
                data: response.data?.payload ? response.data.payload : []
            } as IResponseResponsables;
        },
        addTarea: async (tarea: IAddTarea): Promise<IResponse> => {
            const response = await sendDataAsync(`${URL_API_FIREBASE_APP}/addTarea`, tarea, 'POST');
            return response;
        },
        updateTarea: async (tarea: IUpdateTarea): Promise<IResponse> => {
            const response = await sendDataAsync(`${URL_API_FIREBASE_APP}/updateTarea`, tarea, 'PUT');
            return response;
        },
        deleteTarea: async (id: number): Promise<IResponse> => {
            const response = await sendDataAsync(`${URL_API_FIREBASE_APP}/deleteTarea`, { id }, 'DELETE');
            return response;
        },
        getSeguimientoClientes: async (): Promise<IResponseSeguimientoClientes> => {
            const response = await getDataAsync(`${URL_API_FIREBASE_APP}/seguimientoClientes`, headers);
            return {data: response.data.payload} as IResponseSeguimientoClientes;
        },
        getSeguimientoProveedores: async (): Promise<IResponseSeguimientoProveedores> => {
            const response = await getDataAsync(`${URL_API_FIREBASE_APP}/seguimientoProveedores`, headers);
            return {data: response.data.payload} as IResponseSeguimientoProveedores;
        },
        getTareasEntidad: async (id: string): Promise<IResponseTareasEntidad> => {
            const response = await getDataAsync(`${URL_API_FIREBASE_APP}/tareasEntidad?id=${id}`, headers);
            return {data: response.data.payload} as IResponseTareasEntidad;
        },
        getTareasEntidadRazonSocial: async (razonSocial: string): Promise<IResponseTareasEntidad> => {
            const response = await getDataAsync(`${URL_API_FIREBASE_APP}/tareasByRazonSocial?razon_social=${razonSocial}`, headers);
            return {data: response.data.payload} as IResponseTareasEntidad;
        }
    }
}