import { useMemo } from 'react'
import { useNominaPagada } from 'api/nomina';
import { Popup } from 'devextreme-react';
import { Select, Button, TableGrid } from 'components/Index';
import { useReportComisiones } from '../hooks/useReportComisiones';
import { columsReporteComisiones } from 'consts/TH';
import { IReporteComisiones, IImpuestosNomina } from 'interfaces/ITalentoHumano';
import { ajustarDecimales } from 'utils/Formatos';
import Loading from 'pages/Loading';

const ModalComisiones = () => {

  const { onShowModals, modals } = useNominaPagada();

  const btnCloseOptions = useMemo(() => {
    return {
      text: "CERRAR",
      icon: "close",
      type: "danger",
      width: 150,
      onClick: () => onShowModals("comisiones")
    }
  }, [onShowModals]);

  return (
    <Popup
      closeOnOutsideClick={false}
      title="Comisiones Pagadas por fecha de pago"
      showTitle={true}
      dragEnabled={false}
      height={675}
      showCloseButton={false}
      visible={modals.comisiones}
      toolbarItems={[
        { widget: "dxButton", toolbar: "bottom", location: "after", options: btnCloseOptions }
      ]}
      contentComponent={() => ContentComisiones(modals.comisiones)}
    />
  )
}

const ContentComisiones = (status: boolean) => {

  const {
    anios,
    meses,
    reporteComisiones,
    handlerOnSelectChange,
    handlerOnSearchComisiones,
    handlerOnDownloadComisiones,
    impuestos,
    error,
    loading
  } = useReportComisiones(status);

  const disabledReport: boolean = reporteComisiones.length === 0;

  return (
    <div className='container-modals'>
      <div className='filters-modals'>
        <Select
          width={175}
          item={meses}
          display='nombreMes'
          keyValue='numeroMes'
          onChangeSelect={handlerOnSelectChange}
          showClearButton={false}
        />
        <Select
          width={145}
          item={anios}
          display='anios'
          keyValue='anios'
          onChangeSelect={handlerOnSelectChange}
          showClearButton={false}
        />
        <Button
          text='Buscar comisiones'
          width={200}
          icon="fas fa-search"
          className="btnSearch"
          onClick={() => handlerOnSearchComisiones()}
        />
        <Button
          text='Descargar Reporte'
          width={250}
          icon='fas fa-file-excel'
          className="btnGenerate"
          onClick={() => handlerOnDownloadComisiones()}
          disabled={disabledReport}
        />
        <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
      </div>
      <br />
      <hr />
      <div className='table-comisiones'>
        {
          loading ? (
            <Loading />
          ) : (
            <TableGrid
              dataSource={reporteComisiones}
              columns={columsReporteComisiones}
              height={275}
              enableHeaderFilter={true}
              enableHoverState={true}
            />
          )
        }
        <ShowTotalComponent
          reporteComisiones={reporteComisiones}
          impuestos={impuestos}
        />
      </div>
    </div>
  )
}

interface IProps {
  reporteComisiones: IReporteComisiones[];
  impuestos: IImpuestosNomina;
}

const ShowTotalComponent = (Props: IProps) => {

  const { reporteComisiones, impuestos } = Props;
  const { iva, porcentajeFacturacion } = impuestos;

  let subTotal: number = 0;
  reporteComisiones.forEach((item) => {
    subTotal += item.total_A_Pagar;
  });
  const totalAPagar = (subTotal + (subTotal * (porcentajeFacturacion / 100)));
  if (reporteComisiones.length === 0) {
    return <></>
  }
  return (
    <div className="divTotalesComisiones">
      <div className="titleTotal"><p>Total a pagar : </p><p>$ {ajustarDecimales(subTotal)}</p></div>
      <div className="titleTotal"><p>Subtotal (+ {porcentajeFacturacion}%) : </p><p>$ {ajustarDecimales(totalAPagar)}</p></div>
      <div className="titleTotal"><p>IVA {iva}%: </p><p>$ {ajustarDecimales(totalAPagar * (iva / 100))}</p></div>
      <div className="titleTotal"><p>Total : </p><p>$ {ajustarDecimales(totalAPagar + (totalAPagar * (iva / 100)))}</p></div>
    </div>
  )
}


export default ModalComisiones