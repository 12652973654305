import { arrayBoxOpciones } from "utils/Global"
import { IBoxOpcion } from '../utils/Global';

interface IProps {
  divisiones: IBoxOpcion[];
}

const BoxOpciones = ({ divisiones }: IProps) => {

  return (
    <div className="container-opciones">
      {
        divisiones.map(({ name, img, className, onClick }, index) => (
          <div
            className='opcion'
            key={index}
            onClick={() => onClick(name)}
          >
            <img
              alt='opcion'
              src={img}
              className={className}
            />
            <p>{name}</p>
          </div>
        ))
      }
      {
        arrayBoxOpciones.map(({ name, img, enlace, className, onClick }, index) => (
          <div
            className='opcion'
            key={index}
            onClick={() => onClick(enlace || "")}
          >
            <img
              alt='opcion'
              src={img}
              className={className}
            />
            <p>{name}</p>
          </div>
        ))
      }
    </div>
  )
}

export default BoxOpciones