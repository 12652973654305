import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'api/auth';
import { ClientesService } from 'api/clientes';
import { IFilterChange, IFilterMultiple } from 'components/interfaces/Interfaces';
import { IFiltersEdoCuenta, IGetDataClientes } from 'interfaces/IClientes';
import { onExportingGrid } from 'utils/Functions';
import { ExportingEvent } from 'devextreme/ui/data_grid';

interface IUseEstadoCuenta {
  estadosCuenta: IGetDataClientes;
  catalogos: ICatalogoClientes;
  handlerOnSelectChange: (e: IFilterChange) => void;
  handlerFiltrado: () => Promise<void>;
  onExporting: (e: ExportingEvent) => void
}

interface ICatalogoClientes {
  clientes?: IFilterMultiple;
  loading: boolean;
  error?: string | null;
}

const defaultFilters: IFiltersEdoCuenta = {
  clientes: []
}

export const useEstadoCuenta = (): IUseEstadoCuenta => {
  const { User } = useAuth();
  const [filters, setFilters] = useState<IFiltersEdoCuenta>(defaultFilters);
  const [catalogos, setCatalogos] = useState<ICatalogoClientes>({ loading: false });
  const [estadosCuenta, setEstadosCuenta] = useState<IGetDataClientes>({ loading: false });

  const opsClientes = useCallback(() => {
    return ClientesService(User);
  }, [User]);

  const onExporting = useCallback((e: ExportingEvent) => {
    return onExportingGrid(e, "EstadoDeCuenta", User.divisionSeleccionada!);
  }, [User]);

  const handlerFiltrado = async (): Promise<void> => {
    setEstadosCuenta({ loading: true });
    const filterString: string = JSON.stringify(filters);
    const { result, data, error } = await opsClientes().getDataEstadoCuenta(filterString);
    if (!result) {
      setEstadosCuenta({ error: error, loading: false });
      return
    }
    setEstadosCuenta({ data: data, loading: false, });
  }

  const handlerOnSelectChange = (e: IFilterChange): void => {
    const { key, value } = e;
    setFilters(prev => {
      return {
        ...prev,
        [key]: value
      }
    });
  }
  /**OBTENER CATALOGOS */
  useEffect(() => {
    const getCatalogos = async (): Promise<void> => {
      setCatalogos({ loading: true });
      const { result, data, error } = await opsClientes().getCatalogosEdoCuenta();
      if (!result) {
        setCatalogos({ error: error, loading: false });
        return
      }
      const filtersCatalogos: IFilterMultiple = data;
      setCatalogos({ clientes: filtersCatalogos, loading: false });
    }
    getCatalogos();
  }, [opsClientes]);

  /**OBTENER DATA INICIAL */
  useEffect(() => {
    const getInitialData = async (): Promise<void> => {
      setEstadosCuenta({ loading: true });
      const filterString: string = JSON.stringify(defaultFilters);
      const { result, data, error } = await opsClientes().getDataEstadoCuenta(filterString);
      if (!result) {
        setEstadosCuenta({ error: error, loading: false });
        return
      }
      setEstadosCuenta({ data: data, loading: false, });
    }
    getInitialData();
  }, [opsClientes]);

  return {
    estadosCuenta,
    catalogos,
    handlerOnSelectChange,
    handlerFiltrado,
    onExporting
  }
}