import { useState } from 'react'
import { TableGrid, Button } from 'components/Index';
import { useDeclaraciones } from './hooks/useDeclaracionProveedor';
import ErrorPage from 'pages/Error';
import { columsDeclaracionAnualProveedores } from 'api/proveedores';
import { DateBox } from 'devextreme-react';
import MenuFilters from 'components/MenuFilters';

const DeclaracionImpuestosProveedor = () => {

  const fecha = new Date();
  const [fechaInicial, setFechaInicial] = useState<Date>(fecha);
  const [fechaFinal, setFechaFinal] = useState<Date>(fecha);

  const {
    declaraciones,
    handleFiltrado,
    onExporting
  } = useDeclaraciones();

  return (
    <div className="container-proveedoresModules">
      <MenuFilters>
        <div className="container-filters">
          <div>
            <p style={{ fontWeight: "bold", fontSize: "14px" }}>Fecha Inicio</p>
            <DateBox
              type='date'
              pickerType='calendar'
              max={fechaFinal}
              value={fechaInicial}
              onValueChange={(e) => setFechaInicial(e)}
            />
          </div>
          <div>
            <p style={{ fontWeight: "bold", fontSize: "14px" }}>Fecha Fin</p>
            <DateBox
              type='date'
              pickerType='calendar'
              min={fechaInicial}
              max={Date.now()}
              value={fechaFinal}
              onValueChange={(e) => setFechaFinal(e)}
            />
          </div>
          <Button
            onClick={() => handleFiltrado(fechaInicial, fechaFinal)}
            text={declaraciones.loading ? "Filtrando..." : "Filtrar"}
            icon='fas fa-search'
            disabled={declaraciones.loading}
            width={"100%"}
            height={40}
            iconHover="Claro"
          />
        </div>
      </MenuFilters>
      <div className="container-table">
        {
          declaraciones.loading ? (
            "Cargando..."
          )
            :
            (
              declaraciones.error ? (
                <ErrorPage errorMessage={declaraciones.error} />
              )
                :
                (
                  <TableGrid
                    dataSource={declaraciones.data}
                    columns={columsDeclaracionAnualProveedores}
                    enableGroupPanel={true}
                    enableSearchPanel={true}
                    enableExport={{ enabled: true, onExporting: (e) => onExporting(e) }}
                    enableSessionStorage={{ enabled: true, keyStorage: 'gridDeclaracionProveedor' }}
                  />
                )
            )
        }
      </div>
    </div>
  )
}

export default DeclaracionImpuestosProveedor