import { useAuth } from 'api/auth';
import { columnsConfRangosComisiones } from 'api/configuracion';
import TableGrid, { IOnSaved } from 'components/TableGrid';
import { URL_API_CF } from 'consts/Services_URL';
import { useSWRWithToken } from 'hooks/useSWR';
import { IRemoteConfiguracionComisionesRango } from 'interfaces/IConfiguracion'
import { memo } from 'react'

interface IProps {
  onSavedRango:(data: IRemoteConfiguracionComisionesRango, action: string)=> void;
}

const GridRangoComisiones = (Props: IProps) => {
  const {
    onSavedRango
  } = Props;

  const {User} = useAuth();
  const {data=[]} = useSWRWithToken(`${URL_API_CF}ConfigComisiones/ComisionRangos`,10, User.token!)

  const onSaved = (e:IOnSaved[]) =>{
    if(e.length === 0){
        return
    }
    const {data, type, key} = e[0];
    switch(type){
        case 'insert':
          onSavedRango(data, 'insert')
          break;
        case 'update':
          onSavedRango(data, 'update')
          break;
        case 'remove':
          onSavedRango(key, 'remove')
          break;
        default:
          return
    }
  }
  
  return (
    <div className="card-container">
        <h2>Rangos de Comisiones</h2>
        <TableGrid
          columns={columnsConfRangosComisiones}
          dataSource={data}
          enableEditing={{
            modeEdit: "row", 
            showIcons: true,
            enableInsert: true,
            enableUpdating: true,
            onSavedChanges:onSaved
        }}
      />
    </div>
  )
}

export default memo(GridRangoComisiones)