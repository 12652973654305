import { memo, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import * as Echarts from 'echarts/core';
import {
  TitleComponent,
  TitleComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption
} from 'echarts/components';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { modoCalculo, typeValue } from './interfaces/IEcharts';
import { FunctionsCharts } from './Functions/functions';
import { LoadingPanel } from './Index';

Echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

type EChartsOption = Echarts.ComposeOption<
  | TitleComponentOption
  | ToolboxComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | LineSeriesOption
>;

interface IProps {
  dataSource?: any[];
  legendProp?: string;
  axisXProp?: string;
  valueProp?: string;
  width?: number | string;
  height?: number | string;
  title?: string;
  loading?: boolean;
  mode?: modoCalculo;
  axisType?: typeValue;
  legendType?: typeValue;
  radiusStyle?: boolean;
  colors?: Echarts.Color[];
}

const StackedAreaEchart = (Props: IProps): JSX.Element => {

  if (Props.loading) {
    return <LoadingPanel />
  }
  return <StackerArea {...Props} />
}

const StackerArea = (Props: IProps): JSX.Element => {

  const container = useRef(null);
  const isMounted = useRef(false);
  const [chart, setChart] = useState<Echarts.ECharts>();

  const {
    dataSource = [],
    legendProp = "",
    axisXProp = "",
    width = "100%",
    height = 400,
    title = "",
    valueProp = "",
    mode = "count",
    axisType = "string",
    legendType = "string",
    radiusStyle = false,
    colors = []
  }
    = Props;

  const legends: string[] = useMemo(() => {
    if (dataSource.length === 0) return [];
    const legendsKeys: any[] = dataSource.map((item) => {
      return {
        ...item,
        [legendProp]: FunctionsCharts.ValueByType(item, legendType, legendProp)
      }
    })
    return FunctionsCharts.KeysValues(legendsKeys, legendProp, legendType);
  }, [dataSource, legendProp, legendType]);

  const axisX: string[] = useMemo(() => {
    if (dataSource.length === 0) return [];
    const axisKeys: any[] = dataSource.map((item) => {
      return {
        ...item,
        [axisXProp]: FunctionsCharts.ValueByType(item, axisType, axisXProp)
      }
    })
    return FunctionsCharts.KeysValues(axisKeys, axisXProp, axisType);
  }, [dataSource, axisXProp, axisType]);

  const series: LineSeriesOption[] = useMemo(() => {
    const seriesData: LineSeriesOption[] = [];
    if (dataSource.length === 0) return seriesData;
    legends.forEach((legend) => {
      let values: number[] = []; //GUARAR LOS VALORES DE CADA LEGEND
      const filterDataLegend: any[] = dataSource.filter((item) =>
        FunctionsCharts.FilterData(item, legendType, legend, legendProp)
      );
      /*RECORREMOS NUESTRAS AXIS EN X */
      axisX.forEach((axis) => {
        const filterDataAxis: any[] = filterDataLegend.filter((item) =>
          FunctionsCharts.FilterData(item, axisType, axis, axisXProp)
        );
        let total: number = 0;
        if (mode === "count") {
          total = filterDataAxis.length;
        }
        if (mode === "sum") {
          filterDataAxis.forEach((item) => {
            const totalItem: any = item[valueProp];
            total += typeof totalItem === "number" ? totalItem : 0;
          });
        }
        values.push(total);
      });
      seriesData.push({
        name: legend,
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        smooth: radiusStyle,
        emphasis: {
          focus: 'series'
        },
        data: values,
        color: colors
      })
    });
    return seriesData;
  }, [dataSource, axisXProp, valueProp, axisX, legends, legendProp, mode, axisType, legendType, radiusStyle, colors]);

  const generateGraphic = useCallback(() => {
    if (!chart) return
    const optionsChart: EChartsOption = {
      title: {
        text: title
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        data: legends
      },
      toolbox: {
        feature: {
          saveAsImage: {
            name: `Gráfico ${title}`,
            title: "Guardar como imagen"
          }
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: axisX
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: series
    };
    chart.clear();
    chart.setOption(optionsChart);
  }, [axisX, legends, chart, series, title]);

  generateGraphic();

  useEffect(() => {
    if (!isMounted.current) {
      const myChart = Echarts.init(container.current!);
      isMounted.current = true;
      setChart(myChart);
    }
  }, []);


  return (
    <div
      id='container-stackerArea'
      ref={container}
      style={
        {
          width: typeof width === "number" ? `${width}px` : width,
          height: typeof height === "number" ? `${height}px` : height
        }
      }
    >
    </div>
  )
}

export default memo(StackedAreaEchart)