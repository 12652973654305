import { Select, Button, ModalConfirmation, FechaFilters } from 'components/Index'
import { CSNotificacion, CSStatus } from 'consts/Comisiones'
import { CheckBox } from 'devextreme-react'
import { GridComisionesPendientes } from './Index';
import { useComisionesPendientes } from './hooks/useComisionesPendientes'
import './styles/estilos.scss'
import MenuFilters from 'components/MenuFilters';

const ComisionesPendientes = () => {

  const {
    filters,
    estrategias,
    comisiones,
    estatus,
    isAllSelected,
    confirmation,
    validateEditingComision,
    handlerOnChangeFecha,
    handlerOnSearchComisiones,
    handlerOnChangeEstrategia,
    handlerOnSelectAllComisiones,
    handlerSendComisiones,
    handlerConfirmation,
    handlerOnSyncEmbarque
  } = useComisionesPendientes();

  const { fecha } = filters;

  return (
    <div className="container-comisiones_modules">
      <MenuFilters>
        <div className="container-filters">
          <Select
            title='Estrategia / Divisiones'
            styleDropBox="outlined"
            item={estrategias}
            showClearButton={false}
            onChangeSelect={handlerOnChangeEstrategia}
            width={"100%"}
          />
          <FechaFilters
            fecha={fecha}
            onValueChanged={handlerOnChangeFecha}
          />
          <Button
            text={CSStatus.Searching === estatus ? "Buscando..." : "Buscar"}
            icon="fas fa-search-dollar"
            iconHover='Claro'
            onClick={() => handlerOnSearchComisiones()}
            disabled={CSStatus.Searching === estatus}
          />
          <Button
            text='Mandar a pagar'
            style={{ backgroundColor: "#00D55A" }}
            icon="fas fa-receipt"
            iconHover='Claro'
            className='btnSendComissions'
            disabled={CSStatus.Searching === estatus || CSStatus.Synchronizing === estatus || comisiones.length === 0}
            onClick={() => handlerConfirmation()}
          />
        </div>
      </MenuFilters>
      <GridComisionesPendientes
        keyStateStoraged="ComisionesPendientesGrid"
        comisionesSource={comisiones}
        onStartEditing={validateEditingComision}
        onSyncClick={handlerOnSyncEmbarque}
        isSynchronizing={CSStatus.Synchronizing === estatus}
      />
      <br />
      <div className='check-box-selected-all'>
        <CheckBox
          text='Seleccionar todas las comisiones que aplican'
          value={isAllSelected}
          disabled={comisiones.length === 0 || CSStatus.Searching === estatus || CSStatus.Synchronizing === estatus}
          onValueChange={(select) => handlerOnSelectAllComisiones(select)}
        />
      </div>
      <ModalConfirmation
        status={confirmation}
        message={CSStatus.Sending ? CSNotificacion.PayingComissionsPending : CSNotificacion.AreYouSureToPayComissions}
        close={() => handlerConfirmation()}
        confirmation={() => handlerSendComisiones()}
        disabled={CSStatus.Sending === estatus}
      />
    </div>
  )
}


export default ComisionesPendientes