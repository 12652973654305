import PivotOperaciones from './components/PivotOperaciones';
import { useOpsCanceladas } from './hooks/useOpsCanceladas'
import SelectBox from 'components/Select';
import FilterMultipleGrid from 'components/FilterMultipleGrid';
import { _FieldDesgloce } from 'api/operaciones';
import { Button } from 'components/Index';
import './styles/estilos.scss';
import MenuFilters from 'components/MenuFilters';

const OpsCanceladas = () => {

  const {
    catalogos,
    operacionesCanceladas,
    handleFiltrado,
    handlerOnSelectChange,
    onExporting
  } = useOpsCanceladas();

  const { loading } = operacionesCanceladas;

  const showFilters = () => {
    const añosOps = catalogos.años;
    const estrategias = catalogos.estrategias;
    const motivos = catalogos.motivos;
    return (
      <MenuFilters>
        {
          catalogos.loading ? (
            <p>Cargando...</p>
          ) : (
            <div className='container-filters'>
              <FilterMultipleGrid
                items={añosOps?.items}
                onChange={handlerOnSelectChange}
                keyFilter={añosOps?.key}
                selectionState={añosOps?.selectionState}
                title={añosOps?.title}
              />
              <SelectBox
                item={estrategias}
                key={estrategias?.key}
                onChangeSelect={handlerOnSelectChange}
              />
              <SelectBox
                item={motivos}
                key={motivos?.key}
                onChangeSelect={handlerOnSelectChange}
              />
              <Button
                onClick={() => handleFiltrado()}
                text={operacionesCanceladas.loading ? "Buscando..." : "Filtra OPS"}
                icon='fas fa-search'
                disabled={loading}
                className="btnFilter"
                width={"100%"}
                iconHover="Claro"
              />
            </div>
          )
        }
      </MenuFilters>
    )
  }

  return (
    <div className='container-operaciones'>
      {showFilters()}
      <PivotOperaciones
        dataOperaciones={operacionesCanceladas}
        fields={_FieldDesgloce}
        keyLocalStorage='pivotOpsCanceladas'
        onExporting={onExporting}
      />
    </div>
  )
}

export default OpsCanceladas