import axios from "axios";
import { useEffect, useState } from "react";
import { HttpErrorMessage } from 'utils/ResponseAPI';
import { AxiosRequestConfig } from 'axios';

interface IGetData {
  data?: any;
  loading: boolean;
  error: string | null;
}

export const useGetData = (url?: string): IGetData => {

  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);

  useEffect(() => {

    if (!url) return

    setData(null);
    setError(null);
    axios.get(url)
      .then((response) => {
        setData(response.data);
      }).catch((err) => {
        const error = JSON.parse(JSON.stringify(err));
        const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
        setError(messageError);
      })
  }, [url])

  return {
    data,
    error,
    loading: !data && !error
  }
}

export const useGetDataWithAuth = (url?: string | null, token?: string): IGetData => {

  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);

  useEffect(() => {

    if (!url || !token) return

    setData(null);
    setError(null);
    const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } };
    axios.get(url, headers)
      .then((response) => {
        setData(response.data);
      }).catch((err) => {
        const error = JSON.parse(JSON.stringify(err));
        const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
        setError(messageError);
      })
  }, [url, token])

  return {
    data,
    error,
    loading: !data && !error
  }
}