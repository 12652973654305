import { Popup, ToolbarItem } from 'devextreme-react/popup'
import React, { memo } from 'react'

const ModalEliminarPerfilColaborador = ({statusModal, message, onSumbitDelete, closePopup}) => {

  const optionCerrar = {
    icon: "close",
    text : "Cerrar",
    type: "danger"
  }
  const optionsDelete = {
    icon : "save",
    text :"Aceptar",
    type : "success"
  }
  const renderTitle = () =>{
    return(
        <h3 style = {{margin:"-0.2rem"}}>
            Confirmar
        </h3>
    )
  }

  return (
    <>
      <Popup
        visible={statusModal}
        width={400}
        height={220}
        dragEnabled={false}
        closeOnOutsideClick={false}
        showCloseButton={false}                
        titleRender = {renderTitle}
      >
        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="center"
            options={optionsDelete}
            onClick={() => onSumbitDelete()}
        />

        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="center"
            options={optionCerrar}
            onClick={closePopup}
        />

        <p style={{fontSize: "1.1rem" }}>{message}</p>
      </Popup>
    </>
  )
}

export default memo(ModalEliminarPerfilColaborador)