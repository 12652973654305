import ResponsiveBox, { Location, Item, Row, Col } from 'devextreme-react/responsive-box';
import GridFormaPago from './GridFormaPago';
import GridPorcentajes from './GridPorcentajes';
import GridTipoNomina from './GridTipoNomina';


const BoxNomina = ({
    configuracionNominaPorcentaje, 
    formasPago,
    configuracionNominaTipo, 
    onSavedPorcentajes
  }) => {

  const screen = (width) => {
    return (width < 700) ? 'sm' : 'lg';
  }

  return (
    <ResponsiveBox
        screenByWidth={screen}
        singleColumnScreen='sm'
    >
      <Row />
      <Row />
      <Col ratio={1} />
      <Col ratio={1} />
      <Item>
          <Location row={0} col={0} colspan={2} />
          <GridPorcentajes
            configuracionData={configuracionNominaPorcentaje.data}
            formasPago={formasPago.data}
            onSavedPorcentajes={onSavedPorcentajes} 
          />
      </Item>
      <Item>
          <Location row={1} col={0} />
          <GridTipoNomina
            configuracionData={configuracionNominaTipo.data} 
          />
      </Item>
      <Item>
          <Location row={1} col={1} />
          <GridFormaPago 
            formasPago={formasPago.data}
          />
      </Item>
    </ResponsiveBox>
  )
}

export default BoxNomina