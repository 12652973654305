import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { MenuProvider } from "contexts/MenuContext";
import { NavBarProvider } from "contexts/NavBarContext";
import { TaskProvider } from "contexts/TaskContext";
import App from "./App";
import "./index.css";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

root.render(
  <Suspense fallback={<span>Cargando...</span>}>
    <BrowserRouter basename="/">
      <AuthProvider>
        <MenuProvider>
          <NavBarProvider>
            <TaskProvider>
              <App />
            </TaskProvider>
          </NavBarProvider>
        </MenuProvider>
      </AuthProvider>
    </BrowserRouter>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
