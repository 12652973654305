import { CSSProperties } from 'react';
import { useAuth } from 'api/auth';
import { URL_API_GENERAL } from 'consts/Services_URL';
import { useSWRWithToken } from 'hooks/useSWR';
import { ITopVendedores } from 'interfaces/IHome';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { ColaboradorIcon } from 'images/Index';
import { FormatText } from 'utils/Formatos';

interface IDataTop {
  data: ITopVendedores[];
  error: any;
  loading: boolean;
}

interface IProps {
  className?: string;
  style?: CSSProperties;
}

const TopVendedores = ({ className, style }: IProps) => {

  const { User } = useAuth();
  const { data = [], loading }: IDataTop =
    useSWRWithToken(`${URL_API_GENERAL}top?division=${User.divisionSeleccionada}`, 0, User.token!);

  if (loading) {
    return (
      <Stack spacing={1} className={className}>
        <Skeleton variant="rectangular" width={"100%"} height={"100%"} animation='wave' />
      </Stack >
    )
  }

  return (
    <div className={className} style={style}>
      <div className='container-top'>
        <h2>Ranking</h2>
        <div className='list-departaments'>
          <p className='departament'>Sales Executive</p>
        </div>
        <div className='selection-departament'>
          <span
            id='selection'
          ></span>
        </div>
        <div className='list-colaboradores'>
          {
            data.map(({ operaciones, vendedor, oficina }, index) => (
              <div className='colaborador' key={index}>
                <img src={ColaboradorIcon} alt="Colaborador" />
                <div >
                  <p>{FormatText(vendedor)}</p>
                  <p>{oficina}</p>
                </div>

                <p className='operaciones'>{operaciones}</p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}


export default TopVendedores