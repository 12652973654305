import { useAuth } from "api/auth";
import { getDataAsync, sendDataAsync } from "api/fetch";
import { IGetCatalogoPerfilColab, ITService } from "api/it";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { URL_API_IT } from "consts/Services_URL";
import notify from "devextreme/ui/notify";
import { ITabs } from "functions/It";
import { useSWRWithToken } from "hooks/useSWR";
import { IDivisiones, IPerfilColaboradores, IPermisos, IRemoteJefes, IRemotePerfilColaboradores } from "interfaces/IIt";
import { IHistoricoSueldo } from "interfaces/ITalentoHumano";
import { useCallback, useEffect, useMemo, useState } from "react";

const initialStatePerfilColab: IPerfilColaboradores = {
    colaboradorId: 0,
    nombreColaborador: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombrePreferido: "",
    usuarioCMotion: "",
    contraseniaCMotion: "",
    colaboradorGUID: "",
    fechaIngreso: new Date(),
    fechaBaja: null,
    nombreMagaya: "",
    usuarioMagaya: "",
    contraseniaMagaya: "",
    perfilActivo: true,
    oficinaId: null,
    oficinaNombre: "",
    puestoId: null,
    rolId: 4,
    puestoNombre: "",
    departamentoId: null,
    departamentoNombre: "",
    documento_Id: null,
    documento_Nombre: "",
    jefeId: null,
    nombreJefe: "",
    extencion: "",
    telefono: "",
    whatsapp: "",
    email: "",
    tieneGenteACargo: false,
    permisos: {},
    colaborador_Divisiones: {
        CROSSMOTION: true
    },
    selectAll: false,
    historicoSueldo: {
        empleadoId: 0,
        historicoId: 0,
        nombreEmpleado: "",
        sueldoBruto: 0,
        sobrepasaIMSS: 0,
        formaPago: "NOM",
        formaPagoId: 0,
        comisiona: false,
        aplicaDesde: new Date(),
        aplicaHasta: null,
    },
    infoNomina: {
        clabe: "",
        claveNomilinea: "",
        cuenta: "",
        empleadoId: 0,
        infoNominaId: 0,
        rfc: ""
    }
}

interface INotify {
    type: string;
    message?: string;
}

let MESSAGE_MODAL_DELETE = "¿Estás seguro de eliminar la información del colaborador?";

interface IusePerfil {
    colaborador: IPerfilColaboradores;
    modalStatus: boolean;
    setModalStatus(e: boolean): void;
    modoEdicion: boolean;
    setModoEdicion(e: boolean): void;
    catalogos: IGetCatalogoPerfilColab;
    setColaborador(e: IPerfilColaboradores): void;
    setValorInicial(): void;
    MESSAGE_MODAL_DELETE: string;
    modalDeleteStatus: boolean;
    setModalDeleteStatus(e: boolean): void;
    handleDelete(e: IPerfilColaboradores): void;
    HandleEdit(e: IPerfilColaboradores): void;
    onSavedPerfil(e: IRemotePerfilColaboradores, a: string): void;
    jefes: IRemoteJefes[];
    rol: string | undefined
    handlerOnSelectTab: (tab: ITabs) => void;
    tabVisible: ITabs;
    handleAllpermissionsSelect: (colaboradorReceived: IPerfilColaboradores) => void
    asignarHistorico: (historicoSueldo: IHistoricoSueldo) => void
}

const defaultTab: ITabs = {
    text: "General"
}

export const usePerfil = (): IusePerfil => {
    const { User } = useAuth();
    const { rol } = User;
    const [colaborador, setColaborador] = useState<IPerfilColaboradores>({ ...initialStatePerfilColab })
    const [modoEdicion, setModoEdicion] = useState(false)
    const [modalStatus, setModalStatus] = useState(false)
    const [modalDeleteStatus, setModalDeleteStatus] = useState(false)
    const [catalogos, setCatalogos] = useState<IGetCatalogoPerfilColab>({ loading: false })
    const [tabVisible, setTabVisible] = useState<ITabs>(defaultTab);

    const { data: jefes } = useSWRWithToken(`${URL_API_IT}catalogos/jefes`, 10, User.token!)

    const setValorInicial = useCallback(() => {
        setColaborador(
            {
                colaboradorId: 0,
                nombreColaborador: "",
                apellidoPaterno: "",
                apellidoMaterno: "",
                nombrePreferido: "",
                usuarioCMotion: "",
                contraseniaCMotion: "",
                colaboradorGUID: "",
                fechaIngreso: new Date(),
                fechaBaja: null,
                nombreMagaya: "",
                usuarioMagaya: "",
                contraseniaMagaya: "",
                perfilActivo: true,
                oficinaId: null,
                oficinaNombre: "",
                puestoId: null,
                rolId: 4,
                puestoNombre: "",
                departamentoId: null,
                departamentoNombre: "",
                documento_Id: null,
                documento_Nombre: "",
                jefeId: null,
                nombreJefe: "",
                extencion: "",
                telefono: "",
                whatsapp: "",
                email: "",
                tieneGenteACargo: false,
                permisos: {},
                colaborador_Divisiones: {
                    CROSSMOTION: true
                },
                selectAll: false,
                historicoSueldo: {
                    empleadoId: 0,
                    historicoId: 0,
                    nombreEmpleado: "",
                    sueldoBruto: 0,
                    sobrepasaIMSS: 0,
                    formaPago: "NOM",
                    formaPagoId: 0,
                    comisiona: false,
                    aplicaDesde: new Date(),
                    aplicaHasta: null,
                },
                infoNomina: {
                    clabe: "",
                    claveNomilinea: "",
                    cuenta: "",
                    empleadoId: 0,
                    infoNominaId: 0,
                    rfc: ""
                }
            }
        )
    }, []);

    const itService = useMemo(() => {
        return ITService(User);
    }, [User]);

    const obtenerEmpleadoId = useCallback(async (id: number): Promise<IPerfilColaboradores> => {
        const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${User.token}` } }
        const { data: d } = await getDataAsync(`${URL_API_IT}PerfilColaborador/${id}`, headers);
        const perfil: IPerfilColaboradores = {
            colaboradorId: d.colaboradorId,
            nombreColaborador: d.nombreColaborador,
            nombrePreferido: d.nombrePreferido,
            apellidoPaterno: d.apellidoPaterno,
            apellidoMaterno: d.apellidoMaterno || "",
            fechaIngreso: d.fechaIngreso,
            fechaBaja: d.fechaBaja,
            jefeId: d.jefeId,
            rolId: d.rolId,
            nombreJefe: d.nombreJefe || "",
            oficinaId: d.oficinaId,
            oficinaNombre: d.oficinaNombre || "",
            puestoId: d.puestoId,
            puestoNombre: d.puestoNombre || "",
            departamentoId: d.departamentoId,
            departamentoNombre: d.departamentoNombre || "",
            email: d.email || "",
            usuarioCMotion: d.usuarioCMotion || "",
            contraseniaCMotion: d.contraseniaCMotion || "",
            colaboradorGUID: d.colaboradorGUID || "",
            nombreMagaya: d.nombreMagaya || "",
            usuarioMagaya: d.usuarioMagaya || "",
            contraseniaMagaya: d.contraseniaMagaya || "",
            extencion: d.extencion || "",
            telefono: d.telefono || "",
            whatsapp: d.whatsapp || "",
            perfilActivo: d.perfilActivo,
            tieneGenteACargo: d.tieneGenteACargo,
            documento_Id: d.documento_Id,
            documento_Nombre: d.documento_Nombre || "",
            colaborador_Divisiones: d.colaborador_Divisiones,
            permisos: d.permisos,
            historicoSueldo: {
                empleadoId: 0,
                historicoId: 0,
                nombreEmpleado: "",
                sueldoBruto: 0,
                sobrepasaIMSS: 0,
                formaPago: "NOM",
                formaPagoId: 0,
                comisiona: false,
                aplicaDesde: new Date(),
                aplicaHasta: null
            },
            infoNomina: d.infoNomina || {
                clabe: "",
                claveNomilinea: "",
                cuenta: "",
                empleadoId: 0,
                infoNominaId: 0,
                rfc: ""
            }
        }
        return perfil
    }, [User.token])

    const handlerOnSelectTab = useCallback((tab: ITabs): void => {
        setTabVisible(tab);
    }, []);

    /* Obtener los catálogos */
    useEffect(() => {
        const getCatalogos = async (): Promise<void> => {
            setCatalogos({ loading: true });
            const {
                departamentos: departamentosList,
                oficinas: oficinaList,
                puestos: puestosList,
                divisiones: divisionesList,
                roles: rolesList,
                permisos: permisosList,
                formasPago: formasPagoList,
                error
            } = await itService.getCatalogoPerfilCOlab()

            if (error) {
                setCatalogos({ error: error, loading: false });
                return
            }

            setCatalogos({
                departamentos: departamentosList,
                oficinas: oficinaList,
                puestos: puestosList,
                divisiones: divisionesList,
                loading: false,
                permisos: permisosList,
                roles: rolesList,
                formasPago: formasPagoList,
            });
        }
        getCatalogos()
    }, [itService])

    /**Obtener informacion usuario para editar */
    const HandleEdit = useCallback(async (data: IRemotePerfilColaboradores) => {
        const { colaboradorId: id } = data;
        const perfilEdit = await obtenerEmpleadoId(id!)
        /*Adaptar las divisiones */
        let divisiones: IDivisiones = {};
        const { colaborador_Divisiones } = perfilEdit;
        colaborador_Divisiones.map((item: any) => divisiones[item?.cMotion_Division_Nombre] = true)
        perfilEdit.colaborador_Divisiones = divisiones
        /*Adaptar los permisos */
        let permisos: IPermisos = {};
        const { permisos: colaborador_Permisos } = perfilEdit;
        colaborador_Permisos.map((item: any) => permisos[item?.permisoNombre] = true)
        perfilEdit.permisos = permisos

        setColaborador(perfilEdit)
        setModoEdicion(true)
        setModalStatus(true)
    }, [obtenerEmpleadoId])

    /**Activar modal para eliminar el usuario*/
    const handleDelete = useCallback((data: IPerfilColaboradores) => {
        const { colaboradorId, perfilActivo: isActive, nombreColaborador: Nombre } = data
        MESSAGE_MODAL_DELETE = `¿Estás seguro de dar de ${isActive ? 'Baja' : 'Alta'} al colaborador ${Nombre}? `;
        setModalDeleteStatus(act => !act);
        setColaborador({ ...colaborador, colaboradorId: colaboradorId });
    }, [colaborador])

    /**Notificaciones Alerts */
    const showNotify = useCallback((Props: INotify) => {
        const { type, message = "" } = Props;
        if (type === 'error') {
            notify({
                message: `Ocurrio un error: ${message}`
            }, "error", 4000);
        }
        if (type === 'success') {
            notify({
                message: `Se guardo correctamente`
            }, "success", 4000);
        }
    }, [])

    /**Consulta y validación de las fechas de histórico por empleado */
    const validarFecha = useCallback(async (nuevaFecha: string, idEdicion?: number, idColaborador?: number) => {
        const headersConsulta: AxiosRequestConfig = { headers: { Authorization: `Bearer ${User.token}` } }
        const { data } = await getDataAsync(`${URL_API_IT}historicoSueldo/${idColaborador}`, headersConsulta)
        const fechaAntigua: String[] = data?.historico.filter((item: any) => item.historicoId !== idEdicion).map((item: any) => item.aplicaDesde.substring(0, 10))
        return fechaAntigua.includes(nuevaFecha)
    }, [User.token])

    /**Adaptación de las fechas para mandar a comparar */
    const validarFechaHistorico = useCallback(async (data: IHistoricoSueldo, idColaborador: number) => {
        if (
            data.historicoId! === 0 ?
                (
                    ! await validarFecha(data.aplicaDesde!.toString().substring(0, 10), data.historicoId, idColaborador)
                ) : (
                    ! await validarFecha(data.aplicaDesde!.toString().substring(0, 10), data.historicoId, idColaborador)
                )
        ) {
            return true;
        }
        else {
            showNotify({ type: 'error', message: "¡Ya existe un registro de histórico sueldo para el día seleccionado!" });
            return false;
        }
    }, [showNotify, validarFecha])

    /***Proceso para la manipulación de un registro CRUD */
    const onSavedPerfil = useCallback(async (data: IRemotePerfilColaboradores, action: string) => {
        const headersRequest: AxiosRequestHeaders = { Authorization: `Bearer ${User.token}` }
        switch (action) {
            case 'insert':
                const { result: resInsert, error: resInsertError, estatus } = await sendDataAsync(`${URL_API_IT}PerfilColaborador`, data, "POST", headersRequest)
                const message: string = estatus === 400 ? "Registro duplicado" : resInsertError;
                showNotify({ type: resInsert ? "success" : "error", message });
                break;
            case 'update':
                if (data.historicoSueldo?.sueldoBruto! > 0 || data.historicoSueldo?.sobrepasaIMSS! > 0) {
                    if (! await validarFechaHistorico(data.historicoSueldo!, data.colaboradorId)) {
                        return
                    }
                }
                const { colaboradorId } = data;
                const { result: resUpdate, error: resUpdateError } = await sendDataAsync(`${URL_API_IT}PerfilColaborador/${colaboradorId}`, data, "PUT", headersRequest)
                showNotify({ type: resUpdate ? "success" : "error", message: resUpdateError });
                break;
            case 'delete':
                const { colaboradorId: idPerfil } = data;
                const perfil = await obtenerEmpleadoId(idPerfil)
                const { result: resDelete, error: resDeleteError } = await sendDataAsync(`${URL_API_IT}PerfilColaborador/cambiarEstatus/${idPerfil}`, perfil, "PUT", headersRequest)
                showNotify({ type: resDelete ? "success" : "error", message: resDeleteError });
                break;
            default:
                return
        }
    }, [obtenerEmpleadoId, showNotify, validarFechaHistorico, User.token])

    /** */
    const handleAllpermissionsSelect = useCallback((colaboradorReceived: IPerfilColaboradores) => {
        let newPermisos: IPermisos = {};
        if (colaboradorReceived?.selectAll) {
            catalogos?.permisos?.map((permiso) =>
                newPermisos = { ...newPermisos, [permiso.permisoDescripcion]: true }
            )
        }
        setColaborador({ ...colaboradorReceived, permisos: newPermisos })
    }, [catalogos])

    const asignarHistorico = useCallback((historicoSueldo: IHistoricoSueldo) => {      
        setColaborador(prev => {
            return {
                ...prev,
                historicoSueldo : historicoSueldo
            }
        })
    }, []);

    return {
        rol,
        jefes,
        catalogos,
        tabVisible,
        modalStatus,
        colaborador,
        modoEdicion,
        modalDeleteStatus,
        MESSAGE_MODAL_DELETE,
        asignarHistorico,
        HandleEdit,
        handleDelete,
        onSavedPerfil,
        setModoEdicion,
        setColaborador,
        setModalStatus,
        setValorInicial,
        handlerOnSelectTab,
        setModalDeleteStatus,
        handleAllpermissionsSelect
    }
}