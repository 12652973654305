import React, {
  useState,
  ReactNode,
  FormEvent,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";
import {
  ITarea,
  IAddTarea,
  IEntidades,
  IUpdateTarea,
  IGetDataTareas,
  IGetDataEntidades,
  IGetDataResponsables,
} from "interfaces/ITareas";
import axios from "axios";
import { useAuth } from "api/auth";
import { SyncService } from "api/sync";
import { URL_API_FIREBASE_APP } from "consts/Services_URL";
import { useTareas } from "pages/tareas/hooks/useTareas";
import { ITabs } from "functions/Tareas";
import notify from "devextreme/ui/notify";

interface TaskContextType {
  formData: { Tarea_Nombre: string };
  tipoEntidad: string;
  entidades: IEntidades[];
  responsables: IGetDataResponsables;
  urgenciaSeleccionada: string;
  estatusSeleccionado: string;
  fechaTermino: Date;
  loadingEntidades: boolean;
  tiposEntidades: { nombre: string; value: string }[];
  tiposUrgencias: { nombre: string; value: string }[];
  tiposEstatus: { nombre: string; value: string }[];
  dataTareas: IGetDataTareas;
  modalStatus: boolean;
  tarea: ITarea | null;
  entidadDefined: IGetDataEntidades;
  tabVisible: ITabs;
  openModal: boolean;
  tipo: string | null;
  guids: string;
  keepAlive: boolean;
  loading: boolean;
  data: any[];
  entidadGuid: string | null;
  razonSocial: string | null;
  setFormData: (formData: { Tarea_Nombre: string }) => void;
  setTipoEntidad: (tipoEntidad: string) => void;
  setEntidadSeleccionada: (entidadSeleccionada: string) => void;
  setResponsableSeleccionado: (responsableSeleccionado: string) => void;
  setUrgenciaSeleccionada: (urgenciaSeleccionada: string) => void;
  setEstatusSeleccionado: (estatusSeleccionado: string) => void;
  setFechaTermino: (fechaTermino: Date) => void;
  setModalStatus: (status: boolean) => void;
  handlerCreateTarea: (e: FormEvent<HTMLFormElement>) => void;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  handleEditTarea: (tarea: ITarea) => void;
  updateTarea: (tarea: IUpdateTarea) => void;
  handlerOnSelectTab: (tab: ITabs) => void;
  deleteTarea: (id: number) => void;
  setOpenModal: (status: boolean) => void;
  setTipo: (tipo: string | null) => void;
  setGuids: (guids: string) => void;
  setKeepAlive: (keepAlive: boolean) => void;
  setLoading: (loading: boolean) => void;
  setData: (data: ((prevData: any[]) => any[]) | any[]) => void;
  onHandlerSync: (data: any[]) => void;
  setNote: (note: string) => void;
  note: string;
  setEntidadGuid: (razonSocial: string | null) => void;
  setRazonSocial: (razonSocial: string | null) => void;
}

export const TaskContext = createContext<TaskContextType | undefined>(
  undefined
);

export const TaskProvider = ({ children }: { children: ReactNode }) => {
  const { User } = useAuth();
  const {
    tarea,
    addTarea,
    dataTareas,
    tabVisible,
    modalStatus,
    updateTarea,
    deleteTarea,
    responsables,
    getEntidades,
    entidadDefined,
    setModalStatus,
    handleEditTarea,
    handlerOnSelectTab,
  } = useTareas();

  const fecha = new Date();
  const [fechaTermino, setFechaTermino] = useState<Date>(fecha);
  const [tipoEntidad, setTipoEntidad] = useState("");
  const [entidades, setEntidades] = useState<IEntidades[]>([]);
  const [entidadSeleccionada, setEntidadSeleccionada] = useState("");
  const [responsableSeleccionado, setResponsableSeleccionado] = useState("");
  const [urgenciaSeleccionada, setUrgenciaSeleccionada] = useState("Baja");
  const [estatusSeleccionado, setEstatusSeleccionado] = useState("Pendiente");
  const [formData, setFormData] = useState({
    Tarea_Nombre: "",
  });
  const [loadingEntidades, setLoadingEntidades] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tipo, setTipo] = useState<string | null>(null);
  const [guids, setGuids] = useState("");
  const [note, setNote] = useState("");
  const [keepAlive, setKeepAlive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [entidadGuid, setEntidadGuid] = useState<string | null>(
    null
  );
  const [razonSocial, setRazonSocial] = useState<string | null>(null);

  const tiposEntidades = [
    { nombre: "Selecciona un tipo de entidad", value: "" },
    { nombre: "Cliente", value: "Client" },
    { nombre: "Proveedor", value: "Vendor" },
  ];

  const tiposUrgencias = [
    { nombre: "Baja", value: "Baja" },
    { nombre: "Media", value: "Media" },
    { nombre: "Alta", value: "Alta" },
  ];

  const tiposEstatus = [
    { nombre: "Pendiente", value: "Pendiente" },
    { nombre: "En Proceso", value: "En Proceso" },
    { nombre: "Terminada", value: "Terminada" },
  ];

  useEffect(() => {
    if (tipoEntidad) {
      setLoadingEntidades(true);
      getEntidades(tipoEntidad).then((data) => {
        setEntidades(data);
        setLoadingEntidades(false);
      });
    } else {
      setEntidades([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoEntidad]);

  useEffect(() => {
    const fetchKeepAlive = async () => {
      try {
        const response = await axios.get(
          `${URL_API_FIREBASE_APP}/keepAlive`
        );

        if (response.status !== 200) {
          setKeepAlive(false);
          return;
        }
        setKeepAlive(true);
      } catch (error) {
        setKeepAlive(false);
      }
    };
    fetchKeepAlive();
  }, []);

  const handlerCreateTarea = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData: FormData = new FormData(e.currentTarget);
    const nombreTarea: string = formData.get("Tarea_Nombre")!.toString();
    const urgencia: string = formData.get("urgencia")!.toString();
    const fechaTermino: Date = formData.get(
      "Tarea_Fecha_Termino"
    ) as unknown as Date;

    const nuevaTarea: IAddTarea = {
      Tarea_Nombre: nombreTarea,
      Tarea_Urgencia: urgencia,
      Tarea_Entidad_Cliente_Guid: entidadSeleccionada,
      Tarea_Colaborador_Guid: responsableSeleccionado,
      Tarea_Fecha_Termino: fechaTermino,
      Tarea_Nota: note,
      Tarea_Estatus: estatusSeleccionado,
    };

    await addTarea(nuevaTarea);

    setTipoEntidad("");
    setEntidadSeleccionada("");
    setResponsableSeleccionado("");
    setEstatusSeleccionado("Pendiente");
    setFechaTermino(fecha);
    setFormData({
      Tarea_Nombre: "",
    });
    setNote("");
    notify("Tarea agregada exitosamente", "success", 2000);
  };

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    },
    []
  );

  const onHandlerSync = async (data: any[]) => {
    setLoading(true);
    const syncData = data.map((item) => ({
      Tipo: item.reporte_Sync_Tipo,
      Guid: item.reporte_Sync_Guid,
      isDeleted: false,
    }));
    try {
      await SyncService().syncAdd(syncData, User);
      notify("Todos los datos se agregaron a la cola", "success", 2000);
      setData([]);
    } catch (error) {
      console.log(error);
      notify("Error al sincronizar", "error", 2000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TaskContext.Provider
      value={{
        formData,
        tipoEntidad,
        entidades,
        responsables,
        urgenciaSeleccionada,
        estatusSeleccionado,
        fechaTermino,
        loadingEntidades,
        tiposEntidades,
        tiposUrgencias,
        tiposEstatus,
        dataTareas,
        modalStatus,
        tarea,
        entidadDefined,
        tabVisible,
        openModal,
        tipo,
        guids,
        data,
        keepAlive,
        loading,
        entidadGuid,
        razonSocial,
        setFormData,
        setTipoEntidad,
        setEntidadSeleccionada,
        setResponsableSeleccionado,
        setUrgenciaSeleccionada,
        setFechaTermino,
        setModalStatus,
        handlerCreateTarea,
        handleInputChange,
        handleEditTarea,
        updateTarea,
        handlerOnSelectTab,
        deleteTarea,
        setOpenModal,
        setTipo,
        setGuids,
        setData,
        setKeepAlive,
        setLoading,
        onHandlerSync,
        setNote,
        note,
        setEntidadGuid,
        setEstatusSeleccionado,
        setRazonSocial
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTask = () => {
  const context = useContext(TaskContext);
  if (!context) {
    throw new Error("useTask must be used within a TaskProvider");
  }
  return context;
};
