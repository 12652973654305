export type GridFacturas = "Venta" | "Compra";
export type GridComisiones = "Editing" | "NotEditing";
export type ModoCalculo = "Promedio" | "Embarque";

export type TabComisiones = "Vendedor" | "Subordinados";

export enum IndexTab {
  Vendedor = 0,
  Subordinados = 1
}

export enum CSNotificacion {
  ErrorGetComissionsPending = "No se pudieron obtener las comisiones pendientes",
  ErrorGetComissionsToPay = "No se pudieron obtener las comisiones por pagar",
  ErrorGetComissionsPaid = "No se pudieron obtener las comisiones pagadas",
  ErrorGetComissionsSubordinates = "No se pudieron obtener las comisiones de los subordinados",
  OKSendComissionsPending = "Se guardaron correctamente las comisiones",
  PayingComissionsPending = "Mandando a pagar...",
  AreYouSureToPayComissions = "Estás seguro de mandar a pagar las comisiones?",
  EmptyComissionsToPay = "No hay comisiones por pagar",
  EmptyComissionsPaid = "No hay comisiones pagadas",
  MissingSaller = "Falta seleccionar el vendedor",
  ErrorSyncShipment = "Error al tratar de sincronizar el embarque",
  ErrorUpdateShipment = "Error al actualizar los datos del embarque",
  SuccessSyncShipment = "Se actualizaron los datos del embarque correctamente"
}

export enum CSStatus {
  Searching = 1,
  Synchronizing = 2,
  Sending = 3,
  Stop = 4
}