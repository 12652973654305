export const URL_API_LOGIN: string = process.env.REACT_APP_API_LOGIN!;
export const URL_API_OPERACIONES: string = process.env.REACT_APP_API_OPERACIONES!;
export const URL_API_GENERAL: string = process.env.REACT_APP_API_GENERAL!;
export const URL_API_ADMINISTRACION: string = process.env.REACT_APP_API_ADMINISTRACION!;
export const URL_API_PERFIL_CLIENTE: string = process.env.REACT_APP_API_PERFIL_CLIENTE!;
export const URL_API_TH: string = process.env.REACT_APP_API_TH!;
export const URL_API_COMISIONES: string = process.env.REACT_APP_API_CS!;
export const URL_API_IT: string = process.env.REACT_APP_API_IT!;
export const URL_API_CF: string = process.env.REACT_APP_API_CF!;
export const URL_API_OP: string = process.env.REACT_APP_API_OPERACIONES!;
export const URL_API_FIREBASE_APP: string = process.env.REACT_APP_URL_API_FIREBASE_APP!;
