import { useEffect, useState } from 'react';
import { TestService } from 'api/test';
import { onExportingGrid } from 'utils/Functions';
import DataGrid, { IColumnProps} from 'devextreme-react/data-grid';
import TableGrid from 'components/TableGrid';

export const ColumsData: IColumnProps[] = [
    {
        dataField: "type",
        caption: "Tipo",
        dataType: "string"
    },
    {
        dataField: "date",
        caption: "Fecha",
        dataType: "string"
    },
    {
        dataField: "success",
        caption: "Estatus",
        dataType: "string"
    },
  ];

const Tets = () => {

    const [data, setData] = useState<any[]>([]);
    const keyStateStoraged = "testing";

    useEffect(() => {
        TestService().getTest().then((res) => {
            setData(res);
        });
    }, [])

    return (
        <DataGrid
            dataSource={data}
            key={"testing"}
            onDisposing={() => sessionStorage.removeItem(keyStateStoraged.toLocaleUpperCase())}
            onExporting={(e) => onExportingGrid(e, "Testing", "")}
            export={{ enabled: true }}
            allowColumnResizing={true}
            wordWrapEnabled={true}
            columnAutoWidth={true}
            noDataText="No hay información disponible"
            showBorders={true}
            loadPanel={{ enabled: data.length > 0, text: "Cargando..." }}
            scrolling={{
                columnRenderingMode: "virtual"
            }}
            editing={{
                mode: "cell",
                allowUpdating: true
            }}
            paging={{ enabled: true }}
            stateStoring={{ enabled: true, type: 'sessionStorage', storageKey: keyStateStoraged.toLocaleUpperCase() }}
            headerFilter={{ visible: true, allowSearch: true }}
            searchPanel={{ visible: true, highlightCaseSensitive: false, placeholder: 'Buscar', width: 250 }}
            groupPanel={{ visible: true, emptyPanelText: 'Arrastra aqui 👇 la columna a agrupar' }}
            grouping={{ texts: { groupContinuesMessage: "Continua en la siguiente página" }, autoExpandAll: true }}
            columnChooser={{ enabled: true, title: 'Columnas a ocultar', emptyPanelText: 'Arrastra aqui 👇 las columnas a ocultar' }}
            showRowLines={true}
            columns={ColumsData.map((item) => {
                return {
                    ...item,
                    allowEditing: false
                }
            })}
            masterDetail={{
                enabled: true,
                template: (container, options) => {
                    const { guids } = options.data;
                    return (
                        <div>
                            <h2>Guids</h2>
                            <TableGrid
                                dataSource={guids}
                                columns={[
                                    {
                                        dataField: "guid",
                                        caption: "Guid",
                                        dataType: "string"
                                    },
                                    {
                                        dataField: "type",
                                        caption: "Tipo",
                                        dataType: "string"
                                    },
                                    {
                                        dataField: "date",
                                        caption: "Fecha",
                                        dataType: "string"
                                    },
                                    {
                                        dataField: "success",
                                        caption: "Estatus",
                                        dataType: "string"
                                    },
                                ]}
                                enablePaging={false}
                                enablePager={false}
                            />
                        </div>
                    )
                }
            }}
        />
    )
}

export default Tets