/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from "api/auth";
import { ClientesService } from "api/clientes";
import { IFilterChange } from "components/interfaces/Interfaces";
import { IFiltersDesgloce } from "interfaces/IOperaciones";
import { useCallback, useEffect, useState } from "react";
import { RemoteFilterDesgloceAdapter } from 'adapters/Operaciones';
import {  IGetDataCatalogoClientes } from "interfaces/IClientes";
import moment from "moment";

const defaultFilters: IFiltersDesgloce = {
    años: [],
    vendedores: [],
    modosTransporte: [],
    customer: [],
    operativo: [],
    transportista: []
}

export const usePerfilCliente = () => {
    const { User } = useAuth();
    const [ data, setData ] = useState({});
    const [ dateInicio, setDateInicio ] = useState(new Date("2020-01-01"));
    const [ dateFin, setDateFin ] = useState(new Date());
    const [ cliente, setCliente ] = useState<string | null>(null);
    const [ loading, setLoading ] = useState(false);
    const [ filters, setFilters ] = useState<IFiltersDesgloce>(defaultFilters);
    const [ catalogoClientes, setCatalogoClientes ] = useState<IGetDataCatalogoClientes>({ loading: false, data: [] });

    const opsClientes = useCallback(() => {
        return ClientesService(User);
    }, [User]);

    const handleFiltrado = useCallback(async () => {
        if (cliente) {
            setLoading(true);
            const formattedDateInicio = moment(dateInicio).format('DD-MM-YYYY');
            const formattedDateFin = moment(dateFin).format('DD-MM-YYYY');
            const filtersAdapter = JSON.stringify(RemoteFilterDesgloceAdapter(filters));
            const response = await ClientesService(User).getDataPerfilCliente(cliente as string, formattedDateInicio, formattedDateFin, filtersAdapter);
            const responseEstadoCuente = await opsClientes().getDataEstadoCuentaPerfilCliente(JSON.stringify({ EntidadGuid: [cliente] }));
            var dataEdoCuenta: any;
            if (responseEstadoCuente.error) {
                console.log(responseEstadoCuente.error);
                dataEdoCuenta = [];
            } else {
                dataEdoCuenta = responseEstadoCuente.data;
            }
            setData({ data: [ ...response.data, dataEdoCuenta ] });
            setLoading(false);
            setFilters(defaultFilters);
        }
    }, [User, dateInicio, dateFin, cliente, filters]);

    const handlerOnSelectChange = (e: IFilterChange): void => {
        const { key, value } = e;
        setFilters(prev => {
          return {
            ...prev,
            [key]: value
          }
        });
    }

    const getCatalogoClientes = async (): Promise<void> => {
        setCatalogoClientes({ loading: true });
        const { result, data } = await opsClientes().getCatalogosClientes();
        if (!result) {
            setCatalogoClientes({ loading: false });
        }
        setCatalogoClientes({ data: data, loading: false });
    }

    useEffect(() => {
        const anios: any[] = [];
        for (let i = dateInicio.getFullYear(); i <= dateFin.getFullYear(); i++) {
            anios.push(i);
        }
        setFilters(prev => {
            return {
                ...prev,
                años: anios
            }
        })
    }, [dateInicio, dateFin]);

    return {
        User,
        data,
        dateInicio,
        dateFin,
        cliente,
        loading,
        catalogoClientes,
        setCliente,
        setDateInicio,
        setDateFin,
        handleFiltrado,
        handlerOnSelectChange,
        getCatalogoClientes
    };

}
