import { useAuth } from "api/auth";
import { ConfiguracionService } from "api/configuracion";
import { useLayoutEffect, useMemo, useState } from "react";

export const useImages = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [images, setImages] = useState<any[]>([]);
    const {User} = useAuth();

    const configuracionService = useMemo(() => {
        return ConfiguracionService(User);
    },[User])

    const getImages = async (): Promise<void> => {
        setLoading(true);
        const { data, error } = await configuracionService.getImages();
        if(data){
            setImages(data);
        }
        if(error){
            setError(error);
        }
        setLoading(false);
    }

    useLayoutEffect(() => {
        getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[configuracionService])

    return {
        getImages,
        images,
        loading,
        error
    }
}