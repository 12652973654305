import { IRemoteBudget, IBudget } from '../interfaces/IOperaciones';
import {
  IRemoteFiltersDesgloce, IFiltersDesgloce,
  IRemoteFilterOpsCanceladas, IFilterOpsCanceladas,
  IRemoteFilterOpsTerrestres, IFilterOpsTerrestres
}
  from "interfaces/IOperaciones";

export const FilterDesgloceAdapter = (filterDesgloce: IRemoteFiltersDesgloce): IFiltersDesgloce => {
  const filter: IFiltersDesgloce = {
    años: filterDesgloce.Anio,
    vendedores: filterDesgloce.EjecutivoVentas,
    modosTransporte: filterDesgloce.ModoTransporte,
    customer: filterDesgloce.EjecutivoCS,
    operativo: filterDesgloce.EjecutivoOperaciones,
    transportista: filterDesgloce.Carrier
  }
  return filter;
}

export const RemoteFilterDesgloceAdapter = (filterDesglose: IFiltersDesgloce): IRemoteFiltersDesgloce => {
  const filter: IRemoteFiltersDesgloce = {
    Anio: filterDesglose.años!,
    EjecutivoVentas: filterDesglose.vendedores,
    ModoTransporte: filterDesglose.modosTransporte,
    Carrier: filterDesglose.transportista,
    EjecutivoCS: filterDesglose.customer,
    EjecutivoOperaciones: filterDesglose.operativo
  }
  return filter;
}

export const FiltersOpsCanceladasAdapter = (filtersOpsCanceladas: IRemoteFilterOpsCanceladas): IFilterOpsCanceladas => {
  const filter: IFilterOpsCanceladas = {
    años: filtersOpsCanceladas.Anio,
    estrategiaOrigen: filtersOpsCanceladas.EstrategiaOrigen,
    motivoCancelacion: filtersOpsCanceladas.MotivosCancelacion
  }
  return filter;
}

export const RemoteFilterCanceladasAdapter = (filtersOpsCanceladas: IFilterOpsCanceladas): IRemoteFilterOpsCanceladas => {
  const filter: IRemoteFilterOpsCanceladas = {
    Anio: filtersOpsCanceladas.años,
    EstrategiaOrigen: filtersOpsCanceladas.estrategiaOrigen,
    MotivosCancelacion: filtersOpsCanceladas.motivoCancelacion
  }
  return filter;
}

export const FilterOpsTerrestresAdapter = (filtersOpsTerrestres: IRemoteFilterOpsTerrestres): IFilterOpsTerrestres => {
  const filter: IFilterOpsTerrestres = {
    años: filtersOpsTerrestres.Anio,
    modosTransporte: filtersOpsTerrestres.ModoTransporte,
    operativo: filtersOpsTerrestres.EjecutivoOperaciones,
    transportista: filtersOpsTerrestres.Carrier
  }
  return filter;
}

export const RemoteFilterOpsTerrestresAdapter = (filtersOpsTerrestres: IFilterOpsTerrestres): IRemoteFilterOpsTerrestres => {
  const filter: IRemoteFilterOpsTerrestres = {
    Anio: filtersOpsTerrestres.años,
    ModoTransporte: filtersOpsTerrestres.modosTransporte,
    EjecutivoOperaciones: filtersOpsTerrestres.operativo,
    Carrier: filtersOpsTerrestres.transportista
  }
  return filter;
}

export const BudgetAdapter = (budgetRemote: IRemoteBudget): IBudget => {
  const budget: IBudget = {
    año: budgetRemote.año,
    mes: budgetRemote.mes,
    titulo: budgetRemote.titulo,
    totalOperaciones: budgetRemote.total_Operaciones
  }
  return budget;
}