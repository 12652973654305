import { useMemo, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from "../../../config/firebase.config";
import { useAuth } from "api/auth";
import { ConfiguracionService } from "api/configuracion";

export const useUpload = () => {
    const [imageURL, setImageURL] = useState<string>('');
    const [showingUrl, setShowingUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const {User} = useAuth();

    const configuracionService = useMemo(() => {
        return ConfiguracionService(User);
    },[User])

    const uploadImage = async (index:number, file: File, path: string) => {
        setLoading(true);
        try {
            const imageRef = ref(storage, `images/${path}/${file.name}`);
            console.log(imageRef);
            await uploadBytes(imageRef, file);
            const url = file.name;
            const showingUrl = await getDownloadURL(imageRef);
            await configuracionService.saveBannerImage(index, url);
            setImageURL(url);
            setShowingUrl(showingUrl);
            setError('');
            setLoading(false);
        } catch (error) {
            console.log(error);
            setError('Ocurrió un error al subir la imagen');
            setLoading(false);
        }
    }

    const removeImage = async (index: number) => {
        setLoading(true);
        try {
            await configuracionService.removeImageBanner(index);
            setImageURL('');
            setLoading(false);
        } catch (error) {
            console.log(error);
            setError('Ocurrió un error al eliminar la imagen');
            setLoading(false);
        }
    }

    return {
        imageURL,
        showingUrl,
        loading,
        error,
        uploadImage,
        removeImage
    }
}