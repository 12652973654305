import FilterMultipleGrid from 'components/FilterMultipleGrid'
import SelectBox from 'components/Select'
import { Button } from 'devextreme-react'
import { useProfit } from './hooks/useProfit'
import ErrorPage from 'pages/Error'
import PivotProfit from './components/PivotProfit'
import { _FieldsPivotProfit } from 'functions/Profit'
import ModalDetalleOperacionProfit from './components/ModalDetalleOperacionProfit'
import './styles/estilos.scss';
import MenuFilters from 'components/MenuFilters'
import { useEffect } from 'react'

const Profit = () => {
    const {
        catalogos,
        modalState,
        handlerModalState,
        dataProfit,
        onExporting,
        handlerOnSelectChange,
        handleFiltrado
    } = useProfit();

    useEffect(() => {
        console.log("dataProfit", dataProfit)
    }, [dataProfit])

    const showFilters = () => {
        const {
            años,
            clientes,
            vendedores
        } = catalogos;

        return (
            <MenuFilters>
                {
                    catalogos.loading ? (
                        <p>Cargando...</p>
                    ) : (
                        <div className='container-filters'>
                            <FilterMultipleGrid
                                items={años?.items}
                                key={años?.key}
                                keyFilter={años?.key}
                                title={"Años"}
                                onChange={handlerOnSelectChange}
                                selectionState={años?.selectionState}
                            />
                            <SelectBox
                                item={clientes}
                                key={clientes?.key}
                                display="razonSocial"
                                keyValue='razonSocial'
                                onChangeSelect={handlerOnSelectChange}
                            />
                            <SelectBox
                                item={vendedores}
                                key={vendedores?.key}
                                display="cotizacion_Vendedor"
                                keyValue='cotizacion_Vendedor'
                                onChangeSelect={handlerOnSelectChange}
                            />
                            <Button
                                text={dataProfit.loading ? "Buscando" : "Filtrar"}
                                icon="fa fa-search"
                                type='default'
                                disabled={dataProfit.loading}
                                onClick={() => handleFiltrado()}
                            />
                            <Button
                                text="Ver detalle de operación"
                                type='default'
                                icon="fa fa-file-invoice"
                                onClick={() => handlerModalState()}
                                disabled={dataProfit.loading}
                                stylingMode='outlined'
                            />
                        </div>
                    )
                }
            </MenuFilters>
        )
    }


    return (
        <div className='container-clientesModules'>
            {
                showFilters()
            }
            <div className="card-container">
                {!dataProfit.error ?
                    <PivotProfit
                        dataProfit={dataProfit.dataProfit!}
                        keyLocalStorage='pivotProfit'
                        onExporting={onExporting}
                        fields={_FieldsPivotProfit}

                    />
                    :
                    <ErrorPage
                        errorMessage={catalogos.error!}
                    />
                }
            </div>
            {
                <ModalDetalleOperacionProfit
                    status={modalState}
                    onCloseModal={() => handlerModalState()}
                />
            }
        </div>
    )
}

export default Profit