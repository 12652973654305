import axios, { AxiosRequestConfig } from "axios";
import { IFilterData, } from "components/interfaces/Interfaces";
import { URL_API_IT } from "consts/Services_URL";
import { IRemoteFormaPago } from "interfaces/ITalentoHumano";
import { IUser } from "interfaces/IUsuario";
import { createNewFilter } from "utils/Functions";
import { HttpErrorMessage } from "utils/ResponseAPI";

export interface IGetCatalogoPerfilColab{
    empleados?: IFilterData;
    puestos?: IFilterData;
    departamentos?: IFilterData;
    oficinas?: IFilterData;
    permisos?: IRemotePermisos[];
    roles?: IFilterData;
    divisiones?: IRemoteDivisiones[];
    error?: string| null;
    loading: boolean;
    formasPago?: IFilterData;
}

export interface IRemotePuesto {
    puestoId: number;
    nombrePuesto: string;
}

export interface IRemoteDivisiones {
    divisionId: number;
    razonSocial: string;
    nombreDivision: string;
}

export interface IRemoteDepartamentos {
    departamentoId: number;
    nombreDepartamento: string;
}

export interface IRemoteOficinas {
    oficinaId: number;
    nombreOficina: string;
}

export interface IRemoteRoles {
    rolId: number;
    rolDescripcion: string;
}

export interface IRemotePermisos {
    permisoId: number;
    permisoDescripcion: string;
}

export const ITService = (User: IUser) => {

    const { token } = User;
    const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } }
return{

    getCatalogoPerfilCOlab: async (): Promise<IGetCatalogoPerfilColab> => 
    {

        let response: IGetCatalogoPerfilColab = { error: null, loading: true };

        await Promise.all([
            axios.get(`${URL_API_IT}catalogos/puestos`, headers),
            axios.get(`${URL_API_IT}catalogos/departamentos`, headers),
            axios.get(`${URL_API_IT}catalogos/oficinas  `, headers),
            axios.get(`${URL_API_IT}catalogos/divisiones`, headers),
            axios.get(`${URL_API_IT}catalogos/roles`, headers),
            axios.get(`${URL_API_IT}catalogos/permisos`, headers),
            axios.get(`${URL_API_IT}catalogos/formasPagoNomina`, headers),
        ]).then(([ 
            puestos, 
            departamentos, 
            oficinas, 
            divisiones, 
            roles, 
            permisos,
            formasPago
        ]) => {

            const puestosRemote: IRemotePuesto[] = puestos.data;
            const departamentosRemote: IRemoteDepartamentos[] = departamentos.data;
            const oficinasRemote: IRemoteOficinas[] = oficinas.data;
            const divionesRemote: IRemoteDivisiones[] = divisiones.data;
            const rolesRemote: IRemoteRoles[] = roles.data;
            const permisosRemote: IRemotePermisos[] = permisos.data;
            const formasPagoRemote: IRemoteFormaPago[] = formasPago.data;
            const formaPagoResult= formasPagoRemote.map((item) => {return {formaPagoid: item.cMotion_Forma_Pago_Nomina_Id, formaPagoTipo: item.cMotion_Forma_Pago_Nomina_Tipo}})

            const puestoFIlter: IFilterData = createNewFilter(puestosRemote, null, "puesto")
            const departamentosFilter: IFilterData = createNewFilter(departamentosRemote, null, "departamento")
            const oficinasFilter: IFilterData = createNewFilter(oficinasRemote, null, "oficina")
            const rolesFilter: IFilterData = createNewFilter(rolesRemote, 4, "roles")
            const formasPagoFilter= createNewFilter(formaPagoResult,null, "formaPago")

            response = { 
                ...response, 
                puestos: puestoFIlter,
                departamentos: departamentosFilter,
                oficinas: oficinasFilter,
                divisiones: divionesRemote,
                roles: rolesFilter,
                permisos: permisosRemote,
                formasPago: formasPagoFilter,
            }
        }).catch((err) => {
            const error = JSON.parse(JSON.stringify(err));
            const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
            response = { ...response, error: messageError}
        })
        return response
        }
    }
}