import { memo } from 'react';
import TableGrid from 'components/TableGrid';
import { ColumsDetailsFacturas } from 'functions/Comisiones';
import { IComisiones } from 'interfaces/IComisiones';
import { ajustarDecimales } from 'utils/Formatos';

interface IProps {
  comision: IComisiones;
}

const DetailsFacturas = ({ comision }: IProps) => {

  const { facturasVenta, facturasCompra } = comision;
  console.log(facturasVenta)
  
  return (
    <div style={{ display: "flex", textAlign: "center" }}>
      <div style={{ marginRight: "1rem" }}>
        <h2>Venta</h2>
        <TableGrid
          dataSource={facturasVenta}
          columns={ColumsDetailsFacturas("Venta")}
          enablePaging={false}
          enablePager={false}
          totalesColumns={[{
            column: "monto_MXN",
            summaryType: "sum",
            displayFormat: "Total $ {0}",
            valueFormat: ajustarDecimales
          }]}
        />
      </div>
      <div >
        <h2>Compra</h2>
        <TableGrid
          dataSource={facturasCompra}
          columns={ColumsDetailsFacturas("Compra")}
          enablePaging={false}
          enablePager={false}
          totalesColumns={[{
            column: "monto_MXN",
            summaryType: "sum",
            displayFormat: "Total {0}",
            valueFormat: ajustarDecimales
          }]}
        />
      </div>
    </div>
  )
}


export default memo(DetailsFacturas)
