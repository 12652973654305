import axios, { AxiosRequestConfig, AxiosRequestHeaders, Method } from 'axios';
import { IResponse } from './interfaces/interfaces';
import { HttpErrorMessage } from "utils/ResponseAPI";

export const getDataAsync = async (url: string, options?: AxiosRequestConfig): Promise<IResponse> => {
  try {
    const { status, data } = await axios.get(url, options);
    console.log('status', status);
    if (status === 200) {
      const response: IResponse = { result: true, data: data, estatus: status };
      return response;
    }
    const response: IResponse = { result: false, data: data, estatus: status };
    return response;
  } catch (err) {
    console.log(err);
    const error = JSON.parse(JSON.stringify(err));
    const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
    const response: IResponse = { result: false, data: null, estatus: error.status, error: messageError };
    return response;
  }
}

export const sendDataAsync = async (url: string, body: any, method: Method, headers?: AxiosRequestHeaders ): Promise<IResponse> => {
  try {
    const { status, data } = await axios({
      method: method,
      url: url,
      data: body,      
      headers      
    });
    if (status === 200 || status === 201) {
      const response: IResponse = { result: true, data: data, estatus: status };
      return response;
    }
    const response: IResponse = { result: false, data: data, estatus: status, error: HttpErrorMessage[status] };
    return response;
  } catch (err: any) {
    const error = JSON.parse(JSON.stringify(err));
    const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
    const response: IResponse = { result: false, data: null, estatus: error.status, error: messageError };
    return response;
  }
}

export const sendRequestAsync = async (url: string, method: Method, headers?: AxiosRequestHeaders ): Promise<IResponse> => {
  try {
    const { status, data } = await axios({
      method: method,
      url: url,  
      headers      
    });
    if (status === 200 || status === 201) {
      const response: IResponse = { result: true, data: data, estatus: status };
      return response;
    }
    const response: IResponse = { result: false, data: data, estatus: status, error: HttpErrorMessage[status] };
    return response;
  } catch (err: any) {
    const error = JSON.parse(JSON.stringify(err));
    const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
    const response: IResponse = { result: false, data: null, estatus: error.status, error: messageError };
    return response;
  }
}