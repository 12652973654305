import {
  CSSProperties,
  HTMLInputTypeAttribute,
  memo,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "./styles/estilos.scss";

interface ITextBox {
  placeholder?: string;
  name?: string;
  value?: string;
  defaultValue?: string;
  onChange?: (e: string | number) => void;
  className?: string;
  required?: boolean;
  style?: CSSProperties;
  type?: HTMLInputTypeAttribute;
  icon?: string;
  showPassword?: boolean;
  width?: number | string;
  height?: number | string;
  disabled?: boolean;
}

const TextBox = ({
  type = "text",
  placeholder,
  name,
  value,
  required,
  icon,
  onChange,
  showPassword,
  style,
  className,
  height,
  width,
  defaultValue,
  disabled = false,
}: ITextBox) => {
  const [typeInput, setTypeInput] = useState<HTMLInputTypeAttribute>(type);
  const iconInput = useRef<HTMLElement>(null);

  const showInputText = (): void => {
    if (showPassword) {
      if (typeInput === "password" && type === "password") {
        setTypeInput("text");
        return;
      }
      if (typeInput === "text" && type === "password") {
        setTypeInput("password");
      }
    }
  };

  const handlerChange = useCallback(
    (inputValue: string) => {
      if (required) {
        const icon = iconInput.current!;
        const color = inputValue === "" ? "red" : "blue";
        icon.style.color = color;
      }
      onChange && onChange(inputValue);
    },
    [onChange, required]
  );

  const iconPassword: string = useMemo((): string => {
    if (typeInput === "password" && type === "password") {
      return "fas fa-eye-slash";
    }
    if (typeInput === "text" && type === "password") {
      return "fas fa-eye";
    }
    return "";
  }, [typeInput, type]);

  return (
    <div className={`text-box ${className}`} style={style}>
      <input
        name={name}
        type={typeInput}
        autoComplete={placeholder || ""}
        placeholder=" "
        onChange={(e) => handlerChange(e.target.value)}
        value={value}
        required={required}
        defaultValue={defaultValue}
        style={{ width, height }}
        disabled={disabled}
      />
      <label>
        <span>{placeholder}</span>
      </label>
      <i
        ref={iconInput}
        style={{
          color: required ? "red" : "blue",
          transform: "translate(-50%, -20%)",
        }}
        className={`icon-input ${icon} ${iconPassword} ${
          type === "password" && "cursor"
        }`}
        onClick={() => showInputText()}
      ></i>
    </div>
  );
};

export default memo(TextBox);
