import { useAuth } from "api/auth";
import { ComisionesService } from "api/comisiones";
import { IFilterChange, IFilterData } from "components/interfaces/Interfaces";
import { CSNotificacion } from "consts/Comisiones";
import { URL_API_CF } from "consts/Services_URL";
import notify from "devextreme/ui/notify";
import { useSWRWithToken } from "hooks/useSWR";
import { IFilterComisionesPagar, IComisionPorPagar, IComisiones } from "interfaces/IComisiones"
import { IRemoteVendedores } from "interfaces/IConfiguracion";
import { useMemo, useState, useCallback } from 'react';
import { formatoDecimales } from "utils/Formatos";
import { createNewFilter } from "utils/Functions";
import { IOnSaved } from 'components/TableGrid';

interface IUseComisionesPorPagar {
  filters: IFilterComisionesPagar;
  vendedores: IFilterData;
  comisiones: IComisionPorPagar;
  confirmation: boolean;
  loading: boolean;
  handlerOnChangeFecha: (e: IFilterChange) => void;
  handlerOnChangeVendedor: (e: IFilterChange) => void;
  handlerOnSearchComisiones: () => Promise<void>;
  handlerOnComisionChanged: (e: IOnSaved[], columnKey: string) => void;
  handlerConfirmation: () => void;
  handlerOnPayComisiones: () => Promise<void>;
}
const fechaActual: Date = new Date();
const defaultFilters: IFilterComisionesPagar = {
  fecha: {
    año: fechaActual.getUTCFullYear(),
    mes: fechaActual.getMonth()
  },
  vendedor: ""
}
const defaultComision: IComisionPorPagar = {
  comisionesAPagar: [],
  comisionesPendientes: [],
  comisionesSubordinados: [],
  porcentaje: 0
}

export const useComisionesPorPagar = (): IUseComisionesPorPagar => {
  const { User } = useAuth();
  const [comisiones, setComisiones] = useState<IComisionPorPagar>(defaultComision);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilterComisionesPagar>(defaultFilters);
  const { data = [] }: { data: IRemoteVendedores[] } = useSWRWithToken(`${URL_API_CF}catalogos/vendedores`, 0, User.token!);

  const comisionesService = useMemo(() => {
    return ComisionesService(User);
  }, [User]);

  const vendedores: IFilterData = useMemo(() => {
    const vendedoresArray = data.map((vendedor) => vendedor.cotizacion_Vendedor);
    
    if (!vendedoresArray.includes('AGENTE')) {
      vendedoresArray.unshift('AGENTE');
    }
    
    return createNewFilter(vendedoresArray, "", "vendedor");
  }, [data]);
  /*Asignar el mes y el año a los filtros */
  const handlerOnChangeFecha = (e: IFilterChange): void => {
    const { key, value } = e;
    setFilters(prev => {
      return {
        ...prev,
        fecha: {
          ...prev.fecha,
          [key]: value
        }
      }
    });
  }
  /*Asignar las vendedor seleccionada a los filtros */
  const handlerOnChangeVendedor = (e: IFilterChange): void => {
    setFilters(prev => {
      return {
        ...prev,
        vendedor: e.value
      }
    })
  }
  /*Obtener las comisiones apartir de los filtros seleccionados */
  const handlerOnSearchComisiones = useCallback(async (): Promise<void> => {
    if (!filters.vendedor.trim()) {
      notify(CSNotificacion.MissingSaller, "warning", 3000);
      return
    }
    setComisiones(defaultComision);
    setLoading(true);
    const dataCS = await comisionesService.getComisionesPorPagar(filters);
    if (!dataCS.result) {
      notify(CSNotificacion.ErrorGetComissionsToPay, "error", 2000);
    }
    if (dataCS.result) {
      const { comisionesAPagar, comisionesPendientes, porcentaje } = dataCS.data;
      comisionesAPagar.length === 0 && notify(CSNotificacion.EmptyComissionsToPay, "warning", 2000);
      /*Obtenemos las comisiones de sus subordinados */
      const dataCSSubordinados = await comisionesService.getComisionesPorPagarSubordinados(filters);
      if (!dataCSSubordinados.result) {
        notify(CSNotificacion.ErrorGetComissionsSubordinates, "error", 2000);
      }
      const { data } = dataCSSubordinados;
      /*Asignamos las informacion obtenida*/
      setComisiones({
        comisionesAPagar,
        comisionesPendientes,
        porcentaje,
        comisionesSubordinados: data?.comisiones  || [],
      });
    }
    setLoading(false)
  }, [comisionesService, filters]);

  const handlerOnComisionChanged = useCallback((e: IOnSaved[], columnKey: string): void => {
    const { comisionesAPagar } = comisiones;
    let comisionUpdated: IComisiones = e[0]!.data;
    if (columnKey === "porcentaje") {
      const montoComision: number = comisionUpdated.modoCalculo === "Embarque" ?
        (comisionUpdated.porcentaje / 100) * comisionUpdated.gananciaMXN :
        comisionUpdated.utilidadxContenedorMXN * comisionUpdated.totalPiezas * (comisionUpdated.porcentaje / 100);
      comisionUpdated = { ...comisionUpdated, totalComision: montoComision };
    }
    const updatedComisiones: IComisiones[] = comisionesAPagar
      .map((comision) => comision.embarqueGuid === comisionUpdated.embarqueGuid ?
        { ...comisionUpdated, totalComision: formatoDecimales(comisionUpdated.totalComision, 4) } :
        comision
      )
    setComisiones({ ...comisiones, comisionesAPagar: updatedComisiones });
  }, [comisiones]);

  const handlerOnPayComisiones = useCallback(async (): Promise<void> => {
    setLoading(true);
    const { result, error } = await comisionesService.sendToPayComisiones(comisiones);
    if (!result) {
      notify(error, "error", 4000);
    }
    if (result) {
      notify(CSNotificacion.OKSendComissionsPending, "success", 4000);
    }
    setLoading(false);
    setComisiones(defaultComision);
    setConfirmation(false);
  }, [comisionesService, comisiones]);

  const handlerConfirmation = (): void => {
    setConfirmation(prev => !prev);
  }

  return {
    filters,
    vendedores,
    comisiones,
    confirmation,
    loading,
    handlerOnChangeFecha,
    handlerOnChangeVendedor,
    handlerOnSearchComisiones,
    handlerOnComisionChanged,
    handlerConfirmation,
    handlerOnPayComisiones
  }
}