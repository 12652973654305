import { CSSProperties, memo } from 'react'
import Select from 'devextreme-react/select-box';
import { IFilterChange, IFilterData, typeStyleDropBox } from './interfaces/Interfaces';
import './styles/estilos.scss';

interface IProps {
  item?: IFilterData;
  onChangeSelect?: ({ key, value }: IFilterChange) => void;
  title?: string;
  display?: string;
  keyValue?: string;
  width?: number | string;
  className?: string;
  style?: CSSProperties;
  showClearButton?: boolean;
  loading?: boolean;
  enableSearch?: boolean;
  enableGrouped?: boolean;
  styleDropBox?: typeStyleDropBox;
  value?: any;
}

const SelectBox = (Props: IProps) => {

  const {
    item,
    onChangeSelect,
    title,
    width,
    display,
    loading,
    showClearButton = true,
    enableSearch = false,
    enableGrouped = false,
    keyValue,
    className,
    styleDropBox,
    style,
    value
  } = Props;

  const handlerItemChange = (value: any, key: string): void => {
    onChangeSelect && onChangeSelect({ key, value });
  }

  return (
    <div className={`select-item ${className}`} style={style}>
      <div className="item-label title-filter">{title ? title : item?.title}</div>
      <div className="item-value">
        {
          loading ? (
            <div className={`loading`}></div>
          ) :
            item?.defaultValue ?
              (
                <Select
                  id={item?.key}
                  key={item?.key}
                  dataSource={item?.items}
                  defaultValue={item?.defaultValue}
                  value={value}
                  noDataText="No hay informacion"
                  displayExpr={display}
                  valueExpr={keyValue}
                  onValueChange={(value) => handlerItemChange(value, item?.key || "")}
                  placeholder="--Selecciona--"
                  className='select-item'
                  showClearButton={showClearButton}
                  deferRendering={true}
                  searchEnabled={enableSearch}
                  grouped={enableGrouped}
                  width={width}
                  stylingMode={styleDropBox}
                />
              ) :
              (
                <Select
                  id={item?.key}
                  key={item?.key}
                  dataSource={item?.items}
                  noDataText="No hay informacion"
                  displayExpr={display}
                  valueExpr={keyValue}
                  onValueChange={(value) => handlerItemChange(value, item?.key || "")}
                  placeholder="--Selecciona--"
                  className='select-item'
                  showClearButton={showClearButton}
                  deferRendering={true}
                  searchEnabled={enableSearch}
                  grouped={enableGrouped}
                  width={width}
                  stylingMode={styleDropBox}
                />
              )
        }
      </div>
    </div>
  )
}

export default memo(SelectBox)