import { TotalComisionPagada } from './Index'
import { useComisionesPagadas } from './hooks/useComisionesPagadas';
import { Button, Select, TableGrid, FechaFilters } from 'components/Index';
import { ColumsComisionesPorPagar, tabsComisiones } from 'functions/Comisiones';
import { Tabs } from 'devextreme-react';
import { IndexTab } from 'consts/Comisiones';
import { ajustarDecimales } from 'utils/Formatos';
import { onExportingGrid } from 'utils/Functions';
import MenuFilters from 'components/MenuFilters';

const ComisionesPagadas = () => {
  const {
    filters,
    vendedores,
    comisiones,
    loading,
    tabVisible,
    handlerOnSelectTab,
    handlerOnChangeFecha,
    handlerOnChangeVendedor,
    handlerSearchComisiones
  }
    = useComisionesPagadas();

  const { fecha } = filters;
  const { comisionesPagadas, comisionesSubordinados } = comisiones;

  console.log(fecha);

  return (
    <div className="container-comisiones_modules">
      <MenuFilters>
        <div className="container-filters">
          <Select
            title='Vendedores'
            width={"100%"}
            item={vendedores}
            styleDropBox="outlined"
            showClearButton={false}
            onChangeSelect={handlerOnChangeVendedor}
          />
          <FechaFilters
            fecha={fecha}
            onValueChanged={handlerOnChangeFecha}
          />
          <Button
            text={loading ? `Buscando...` : "Buscar"}
            icon="fas fa-search-dollar"
            iconHover='Claro'
            onClick={handlerSearchComisiones}
            disabled={loading}
          />
        </div>
      </MenuFilters>
      <TotalComisionPagada
        comisiones={comisiones}
      />
      <hr />
      <Tabs
        visible={comisionesSubordinados.length > 0}
        height={"100%"}
        selectedIndex={tabVisible.text === "Vendedor" ? IndexTab.Vendedor : IndexTab.Subordinados}
        dataSource={tabsComisiones(comisionesSubordinados.length > 0)}
        onItemClick={(e) => handlerOnSelectTab(e.itemData!)}
      />
      <br />
      <TableGrid
        dataSource={comisionesPagadas}
        columns={[
          ...ColumsComisionesPorPagar("NotEditing"),
          { caption: "Fecha de Pago", "dataField": "fechaPago", alignment: "left", dataType: "date", format: "dd/MM/yyyy" }]}
        pageSize={100}
        enablePaging={true}
        enableExport={{ enabled: true, onExporting: (e) => onExportingGrid(e, "Comisiones pagadas", "") }}
        enableHeaderFilter={true}
        enableGroupPanel={true}
        enableSearchPanel={true}
        visible={tabVisible.text === "Vendedor"}
        enableSessionStorage={{ enabled: true, keyStorage: 'gridCSPagadas' }}
        totalesColumns={[{
          column: "totalComision",
          summaryType: "sum",
          displayFormat: "Total {0}",
          valueFormat: ajustarDecimales
        }]}
      />
      <TableGrid
        dataSource={comisionesSubordinados}
        columns={[
          { caption: "Vendedor", dataField: "vendedor", alignment: "left", width: 175, groupIndex: 0 },
          ...ColumsComisionesPorPagar("NotEditing"),
          { caption: "Fecha de Pago", "dataField": "fechaPago", alignment: "left", dataType: "date", format: "dd/MM/yyyy" }]}
        enablePaging={true}
        pageSize={100}
        enableHeaderFilter={true}
        enableGroupPanel={true}
        enableExport={{ enabled: true, onExporting: (e) => onExportingGrid(e, "Comisiones pagadas subordinados", "") }}
        enableSearchPanel={true}
        expandAllGrouping={false}
        visible={tabVisible.text === "Subordinados"}
        enableSessionStorage={{ enabled: true, keyStorage: 'gridCSPagadasSubordinados' }}
        totalesColumns={[{
          column: "totalComision",
          summaryType: "sum",
          displayFormat: "Total {0}",
          valueFormat: ajustarDecimales
        }]}
      />
    </div>
  )
}

export default ComisionesPagadas