import "./styles/estilos.scss";
import { useTask } from "contexts/TaskContext";
import GridTareas from "./components/GridTareas";
import ModalTarea from "./components/ModalTarea";
import MenuFilters from "./components/MenuFilters";
import CreateTaskForm from "./components/CreateTaskForm";

const Tareas = () => {
  const {
    tarea,
    dataTareas,
    tabVisible,
    modalStatus,
    updateTarea,
    deleteTarea,
    responsables,
    entidadDefined,
    setModalStatus,
    handleEditTarea,
    handlerOnSelectTab,
  } = useTask();

  return (
    <>
      {dataTareas.loading ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            padding: "100px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="icon loading-icon loading-size-lg"></div>
        </div>
      ) : (
        <div className="container-tareas">
          <hr />
          <br />
          <MenuFilters>
            <CreateTaskForm />
          </MenuFilters>
          <GridTareas
            deleteTarea={deleteTarea}
            dataTareas={dataTareas.data}
            handleEditTarea={handleEditTarea}
          />
          {modalStatus && (
            <ModalTarea
              tarea={tarea}
              tabVisible={tabVisible}
              statusModal={modalStatus}
              updateTarea={updateTarea}
              entidades={entidadDefined.data}
              responsables={responsables.data}
              handlerOnSelectTab={handlerOnSelectTab}
              closePopup={() => setModalStatus(!modalStatus)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Tareas;
