import { useMemo, useState, useCallback } from 'react';
import { useAuth } from "api/auth"
import { URL_API_TH } from "consts/Services_URL";
import { useGetDataWithAuth } from 'hooks/useGetData';
import { createNewFilterMultiple, createNewFilter } from 'utils/Functions';
import { IFilterMultiple, IFilterData, IFilterChange } from 'components/interfaces/Interfaces';
import { ReportsService } from 'api/nomina';
import { IFilterReporteAcumulado, IReporteAcumulado } from 'interfaces/ITalentoHumano';
import { filterAcumuladoAdapter } from 'adapters/TH';
import notify from 'devextreme/ui/notify';

interface IUseReporteAcumulado {
  meses?: IFilterMultiple;
  anios?: IFilterData;
  reporteAcumulado: IReporteAcumulado[];
  loading: boolean;
  error: string | null;
  filters: IFilterReporteAcumulado;
  handlerOnSelectChange: (e: IFilterChange) => void;
  handlerOnSearchAcumulado: () => Promise<void>;
  handlerOnDownloadAcumulado: () => Promise<void>;
}

const fechaActual: Date = new Date();

const DEFAULT_FILTERS: IFilterReporteAcumulado = {
  numeroMes: [],
  anio: fechaActual.getUTCFullYear()
}

export const useReportAcumulado = (status: boolean): IUseReporteAcumulado => {

  const { User } = useAuth();
  const { data: mesesData } = useGetDataWithAuth(status ? `${URL_API_TH}catalogos/meses` : null, User.token);
  const { data: aniosData } = useGetDataWithAuth(status ? `${URL_API_TH}catalogos/anios` : null, User.token);
  const [filters, setFilters] = useState<IFilterReporteAcumulado>(DEFAULT_FILTERS);
  const [reporteAcumulado, setReporteAcumulado] = useState<IReporteAcumulado[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const meses = useMemo(() => {
    if (!mesesData) return
    return createNewFilterMultiple(mesesData, "numeroMes", []);
  }, [mesesData]);

  const anios = useMemo(() => {
    if (!aniosData) return
    return createNewFilter(aniosData, fechaActual.getUTCFullYear(), "anio");
  }, [aniosData]);

  const reportesService = useMemo(() => {
    return ReportsService(User);
  }, [User]);

  const handlerOnSelectChange = (e: IFilterChange): void => {
    const { key, value } = e;
    setFilters(prev => {
      return {
        ...prev,
        [key]: value
      }
    });
    setReporteAcumulado([]);
  }

  const handlerOnSearchAcumulado = useCallback(async (): Promise<void> => {
    setLoading(true);
    setError(null);
    const filterAdapter = filterAcumuladoAdapter(filters);
    const { result, data, error } = await reportesService.getReportAcumulados(filterAdapter);
    if (!result) {
      setError(error);
    }
    if (result) {
      setReporteAcumulado(data);
    }
    setLoading(false);
  }, [filters, reportesService]);

  const handlerOnDownloadAcumulado = async () => {
    const wasExcelCreated = await reportesService.acumulado(reporteAcumulado, filters);
    if (!wasExcelCreated) {
      notify("No se pudo crear el reporte", "error", 4000);
      return
    }
  }


  return {
    meses,
    anios,
    reporteAcumulado,
    error,
    loading,
    filters,
    handlerOnSearchAcumulado,
    handlerOnSelectChange,
    handlerOnDownloadAcumulado
  }
}