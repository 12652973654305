/* eslint-disable eqeqeq */
import Button from "components/Button";
import { useUpload } from "pages/config/hooks/useUpload";
import { useRef, useState } from "react";

interface ImageCardProps {
    index: number;
    urlImage?: string;
}

const ImageCard = (props: ImageCardProps ) => {
    const [eliminated, setEliminated] = useState<boolean>(false);
    const ref = useRef<HTMLInputElement>(null);
    const { imageURL, showingUrl, loading, uploadImage, removeImage } = useUpload();


    const handleOnChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        uploadImage(props.index, e.target.files![0], 'banners');
        setEliminated(false);
    }

    const handleOnClickUpload= () => {
        if (ref.current) {
            ref.current.click();
        }
    }

    const deleteImage = async () => {
        await removeImage(props.index);
        setEliminated(true);
    }

    return (
        <div className="container-card-image">
            <h1>Imagen {props.index}</h1>
            {
                loading && (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '100px'}}>
                    <div className="icon loading-icon loading-size-lg"></div>
                </div>
                )
            }

            {
                !loading && showingUrl !== "" && !eliminated ? (
                    <img src={showingUrl} alt="newBanner" />
                )
                :
                !loading && props.urlImage != null && props.urlImage != "" && !eliminated ? (
                    <img src={`https://firebasestorage.googleapis.com/v0/b/crossmotion.appspot.com/o/images%2Fbanners%2F${props.urlImage}?alt=media&token=bc48bda8-a183-4a10-a838-ad22b203618d`} alt="banner" />
                )
                :
                !loading && (
                    <img src='https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg' alt="placeholder" />
                )
            }

            <div className="container-buttons">
            {
                (props.urlImage != null && props.urlImage != "")  || imageURL != "" ? (
                    <>
                        <input accept="image/png, image/jpeg" type="file" name="image" id="image" hidden ref={ref} onChange={handleOnChangeImage}  />
                        <Button
                            text={"Modificar"}
                            icon="fas fa-edit"
                            iconHover='Claro'
                            onClick={handleOnClickUpload}
                        />

                        <Button
                            text={"Eliminar"}
                            icon="fas fa-trash-alt"
                            iconHover='Claro'
                            onClick={deleteImage}
                        />
                    </>
                ):(
                    <>
                    <input accept="image/png, image/jpeg" type="file" name="image" id="image" hidden ref={ref} onChange={handleOnChangeImage}  />
                    <Button
                        text={"Subir imagen"}
                        icon="fas fa-plus"
                        iconHover='Claro'
                        onClick={handleOnClickUpload}
                        />
                    </>
                )
                    
            }
            </div>
        </div>
    );
    } 

export default ImageCard;