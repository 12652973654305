import { useAuth } from "api/auth";
import { ClientesService, ProfitCliente, ProfitMensualAnual } from "api/clientes";
import { IFilterChange, IFilterData, IFilterMultiple } from "components/interfaces/Interfaces";
import { ExportingEvent } from "devextreme/ui/pivot_grid";
import { IFiltersProfit } from "interfaces/IClientes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { onExportingPivot } from "utils/Functions";

interface IUseProfit {
    catalogos: IGetCatalogosProfit;
    onExporting: (e: ExportingEvent) => void;
    handlerOnSelectChange: (e: IFilterChange) => void;
    handleFiltrado: () => Promise<void>;
    filterState: IFiltersProfit;
    modalState: boolean;
    handlerModalState: () => void;
    dataProfit: IDataProfit;
}

interface IGetCatalogosProfit {
    años?: IFilterMultiple;
    clientes?: IFilterData;
    vendedores?: IFilterData;
    error?: string | null;
    loading: boolean;
}

interface IDataProfit {
    dataProfit?: ProfitCliente[];
    profitMensualAnual?: ProfitMensualAnual[];
    error?: string | null;
    loading: boolean;
}

const añoActual: number = new Date().getUTCFullYear();
const initialFilters: IFiltersProfit = {
    años: [añoActual],
    clientes: "",
    vendedores: ""
}

export const useProfit = (): IUseProfit => {
    const { User } = useAuth();
    const [filterState, setFilterState] = useState<IFiltersProfit>(initialFilters);
    const [dataProfit, setDataProfit] = useState<IDataProfit>({ loading: false, error: null });
    const [catalogos, setCatalogos] = useState<IGetCatalogosProfit>({ loading: false });
    const [modalState, setModalState] = useState<boolean>(false);

    const onExporting = useCallback((e: ExportingEvent) => {
        return onExportingPivot(e, "ProfitClientes", User.divisionSeleccionada!);
    }, [User]);

    const clientesService = useMemo(() => {
        return ClientesService(User);
    }, [User]);

    const handlerOnSelectChange = (e: IFilterChange): void => {
        const { key, value } = e;
        
        setFilterState(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
    }

    const handleFiltrado = useCallback(async (): Promise<void> => {
        setDataProfit({ loading: true });
        const { result, data, error } = await clientesService.getDataProfit(filterState);
        if (!result) {
            setDataProfit({ loading: false, error });
        }
        setDataProfit({ loading: false, dataProfit: data! });
    }, [clientesService, filterState]);

    const handlerModalState = (): void => {
        setModalState(prev => !prev);
    }
    /**Obtener catalogos */
    useEffect(() => {
        const getCatalogos = async (): Promise<void> => {
            setCatalogos({ loading: true });
            const { result, data, error } = await clientesService
                .getCatalogosProfit();
            if (!result) {
                setCatalogos({ loading: false, error: error });
                return
            }
            setCatalogos(
                {
                    loading: false,
                    años: data!.años,
                    clientes: data!.clientes,
                    vendedores: data!.vendedores
                }
            )
        }
        getCatalogos();
    }, [clientesService]);

    /**Obtener Data profit inicial */
    useEffect(() => {
        const getDataProfit = async (): Promise<void> => {
            setDataProfit({ loading: true });
            const { result, data, error } = await clientesService.getDataProfit(initialFilters);
            if (!result) {
                setDataProfit({ loading: false, error });
            }
            setDataProfit({ loading: false, dataProfit: data! })
        }
        getDataProfit();
    }, [clientesService]);

    return {
        catalogos,
        filterState,
        dataProfit,
        modalState,
        onExporting,
        handleFiltrado,
        handlerOnSelectChange,
        handlerModalState
    }
}