import { useCallback, useEffect, useState } from 'react';
import { IFiltersDesgloce, IGetDataOperaciones } from 'interfaces/IOperaciones';
import { RemoteFilterDesgloceAdapter } from 'adapters/Operaciones';
import { useAuth } from 'api/auth';
import { IFilterMultiple, IFilterChange } from 'components/interfaces/Interfaces';
import { OperacionesService } from 'api/operaciones';
import { IGetCatalogos } from 'interfaces/IHome';
import { ExportingEvent } from 'devextreme/ui/pivot_grid';
import { onExportingPivot } from 'utils/Functions';

interface IUseDesglose {
  desglose: IGetDataOperaciones;
  catalogos: IGetCatalogos;
  handlerOnSelectChange: (e: IFilterChange) => void;
  handleFiltrado: () => Promise<void>;
  onExporting: (e: ExportingEvent) => void;
}

const defaultFilters: IFiltersDesgloce = {
  años: [],
  vendedores: [],
  modosTransporte: [],
  customer: [],
  operativo: [],
  transportista: []
}

export const useDesglose = (): IUseDesglose => {
  const { User } = useAuth();
  const [filters, setFilters] = useState<IFiltersDesgloce>(defaultFilters);
  const [catalogos, setCatalogos] = useState<IGetCatalogos>({ loading: false });
  const [desglose, setDeslose] = useState<IGetDataOperaciones>({ loading: false });

  const opsService = useCallback(() => {
    return OperacionesService(User);
  }, [User]);

  const onExporting = useCallback((e: ExportingEvent) => {
    return onExportingPivot(e, "DesgloseOps", User.divisionSeleccionada!);
  }, [User]);

  const handleFiltrado = async (): Promise<void> => {
    setDeslose({ loading: true });
    const filtersAdapter: string = JSON.stringify(RemoteFilterDesgloceAdapter(filters));
    const { result, data, error } = await opsService().getDataDesglose(filtersAdapter);
    console.log(data);
    if (!result) {
      setDeslose({ error: error, loading: false });
      return
    }
    setDeslose({ data: data, loading: false, });
  }

  const handlerOnSelectChange = (e: IFilterChange): void => {
    const { key, value } = e;
    setFilters(prev => {
      return {
        ...prev,
        [key]: value
      }
    });
  }
  /**GET CATALOGOS */
  useEffect(() => {
    const getCatalogos = async (): Promise<void> => {
      setCatalogos({ loading: true });
      const { result, data, error } = await opsService().getCatalogosDesglose();
      console.log(data);
      if (!result) {
        setCatalogos({ error: error, loading: false });
        return
      }
      const filtersCatalogos: IFilterMultiple[] = data;
      setCatalogos({ data: filtersCatalogos, loading: false });
    }
    getCatalogos();
  }, [opsService]);

  /**GET DATA INICIAL */
  useEffect(() => {
    const getInitialData = async (): Promise<void> => {
      setDeslose({ loading: true });
      const filtersAdapter: string = JSON.stringify(RemoteFilterDesgloceAdapter(defaultFilters));
      const { result, data, error } = await opsService().getDataDesglose(filtersAdapter);
      console.log(defaultFilters);
      console.log(data);
      if (!result) {
        setDeslose({ error: error, loading: false });
        return
      }
      setDeslose({ data: data, loading: false, });
    }
    getInitialData();
  }, [opsService]);

  return {
    desglose,
    catalogos,
    handlerOnSelectChange,
    handleFiltrado,
    onExporting
  }
}