import { memo, useState } from 'react'
import ResponsiveBox, { Location, Item, Row, Col } from 'devextreme-react/responsive-box';
import GridPagoDivisiones from './GridPagoDivisiones';
import GridRangoComisiones from './GridRangoComisiones';
import GridComisionFija from './GridComisionFija';
import GridComisionPuesto from './GridComisionPuesto';


const BoxComisiones = ({ 
        catalogosComisionPresupuesto,
        handleDelete,
        handleDeleteStatus,
        MESSAGE_MODAL_DELETE,
        historialComisionPuesto,
        modalDeleteStatus,
        onSavedFijos,
        onSavedPagoDivision,
        onSavedPuesto,
        onSavedRango,
        setHistorialComisionPuesto
}) => {
    const [ModalPuestoTitle, setModalPuestoTitle] = useState('')
    const {vendedores} = catalogosComisionPresupuesto;

    const screen = (width) => {
        return (width < 1050) ? 'sm' : 'lg';
    }

    return (
        <ResponsiveBox
            screenByWidth={screen}
            singleColumnScreen="sm"
        >
            <Row />
            <Row />
            <Col ratio={1} />
            <Col ratio={1} />
            <Col ratio={1} />
            <Item>
                <Location row={0} col={0} />
                <GridPagoDivisiones 
                    onSavedDivision={onSavedPagoDivision} 
                />
            </Item>
            <Item>
                <Location row={0} col={1} />
                <GridRangoComisiones 
                    onSavedRango={onSavedRango}
                />
            </Item>
            <Item>
                <Location row={0} col={2} />
                <GridComisionFija 
                    empleados={vendedores} 
                    onSavedCFija={onSavedFijos}
                />
            </Item>
            <Item>
                <Location row={1} col={0} colspan={3} />
                <GridComisionPuesto 
                    catalogos={catalogosComisionPresupuesto}
                    handleDelete={handleDelete} 
                    MESSAGE_MODAL_DELETE={MESSAGE_MODAL_DELETE}
                    modalDeleteStatus={modalDeleteStatus}
                    onSavedPuesto={onSavedPuesto}
                    handleDeleteStatus={handleDeleteStatus}
                    historialComisionPuesto={historialComisionPuesto}
                    setHistorialComisionPuesto={setHistorialComisionPuesto}
                    modalPuestoTitle={ModalPuestoTitle}
                    setModalPuestoTitle={(data) => setModalPuestoTitle(data)} 
                />
            </Item>
        </ResponsiveBox>
    )
}

export default memo(BoxComisiones)