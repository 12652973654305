import { Select, Button } from 'components/Index'
import { useNominaPagada } from 'api/nomina';
import { IFilterChange, IFilterData } from 'components/interfaces/Interfaces';
import { IOpcionesReportes } from 'interfaces/ITalentoHumano';
import { useState } from 'react';

interface IProps {
  tiposPago?: IFilterData,
  periodos?: IFilterData,
  handlerOnTipoPagoChanged: (e: IFilterChange) => Promise<void>;
  opciones: IOpcionesReportes;
}

const FiltersNominasPagadas = (Props: IProps) => {

  const {
    handlerOnTipoPagoChanged,
    opciones,
    periodos,
    tiposPago
  } = Props;

  const [selectedTipoPago, setSelectedTipoPago] = useState(tiposPago?.defaultValue);

  const {
    onHandlerReports,
    onHandlerSearchNominaPagada,
    onHandlerPeriodoNomina,
    clearData,
    onShowModals
  } = useNominaPagada();

  const onTipoPagoChanged = (e: IFilterChange): void => {
    clearData();
    setSelectedTipoPago(e.value);
    handlerOnTipoPagoChanged(e);
  }

  const onPeriodoChanged = (e: IFilterChange): void => {
    clearData();
    onHandlerPeriodoNomina(e);
  }

  return (
    <div className="container-filters-pagada">
      <Select
        title='Tipo de pago'
        item={tiposPago}
        loading={!tiposPago}
        display={"cMotion_Tipo_Nomina_Descripcion"}
        onChangeSelect={onTipoPagoChanged}
        showClearButton={false}
        width={"100%"}
        value={selectedTipoPago}
      />
      <Select
        title='Periodos'
        display='periodo'
        keyValue='periodo'
        item={periodos}
        showClearButton={false}
        onChangeSelect={onPeriodoChanged}
        width={"100%"}
        enableSearch={true}
        enableGrouped={true}
      />
      <Button
        text='Buscar nominas'
        icon='fas fa-search-dollar'
        onClick={() => onHandlerSearchNominaPagada()}
        className="btnBuscarNomina"
        height={35}
        width={"100%"}
        iconHover="Claro"
      />

      <Button
        text='Billetiza'
        icon='fas fa-money-bill-wave-alt'
        onClick={() => onHandlerReports("Billetiza")}
        className="btnBuscarNomina"
        disabled={opciones.billetiza}
        iconHover="Claro"
        style={{ backgroundColor: "#04B3C2" }}
        width={"100%"}
      />
      <Button
        text='Recibos EFE'
        icon='fas fa-receipt'
        onClick={() => onHandlerReports("Recibos")}
        className="btnBuscarNomina"
        disabled={opciones.recibos}
        iconHover="Claro"
        style={{ backgroundColor: "#04B3C2" }}
        width={"100%"}
      />
      <Button
        text='ASIM NOM'
        icon='fas fa-file-excel'
        onClick={() => onHandlerReports("Asimilados")}
        className="btnBuscarNomina"
        disabled={opciones.asimilados}
        width={"100%"}
        iconHover='Claro'
        style={{ backgroundColor: "#04B3C2" }}
      />
      <Button
        text='Reporte Acumulado'
        icon='fas fa-file-excel'
        onClick={() => onShowModals("acumulado")}
        className="btnBuscarNomina"
        width={"100%"}
        iconHover='Claro'
        style={{ backgroundColor: "#04B3C2" }}
      />
      <Button
        icon='fas fa-coins'
        text='Comisiones Pagadas'
        onClick={() => onShowModals("comisiones")}
        className="btnBuscarNomina"
        width={"100%"}
        iconHover="Oscuro"
        style={{ backgroundColor: "var(--grayDark)" }}
      />
    </div>
  )
}

export default FiltersNominasPagadas