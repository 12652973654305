import { useMemo, useState } from 'react';
import { useAuth } from "api/auth";
import { URL_API_TH } from "consts/Services_URL";
import { useGetDataWithAuth } from "hooks/useGetData";
import { IOpcionesReportes, IPeriodosPagados, IRemoteTipoPago } from "interfaces/ITalentoHumano";
import { createNewFilter } from "utils/Functions";
import { IFilterChange, IFilterData } from 'components/interfaces/Interfaces';
import { SKIP_TIPO_COMISIONES } from "consts/TH";
import { NominaService } from "api/nomina";
import notify from "devextreme/ui/notify";
import { IColumnProps } from "devextreme-react/data-grid";
import DataSource from 'devextreme/data/data_source';

interface useNominaPagada {
  tipoPagoSelected?: IRemoteTipoPago;
  tiposPago?: IFilterData;
  periodos?: IFilterData;
  handlerOnTipoPagoChanged: (e: IFilterChange) => Promise<void>;
  loading: boolean;
  opciones: IOpcionesReportes;
  columsGrid: IColumnProps[];
}

interface IGetData {
  data?: IRemoteTipoPago[];
  error: string | null;
  loading: boolean;
}

const OPCIONES_DESHABILITADAS: IOpcionesReportes = {
  billetiza: true,
  asimilados: true,
  recibos: true
}

export const useFiltersNominaPagada = (): useNominaPagada => {

  const { User } = useAuth();
  const { data }: IGetData = useGetDataWithAuth(`${URL_API_TH}catalogos/tipoNominaPago`, User.token);
  const [periodos, setPeriodos] = useState<IFilterData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [opciones, setOpciones] = useState<IOpcionesReportes>(OPCIONES_DESHABILITADAS);
  const [columsGrid, setColumsGrid] = useState<IColumnProps[]>([]);
  const [tipoPago, setTipoPago] = useState<IRemoteTipoPago>();

  const tiposPago = useMemo(() => {
    if (!data) return
    return createNewFilter(
      data.filter((tipo: IRemoteTipoPago) =>
        tipo.cMotion_Tipo_Nomina_Descripcion !== SKIP_TIPO_COMISIONES),
      tipoPago,
      "tipoPago");
  }, [data, tipoPago]);

  const nominasService = useMemo(() => {
    return NominaService(User);
  }, [User]);

  const handlerOnTipoPagoChanged = async (e: IFilterChange) => {
    setLoading(true);
    const tipoPago: IRemoteTipoPago = e.value;
    const { cMotion_Tipo_Nomina_Descripcion: tipo, cMotion_Tipo_Nomina_Id } = tipoPago;

    await nominasService.getPeriodosPagados(cMotion_Tipo_Nomina_Id, (periodos, result) => {
      !result && notify("Ocurrio un error al obtener los periodos", "error", 4000);
      const groupedPeriodos = new DataSource({
        store: {
          type: 'array',
          data: periodos,
          key: 'periodo'
        },
        map(item: IPeriodosPagados) {
          item.key = item.mes;
          item.items = item.periodos;
          return item;
        }
      });
      const filterPeriodos = createNewFilter(groupedPeriodos, null, "periodo");
      setPeriodos(filterPeriodos);
    });
    setTipoPago(tipoPago);
    const columns = nominasService.showColumnsPagoNomina("PAGADA", tipoPago);
    setColumsGrid(columns);
    /**DESHABILITAMOS LAS OPCIONES CORRESPONDIENTES */
    const opciones = nominasService.deshabilitarOpcionesReportes(tipo);
    setOpciones(opciones);
    setLoading(false);
  }

  return {
    tiposPago,
    periodos,
    columsGrid,
    handlerOnTipoPagoChanged,
    loading,
    opciones
  }
}