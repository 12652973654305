import { AxiosRequestConfig } from "axios";
import { URL_API_COMISIONES, URL_API_FIREBASE_APP } from "consts/Services_URL";
import { IUser } from "interfaces/IUsuario";
import { IComisiones, IComisionPorPagar, IFilterComisionesPendientes, IRemoteComisiones, IClientesExentosDemoras } from 'interfaces/IComisiones';
import { getDataAsync, sendDataAsync } from './fetch';
import { IResponse } from './interfaces/interfaces';
import { IFilterComisionesPagar } from '../interfaces/IComisiones';
import { ProfitService } from 'api/clientes';

/*POR PAGAR- PAGADAS -SUBORDINADOS*/
interface IReponseComisiones extends IResponse {
    data: IRemoteComisiones;
}
interface IResponseComisionesPendientes extends IResponse {
    data: IComisiones[];
}
interface IGetComisionesPorPagar {
    data: IComisionPorPagar,
    result: boolean;
}

export const ComisionesService = (User: IUser) => {
    const { token } = User;
    const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } };
    /*USAMOS EL SERVICIO DE PROFIT PARA SINCRONIZAR EMBARQUES */
    const profitService = ProfitService(User);
    return {
        getComisionesPendientes: async (filters: IFilterComisionesPendientes): Promise<IResponseComisionesPendientes> => {
            const { estrategia, fecha: { año, mes } } = filters;
            const response = await getDataAsync(`${URL_API_COMISIONES}comisiones/pendientes?mes=${mes}&anio=${año}&estrategia=${estrategia}`, headers);
            return response as IResponseComisionesPendientes;
        },
        getComisionesPorPagar: async (filters: IFilterComisionesPagar): Promise<IGetComisionesPorPagar> => {
            const { vendedor, fecha: { año, mes } } = filters;
            const response = await getDataAsync(`${URL_API_COMISIONES}comisiones/pagar?mes=${mes}&anio=${año}&vendedor=${vendedor}`, headers) as IReponseComisiones;
            const comisiones = response.data?.comisiones || [];
            const porcentajeAplica = response.data.porcentaje || 0;
            const comisionesAPagar = comisiones.filter((comision) => comision.pagarComision && comision.generaComision);
            const comisionesPendientes = comisiones.filter((comision) => !comision.pagarComision);
            return {
                result: response.result,
                data: {
                    comisionesAPagar,
                    comisionesPendientes,
                    porcentaje: porcentajeAplica,
                    comisionesSubordinados: []
                }
            }
        },
        getComisionesPorPagarSubordinados: async (filters: IFilterComisionesPagar): Promise<IReponseComisiones> => {
            const { vendedor, fecha: { año, mes } } = filters;
            const response = await getDataAsync(`${URL_API_COMISIONES}comisiones/pagar/subordinados?mes=${mes}&anio=${año}&vendedor=${vendedor}`, headers);
            return response as IReponseComisiones;
        },
        getComisionesPagadas: async (filters: IFilterComisionesPagar): Promise<IReponseComisiones> => {
            const { fecha: { año, mes }, vendedor } = filters;
            let newVendedor = vendedor;
            switch (vendedor) {
                case "JORDI VAZQUEZ RAMOS":
                    newVendedor = "JORDI  VAZQUEZ RAMOS";
                    break;
            }
            const response = await getDataAsync(`${URL_API_COMISIONES}comisiones/pagadas?mes=${mes}&anio=${año}&vendedor=${newVendedor}`, headers);
            return response as IReponseComisiones;
        },
        getComisionesPagadasSubordinados: async (filters: IFilterComisionesPagar): Promise<IReponseComisiones> => {
            const { fecha: { año, mes }, vendedor } = filters;
            let newVendedor = vendedor;
            switch (vendedor) {
                case "JORDI VAZQUEZ RAMOS":
                    newVendedor = "JORDI  VAZQUEZ RAMOS";
                    break;
            }
            const response = await getDataAsync(`${URL_API_COMISIONES}comisiones/pagadas/subordinados?mes=${mes}&anio=${año}&vendedor=${newVendedor}`, headers);
            return response as IReponseComisiones;
        },
        sendToPayComisionesPendientes: async (comisiones: IComisiones[]): Promise<IResponse> => {
            return sendDataAsync(`${URL_API_COMISIONES}comisiones/pendientes`, comisiones, "POST", headers.headers);
        },
        sendToPayComisiones: async (comisiones: IComisionPorPagar): Promise<IResponse> => {
            const body: IRemoteComisiones =
            {
                comisiones: comisiones.comisionesAPagar,
                porcentaje: comisiones.porcentaje
            }
            return sendDataAsync(`${URL_API_COMISIONES}comisiones/pagar`, body, "POST", headers.headers);
        },
        syncComission: (embarqueGuid: string) => profitService.syncComission(embarqueGuid),
        updateComission: (embarqueGuid: string) => profitService.updateComission(embarqueGuid),
        getClientesExentosDemoras: async (): Promise<IResponse> => {
            return await getDataAsync(`${URL_API_FIREBASE_APP}/getClientsExemptDelays`, headers)
            .then((result) => {
                const clientesExentosDemoras = result.data.payload as IClientesExentosDemoras;
                const response: IResponse = { estatus: result.estatus, data: clientesExentosDemoras, result: true };
                return response;
            }).catch((error) => {
                return { estatus: error.estatus, result: false, data: null };
            });
        },
        insertClientesExentosDemoras: async (entidad_guids: string[]): Promise<IResponse> => {
            return sendDataAsync(`${URL_API_FIREBASE_APP}/insertClientExemptDelays`, { entidad_guids }, "POST", headers.headers);
        },
        deleteClienteExentoDemoras: async (entidad_guid: string): Promise<IResponse> => {
            return sendDataAsync(`${URL_API_FIREBASE_APP}/deleteClientExemptDelays`, { entidad_guid }, "DELETE", headers.headers);
        }
    }
}