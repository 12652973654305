import { useMemo } from 'react';
import { IOpsByTitle, IRemoteBudget, ITitulosBudget } from 'interfaces/IOperaciones';
import { useAuth } from 'api/auth';
import { useSWRWithToken } from 'hooks/useSWR';
import { URL_API_OPERACIONES } from "consts/Services_URL";
import { groupByKey } from 'utils/Functions';
import { TITLE_ALCANCE_BUDGET, TITLE_DIFF_BUDGET } from 'consts/Operaciones';
import { OperacionesService } from 'api/operaciones';

interface IBase {
  error?: string | null;
  loading: boolean;
}
interface IUseBudget extends IBase {
  budget?: IRemoteBudget[];
  opsByMonth?: IOpsByTitle[];
}

interface IGetDataBudget extends IBase {
  data?: IRemoteBudget[];
}

export const useBudget = (): IUseBudget => {
  const { User } = useAuth();
  const { data, error, loading }: IGetDataBudget = useSWRWithToken(`${URL_API_OPERACIONES}budgetTarget?division=${User.divisionSeleccionada}`, 0, User.token!);

  const getOperaciones = useMemo(() => {

    if (!data) return

    const titulos: string[] = Object.keys(groupByKey(data || [], "titulo"));
    const titulosBudget: ITitulosBudget = {
      budgetActual: titulos[0],
      opsActual: titulos[2],
      alcanceActual: TITLE_ALCANCE_BUDGET,
      diferenciaActual: TITLE_DIFF_BUDGET,
      opsPasada: titulos[1]
    }
    return OperacionesService().getOpsMensualesBudget(titulosBudget, data);
  }, [data]);

  return {
    budget: data,
    error,
    loading,
    opsByMonth: getOperaciones
  }
}
