import { URL_API_FIREBASE_APP } from "consts/Services_URL";
import axios from "axios";

export const TestService = () => {
    return {
        getTest: async () => {
            return []
        },
        executeTest: async (
            guid: string,
            tipo: string,
        ) => {
            var Tipo = "";
            switch (tipo) {
                case "Facturas de Pago":
                    Tipo = "BI";
                    break;
                case "Facturas de Cobro":
                    Tipo = "IN";
                    break;
                case "Cotizaciones":
                    Tipo = "QT";
                    break;
                case "Embarque":
                    Tipo = "SH";
                    break;
                case "Cliente":
                    Tipo = "CL";
                    break;
                case "Proveedor":
                    Tipo = "VE";
                    break;
                default:
                    break;
            }

            const response = await axios.get(`${URL_API_FIREBASE_APP}/comparative?guid=${guid}&type=${Tipo}`);

            const data: any[] = [];

            if (response.data.payload.compare) {
                Object.keys(response.data.payload.compare).forEach((key: any) => {
                    data.push({
                        ...response.data.payload.compare[key],
                    });
                });
            }

            if (
                data.every((item: any) => !item.test)
            ) {
                return {
                    result: false,
                    data: data.filter((item: any) => !item.test),
                    message: "No existe en la base de datos"
                }
            }

            if (
                data.some((item: any) => !item.test)
            ) {
                return {
                    result: false,
                    data: data.filter((item: any) => !item.test)
                }
            } else {
                return {
                    result: true,
                    data: []
                }
            }
        }
    }
}