import { useLogs } from './hooks/useLogs'
import TableGrid from 'components/TableGrid'

const Logs = () => {

    const {
        dataLogs,
        porcentaje
    } = useLogs()
    return (
        <> {
            
            dataLogs.loading ? 
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '100px'}}>
                    <div className="icon loading-icon loading-size-lg"></div>
                </div>
            :
            <div className="container-configuracionModules">               
                <hr />
                <br />
                <div className='budget-mensual-actual-card'>
                <div className='container-operaciones-budget'>
                  <p className='title-operaciones'><strong>{dataLogs.data!.length}</strong><span style={{ fontSize: 20 }}> Logs</span></p>
                  <p className='title-cumplimiento'><span>{porcentaje}% <i className='fas fa-arrow-up'></i></span> de cumplimiento vs Logs</p>
                </div>   
                    <div className='porcentaje'><span style={
                        {
                        width: `${porcentaje}%`,
                        backgroundColor: '#53E079'
                        }}></span>
                    </div>                
                </div>
                <TableGrid
                    dataSource={dataLogs.data}
                    columns={[
                        { dataField: 'Reporte_Sync_Id', caption: 'Sync ID' },
                        { dataField: 'Reporte_Sync_Guid', caption: 'GUID' },
                        { dataField: 'Reporte_Sync_Tipo', caption: 'Tipo' },
                        { dataField: 'Reporte_Sync_Accion', caption: 'Acción' },
                        { dataField: 'parsedFechaCreacion', caption: 'Fecha de Magaya' },
                        { dataField: 'parsedFechaMagaya', caption: 'Fecha de creción' },
                        { dataField: 'parsedIsAdded', caption: '¿Fue añadido al sync?' },
                    ]}
                    enableHeaderFilter={true}
                    enableGroupPanel={true}
                    enableSearchPanel={true}
                    enableHoverState={true}
                    showRowLines={true}
                    enableColumnChooser={true}
                    enableSessionStorage={{ enabled: true, keyStorage: 'gridSync' }}
                    />
            </div>
        }
        </>
    )
}

export default Logs