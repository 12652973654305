import { URL_API_FIREBASE_APP } from "consts/Services_URL";
import TableGrid from 'components/TableGrid';
import { useEffect, useState } from 'react';
import { db } from 'config/firebase.config';
import { getDocs, collection } from 'firebase/firestore';
import Button from 'components/Button';
import axios from 'axios';
import notify from 'devextreme/ui/notify';

const Queue = () => {
    const [data, setData] = useState<any>([]);
    const [keepAlive, setKeepAlive] = useState(false);

    const execute = async () => {
        const querySnapshot = await getDocs(collection(db, 'errors'));
        const data = querySnapshot.docs.map(doc => doc.data());
        setData(data);
    }

    useEffect(() => {
        execute();
    }, [])

    useEffect(() => {
        const fetchKeepAlive = async () => {
            try {
                const data = await axios.get(`${URL_API_FIREBASE_APP}/keepAlive`);

                if (data.status !== 200) {
                    setKeepAlive(false);
                    return;
                }
                setKeepAlive(true);
            } catch (error) {
                setKeepAlive(false);
            }
        }
        fetchKeepAlive();
    }, []);

    const onHandlerSync = async () => {
        if (!keepAlive) return;
        try {
            await axios.get(`${URL_API_FIREBASE_APP}/errorsExecute`);
            notify('Se añadio a la cola de sincronización', 'success', 2000);
            execute();
        } catch (error) {
            notify('Error al añadir a la cola de sincronización', 'error', 2000);
        }
    }

    return (
        <div className='container-modulo-th'>
            <Button 
                disabled={!keepAlive}
                onClick={onHandlerSync} 
                text={!keepAlive ? 'Servicio no disponible' : 'Sincronizar'}
                style={{
                    backgroundColor: !keepAlive ? "#D33" : "#10A800",
                    width: '200px',
                    margin: '10px'
                }}
            />
            <div className='container-grid'>
                <TableGrid
                    dataSource={data}
                    columns={[
                        { dataField: 'error', caption: 'Error' },
                        { dataField: 'GUID', caption: 'GUID' },
                        { dataField: 'type', caption: 'Tipo' },
                        { dataField: 'date', caption: 'Fecha' },
                    ]}
                    enableHeaderFilter={true}
                    enableGroupPanel={true}
                    enableSearchPanel={true}
                    enableHoverState={true}
                    showRowLines={true}
                    enableColumnChooser={true}
                    enableSessionStorage={{ enabled: true, keyStorage: 'gridSync' }}
                    enableEditing={{ modeEdit: 'cell', showIcons: true, enableUpdating: true, onSavedChanges: () => { } }}
                />
            </div>
        </div>
    )
}

export default Queue