import { useAuth } from "api/auth";
import { ConfiguracionService } from "api/configuracion";
import { IGetDataLogs } from "interfaces/IConfiguracion";
import { useLayoutEffect, useMemo, useState } from "react";


interface IUseLogs {
    dataLogs : IGetDataLogs;
    porcentaje: number;
}

export const useLogs = (): IUseLogs => {
    const [dataLogs, setDataLogs] = useState<IGetDataLogs>({ loading: false, data: [] });
    const [porcentaje, setPorcentaje] = useState<number>(0);
    const {User} = useAuth();

    const configuracionService = useMemo(() => {
        return ConfiguracionService(User);
    },[User])

    useLayoutEffect(() => {
        const getLogs = async (): Promise<void> => {
            setDataLogs({ loading: true });
            const { data, porcentaje } = await configuracionService.getLogs();

            data.forEach((item: any) => {
                item.parsedIsAdded = item.Reporte_Sync_is_Added_Sync === true ? 'Sí' : item.Reporte_Sync_is_Added_Sync === false ? 'No' : 'Sin información';
                const fechaCreacion = new Date(item.Reporte_Sync_Fecha_Creacion);
                const fechaMagaya = new Date(item.Reporte_Sync_Fecha_Magaya);
                item.parsedFechaCreacion = fechaCreacion.getFullYear() + '-' + (fechaCreacion.getMonth() + 1) + '-' + fechaCreacion.getDate();
                item.parsedFechaMagaya = fechaMagaya.getFullYear() + '-' + (fechaMagaya.getMonth() + 1) + '-' + fechaMagaya.getDate();
            });

            setDataLogs({ loading: false, data });
            setPorcentaje(porcentaje);
        }
        getLogs();
    },[configuracionService])



    return {
        dataLogs,
        porcentaje
    }
}