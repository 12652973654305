import { Select, Button, TableGrid, ModalConfirmation, FechaFilters } from 'components/Index'
import { TotalComision, GridComisionSubordinados } from './Index';
import { useComisionesPorPagar } from './hooks/useComisionesPorPagar';
import { ColumsComisionesPorPagar } from 'functions/Comisiones';
import { CSNotificacion } from 'consts/Comisiones';
import { ajustarDecimales } from 'utils/Formatos';
import './styles/estilos.scss';
import { onExportingGrid } from 'utils/Functions';
import MenuFilters from 'components/MenuFilters';

const PagarComisiones = () => {

  const {
    filters,
    vendedores,
    comisiones,
    confirmation,
    loading,
    handlerConfirmation,
    handlerOnChangeFecha,
    handlerOnChangeVendedor,
    handlerOnSearchComisiones,
    handlerOnComisionChanged,
    handlerOnPayComisiones
  } = useComisionesPorPagar();

  const { fecha } = filters;

  return (
    <div className="container-comisiones_modules">
      <MenuFilters>
        <div className="container-filters">
          <Select
            title='Vendedores'
            styleDropBox="outlined"
            item={vendedores}
            width={"100%"}
            showClearButton={false}
            onChangeSelect={handlerOnChangeVendedor}
          />
          <FechaFilters
            fecha={fecha}
            onValueChanged={handlerOnChangeFecha}
          />
          <Button
            text={loading ? "Buscando" : "Buscar"}
            icon="fas fa-search-dollar"
            iconHover='Claro'
            onClick={() => handlerOnSearchComisiones()}
            disabled={loading}
          />
          <Button
            text='Pagar'
            icon="fas fa-money-check-alt"
            iconHover='Claro'
            style={{ backgroundColor: "#00D55A" }}
            disabled={comisiones.comisionesAPagar.length === 0}
            onClick={() => handlerConfirmation()}
          />
        </div>
      </MenuFilters>
      <TotalComision
        comisiones={comisiones}
      />
      <hr />
      <TableGrid
        dataSource={comisiones.comisionesAPagar}
        columns={ColumsComisionesPorPagar("Editing")}
        pageSize={100}
        enablePaging={true}
        enableHeaderFilter={true}
        enableGroupPanel={true}
        enableSearchPanel={true}
        enableSessionStorage={{ enabled: true, keyStorage: 'gridCSPorPagar' }}
        enableExport={{ enabled: true, onExporting: (e) => onExportingGrid(e, "Comisiones por pagar", "") }}
        enableEditing={{
          enableUpdating: true,
          modeEdit: "cell",
          onCellSaving: handlerOnComisionChanged
        }}
        totalesColumns={[{
          column: "totalComision",
          summaryType: "sum",
          displayFormat: "Total {0}",
          valueFormat: ajustarDecimales
        }]}
      />      
      {
        comisiones.comisionesSubordinados.length > 0 && (
          <GridComisionSubordinados
            comisiones={comisiones.comisionesSubordinados}
          />
        )
      }
      {
        comisiones.comisionesPendientes.length > 0 && (
          <>
            <h1>Comisiones pendientes</h1>
            <TableGrid
              dataSource={comisiones.comisionesPendientes}
              columns={ColumsComisionesPorPagar("NotEditing")}
              pageSize={100}
              enablePaging={true}
              enableExport={{ enabled: true, onExporting: (e) => onExportingGrid(e, "Comisiones pendientes", "") }}
              enableHeaderFilter={true}
              enableSessionStorage={{ enabled: true, keyStorage: 'gridCSPendientes' }}
              enableGroupPanel={true}
              enableSearchPanel={true}
              totalesColumns={[{
                column: "totalComision",
                summaryType: "sum",
                displayFormat: "Total {0}",
                valueFormat: ajustarDecimales
              }]}
            />
          </>
        )
      }
      <ModalConfirmation
        status={confirmation}
        message={loading ? CSNotificacion.PayingComissionsPending : CSNotificacion.AreYouSureToPayComissions}
        close={() => handlerConfirmation()}
        confirmation={() => handlerOnPayComisiones()}
        disabled={loading}
      />
    </div>
  )
}

export default PagarComisiones