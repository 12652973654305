import { CSSProperties, useMemo } from 'react';
import { useAuth } from 'api/auth';
import { URL_API_GENERAL } from 'consts/Services_URL';
import { useSWRWithToken } from 'hooks/useSWR';
import { IRemoteModoTransporte, IModoTransporte } from 'interfaces/IHome';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { FormatoSinDecimales, FormatText } from 'utils/Formatos';
import { ICONS_MODOSTRANSPORTE } from 'consts/Home';

interface IDataBudget {
  data: IRemoteModoTransporte[];
  error: any;
  loading: boolean;
}
interface IProps {
  className?: string;
  style?: CSSProperties;
}

const ModoTransporte = ({ className, style }: IProps) => {

  const { User } = useAuth();
  const { data, loading }: IDataBudget =
    useSWRWithToken(`${URL_API_GENERAL}operacionesPorModoMes?division=${User.divisionSeleccionada}`, 0, User.token!);
  
  const modosTransporte: IModoTransporte[] = useMemo(() => {
    if (!data) return [];
    const newOpsModo: IModoTransporte[] = data.map((modo) => {
      return {
        opsActual: modo.actual.total_Operaciones,
        opsPasadas:modo.pasada.total_Operaciones,
        modo: modo.modo_Transporte!,
        icon: ICONS_MODOSTRANSPORTE[modo.modo_Transporte!]
      }
    });

    // Verificar que los modos de transportes esten
    // en el mismo orden que el arreglo de iconos
    const modosTransporte: string[] = Object.keys(ICONS_MODOSTRANSPORTE);
    console.log(modosTransporte);
    modosTransporte.forEach((modo) => {
      const md = newOpsModo.find((m) => m.modo === modo);
      if (!md) {
        newOpsModo.push({
          opsActual: 0,
          opsPasadas: 0,
          modo,
          icon: ICONS_MODOSTRANSPORTE[modo]
        })
      }
    });
    return newOpsModo.reverse();
  }, [data]);

  if (loading) {
    return (
      <Stack spacing={1} className={className}>
        <Skeleton variant="rectangular" width={"100%"} height={"100%"} animation='wave' />
      </Stack >
    )
  }
 
  return (
    <div className={className} style={style}>
      {
        modosTransporte.map(({ icon, modo, opsActual,opsPasadas }, index) => (
          <div className='card' key={index}>
            <img alt='ModoTransporte' src={icon} />
            <div className='container-ops'>
              <p className='title-modo'>{FormatText(modo)}</p>
              <p className='title-operaciones'><strong>{FormatoSinDecimales(opsActual)}</strong> OPS</p>
              <p className='title-operaciones-pasadas'>Anterior <strong>{FormatoSinDecimales(opsPasadas)}</strong> OPS</p>
            </div>
          </div>
        ))
      }
    </div>
  )
}



export default ModoTransporte