import { memo, useMemo } from 'react';
import { Popup } from 'devextreme-react/popup';

interface IModalConfirmation {
  status?: boolean;
  confirmation?: () => void;
  close?: () => void;
  message?: string;
  disabled?: boolean;
}

const ModalConfirmation = ({ status, confirmation, close, message, disabled }: IModalConfirmation) => {

  const buttonsOptions: any[] = useMemo(() => {
    return [
      {
        widget: "dxButton",
        toolbar: "bottom",
        location: "center",
        options: {
          icon: "save",
          text: "Aceptar",
          type: "success",
          disabled: disabled
        },
        onClick: () => confirmation && confirmation()
      },
      {
        widget: "dxButton",
        toolbar: "bottom",
        location: "center",
        options: {
          icon: "close",
          text: "Cerrar",
          type: "danger",
          disabled: disabled
        },
        onClick: () => close && close()
      }
    ]
  }, [close, confirmation, disabled])

  const renderContent = (): JSX.Element => {
    return (
      <p style={{ fontSize: "1.15rem" }}>{message}</p>
    )
  }

  return (
    <Popup
      visible={status}
      width={400}
      height={240}
      dragEnabled={false}
      closeOnOutsideClick={false}
      showCloseButton={false}
      title="Confirmar"
      toolbarItems={buttonsOptions}
      contentRender={() => renderContent()}
    />
  )
}

export default memo(ModalConfirmation)
