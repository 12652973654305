import { memo } from "react";
import FormTarea from "./FormTarea";
import { IndexTab } from "consts/Tareas";
import { ScrollView } from "devextreme-react";
import { Popup } from "devextreme-react/popup";

const ModalPerfilColaborador = ({
  tarea,
  entidades,
  closePopup,
  tabVisible,
  updateTarea,
  statusModal,
  responsables,
  handlerOnSelectTab,
}) => {
  const renderTitle = () => {
    return <h3 className="title_task">Editar Tarea</h3>;
  };

  return (
    <Popup
      width={575}
      height={475}
      dragEnabled={false}
      visible={statusModal}
      showCloseButton={false}
      titleRender={renderTitle}
      closeOnOutsideClick={false}
    >
      <ScrollView>
        <>
          <FormTarea
            tarea={tarea}
            entidades={entidades}
            closePopup={closePopup}
            updateTarea={updateTarea}
            responsables={responsables}
            indexTab={obtenerIndex(tabVisible)}
          />
        </>
      </ScrollView>
    </Popup>
  );
};

const obtenerIndex = (tabVisible) => {
  return IndexTab[tabVisible.text];
};

export default memo(ModalPerfilColaborador);
