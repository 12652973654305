import { TestService } from 'api/test';
import Button from 'components/Button';
import Select from 'components/Select';
import React from 'react';
import notify from "devextreme/ui/notify";

const Testing = () => {

    const [tipo, setTipo] = React.useState('');
    const [guid, setGuid] = React.useState('');
    const [result, setResult] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any[]>([]);

    const onHandlerSync = async () => {
        try {
            setLoading(true);
            const result = await TestService().executeTest(guid, tipo);
            setResult(result.result);
            setData(result.data);
            setLoading(false);

            if (!result.result && result.message) {
                notify(result.message || "No existe en base de datos", 'error', 2000);
                return;
            }

            if (result) {
                notify('Todos los datos coinciden', 'success', 2000);
            } else {
                notify('Algunos datos no coinciden', 'error', 2000);
            }
        } catch (error) {
            console.error(error);
            notify('Error al realizar la prueba', 'error', 2000);
            setResult(false);
            setLoading(false);
        }
    }

    return (
        <div style={{ fontFamily: 'Arial', backgroundColor: '#f4f4f4', margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            {
                loading &&
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
                    <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <h1 style={{ color: '#333', textAlign: 'center' }}>Cargando...</h1>
                    </div>
                </div>
            }
            <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <h1 style={{ color: '#333', textAlign: 'center' }}>Testing</h1>
                <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                <tr>
                    <td>
                    <label style={{ display: 'block', marginBottom: '5px', color: '#555' }}>GUID</label>
                    <input type='text' value={guid} onChange={(e) => setGuid(e.target.value)} style={{ width: '100%', padding: '8px', marginBottom: '10px', boxSizing: 'border-box' }} />
                    </td>
                    <td>
                    <Select
                        title="Tipo"
                        item={{
                            key: 'Sync',
                            title: 'Sync',
                            items: [
                                "Embarque",
                                "Cliente",
                                "Proveedor",
                                "Facturas de Pago",
                                "Facturas de Cobro",
                                "Cheques",
                                "Pagos",
                                "Cotizaciones"
                            ]
                        }}
                        onChangeSelect={(e) => {
                            setTipo(e.value);
                        }}
                        style={{ width: '100%', padding: '8px', marginBottom: '10px', boxSizing: 'border-box' }}
                    />
                    </td>
                    <td>
                    <Button
                        icon={`fas sync-alt`}
                        text='Probar'
                        width={250}
                        iconHover="Claro"
                        onClick={onHandlerSync}
                        style={{ backgroundColor: '#4CAF50', color: '#fff', padding: '10px', border: 'none', borderRadius: '4px', cursor: 'pointer', width: '100%' }}
                    />
                    </td>
                    <td>
                    <label style={{ display: 'block', marginBottom: '5px', color: '#555' }}>Resultado</label>
                    <input type='text' value={result ? 'Correcto' : 'Incorrecto'} readOnly style={{ width: '100%', padding: '8px', marginBottom: '10px', boxSizing: 'border-box', backgroundColor: result ? '#4CAF50' : '#FF5733', color: '#fff' }} />
                    </td>
                </tr>
                </table>
            </div>
            <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginTop: '20px' }}>
                <h1 style={{ color: '#333', textAlign: 'center' }}>Resultado</h1>
                <div style={{ overflow: 'auto', marginTop: '20px' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tr>
                    <th style={{ padding: '8px', backgroundColor: '#f4f4f4', color: '#333' }}>Key</th>
                    <th style={{ padding: '8px', backgroundColor: '#f4f4f4', color: '#333' }}>BD</th>
                    <th style={{ padding: '8px', backgroundColor: '#f4f4f4', color: '#333' }}>Magaya</th>
                    </tr>
                    {data.map((item, index) => (
                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f4f4f4' : '#fff' }}>
                            <td style={{ padding: '8px', color: '#333' }}>{item.field}</td>
                            <td style={{ padding: '8px', color: '#333' }}>{item.value_db}</td>
                            <td style={{ padding: '8px', color: '#333' }}>{item.value_xml}</td>
                        </tr>
                    ))}
                </table>
                </div>
            </div>
        </div>
    )
}

export default Testing