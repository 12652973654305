import { useAuth } from "api/auth";
import { ConfiguracionService } from "api/configuracion";
import { sendDataAsync } from "api/fetch";
import { AxiosRequestHeaders } from "axios";
import { URL_API_CF } from "consts/Services_URL";
import notify from "devextreme/ui/notify";
import { useSWRWithToken } from "hooks/useSWR";
import { IRemoteGetCatalogosEstrategias, IGetDataConfiguracionEstrategias, IRemoteConfiguracionEstrategias } from "interfaces/IConfiguracion"
import { useCallback, useLayoutEffect, useMemo, useState } from "react";

interface IUseEstrategias {
    configuracionEstrategias : IGetDataConfiguracionEstrategias;
    catalogosEstrategias: IRemoteGetCatalogosEstrategias;
    onSavedEstrategias:(data:IRemoteConfiguracionEstrategias) => void;
}

interface INotify {
    type: string;
    message?: string;
}

export const useEstrategias = (): IUseEstrategias => {
    const [catalogosEstrategias, setCatalogosEstrategias] = useState<IRemoteGetCatalogosEstrategias>({ loading: false });
    const {User} = useAuth();
    const headers: AxiosRequestHeaders = { Authorization: `Bearer ${User.token}` }

    const configuracionService = useMemo(() => {
        return ConfiguracionService(User);
    },[User])

    const showNotify = useCallback((Props:INotify) => {
        const {type,message=""} = Props;
        if (type === 'error') {
            notify({
                message: `Ocurrio un error: ${message}`
            }, "error", 4000);
        }
        if (type === 'success') {
            notify({
                message: `Se guardo correctamente`
            }, "success", 4000);
        }
    },[])

    const {data: dataPaises} = useSWRWithToken(`${URL_API_CF}ConfigPaises/estrategiasComerciales`,10, User.token!)

    const onSavedEstrategias = async (data:IRemoteConfiguracionEstrategias) =>{
        const {segmentacionId} = data;
        const { result: resUpdate, error: resUpdateError } = await sendDataAsync(`${URL_API_CF}ConfigPaises/estrategiasComerciales/${segmentacionId}`, data, "PUT", headers)
        showNotify({ type: resUpdate ? "success" : "error", message: resUpdateError });
        return
    }

    const configuracionEstrategias:IGetDataConfiguracionEstrategias = {loading: false, data: dataPaises}

    useLayoutEffect(() => {
        const getCatalogos = async (): Promise<void> => {
            setCatalogosEstrategias({ loading: true });
            const { result, data, error } = await configuracionService.getCatalogosEstrategias();
            if (!result) {
                setCatalogosEstrategias({ error: error, loading: false });
                return
            }
            const filtersCatalogos: IRemoteGetCatalogosEstrategias = data!;
            setCatalogosEstrategias({ segmentacionCross:filtersCatalogos.segmentacionCross, segmentacionMTI:filtersCatalogos.segmentacionMTI, loading: false });
        }
        getCatalogos();
    }, [configuracionService]);

    return{
        configuracionEstrategias,
        catalogosEstrategias,
        onSavedEstrategias,
    }
}