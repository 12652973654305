import { useCallback, memo } from 'react';
import PivotGrid from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { ExportingEvent } from 'devextreme/ui/pivot_grid';
import './styles/estilos.scss';

interface IProps {
  data?: any[];
  fieldPivot?: any[];
  keyPersistState?: string;
  onExporting?: (e: ExportingEvent | any) => void
}

const PivotTable = ({ data, fieldPivot, keyPersistState = "", onExporting }: IProps) => {

  const dataSource = useCallback(() => {
    return new PivotGridDataSource({
      fields: fieldPivot,
      store: data
    });
  }, [data, fieldPivot]);

  return (
    <PivotGrid
      className='pivot-container'
      id={keyPersistState}
      onDisposing={() => sessionStorage.removeItem(keyPersistState.toLocaleUpperCase())}
      dataSource={dataSource()}
      rowHeaderLayout='tree'
      wordWrapEnabled={true}
      onExporting={onExporting}
      export={{ enabled: true }}
      height={"100%"}
      allowFiltering={true}
      allowSorting={true}
      allowSortingBySummary={true}
      allowExpandAll={true}
      //Totales
      showColumnTotals={false}
      showColumnGrandTotals={true}
      //
      stateStoring={{ enabled: true, type: "sessionStorage", storageKey: keyPersistState.toLocaleUpperCase() }}
      loadPanel={{ enabled: true, showPane: true, text: 'Cargando' }}
      scrolling={{ mode: "virtual" }}
      fieldChooser={{ enabled: true, height: 700, title: 'Selecciona los campos' }}
      headerFilter={{ allowSearch: true, showRelevantValues: true, width: 400, height: 400 }}
      fieldPanel={{ showColumnFields: true, showDataFields: true, showFilterFields: true, showRowFields: true, allowFieldDragging: true, visible: true, texts: { filterFieldArea: 'Arrastra aqui los campos a filtrar' } }}
    />
  )
};

export default memo(PivotTable);
