import { memo, useCallback, useRef } from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import {
  CellClickEvent,
  CustomSummaryInfo,
  EditingStartEvent,
  ExportingEvent,
  SummaryTotalItem,
} from "devextreme/ui/data_grid";

type modeEditing = "batch" | "cell" | "row" | "form" | "popup";

interface IStateStoring {
  enabled: boolean;
  keyStorage?: string;
}
interface IExport {
  enabled: boolean;
  onExporting?: (e: ExportingEvent) => void;
}
export interface IOnSaved {
  data: any;
  key: any;
  type: string;
  insertBeforeKey?: any;
}
export interface IChangesSaving extends IOnSaved {
  insertAfterKey?: any;
}
export interface IOnSaving {
  changes: IChangesSaving[];
  cancel: boolean;
}
interface IEditing {
  modeEdit?: modeEditing;
  enableUpdating?: boolean;
  enableInsert?: boolean;
  enableDelete?: boolean;
  showIcons?: boolean;
  onStartEditing?: (e: EditingStartEvent<any, any>) => void;
  onSavedChanges?: (e: IOnSaved[]) => void;
  onSavingChanges?: (e: IOnSaving) => void;
  onCellSaving?: (e: IOnSaved[], columnKey: string) => void;
}
interface IProps {
  dataSource?: any[];
  enableSessionStorage?: IStateStoring;
  enableSearchPanel?: boolean;
  enableFilterRow?: boolean;
  enableGroupPanel?: boolean;
  enableHeaderFilter?: boolean;
  enableLoadingPanel?: boolean;
  enableColumnChooser?: boolean;
  enableExport?: IExport;
  columns?: (string | IColumnProps)[];
  totalesColumns?: SummaryTotalItem[];
  enableHoverState?: boolean;
  showRowLines?: boolean;
  enableEditing?: IEditing;
  height?: number | string;
  expandAllGrouping?: boolean;
  enablePaging?: boolean;
  enablePager?: boolean;
  visible?: boolean;
  pageSize?: number;
  calcultateCustomSummary?: (options: CustomSummaryInfo<any, any>) => void;
}

const TableGrid = (props: IProps) => {
  const {
    dataSource,
    enableSessionStorage = { enabled: false, keyStorage: "" },
    enableSearchPanel = false,
    enableGroupPanel = false,
    enableHeaderFilter = false,
    enableColumnChooser = false,
    enableLoadingPanel = false,
    enableFilterRow = false,
    enableExport = { enabled: false },
    columns,
    totalesColumns,
    enableHoverState = false,
    showRowLines = false,
    enableEditing,
    height,
    expandAllGrouping = false,
    enablePaging = true,
    visible = true,
    calcultateCustomSummary,
    pageSize = 25,
  } = props;

  const columnEditing = useRef<string>("");

  const onEditingStart = useCallback(
    (e: EditingStartEvent<any, any>): void => {
      enableEditing?.onStartEditing && enableEditing.onStartEditing(e);
    },
    [enableEditing]
  );

  const onSaved = useCallback(
    (e: IOnSaved[]): void => {
      enableEditing?.onSavedChanges && enableEditing.onSavedChanges(e);
      enableEditing?.onCellSaving &&
        enableEditing.onCellSaving(e, columnEditing.current);
    },
    [enableEditing]
  );

  const onSaving = useCallback(
    (e: IOnSaving): void => {
      enableEditing?.onSavingChanges && enableEditing.onSavingChanges(e);
    },
    [enableEditing]
  );

  const OnCellClick = useCallback((e: CellClickEvent<any, any>): void => {
    columnEditing.current = e.column.dataField || "";
  }, []);

  return (
    <DataGrid
      dataSource={dataSource}
      onDisposing={() =>
        sessionStorage.removeItem(
          enableSessionStorage.keyStorage!.toLocaleUpperCase()
        )
      }
      onExporting={enableExport.onExporting}
      columns={columns}
      visible={visible}
      allowColumnReordering={true}
      allowColumnResizing={true}
      autoNavigateToFocusedRow={true}
      wordWrapEnabled={true}
      columnAutoWidth={true}
      noDataText="No hay información disponible"
      showBorders={true}
      loadPanel={{
        enabled: enableLoadingPanel,
        text: "Cargando...",
        showPane: enableLoadingPanel,
      }}
      filterRow={{ visible: enableFilterRow }}
      paging={{ enabled: enablePaging, pageSize: pageSize }}
      stateStoring={{
        enabled: enableSessionStorage.enabled,
        type: "sessionStorage",
        storageKey: enableSessionStorage.keyStorage!.toLocaleUpperCase(),
      }}
      headerFilter={{ visible: enableHeaderFilter, allowSearch: true }}
      searchPanel={{
        visible: enableSearchPanel,
        highlightCaseSensitive: false,
        placeholder: "Buscar",
        width: 250,
      }}
      groupPanel={{
        visible: enableGroupPanel,
        emptyPanelText: "Arrastra aqui 👇 la columna a agrupar",
      }}
      grouping={{
        texts: { groupContinuesMessage: "Continua en la siguiente página" },
        autoExpandAll: expandAllGrouping,
      }}
      columnChooser={{
        enabled: enableColumnChooser,
        title: "Columnas a ocultar",
        emptyPanelText: "Arrastra aqui 👇 las columnas a ocultar",
      }}
      export={{ enabled: enableExport.enabled }}
      summary={{
        totalItems: totalesColumns,
        calculateCustomSummary: calcultateCustomSummary,
      }}
      hoverStateEnabled={enableHoverState}
      showRowLines={showRowLines}
      onEditingStart={onEditingStart}
      onSaved={(e) => onSaved(e.changes)}
      onSaving={(e) => onSaving({ cancel: e.cancel, changes: e.changes })}
      onCellClick={(e) => OnCellClick(e)}
      editing={{
        mode: enableEditing?.modeEdit,
        allowAdding: enableEditing?.enableInsert,
        allowUpdating: enableEditing?.enableUpdating,
        allowDeleting: enableEditing?.enableDelete,
        useIcons: enableEditing?.showIcons,
      }}
      height={height}
      width={"100%"}
    />
  );
};

export default memo(TableGrid);
