import { useBudget } from './hooks/useBudget'
import BudgetGrid from './components/BudgetGrid';
import './styles/estilos.scss';

const Budget = () => {

  const { error, loading, opsByMonth } = useBudget();

  return (
    <div>
      <BudgetGrid error={error} loading={loading} opsByMonth={opsByMonth} />
    </div>
  )
}

export default Budget