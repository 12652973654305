import { useAuth } from 'api/auth';
import { useCallback,  useEffect, useState } from 'react';

interface IMenuFilters {
  isOpenMenu: boolean | null;
  handlerStatusMenu: () => void;
  handlerCloseMenuFilter: () => void;
}

export const useMenuFilter = (): IMenuFilters => {
  const { User } = useAuth();
  const [isOpenMenu, setIsOpenMenu] = useState<boolean | null>(null);

  const handlerStatusMenu = useCallback(() => {
    if (isOpenMenu === null) {
      setIsOpenMenu(true);
      return
    }
    setIsOpenMenu(prev => !prev);
  }, [isOpenMenu]);

  const handlerCloseMenuFilter = useCallback(() => {   
    isOpenMenu && setIsOpenMenu(null);
  }, [isOpenMenu]);


  useEffect(() => {
    if (!User.isActive) return
    setIsOpenMenu(null);
  }, [User.isActive]);

  return {
    handlerCloseMenuFilter,
    handlerStatusMenu,
    isOpenMenu
  }
}
