import axios from "axios";
import { URL_API_FIREBASE_APP } from "consts/Services_URL";

export const SyncService = () => {
    return {
        syncAdd: async (faltantes: any[], User: any) => {
            const syncData = faltantes.map((faltante, index) => {
                var Tipo = "";
                switch (faltante.Tipo) {
                    case "Facturas de Pago":
                        Tipo = "BI";
                        break;
                    case "Facturas de Cobro":
                        Tipo = "IN";
                        break;
                    case "Pago":
                        Tipo = "PM";
                        break;
                    case "Cotizaciones":
                        Tipo = "QT";
                        break;
                    case "Embarque":
                        Tipo = "SH";
                        break;
                    case "Cliente":
                        Tipo = "CL";
                        break;
                    case "Proveedor":
                        Tipo = "VE";
                        break;
                    case "Cheque":
                        Tipo = "CK";
                        break;
                    default:
                        break;
                }

                return {
                    Type: Tipo,
                    GUID: faltante.Guid,
                    isDeleted: faltante.isDeleted,
                    isLast: index === faltantes.length - 1
                }
            });
            console.log("datos sync", faltantes, syncData, User.nameMagaya!)

            let batch = [];
            for (let i = 0; i < syncData.length; i++) {
                batch.push(syncData[i]);
                if (batch.length === 50 || i === syncData.length - 1) {
                    try {
                        console.log(batch)
                        await axios.get(`${URL_API_FIREBASE_APP}/insertAll?ItemLog=${JSON.stringify(batch)}&usernameMagaya=${User.nameMagaya!}`);
                        batch = [];
                    } catch (error) {
                        Promise.reject(error);
                    }
                }
            }
        }
    }

}