import ImageCard from "./components/images/imageCard";
import { useImages } from "./hooks/useImages";

const BannerImages = () => {

    const {loading, images} = useImages();

    return (
        <div className="grid-container-card-image">
            {
                loading && (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '100px'}}>
                    <div className="icon loading-icon loading-size-lg"></div>
                </div>
                )
            }
            {
                images.map((item, index) => (
                    <ImageCard 
                        key={index}
                        index={index + 1}
                        urlImage={item.cMotion_Imagen_Url}
                    />
                ))
            }
        </div>
    );
}

export default BannerImages;