import { RemoteFacturacionAnualProveedoresAdapter } from "adapters/Proveedores";
import { useAuth } from "api/auth";
import { ICatalogosFacturacionproveedores, ProveedoresService } from "api/proveedores";
import { IFilterChange, IFilterData, IFilterMultiple } from "components/interfaces/Interfaces";
import { IFiltersAnualProveedores, IGetDataProveedores } from "interfaces/IProveedores";
import { useCallback, useEffect, useState } from "react";
import { ExportingEvent } from 'devextreme/ui/pivot_grid';
import { onExportingPivot } from 'utils/Functions';



interface IUseFacturacionAnualProveedores {
    facturas: IGetDataProveedores;
    catalogos: IGetCatalogosFacturacionProveedor;
    handlerOnSelectChange: (e: IFilterChange) => void;
    handleFiltrado: () => Promise<void>;
    onExporting: (e: ExportingEvent) => void;
}

export interface IGetCatalogosFacturacionProveedor{
    años?: IFilterMultiple;
    moneda?: IFilterData;
    proveedores?: IFilterMultiple;
    error?: string | null;
    loading: boolean;
}

const defaultFilters: IFiltersAnualProveedores = {
    años: [],
    moneda: "",
    proveedores: [],
}

export const useFacturacionAnualProveedor = (): IUseFacturacionAnualProveedores => {
    const { User } = useAuth();
    const [filters, setFilters] = useState<IFiltersAnualProveedores>(defaultFilters);
    const [catalogos, setCatalogos] = useState<IGetCatalogosFacturacionProveedor>({ loading: false });
    const [facturas, setFacturas] = useState<IGetDataProveedores>({ loading: false });

    const onExporting = useCallback((e: ExportingEvent) => {
        return onExportingPivot(e, "FacturacionAnualProveedores", User.divisionSeleccionada!);
    }, [User]);

    const handleFiltrado = useCallback(async () : Promise<void> => {
        setFacturas({loading:true})
        const filtersAdapter: string = JSON.stringify(RemoteFacturacionAnualProveedoresAdapter(filters))
        const { result, data, error } = await ProveedoresService(User).getDataFacturacionProveedor(filtersAdapter);
        if(!result){
            setFacturas({ error:error, loading:false})
            return
        }
        setFacturas({ data:data, loading:false})
    },[filters, User])

    const handlerOnSelectChange = (e:IFilterChange): void =>{
        const {key, value} = e;
        setFilters(prev => {
            return{
                ...prev,
                [key]: value
            }
        })
    }

  /* Obtener los catálogos */
    useEffect(() => {
        const getCatalogos = async (): Promise<void> => {
            setCatalogos({ loading: true });
            const { result, data, error } = await ProveedoresService(User).getCatalogosFacturacionAnual();
            if (!result) {
                setCatalogos({ error: error, loading: false });
                return
            }
            const filtersCatalogos: ICatalogosFacturacionproveedores = data!;
            setCatalogos({ años:filtersCatalogos.años, proveedores:filtersCatalogos.proveedores, moneda:filtersCatalogos.moneda, loading: false });
        }
    getCatalogos();
    }, [User]);

  /**OBTENER DATA INICIAL */
    useEffect(() => {
        const getInitialData = async (): Promise<void> => {
            setFacturas({ loading: true });
            const filtersAdapter: string = JSON.stringify(RemoteFacturacionAnualProveedoresAdapter(defaultFilters));
            const { result, data, error } = await ProveedoresService(User).getDataFacturacionProveedor(filtersAdapter);
            if (!result) {
                setFacturas({ error: error, loading: false });
                return
            }
            setFacturas({ data: data, loading: false });
        };
        getInitialData();
    }, [User]);

    return {
        facturas,
        catalogos,
        handlerOnSelectChange,
        handleFiltrado,
        onExporting
    };
};