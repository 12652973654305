import { SharePointIcon, WorkyIcon, OneDriveIcon, CallPickerIcon } from 'images/Index';

export const Meses: string[] = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];
export const Dias: string[] = [
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo"
];

export const Años = (): number[] => {
  const añoActual: number = new Date().getUTCFullYear();
  const añoInicio: number = 2021;
  const años: number[] = [];
  for (let año: number = añoInicio; año <= añoActual; año++) {
    años.push(año);
  }
  return años;
}

export const fechaActual: Date = new Date();


export interface IBoxOpcion {
  name: string;
  className: string;
  img: string;
  enlace?: string;
  onClick: (name: string) => void;
}

const OnClickRedireccionar = (enlace: string): void => {
  window.open(enlace);
};

export const arrayBoxOpciones: IBoxOpcion[] = [
  {
    name: "SharePoint",
    img: SharePointIcon,
    enlace: "https://crossmotionmx.sharepoint.com/",
    onClick: OnClickRedireccionar,
    className: "img-opcion"
  },
  {
    name: "Worky",
    img: WorkyIcon,
    enlace: "https://app.worky.mx/dashboard",
    onClick: OnClickRedireccionar,
    className: "img-opcion"
  },
  {
    name: "OneDrive",
    img: OneDriveIcon,
    enlace: "https://crossmotionmx-my.sharepoint.com",
    onClick: OnClickRedireccionar,
    className: "img-opcion"
  },
  {
    name: "Callpicker",
    img: CallPickerIcon,
    enlace: "https://my.callpicker.com/",
    onClick: OnClickRedireccionar,
    className: "img-opcion"
  }
]

