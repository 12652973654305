import { useImages } from 'pages/config/hooks/useImages';
import { useState, useEffect } from 'react';

const Carousel =  () => {
  const {images} = useImages();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [hasImages, setHasImages] = useState(false);
  const [actualImg, setActualImg] = useState<any>("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let settedImgs: any[] = [];
  
  settedImgs = images.filter((item) => item.cMotion_Imagen_Url !== "" && item.cMotion_Imagen_Url !== null && item.cMotion_Imagen_Url !== undefined);
  
  useEffect(() => {
      const maxLength = settedImgs.length;
      const interval = setInterval(() => {
        if (currentImageIndex === maxLength - 1) {
            setCurrentImageIndex(0);
        } else {
            setCurrentImageIndex(currentImageIndex + 1);
        }
        }, 5000);
        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentImageIndex]);
    
    useEffect(() => {
        const maxLength = settedImgs.length;
        if(maxLength > 0){
            setActualImg(settedImgs[currentImageIndex].cMotion_Imagen_Url);
            setHasImages(true);
          }
    }, [currentImageIndex, settedImgs]);
    
  return (
    <div>
        {
            !hasImages ? (
            <div>
            </div>
            )
            :(
            <div className="carousel">
                <img src={`https://firebasestorage.googleapis.com/v0/b/crossmotion.appspot.com/o/images%2Fbanners%2F${actualImg}?alt=media&token=bc48bda8-a183-4a10-a838-ad22b203618d`} alt={`Banner ${currentImageIndex + 1}`} className='img' />
            </div>
            )
        }
    </div>
  );
};

export default Carousel;
