import moment from "moment";
import "../styles/estilos.scss";
import { Form } from "devextreme-react";
import { ButtonItem, GroupItem, SimpleItem } from "devextreme-react/form";

const FormTarea = ({
  tarea,
  indexTab,
  entidades,
  closePopup,
  updateTarea,
  responsables,
}) => {
  const urgenciaItems = [
    { Urgencia_Id: 1, urgencia: "Baja" },
    { Urgencia_Id: 2, urgencia: "Media" },
    { Urgencia_Id: 3, urgencia: "Alta" },
  ];
  const estatusItems = [
    { Estatus_Id: 1, estatus: "Pendiente" },
    { Estatus_Id: 2, estatus: "En proceso" },
    { Estatus_Id: 3, estatus: "Terminada" },
  ];
  const cancelPopup = () => {
    closePopup();
  };

  const optionsComponents = {
    responsablesOptions: {
      searchEnabled: true,
      items: responsables || [],
      displayExpr: "colaborador",
      valueExpr: "colaborador_id",
      placeholder: "--Selecciona--",
      noDataText: "No hay información",
    },
    entidadesOptions: {
      searchEnabled: true,
      items: entidades || [],
      valueExpr: "Entidad_Id",
      placeholder: "--Selecciona--",
      noDataText: "No hay información",
      displayExpr: "Entidad_Razon_Social",
    },
    urgenciaOptions: {
      searchEnabled: true,
      displayExpr: "urgencia",
      valueExpr: "Urgencia_Id",
      items: urgenciaItems || [],
      placeholder: "--Selecciona--",
      noDataText: "No hay información",
    },
    estatusOptions: {
      searchEnabled: true,
      displayExpr: "estatus",
      valueExpr: "Estatus_Id",
      items: estatusItems || [],
      placeholder: "--Selecciona--",
      noDataText: "No hay información",
    },
    formatFecha: {
      format: "dd/MMM/yyyy",
    },
    btnCloseOptions: {
      type: "normal",
      width: "250px",
      text: "CERRAR",
      onClick: () => cancelPopup(),
    },
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const nota = tarea.Tarea_Nota;
    const tareaId = tarea.Tarea_Id;
    const nombre = tarea.Tarea_Nombre;
    const entidadId = tarea.Entidad_Id;
    const estatusId = tarea.Estatus_Id;
    const urgenciaId = tarea.Urgencia_Id;
    const responsableId = tarea.colaborador_id;
    const fechaTermino = tarea.Tarea_Fecha_Termino;
    const fechaActualizacion = moment().utc().format("YYYY-MM-DD 00:00:00");

    const getGuidResponsable = (colaboradorId, responsables) => {
      const responsable = responsables.find(
        (r) => r.colaborador_id === colaboradorId
      );
      return responsable ? responsable.guid : null;
    };

    const getGuidEntidad = (entidadId, entidades) => {
      const entidad = entidades.find((e) => e.Entidad_Id === entidadId);
      return entidad ? entidad.Entidad_Guid : null;
    };

    const getUrgencia = (urgenciaId, urgenciaItems) => {
      const urgencia = urgenciaItems.find((u) => u.Urgencia_Id === urgenciaId);
      return urgencia ? urgencia.urgencia : null;
    };

    const getEstatus = (estatusId, estatusItems) => {
      const estatus = estatusItems.find((e) => e.Estatus_Id === estatusId);
      return estatus ? estatus.estatus : null;
    };

    const guidResponsable = getGuidResponsable(responsableId, responsables);
    const guidEntidad = getGuidEntidad(entidadId, entidades);
    const urgencia = getUrgencia(urgenciaId, urgenciaItems);
    const estatus = getEstatus(estatusId, estatusItems);

    const tareaUpdated = {
      Tarea_Nota: nota,
      Estatus: estatus,
      Tarea_Id: tareaId,
      Urgencia: urgencia,
      Tarea_Nombre: nombre,
      Entidad_Guid: guidEntidad,
      Tarea_Fecha_Termino: fechaTermino,
      Colaborador_Guid: guidResponsable,
      Tarea_Fecha_Actualizacion: fechaActualizacion,
    };
    updateTarea(tareaUpdated);
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Form
          key="id"
          formData={tarea}
          scrollByContent={true}
          scrollingEnabled={true}
          showValidationSummary={true}
          colCountByScreen={colCountByScreen}
        >
          <GroupItem cssClass="form-group" colCount={1}>
            <GroupItem colSpan={1}>
              <SimpleItem editorType="dxTextBox" dataField="Tarea_Nombre" />
              <SimpleItem
                editorType="dxSelectBox"
                dataField="colaborador_id"
                editorOptions={optionsComponents.responsablesOptions}
              />
              <SimpleItem
                dataField="Entidad_Id"
                editorType="dxSelectBox"
                editorOptions={optionsComponents.entidadesOptions}
              />
            </GroupItem>

            <GroupItem colSpan={1}>
              <SimpleItem
                dataField="Urgencia_Id"
                editorType="dxSelectBox"
                editorOptions={optionsComponents.urgenciaOptions}
              />
              <SimpleItem
                dataField="Estatus_Id"
                editorType="dxSelectBox"
                editorOptions={optionsComponents.estatusOptions}
              />
              <SimpleItem
                editorType="dxDateBox"
                dataField="Tarea_Fecha_Termino"
                editorOptions={optionsComponents.formatFecha}
              />
            </GroupItem>
          </GroupItem>
          <GroupItem colCount={1}>
            <SimpleItem
              colSpan={1}
              dataField="Tarea_Nota"
              editorType="dxTextArea"
            />
          </GroupItem>
          <GroupItem colCount={2} cssClass={"btnToolbar-group"}>
            <ButtonItem
              verticalAlignment="bottom"
              horizontalAlignment="center"
              cssClass={"btnToolbar-group"}
              buttonOptions={{
                width: "250px",
                text: "GUARDAR",
                type: "default",
                useSubmitBehavior: true,
              }}
            />
            <ButtonItem
              verticalAlignment="bottom"
              horizontalAlignment="center"
              buttonOptions={optionsComponents.btnCloseOptions}
            />
          </GroupItem>
        </Form>
      </form>
    </div>
  );
};

const colCountByScreen = {
  sm: 1,
  md: 1,
  lg: 1,
};

export default FormTarea;
