import { URL_API_FIREBASE_APP } from "consts/Services_URL";
import { AxiosRequestConfig } from "axios";
import { IUser } from "interfaces/IUsuario";
import { IEmbarques, IDesgloseServiciosEmbarque } from 'interfaces/IEmbarques';
import { getDataAsync } from './fetch';
import { IResponse } from './interfaces/interfaces';

interface IResponseEmbarques extends IResponse {
    data: IEmbarques[];
}

interface IResponseDesgloseServiciosEmbarque extends IResponse {
    data: IDesgloseServiciosEmbarque[];
}

export const EmbarquesService = (User: IUser) => {
    const { token } = User;
    const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } };

    return {
        getEmbarquesDocumentosContablesPendientes: async (): Promise<IResponseEmbarques> => {
            const response = await getDataAsync(`${URL_API_FIREBASE_APP}/documentosContablesAbiertosEmbarques`, headers);
            return {
                data: response.data.payload
            } as IResponseEmbarques;
        },
        getDesgloseServiciosEmbarque: async (numero_factura: string): Promise<IResponseDesgloseServiciosEmbarque> => {
            const response = await getDataAsync(`${URL_API_FIREBASE_APP}/desgloseServiciosEmbarques?numero_factura=${numero_factura}`, headers);
            return {
                data: response.data.payload.recordset
            } as IResponseDesgloseServiciosEmbarque;
        }
    }
}