import { memo, useState, useMemo, useCallback } from 'react';
import { DropDownBox, DataGrid } from 'devextreme-react';
import { IFilterProps } from './interfaces/Interfaces';
import { FilterMultipleDropBox } from 'utils/Functions';
import './styles/estilos.scss';

const Filter = ({ keyFilter, selectionState, items, onChange, title, width, displayFilter, styleDropBox, mode }: IFilterProps) => {

  const [selectionsItems, setSelectionsItems] = useState<any[]>(selectionState || []);

  const dataSource = useMemo(() => {
    return FilterMultipleDropBox(items || [], keyFilter || "");
  }, [items, keyFilter]);

  const handlerDataGridChanged = useCallback((e: any): void => {
    const rowsSelected: string[] = (e.selectedRowKeys.length && e.selectedRowKeys) || [];
    setSelectionsItems(rowsSelected);
    onChange && onChange({ key: keyFilter || "", value: rowsSelected });
  }, [keyFilter, onChange])

  const handlerDropChanged = useCallback((e: any): void => {
    const rowsSelected: string[] = e.value || [];
    setSelectionsItems(rowsSelected);
    onChange && onChange({ key: keyFilter || "", value: rowsSelected })
  }, [keyFilter, onChange])

  const Grid = () => {
    return (
      <DataGrid
        key='key'
        noDataText='No hay información disponible'
        dataSource={dataSource}
        selection={{ mode: mode || 'multiple', selectAllMode: 'page'}}
        scrolling={{ mode: 'standard' }}
        pager={{ allowedPageSizes: "auto", visible: true, showNavigationButtons: true}}
        paging={{ enabled: true, pageSize: 4 }}       
        columns={[{ dataField: displayFilter || keyFilter, caption: title }]}
        hoverStateEnabled={true}
        selectedRowKeys={selectionsItems}
        onSelectionChanged={handlerDataGridChanged}
        searchPanel={{ visible: true, highlightCaseSensitive: false, placeholder: 'Buscar', width: 250 }}
        
      />
    )
  }

  return (
    <div className='select-item'>
      <div className="item-label title-filter">{title}</div>
      <div className="item-value">
        <DropDownBox
          width={width}
          value={selectionsItems}
          valueExpr={keyFilter}
          deferRendering={true}
          displayExpr={displayFilter || keyFilter}
          placeholder="--Selecciona--"
          showClearButton={true}
          dataSource={dataSource}
          onValueChanged={handlerDropChanged}
          contentRender={Grid}
          stylingMode={styleDropBox}
        />
      </div>
    </div>
  )
}

export default memo(Filter)