import { memo, ReactElement } from 'react';
import ErrorPage from 'pages/Error';
import FiltersMultipleGrid from 'components/FiltersMultipleGrid';
import { IFilterChange } from 'components/interfaces/Interfaces';
import { IGetCatalogos } from 'interfaces/IHome';

interface IProps {
  catalogos: IGetCatalogos,
  handlerOnSelectChange: (e: IFilterChange) => void;
  itemsByRow?: number;
  children?: ReactElement | ReactElement[];
}

const FilterOperaciones = ({ catalogos, handlerOnSelectChange, itemsByRow, children }: IProps) => {
  const { loading, data, error } = catalogos;

  if (loading) {
    return <p>CARGANDO...</p>
  }
  if (error) {
    return <ErrorPage errorMessage={error} />
  }

  return (
    <div>
      <FiltersMultipleGrid
        items={data}
        onItemsSelected={handlerOnSelectChange}
        itemsByRow={itemsByRow}
      >
        {children}
      </FiltersMultipleGrid>
    </div>
  )
}

export default memo(FilterOperaciones)