import { URL_API_FIREBASE_APP } from "consts/Services_URL";
import BudgetMensual from './components/BudgetMensual';
import BudgetEstrategia from './components/BudgetEstrategia';
import ModoTransporte from './components/ModoTransporte';
import TopVendedores from './components/TopVendedores';
import { BudgetProvider } from 'contexts/EstrategiasContext';
import './styles/estilos.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

const Home = () => {
  const [lastSync, setLastSync] = useState<string | null>(null);

  useEffect(() => {
    const getLastSync = async () => {
      const data = await axios.get(`${URL_API_FIREBASE_APP}/getLastSync`);
      setLastSync(moment(data.data.payload.lastExecution).format('DD/MM/YYYY HH:mm:ss'));
    }
    getLastSync();
  }, []);

  return (
    <div className='container-home'>
      <p 
        style={{
          position: 'absolute',
          top: '20px',
          right: '30px',
          color: 'black',
          fontSize: '12px',
          zIndex: 1000
        }}
      >
        Última sincronización: {lastSync}
      </p>
      <BudgetMensual className='budget-card' />
      <TopVendedores className='top-card' />
      <ModoTransporte className='modos-card' />
      <BudgetProvider>
        <BudgetEstrategia className='estrategias-card' />
      </BudgetProvider>
    </div>
  )
}

export default Home