import { useAuth } from "api/auth";
import { IFilterChange, IFilterData } from "components/interfaces/Interfaces";
import { URL_API_COMISIONES } from "consts/Services_URL";
import { IFilterComisionesPagar, IComisionesPagadas, IRemoteVendedoresComisiones } from "interfaces/IComisiones";
import { useCallback, useMemo, useState } from "react";
import { createNewFilter } from "utils/Functions";
import { ComisionesService } from 'api/comisiones';
import notify from "devextreme/ui/notify";
import { CSNotificacion } from "consts/Comisiones";
import { useGetDataWithAuth } from 'hooks/useGetData';
import { ITabs } from "functions/Comisiones";

interface IUseComisiones {
  filters: IFilterComisionesPagar;
  vendedores: IFilterData;
  comisiones: IComisionesPagadas;
  loading: boolean;
  tabVisible: ITabs;
  handlerOnChangeFecha: (e: IFilterChange) => void;
  handlerOnChangeVendedor: (e: IFilterChange) => void;
  handlerSearchComisiones: () => void;
  handlerOnSelectTab: (tab: ITabs) => void;
}
const fechaActual: Date = new Date();
const defaultFilters: IFilterComisionesPagar = {
  fecha: {
    año: fechaActual.getUTCFullYear(),
    mes: fechaActual.getMonth()
  },
  vendedor: ""
}
const defaultComisiones: IComisionesPagadas = {
  porcentaje: 0,
  comisionesPagadas: [],
  comisionesSubordinados: []
}
const defaultTab: ITabs = {
  text: "Vendedor"
}
interface IGetData {
  data?: IRemoteVendedoresComisiones[];
  loading: boolean;
  error: string | null;
}

export const useComisiones = (): IUseComisiones => {
  const { User } = useAuth();
  const [comisiones, setComisiones] = useState<IComisionesPagadas>(defaultComisiones)
  const [filters, setFilters] = useState<IFilterComisionesPagar>(defaultFilters);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabVisible, setTabVisible] = useState<ITabs>(defaultTab);
  const { data = [] }: IGetData =
    useGetDataWithAuth(`${URL_API_COMISIONES}catalogos/vendedor/subordinados?vendedor=${User.nameMagaya}`, User.token!);

  const comisionesService = useMemo(() => {
    return ComisionesService(User);
  }, [User]);

  const vendedores: IFilterData = useMemo(() => {
    return createNewFilter(
      data?.map((vendedor) => { return vendedor.vendedorCotizacion }),
      "",
      "vendedor");
  }, [data]);
  /*Asignar el mes y el año a los filtros */
  const handlerOnChangeFecha = (e: IFilterChange): void => {
    const { key, value } = e;
    setFilters(prev => {
      return {
        ...prev,
        fecha: {
          ...prev.fecha,
          [key]: value
        }
      }
    });
  }
  /*Asignar las vendedor seleccionada a los filtros */
  const handlerOnChangeVendedor = (e: IFilterChange): void => {
    setFilters(prev => {
      return {
        ...prev,
        vendedor: e.value
      }
    })
  }
  const handlerSearchComisiones = useCallback(async (): Promise<void> => {
    if (!filters.vendedor.trim()) {
      notify(CSNotificacion.MissingSaller, "warning", 3000);
      return
    }
    setComisiones(defaultComisiones);
    setLoading(true);
    setTabVisible(defaultTab);
    const dataCS = await comisionesService.getComisionesPagadas(filters);
    if (!dataCS.result) {
      notify(CSNotificacion.ErrorGetComissionsPaid, "error", 2000);
    }
    if (dataCS.result) {
      const { comisiones, porcentaje } = dataCS.data;
      comisiones.length === 0 && notify(CSNotificacion.EmptyComissionsPaid, "warning", 2000);
      /*OBTENEMOS LAS COMISIONES DE LOS SUBORDINADOS */
      const dataCSSubordinados = await comisionesService.getComisionesPagadasSubordinados(filters);
      if (!dataCSSubordinados.result) {
        notify(CSNotificacion.ErrorGetComissionsSubordinates, "error", 2000);
      }
      const { data } = dataCSSubordinados;
      /*Asignamos las informacion obtenida*/
      setComisiones({ porcentaje, comisionesPagadas: comisiones, comisionesSubordinados: data?.comisiones || [] });
    }
    setLoading(false);
  }, [comisionesService, filters]);

  const handlerOnSelectTab = useCallback((tab: ITabs): void => {
    setTabVisible(tab);
  }, []);


  return {
    filters,
    vendedores,
    loading,
    tabVisible,
    comisiones,
    handlerOnChangeFecha,
    handlerOnChangeVendedor,
    handlerSearchComisiones,
    handlerOnSelectTab
  }
}