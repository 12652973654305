import { CSSProperties, MouseEvent, memo } from "react";
import { ColorHoverButton } from "./interfaces/Interfaces";
import "./styles/estilos.scss";

type button = "button" | "submit" | "reset" | undefined;

interface IButton {
  text?: string;
  className?: string;
  style?: CSSProperties;
  icon?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  type?: button;
  disabled?: boolean;
  width?: number | string;
  height?: number | string;
  iconHover?: ColorHoverButton;
  ref?: React.LegacyRef<HTMLButtonElement>;
}

const Button = (Props: IButton) => {
  const {
    text,
    icon,
    type = "button",
    disabled = false,
    className,
    style,
    onClick,
    width,
    height,
    iconHover,
    ref,
  } = Props;
  const handlerOnClick = (e: MouseEvent<HTMLButtonElement>): void => {
    onClick && onClick(e);
  };

  return (
    <button
      ref={ref}
      className={`button ${
        iconHover === "Claro" ? "transition-claro" : "transition-oscuro"
      } ${disabled && "disabled"} ${className}`}
      style={{ ...style, width, height, gap: !text ? "0px" : "12px" }}
      onClick={(e) => handlerOnClick(e)}
      type={type}
      disabled={disabled}
    >
      <div className={`icon ${icon}`}></div>
      <b>{text}</b>
    </button>
  );
};

export default memo(Button);
