import { ajustarDecimales } from "utils/Formatos"


export const _FieldFacturacionClientes: any[] =[
    {
        caption: 'Razón Social',
        width: 450,
        dataField: 'entidad_Razon_Social',
        area: 'row',
        expanded: false,
        sortBySummaryField: 'Total',
    },
    
    {
        caption: 'Embarque',
        dataField: 'documento_Contable_Embarque_Numero',
        width: 150,
        expanded: false,
        area: 'row'
    },
    
    {
        caption: 'Producto',
        dataField: 'producto_Descripcion',
        width: 150,
        expanded: true,
        area: 'row'
    },
    
    {
        dataField: 'documento_Contable_Fecha_Creacion',
        dataType: 'date',
        area: 'column',
        expanded: true,
        width: 74
    },
    
    {
        groupName: 'documento_Contable_Fecha_Creacion',
        groupInterval: 'quarter',
        visible: false,
    },
    
    {
        groupName: 'documento_Contable_Fecha_Creacion',
        groupInterval: 'month',
        visible: true,
    },

    {
        caption: 'Monto',
        dataField: 'monto',
        summaryType: 'sum',
        width: 80,
        format: ajustarDecimales,
        area: 'data',
    },

    {
        caption: 'Moneda',
        dataField: 'moneda_Codigo',
        width: 150,
        expanded: true,
        area: 'column'
    }

]


export const _FieldFacturacionProveedores: any[] =[
    {
        caption: 'Razón Social',
        width: 450,
        dataField: 'entidad_Razon_Social',
        area: 'row',
        expanded: false,
        sortBySummaryField: 'Total',
    },
    
    {
        caption: 'Embarque',
        dataField: 'numero_Embarque',
        width: 150,
        expanded: false,
        area: 'row'
    },
    
    {
        caption: 'Producto',
        dataField: 'producto_Descripcion',
        width: 150,
        expanded: true,
        area: 'row'
    },
    
    {
        dataField: 'documento_Contable_Fecha_Creacion',
        dataType: 'date',
        expanded: true,
        area: 'column',
        visible: true
    },
    
    {
        groupName: 'documento_Contable_Fecha_Creacion',
        groupInterval: 'quarter',
        expanded: false,
        visible: false,
    },
    
    {
        groupName: 'documento_Contable_Fecha_Creacion',
        groupInterval: 'month',
        visible: true,
    },
        
    {
        caption: 'Monto',
        dataField: 'monto_Real_Factura',
        summaryType: 'sum',
        width: 80,
        format: ajustarDecimales,
        area: 'data',
    },

    {
        caption: 'Moneda',
        dataField: 'moneda_Facturado',
        width: 150,
        area: 'column',
    }

]