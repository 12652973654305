import { memo, useEffect } from 'react';
import PivotTable from 'components/Pivot';
import ErrorPage from 'pages/Error';
import { IGetDataOperaciones } from 'interfaces/IOperaciones';
import { ExportedEvent } from 'devextreme/ui/pivot_grid';

interface IProps {
  dataOperaciones: IGetDataOperaciones;
  fields?: any[];
  keyLocalStorage?: string;
  onExporting?: (e: ExportedEvent) => void;
}

const PivotOperaciones = ({ dataOperaciones, fields, keyLocalStorage, onExporting }: IProps) => {
  const { loading, data, error } = dataOperaciones;

  console.log(data);

  useEffect(() => {
    return () => {
      keyLocalStorage && localStorage.removeItem(keyLocalStorage);
    }
  }, [keyLocalStorage]);

  if (loading) {
    return <p>CARGANDO...</p>
  }
  if (error) {
    return <ErrorPage errorMessage={error} />
  }

  return (
    <PivotTable data={data} fieldPivot={fields} keyPersistState={keyLocalStorage} onExporting={onExporting} />
  )
}

export default memo(PivotOperaciones)