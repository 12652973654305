
interface IError {
  errorMessage?: string;
}

const Error = ({ errorMessage }: IError) => {
  return (
    <div><h1>{errorMessage}</h1></div>
  )
}

export default Error