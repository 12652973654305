import { memo } from 'react';
import { IFilterChange } from 'components/interfaces/Interfaces';
import { ICatalogosBudget } from 'interfaces/IConfiguracion';
import { Button, Select } from 'components/Index';
import { CFStatusBudget } from 'consts/Configuracion';

interface IProps {
  handleOnSaveBudget: () => Promise<void>;
  handlerOnFilterChanged: (e: IFilterChange) => void;
  handlerOnSearchBudget: () => Promise<void>;
  handlerOnAddYearBudget: () => Promise<boolean>;
  catalogosBudget?: ICatalogosBudget;
  statusBudget: CFStatusBudget;
}

const FilterConfig = (Props: IProps) => {
  const {
    handleOnSaveBudget,
    handlerOnFilterChanged,
    handlerOnSearchBudget,
    handlerOnAddYearBudget,
    catalogosBudget,
    statusBudget
  } = Props;

  return (
    <div className='container-filters'>
      <Select
        title='Año'
        item={catalogosBudget?.anios}
        showClearButton={false}
        keyValue="aniosBudget"
        display="aniosBudget"
        width={"100%"}
        onChangeSelect={handlerOnFilterChanged}
      />
      <Button
        text={statusBudget === CFStatusBudget.Searching ? "Buscando..." : "Buscar"}
        icon='fas fa-search'
        className='btnSearch'
        onClick={handlerOnSearchBudget}
        width={"100%"}
        iconHover='Claro'
        disabled={statusBudget === CFStatusBudget.Searching}
      />
      <Button
        text={statusBudget === CFStatusBudget.Saving ? "Guardando..." : "Guardar"}
        icon='fas fa-save'
        onClick={handleOnSaveBudget}
        width={"100%"}
        iconHover='Claro'
        style={{ backgroundColor: "#00D55A" }}
        disabled={statusBudget === CFStatusBudget.Searching || statusBudget === CFStatusBudget.Saving}
      />
      <Button
        text={statusBudget === CFStatusBudget.Saving ? "Añadir año..." : "Añadir año"}
        icon='fas fa-plus'
        onClick={handlerOnAddYearBudget}
        width={"100%"}
        iconHover='Claro'
        style={{ backgroundColor: "#00D55A" }}
        disabled={statusBudget === CFStatusBudget.Searching || statusBudget === CFStatusBudget.Saving}
      />
    </div>
  )
}

export default memo(FilterConfig)