import type { IColumnProps } from "devextreme-react/data-grid";

export const ColumsComparativo: IColumnProps[] = [
  {
    caption: "Guid",
    dataField: "GUID",
    dataType: "string",
    alignment: "center",
  },
  {
    caption: "Tipo",
    dataField: "type",
    dataType: "string",
    alignment: "center",
  },
  {
    caption: "Fecha Log Magaya",
    dataField: "date",
    dataType: "dateTime",
    alignment: "center",
  },
];
