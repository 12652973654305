import { columnsConfEstrategias } from 'api/configuracion'
import TableGrid, { IOnSaved } from 'components/TableGrid'
import { IGetDataConfiguracionEstrategias, IRemoteConfiguracionEstrategias, IRemoteGetCatalogosEstrategias } from 'interfaces/IConfiguracion'
import { useMemo } from 'react'

interface IProps {
    dataTable : IGetDataConfiguracionEstrategias;
    catalogos: IRemoteGetCatalogosEstrategias;
    onSaved(data:IRemoteConfiguracionEstrategias):void;
}

const GridEstrategias = (Props:IProps) => {

    const { dataTable:{data}, catalogos:{segmentacionCross: cross, segmentacionMTI: mti}, onSaved } = Props
    
    const columsGrid = useMemo(() =>{
        if(cross && mti){
            return columnsConfEstrategias(cross!,mti!);
        }
    },[cross,mti]);

    const handleChange = (e: IOnSaved[]) => {
        if(e.length === 0){
            return
        }
        const {data, type} = e[0];
        switch(type){
            case 'update':
                onSaved(data)
                break;
            default:
                return
        }
    }

    return (
        <TableGrid
            enableHeaderFilter={true}
            enableSearchPanel={true}
            dataSource={data}
            columns={columsGrid}
            enableEditing={{
                modeEdit: "row", 
                showIcons: true,
                enableInsert: false,
                enableDelete: false,
                enableUpdating: true,
                onSavedChanges: handleChange
            }}
        />
    )
}

export default GridEstrategias