export enum ClientStatus {
  Searching = 1,
  Synchronizing = 2,
  Updating = 3,
  Stop = 4
}

export enum ClientNotify {
  EmptyNumberShipment = "Falta en número de embarque",
  ErrorGetDataShipment = "Ocurrio un error al obtener la información del embarque",
  EmptyDataShipment = "No se encontró información del embarque",
  ErrorSyncShipment = "No se pudo sincronizar el embarque",
  ErrorUpdateShipment = "No se pudo actualizar la información del embarque",
  SuccessSyncShipment = "Se sincronizo correctamente el embarque"
}

export enum ProfitSyncMessage {
  Synchronizing = "Sincronizando con magaya",
  Updating = "Actualizando información",
  Sync = "Sincronizar"
}