import { columsConfTipos } from 'api/configuracion'
import TableGrid from 'components/TableGrid'
import { IRemoteConfiguracionNominaTipo } from 'interfaces/IConfiguracion';

interface IProps {
  configuracionData : IRemoteConfiguracionNominaTipo[],
}

const GridTipoNomina = (Props:IProps) => {

  const {configuracionData} = Props;

  return (
    <div className='card-container'>
      <h2>Grid Tipo Nomina</h2>
      <TableGrid 
        showRowLines={true}
        columns={columsConfTipos}
        dataSource={configuracionData}
      />
    </div>
  )
}

export default GridTipoNomina