export type TabPerfil = "General" | "IT" | "Permiso" | "Histórico";

export enum IndexTab {
    General = 0,
    Histórico = 3
}

export enum IndexTabSuperAdmin {
    General = 0,
    IT = 1,
    Permiso = 2,
    Histórico = 3
}