import { TabPerfil } from "consts/IT";

export interface ITabs {
    text: TabPerfil
}

export const tabsPerfil = (SuperAdmin: boolean): ITabs[] => {
    const longtabs: ITabs[] = [
        { text: 'General' }
    ]
    if (SuperAdmin) {
        longtabs.push({ text: 'IT' })
        longtabs.push({ text: 'Permiso' })
    }
        longtabs.push({ text: 'Histórico' })
    return longtabs;
}