import { useCallback, useMemo, useState, useEffect } from "react";
import { useAuth } from "api/auth";
import { useGetDataWithAuth } from "hooks/useGetData";
import { IReporteComisiones, IFilterReporteComisiones, IImpuestosNomina } from "interfaces/ITalentoHumano";
import { createNewFilter } from "utils/Functions";
import { IFilterChange, IFilterData } from 'components/interfaces/Interfaces';
import { URL_API_TH } from "consts/Services_URL";
import { NominaService, ReportsService } from "api/nomina";
import notify from "devextreme/ui/notify";
import { Meses } from "utils/Global";

interface IUseReportComisiones {
  meses?: IFilterData;
  anios?: IFilterData;
  handlerOnSelectChange: (e: IFilterChange) => void;
  handlerOnSearchComisiones: () => Promise<void>;
  reporteComisiones: IReporteComisiones[];
  impuestos: IImpuestosNomina;
  handlerOnDownloadComisiones: () => Promise<void>;
  loading: boolean;
  error: string | null;
}

const fechaActual: Date = new Date();

const DEFAULT_FILTERS: IFilterReporteComisiones = {
  anio: fechaActual.getUTCFullYear()
}

export const useReportComisiones = (status: boolean): IUseReportComisiones => {

  const { User } = useAuth();
  const { data: mesesData } = useGetDataWithAuth(status ? `${URL_API_TH}catalogos/meses` : null, User.token);
  const { data: aniosData } = useGetDataWithAuth(status ? `${URL_API_TH}catalogos/anios` : null, User.token);
  const [impuestos, setImpuestos] = useState<IImpuestosNomina>({ iva: 0, porcentajeFacturacion: 0 });
  const [filters, setFilters] = useState<IFilterReporteComisiones>(DEFAULT_FILTERS);
  const [reporteComisiones, setReporteComisiones] = useState<IReporteComisiones[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const meses = useMemo(() => {
    if (!mesesData) return
    return createNewFilter(mesesData, filters.mes, "mes",);
  }, [mesesData, filters]);

  const anios = useMemo(() => {
    if (!aniosData) return
    return createNewFilter(aniosData, fechaActual.getUTCFullYear(), "anio");
  }, [aniosData]);

  const reportesService = useMemo(() => {
    return ReportsService(User);
  }, [User]);

  const nominasService = useMemo(() => {
    return NominaService(User);
  }, [User]);

  const handlerOnSelectChange = (e: IFilterChange): void => {
    const { key, value } = e;
    setFilters(prev => {
      return {
        ...prev,
        [key]: value
      }
    });
    setReporteComisiones([]);
  }
  const handlerOnSearchComisiones = useCallback(async (): Promise<void> => {
    if (!filters.mes) {
      notify("Selecciona un mes a buscar nomina", "warning", 4000);
      return
    }
    setLoading(true);
    setError(null);
    const { result, data, error } = await reportesService.getReportComisiones(filters);
    if (!result) {
      setError(error);
    }
    if (result) {
      setReporteComisiones(data);
    }
    setLoading(false);
  }, [filters, reportesService]);

  const handlerOnDownloadComisiones = async () => {
    const { iva, porcentajeFacturacion } = impuestos;
    const newReporteComisiones: IReporteComisiones[] = reporteComisiones.map((comision) => {
      return { ...comision, iva, porcentajeFacturacion, periodo: `${Meses[filters.mes! - 1]}-${filters.anio}` }
    });
    const wasExcelCreated = await reportesService.comisionesPagadas(newReporteComisiones);
    if (!wasExcelCreated) {
      notify("No se pudo crear el reporte", "error", 4000);
      return
    }
  }

  /**OBTENER LOS IMPUESTOS */
  useEffect(() => {
    const getImpuestos = async (): Promise<void> => {
      const { result, data, error } = await nominasService.getImpuestos();
      if (!result) {
        notify(error, "error", 4000);
        return
      }
      setImpuestos(data as IImpuestosNomina);
    }
    getImpuestos();
  }, [nominasService]);

  return {
    meses,
    anios,
    reporteComisiones,
    handlerOnSelectChange,
    handlerOnSearchComisiones,
    impuestos,
    handlerOnDownloadComisiones,
    loading,
    error
  }
}