import { ICustomerDropBox, IFilterData } from "components/interfaces/Interfaces";
import CustomStore from "devextreme/data/custom_store";
import { IFilterMultiple } from '../components/interfaces/Interfaces';
import { ExportingEvent as ExportingEventGrid } from "devextreme/ui/data_grid";
import { ExportingEvent as ExportingEventPivot } from 'devextreme/ui/pivot_grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid, exportPivotGrid } from 'devextreme/excel_exporter';
import { formatoDecimales } from './Formatos';
import DataSource from "devextreme/data/data_source";

export const groupBy = (xs: any[], key: string): any => {
  return xs.reduce((rv: any[], x: any): any[] => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const groupByKey = (xs: any[], key: string): any => {
  return xs.reduce((rv: any[], x: any): any[] => {
    (rv[x[key]] = rv[x[key]] || []);
    return rv;
  }, {});
};
/**SELECT MULTIPLE */
export const createNewFilterGroupBy = (data: any[], groupBy: string, defaultValue: any, key: string): IFilterData => {
  const dataGroup = Object.keys(groupByKey(data || [], groupBy));
  const filterData: IFilterData = {
    items: dataGroup,
    key,
    defaultValue
  }
  return filterData;
}
export const createNewFilter = (data: any[] | DataSource<any, any>, defaultValue: any, key: string): IFilterData => {
  const filterData: IFilterData = {
    items: data,
    key,
    defaultValue,
    title: SetTitle(key)
  }
  return filterData;
}

export const createNewFilterMultipleGroupBy = (data: any[], groupBy: string, key: string, selections: any[]) => {
  const dataGroup = Object.keys(groupByKey(data || [], groupBy));
  const filterData: IFilterMultiple = {
    items: dataGroup,
    key,
    title: SetTitle(key),
    selectionState: selections
  }
  return filterData;
}
export const createNewFilterMultiple = (data: any[], key: string, selections: any[]): IFilterMultiple => {
  const filterData: IFilterMultiple = {
    items: data,
    key,
    title: SetTitle(key),
    selectionState: selections
  }
  return filterData;
}

export const SetTitle = (value: string): string => {
  const arrayString: string[] = value.split('');
  let title: string = "";
  arrayString.forEach((char, index) => {
    if (index === 0) {
      title += char.toUpperCase();
      return
    }
    if (char === char.toUpperCase()) {
      title += ` ${char}`;
      return
    }
    title += char;
  });
  return title;
}

/**DROPBOX SELECT DEVEXTREME */

export const FilterMultipleDropBox = (data: any[], key: string): CustomStore<ICustomerDropBox, any> => {
  let filtersSource: ICustomerDropBox[] = [];
  if (typeof data[0] === "string" || typeof data[0] === "number") {
    filtersSource = data.map((item: string, index) => {
      return { key: index, [key]: item }
    });
  } else {
    filtersSource = data;
  }
  return new CustomStore({
    loadMode: 'raw',
    key,
    load() {
      return filtersSource
    }
  });
}

export const onExportingGrid = (e: ExportingEventGrid, fileName: string, division: string): void => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(fileName);
  exportDataGrid({
    component: e.component,
    worksheet: worksheet,
    autoFilterEnabled: true,
    customizeCell: ({ gridCell, excelCell }) => {
      if (gridCell?.rowType === "data") {
        if (gridCell?.column?.dataType === "number") {
          excelCell.value = formatoDecimales(gridCell.value, 2);
        }
      }
    }
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${division}-${fileName}.xlsx`);
    });
  });
  e.cancel = true;
}

export const onExportingPivot = (e: ExportingEventPivot, fileName: string, division: string): void => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(fileName);
  exportPivotGrid({
    component: e.component,
    worksheet
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${division}-${fileName}.xlsx`);
    });
  });
  e.cancel = true;
}

export const OnExportingGridPDF = () => {

}


