import { memo } from 'react';
import { IComisiones } from 'interfaces/IComisiones';
import TableGrid from 'components/TableGrid';
import { ColumsComisionesPorPagar } from 'functions/Comisiones';
import { ajustarDecimales } from 'utils/Formatos';
import { onExportingGrid } from 'utils/Functions';

interface IProps {
  comisiones: IComisiones[];
}

const GridComisionSubordinados = ({ comisiones }: IProps) => {

  return (
    <>
      <h1>Comisiones Subordinados</h1>
      <TableGrid
        dataSource={comisiones}
        columns={[
          { caption: "Vendedor", dataField: "vendedor", alignment: "left", width: 175, groupIndex: 0 }
          , ...ColumsComisionesPorPagar("NotEditing")]}
        enableExport={{ enabled: true, onExporting: (e) => onExportingGrid(e, "Comisiones por pagar subordinados", "") }}
        enablePager={false}
        enableSessionStorage={{ enabled: true, keyStorage: 'gridCSSubordinados' }}
        enableHeaderFilter={true}
        enableGroupPanel={true}
        enableSearchPanel={true}
        expandAllGrouping={false}
        totalesColumns={[{
          column: "totalComision",
          summaryType: "sum",
          displayFormat: "Total {0}",
          valueFormat: ajustarDecimales
        }]}
      />
    </>
  )
}

export default memo(GridComisionSubordinados)