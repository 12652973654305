import { useAuth } from "api/auth";
import { ConfiguracionService } from "api/configuracion";
import { sendDataAsync } from "api/fetch";
import { URL_API_CF } from "consts/Services_URL";
import notify from "devextreme/ui/notify";
import { 
    IRemoteConfiguracionComisionesFijas, 
    IRemoteConfiguracionComisionesPago, 
    IRemoteConfiguracionComisionesRango, 
    IRemoteConfiguracionComisionPuesto, 
    IGetCatalogosComisionPuesto
} from "interfaces/IConfiguracion"
import { 
    useCallback, 
    useLayoutEffect, 
    useMemo, 
    useState 
} from "react";

export interface IhistorialComisionPuesto{
    historicoActual: IRemoteConfiguracionComisionPuesto | {};
    historial: IRemoteConfiguracionComisionPuesto[];
}

let MESSAGE_MODAL_DELETE = "¿Estás seguro de eliminar la comisión del puesto?";

interface IuseComisiones {  
    comisionPuesto: IRemoteConfiguracionComisionPuesto;
    catalogosComisionPresupuesto: IGetCatalogosComisionPuesto;
    historialComisionPuesto: IhistorialComisionPuesto;
    MESSAGE_MODAL_DELETE: string;
    modalDeleteStatus: boolean;
    handleDelete:(e: IRemoteConfiguracionComisionPuesto) => void;
    onSavedPagoDivision:(data: IRemoteConfiguracionComisionesPago, action: string) => void;
    onSavedRangos:(data: IRemoteConfiguracionComisionesRango, action: string)=> void;
    onSavedFijos:(data: IRemoteConfiguracionComisionesFijas, action: string)=> void;
    onSavedPuesto:(data: IRemoteConfiguracionComisionPuesto, action: string)=> void;
    handleDeleteStatus:() => void;
    handleOnSetHistorialComisionPuesto:(e: IhistorialComisionPuesto) => void;
}

const initialDataComisionPuesto: IRemoteConfiguracionComisionPuesto = {
    aplicaApartir: new Date(),
    porcentajeAdicionalId: 0,
    departamentoId:0,
    oficinaId:0,
    porcentaje:0,
    puestoId:0,
    porcentajeActivo: true,
}

interface INotify {
    type: string;
    message?: string;
}

const inicialHistorialData:IhistorialComisionPuesto = {
    historicoActual: {},  
    historial: []
}

export const useComisiones = ():IuseComisiones => {

    const {User} = useAuth();
    const [catalogosComisionPresupuesto, setCatalogosComisionPresupuesto] = useState<IGetCatalogosComisionPuesto>({ loading: false })
    const [comisionPuesto, setComisionPuesto] = useState<IRemoteConfiguracionComisionPuesto>(initialDataComisionPuesto)
    const [modalDeleteStatus, setModalDeleteStatus] = useState(false)
    const [historialComisionPuesto, setHistorialComisionPuesto] = useState<IhistorialComisionPuesto>( inicialHistorialData )

    const headers = useMemo(() => {
        return { Authorization: `Bearer ${User.token}` }
    }, [User])

    const configuracionService = useMemo(() => {
        return ConfiguracionService(User);
    },[User])

    const showNotify = useCallback((Props:INotify) => {
        const {type,message=""} = Props;
        if (type === 'error') {
            notify({
                message: `Ocurrio un error: ${message}`
            }, "error", 4000);
        }
        if (type === 'success') {
            notify({
                message: `Se guardo correctamente`
            }, "success", 4000);
        }
    },[])

    const handleOnSetHistorialComisionPuesto = (data:IhistorialComisionPuesto) => {
        setHistorialComisionPuesto(data);
    }

    const onSavedPagoDivision = useCallback( async(data: IRemoteConfiguracionComisionesPago, action: string) =>{
        switch(action){
            case 'insert':
                const { result: resInsert, error: resInsertError } = await sendDataAsync(`${URL_API_CF}ConfigComisiones/pagoDivision`, data, "POST", headers)
                showNotify({ type: resInsert ? "success" : "error", message: resInsertError });
                break;
            case 'update':
                const {divisionId} = data;
                const { result: resUpdate, error: resUpdateError } = await sendDataAsync(`${URL_API_CF}ConfigComisiones/pagoDivision/${divisionId}`, data, "PUT", headers)
                showNotify({ type: resUpdate ? "success" : "error", message: resUpdateError });
                break;
            default:
                return
        }
    }, [headers, showNotify])

    const onSavedRangos = useCallback( async (data:IRemoteConfiguracionComisionesRango, action: string) =>{
        switch(action){
            case 'insert':
                const { result: resInsert, error: resInsertError } = await sendDataAsync(`${URL_API_CF}ConfigComisiones/ComisionRangos`, data, "POST", headers)
                showNotify({ type: resInsert ? "success" : "error", message: resInsertError });
                break;
            case 'update':
                const {rangoId} = data;
                const {result: resUpdate, error: resUpdateError} = await sendDataAsync(`${URL_API_CF}ConfigComisiones/ComisionRangos/${rangoId}`, data, "PUT", headers)
                showNotify({ type: resUpdate ? "success" : "error", message: resUpdateError });
                break;
            default:
                return
        }
    }, [headers,showNotify])

    const onSavedFijos = useCallback( async (data: IRemoteConfiguracionComisionesFijas, action: string) =>{
        switch(action){
            case 'insert':
                const { result: resInsert, error: resInsertError } = await sendDataAsync(`${URL_API_CF}ConfigComisiones/porcentajeFijo`, data, "POST", headers)
                showNotify({ type: resInsert ? "success" : "error", message: resInsertError });
                break;
            case 'update':
                const {porcentajeId} = data;
                const { result: resUpdate, error: resUpdateError } = await sendDataAsync(`${URL_API_CF}ConfigComisiones/porcentajeFijo/${porcentajeId}`, data, "PUT", headers)
                showNotify({ type: resUpdate ? "success" : "error", message: resUpdateError });
                break;
            case 'remove':
                const {porcentajeId: id} = data;
                const { result: resDelete, error: resDeleteError } = await sendDataAsync(`${URL_API_CF}ConfigComisiones/porcentajeFijo/${id}`, null, "DELETE", headers)
                showNotify({ type: resDelete ? "success" : "error", message: resDeleteError });
                break;
            default:
                return
        }
    }, [headers, showNotify])

    const onSavedPuesto = useCallback( async (data: IRemoteConfiguracionComisionPuesto, action: string) =>{
        switch(action){
            case 'insert':
                const { result: resInsert, error: resInsertError } = await sendDataAsync(`${URL_API_CF}ConfigComisiones/porcentajePuesto`, data, "POST", headers)
                showNotify({ type: resInsert ? "success" : "error", message: resInsertError });
                break;
            case 'update':
                const {porcentajeAdicionalId: idEdit} = data;
                const { result: resUpdate, error: resUpdateError } = await sendDataAsync(`${URL_API_CF}ConfigComisiones/porcentajePuesto/${idEdit}`, data, "PUT", headers)
                showNotify({ type: resUpdate ? "success" : "error", message: resUpdateError });
                break;
            case 'status':
                const {porcentajeAdicionalId: id} = data;
                const { result: resDelete, error: resDeleteError } = await sendDataAsync(`${URL_API_CF}ConfigComisiones/porcentajePuesto/${id}`, data, "PUT", headers)
                showNotify({ type: resDelete ? "success" : "error", message: resDeleteError });
                break;
            default:
                return
        }
    }, [headers, showNotify])

    /**Activar modal para dar de baja la comisión*/
    const handleDelete = useCallback( (data: IRemoteConfiguracionComisionPuesto) => {
        const {porcentajeAdicionalId, puestoId: puesto, porcentajeActivo: isActive} = data
        const nombrePuesto = catalogosComisionPresupuesto.puestos?.find(catPuesto => catPuesto.puestoId === puesto)?.nombrePuesto
        MESSAGE_MODAL_DELETE = `¿Estás seguro de dar de ${isActive ? 'Baja' : 'Alta'} la comisión adicional al puesto ${nombrePuesto}? `
        setModalDeleteStatus(act => !act)
        setComisionPuesto({...comisionPuesto, porcentajeAdicionalId: porcentajeAdicionalId})
    }, [comisionPuesto, catalogosComisionPresupuesto.puestos])

    useLayoutEffect(() => {
        const getCatalogos = async (): Promise<void> => {
            setCatalogosComisionPresupuesto({ loading: true })
            const { result, data, error } = await configuracionService.getCatalogosFacturacionAnual()
            if (!result) {
                setCatalogosComisionPresupuesto({ error: error, loading: false })
                return
            }
            const filtersCatalogos: IGetCatalogosComisionPuesto = data!
            setCatalogosComisionPresupuesto({ 
                departamentos:filtersCatalogos.departamentos, 
                oficinas:filtersCatalogos.oficinas, 
                puestos:filtersCatalogos.puestos, 
                vendedores:filtersCatalogos.vendedores,loading: false })
        }
        getCatalogos()
    }, [configuracionService])

    const handleDeleteStatus = useCallback(() => {
        setModalDeleteStatus(!modalDeleteStatus)
    }, [modalDeleteStatus])

    return {
        comisionPuesto,
        catalogosComisionPresupuesto,
        historialComisionPuesto,
        MESSAGE_MODAL_DELETE,
        modalDeleteStatus,
        handleDelete,
        handleDeleteStatus,
        onSavedPagoDivision,
        onSavedRangos,
        onSavedFijos,
        onSavedPuesto,
        handleOnSetHistorialComisionPuesto
    }
}