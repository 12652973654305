import { TableGrid, Button } from 'components/Index'
import { useNomina } from 'api/nomina';
import { ajustarDecimales } from 'utils/Formatos';

const GridPagoNomina = () => {

  const {
    onCellValueChange,
    onHandlerSaveNominas,
    nominas,
    loading,
    columsGrid,
    messageLoading
  } = useNomina();

  if (loading) {
    return <p>{messageLoading}</p>
  }

  return (
    <div className='container-grid'>
      <TableGrid
        dataSource={nominas}
        columns={columsGrid}
        enableHeaderFilter={true}
        enableGroupPanel={true}
        enableSearchPanel={true}
        enableHoverState={true}
        showRowLines={true}
        enableColumnChooser={true}
        enableSessionStorage={{ enabled: true, keyStorage: 'gridPagoNomina' }}
        enableEditing={{ modeEdit: 'cell', showIcons: true, enableUpdating: true, onSavedChanges: onCellValueChange }}
      />
      {<ShowTotales />}
      <div className='container-buttons'>
        <Button
          icon='fas fa-save'
          text='Guardar Nómina'
          width={250}
          onClick={() => onHandlerSaveNominas("PRENOMINA")}
          iconHover="Claro"
          disabled={nominas.length === 0}
        />
        <Button
          icon='fas fa-cash-register'
          text='Pagar Nómina'
          width={250}
          onClick={() => onHandlerSaveNominas("NOMINA")}
          style={{backgroundColor:"#10A800"}}
          iconHover="Claro"
          disabled={nominas.length === 0}
        />
      </div>
    </div>
  )
}

const ShowTotales = () => {
  const { filtros } = useNomina();
  if (filtros.formaPago === "BECA") {
    return <ShowTotalesBeca />;
  }
  return <ShowTotalesNomina />;
}

const ShowTotalesNomina = () => {
  const { nominas, impuestos } = useNomina();
  const { porcentajeFacturacion, iva } = impuestos;
  let subTotalASIM: number = 0;
  let totalEfectivo: number = 0;
  nominas.forEach((item) => {
    if (item.formaPago === "EFE") {
      totalEfectivo += item.total_a_pagar;
    }
    if (item.formaPago !== "EFE") {
      subTotalASIM += item.total_a_pagar;
    }
  });
  const totalAPagar = (subTotalASIM + (subTotalASIM * (porcentajeFacturacion / 100)));
  return nominas.length > 0 ? (
    <div className="divTotals">
      <div>
        <div className="titleTotal"><p>Total a pagar EFECTIVO : </p><p>$ {ajustarDecimales(totalEfectivo)}</p></div>
      </div>
      <div>
        <div className="titleTotal"><p>Total a pagar  ASIM NOM: </p><p>$ {ajustarDecimales(subTotalASIM)}</p></div>
        <div className="titleTotal"><p>Subtotal (+ {porcentajeFacturacion}%) : </p><p>$ {ajustarDecimales(totalAPagar)}</p></div>
        <div className="titleTotal"><p>IVA {iva}%: </p><p>$ {ajustarDecimales(totalAPagar * (iva / 100))}</p></div>
        <div className="titleTotal"><p>Total : </p><p>$ {ajustarDecimales(totalAPagar + (totalAPagar * (iva / 100)))}</p></div>
      </div>
    </div>
  ) : (<></>)
}

const ShowTotalesBeca = () => {
  const { nominas } = useNomina();
  let subTotal: number = 0;
  nominas.forEach((item) => {
    subTotal += item.total_a_pagar;
  });
  return nominas.length > 0 ? (
    <div className="divTotals">
      <div>
        <div className="titleTotal"><p>Total a pagar BECA : </p><p>$ {ajustarDecimales(subTotal)}</p></div>
      </div>
    </div>
  ) : (<></>)
}

export default GridPagoNomina