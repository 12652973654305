import { Button } from 'devextreme-react'
import GridPerfilColaborador from './components/GridPerfilColaborador'
import ModalPerfilColaborador from './components/ModalPerfilColaborador'
import { usePerfil } from './hooks/usePerfil'
import "./styles/estilos.scss"

const Perfil = () => {

    const {
        colaborador,
        modalStatus,
        modoEdicion,
        MESSAGE_MODAL_DELETE,
        modalDeleteStatus,
        catalogos,
        jefes,
        rol,
        tabVisible,
        asignarHistorico,
        handlerOnSelectTab,
        setModalStatus,
        setModoEdicion,
        setValorInicial,
        setModalDeleteStatus,
        handleDelete,
        HandleEdit,
        onSavedPerfil,
        handleAllpermissionsSelect
    } = usePerfil()

    return (
        <div className="container-modulo-it">
            <hr />
            <div className='container-buttons'>
                <Button
                    onClick={() => setModalStatus(true)}
                    text="Agregar"
                    icon='fas fa-user-plus'
                    type='default'
                />
            </div>
            <GridPerfilColaborador
                setModalDeleteStatus={setModalDeleteStatus}
                modalDeleteStatus={modalDeleteStatus}
                colaborador={colaborador}
                MESSAGE_MODAL_DELETE={MESSAGE_MODAL_DELETE}
                handleDelete={handleDelete}
                handleEdit={HandleEdit}
                onSavedPerfil={onSavedPerfil}
                setValorInicial={setValorInicial}
            />
            {
                modalStatus && (
                    <ModalPerfilColaborador
                        catalogos={catalogos}
                        colaborador={colaborador}
                        isEdition={modoEdicion}
                        statusModal={modalStatus}
                        jefes={jefes}
                        asignarHistorico={asignarHistorico}
                        setModoEdicion={setModoEdicion}
                        setValorInicial={setValorInicial}
                        onSavedPerfil={onSavedPerfil}
                        closePopup={() => setModalStatus(!modalStatus)}
                        rol={rol}
                        tabVisible={tabVisible}
                        handlerOnSelectTab={handlerOnSelectTab}
                        handleAllpermissionsSelect={handleAllpermissionsSelect}
                    />
                )
            }
        </div>
    )
}

export default Perfil