import ErrorPage from 'pages/Error'
import PivotTable from 'components/Pivot'
import { memo, useEffect } from "react";
import { IGetDataClientes } from 'interfaces/IClientes';
import { ExportedEvent } from 'devextreme/ui/pivot_grid';


interface IProps{
    dataClientes: IGetDataClientes;
    fields: any[];
    keyLocalStorage?: string;
    onExporting?: (e: ExportedEvent) => void;
}

const PivotClientes = ({ dataClientes, fields, keyLocalStorage, onExporting }: IProps) => {
    const { loading, data, error } = dataClientes;

    useEffect(() =>{
        return () => {
            keyLocalStorage && localStorage.removeItem(keyLocalStorage);
        }
    },[keyLocalStorage])

    if(loading){
        return <p>CARGANDO...</p>
    }
    if(error){
        return <ErrorPage errorMessage={error} />
    }

    return(
        <PivotTable 
            data={data} 
            fieldPivot={fields} 
            keyPersistState={keyLocalStorage} 
            onExporting={onExporting}
        />
    )
}
export default memo(PivotClientes)