import { Autocomplete, TextField } from "@mui/material";
import { Button, Select } from "components/Index";
import { DateBox, Popup } from "devextreme-react";
import { useTask } from "contexts/TaskContext";

const CreateTaskForm = () => {
  const {
    formData,
    tipoEntidad,
    tiposEstatus,
    entidades,
    responsables,
    urgenciaSeleccionada,
    fechaTermino,
    loadingEntidades,
    tiposEntidades,
    tiposUrgencias,
    openModal,
    tipo,
    guids,
    loading,
    data,
    estatusSeleccionado,
    setTipoEntidad,
    setEntidadSeleccionada,
    setResponsableSeleccionado,
    setUrgenciaSeleccionada,
    setFechaTermino,
    handlerCreateTarea,
    handleInputChange,
    setOpenModal,
    setTipo,
    setGuids,
    setData,
    onHandlerSync,
    note,
    setNote,
    setEstatusSeleccionado,
  } = useTask();
  return (
    <>
      <Popup
        visible={openModal}
        onHiding={() => setOpenModal(false)}
        title="Agregar GUID o Job Number"
        width={300}
        height={400}
        contentRender={() => (
          <>
            <Select
              title="Tipo"
              item={{
                key: "Sync",
                title: "Sync",
                items: [
                  "Embarque",
                  "Cliente",
                  "Proveedor",
                  "Facturas de Pago",
                  "Facturas de Cobro",
                  "Cheques",
                  "Pagos",
                  "Cotizaciones",
                ],
              }}
              onChangeSelect={(e) => {
                setTipo(e.value);
              }}
            />
            <textarea
              className="textarea"
              placeholder="GUIDs"
              style={{ width: "100%", marginTop: 10, height: 200 }}
              value={guids}
              onChange={(e) => setGuids(e.target.value)}
            />
            <Button
              disabled={loading}
              icon={`fas fa-${loading ? "spin" : "sync-alt"}`}
              text="Agregar GUIDs"
              width={250}
              iconHover="Claro"
              style={{ backgroundColor: "#10A800" }}
              onClick={async () => {
                const guidsArray = guids.split("\n").filter(Boolean);

                if (!tipo || !guidsArray.length) return;

                const uniqueGuids = Array.from(new Set(guidsArray));
                const newGuids = uniqueGuids.filter(
                  (guid) =>
                    !data.some((item) => item.reporte_Sync_Guid === guid)
                );

                const syncData = newGuids.map((guid) => ({
                  reporte_Sync_Tipo: tipo,
                  reporte_Sync_Guid: guid,
                  isDeleted: false,
                }));

                setData((prevData) => [...prevData, ...syncData]);
                onHandlerSync(syncData);
                setOpenModal(false);
                setGuids("");
              }}
            />
          </>
        )}
      />
      <div className="container-filters">
        <form onSubmit={(e) => handlerCreateTarea(e)}>
          <h2>Crear Tarea</h2>
          <input
            type="text"
            name="Tarea_Nombre"
            placeholder="Nombre de la tarea"
            required={true}
            value={formData.Tarea_Nombre}
            onChange={handleInputChange}
          />
          <div className="select-container">
            <label htmlFor="tipoEntidad">Tipo de Entidad</label>
            <select
              id="tipoEntidad"
              name="tipoEntidad"
              value={tipoEntidad}
              onChange={(e) => setTipoEntidad(e.target.value)}
              style={{ width: "100%", marginBottom: "5px" }}
            >
              {tiposEntidades.map((entidad) => (
                <option key={entidad.value} value={entidad.value}>
                  {entidad.nombre}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: "100%", paddingBottom: "5px" }}>
            <label htmlFor="entidadAutocomplete">Entidad</label>
            <Autocomplete
              id="entidadAutocomplete"
              style={{ backgroundColor: "white" }}
              size="small"
              options={entidades}
              loading={loadingEntidades}
              disabled={entidades.length === 0}
              getOptionLabel={(option) => option.Entidad_Razon_Social}
              renderInput={(params: any) => <TextField {...params} />}
              renderOption={(props, option) => (
                <li {...props} key={option.Entidad_Guid}>
                  {option.Entidad_Razon_Social}
                </li>
              )}
              onChange={(event, newValue) =>
                setEntidadSeleccionada(newValue ? newValue.Entidad_Guid : "")
              }
            />
          </div>
          <div style={{ width: "100%", paddingBottom: "10px" }}>
            <label htmlFor="responsableAutocomplete">
              Responsable de la tarea
            </label>
            <Autocomplete
              id="responsableAutocomplete"
              style={{ backgroundColor: "white" }}
              size="small"
              options={responsables.data!}
              loading={loadingEntidades}
              getOptionLabel={(option) => option.colaborador}
              renderInput={(params: any) => <TextField {...params} label="" />}
              renderOption={(props, option) => (
                <li {...props} key={option.guid}>
                  {option.colaborador}
                </li>
              )}
              onChange={(event, newValue) =>
                setResponsableSeleccionado(newValue ? newValue.guid : "")
              }
            />
          </div>
          <div className="select-container">
            <label htmlFor="entidad">Prioridad</label>
            <select
              id="urgencia"
              name="urgencia"
              value={urgenciaSeleccionada}
              onChange={(e) => setUrgenciaSeleccionada(e.target.value)}
              style={{ width: "100%", marginBottom: "5px" }}
            >
              {tiposUrgencias.map((urgencia) => (
                <option key={urgencia.value} value={urgencia.value}>
                  {urgencia.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="select-container">
            <label htmlFor="estatus">Estatus</label>
            <select
              id="estatus"
              name="estatus"
              value={estatusSeleccionado}
              onChange={(e) => setEstatusSeleccionado(e.target.value)}
              style={{ width: "100%", marginBottom: "5px" }}
            >
              {tiposEstatus.map((estatus) => (
                <option key={estatus.value} value={estatus.value}>
                  {estatus.nombre}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="Tarea_Fecha_Termino">Fecha Termino</label>
            <DateBox
              name="Tarea_Fecha_Termino"
              type="date"
              pickerType="calendar"
              value={fechaTermino}
              onValueChanged={(e) => setFechaTermino(e.value)}
              style={{ width: "100%" }}
            />
          </div>
          <div>
            <label htmlFor="note">Nota</label>
            <input
              type="text"
              name="note"
              placeholder="Nota"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
          <Button
            icon="fas fa-plus"
            width={"100%"}
            iconHover="Claro"
            style={{ backgroundColor: "#00D55A", marginTop: "10px" }}
            type="submit"
            text="Crear Tarea"
          />
        </form>
      </div>
    </>
  );
};

export default CreateTaskForm;
