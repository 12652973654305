import { Form } from 'devextreme-react';
import { Item, Label } from 'devextreme-react/data-grid';
import { ButtonItem, GroupItem, PatternRule, RequiredRule } from 'devextreme-react/form';
import { divisionAdapter, permisoAdapter } from 'adapters/IT';
import { useCallback, useEffect, useState } from 'react'
import ModalHistorico from './ModalHistorico';
import '../styles/estilos.scss'

const FormPerfilColaborador = ({
    asignarHistorico,
    catalogos,
    onSavedPerfil,
    colaborador,
    isEdition,
    setModoEdicion,
    setValorInicial,
    closePopup,
    jefes,
    formSuperAdmin,
    indexTab,
    handleAllpermissionsSelect
}) => {

    const [formaPagoFilter, setFormaPagoFilter] = useState([])
    const [isCmotion, setIsCmotion] = useState(false)
    const [isMagalla, setIsMagalla] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [tipoPagoId, settipoPagoId] = useState(colaborador?.historicoSueldo?.formaPagoId);


    const validacionesFormaPago = useCallback(() => {

        const {
            comisiona: C,
            sobrepasaIMSS: SIM,
            sueldoBruto: SB
        } = colaborador?.historicoSueldo

        const SUELDO_NOM = 0;
        const SUELDO_BRUTO = 0;
        const sueldoIMSS = parseFloat(SIM)
        const sueldoBruto = parseFloat(SB)

        if (sueldoIMSS === SUELDO_NOM && sueldoBruto === SUELDO_BRUTO && !C) {
            setFormaPagoFilter(catalogos.formasPago?.items.filter(item => item.formaPagoTipo === "NOM"))
            return
        }
        if (sueldoIMSS === SUELDO_NOM && sueldoBruto > SUELDO_BRUTO && C) {
            setFormaPagoFilter(catalogos.formasPago?.items.filter(item => item.formaPagoTipo === "ASIM COM"))
            return
        }
        if (sueldoIMSS > SUELDO_NOM && sueldoBruto > SUELDO_BRUTO && !C) {
            setFormaPagoFilter(catalogos.formasPago?.items.filter(item => item.formaPagoTipo === "ASIM NOM" || item.formaPagoTipo === "EFE"))
            return
        }
        if (sueldoIMSS > SUELDO_NOM && sueldoBruto > SUELDO_BRUTO && C) {
            setFormaPagoFilter(catalogos.formasPago?.items.filter(item => item.formaPagoTipo === "ASIM NOM Y COM"))
            return
        }
        if (sueldoIMSS > SUELDO_NOM && sueldoBruto === SUELDO_BRUTO && !C) {
            setFormaPagoFilter(catalogos.formasPago?.items.filter(item => item.formaPagoTipo === "BECA"))
            return
        }
        setFormaPagoFilter(catalogos.formasPago?.items.filter(item => item.formaPagoTipo === "NOM"))

    }, [catalogos.formasPago?.items, colaborador.historicoSueldo])

    const onSumbit = async (e) => {
        e.preventDefault()

        const divisionesCatalogos = catalogos?.divisiones.filter(
            item =>
                colaborador.colaborador_Divisiones[item.nombreDivision]
        ).map(
            item => divisionAdapter(item)
        )
        const permisosCatalogos = catalogos?.permisos.filter(
            item =>
                colaborador.permisos[item.permisoDescripcion]
        ).map(
            item => {
                return permisoAdapter(item)
            }
        )
        
        colaborador.fechaBaja ? colaborador.perfilActivo = false : colaborador.perfilActivo = true
        const nombre = colaborador.nombreColaborador.toUpperCase()
        const apellidoP = colaborador.apellidoPaterno.toUpperCase()
        const apellidoM = colaborador.apellidoMaterno ? colaborador.apellidoMaterno.toUpperCase() : ""
        const nombrePref = colaborador.nombrePreferido ? colaborador.nombrePreferido.toUpperCase() : ""

        const colaboradorNew = {
            ...colaborador,
            colaborador_Divisiones: divisionesCatalogos,
            nombreColaborador: nombre,
            apellidoPaterno: apellidoP,
            apellidoMaterno: apellidoM,
            nombrePreferido: nombrePref,
            permisos: permisosCatalogos,
            historicoSueldo: {
                ...colaborador.historicoSueldo,
                formaPagoId: tipoPagoId
            },
        }
        isEdition ? onSavedPerfil(colaboradorNew, "update") : onSavedPerfil(colaboradorNew, "insert")
        setValorInicial()
        setModoEdicion(false)
        closePopup()
    }

    const validacionCMotion = useCallback((colaborador) => {
        const { usuarioCMotion: usuarioC } = colaborador
        if (usuarioC.trim()) {
            setIsCmotion(true)
            return
        }
        setIsCmotion(false)
    }, [])

    const validacionMMotion = useCallback((colaborador) => {
        const { usuarioMagaya: usuarioM } = colaborador
        if (usuarioM.trim()) {
            setIsMagalla(true)
            return
        }
        setIsMagalla(false)
    }, [])

    const validacionInfoNomina = useCallback((infoNomina) => {
        const {
            clabe,
            claveNomilinea: nomilinea,
            cuenta,
            rfc
        } = infoNomina
        if (clabe.trim() || nomilinea.trim() || rfc.trim() || cuenta.trim()) {
            setIsInfo(true)
            return
        }
        setIsInfo(false)
    }, [])

    const cancelPopup = () => {
        closePopup()
        setModoEdicion(false)
        setValorInicial()
    }

    const optionsComponents = {
        empleadosOptions: {
            items: jefes || [],
            displayExpr: "nombreColaborador",
            valueExpr: "empleadoId",
            searchEnabled: true,
            noDataText: "No hay información",
            placeholder: "--Selecciona--",
        },
        departamentosOptions: {
            items: catalogos?.departamentos?.items || [],
            displayExpr: "nombreDepartamento",
            valueExpr: "departamentoId",
            searchEnabled: true,
            noDataText: "No hay información",
            placeholder: "--Selecciona--",
        },
        oficinasOptions: {
            items: catalogos?.oficinas?.items || [],
            displayExpr: "nombreOficina",
            valueExpr: "oficinaId",
            searchEnabled: true,
            noDataText: "No hay información",
            placeholder: "--Selecciona--",
        },
        puestosOptions: {
            items: catalogos?.puestos?.items || [],
            displayExpr: "nombrePuesto",
            valueExpr: "puestoId",
            searchEnabled: true,
            noDataText: "No hay información",
            placeholder: "--Selecciona--",
        },
        rolesOptions: {
            items: catalogos?.roles?.items || [],
            displayExpr: "rolDescripcion",
            valueExpr: "rolId",
            searchEnabled: true,
            noDataText: "No hay información",
            placeholder: "--Selecciona--",
        },
        usuarioCOptions: {
            onValueChanged: () => { validacionCMotion(colaborador) },
        },
        usuarioMOptions: {
            onValueChanged: () => { validacionMMotion(colaborador) },
        },
        infoCOptions: {
            onValueChanged: () => { validacionInfoNomina(colaborador.infoNomina) },
        },
        formatFecha: {
            format: "dd/MMM/yyyy",
        },
        checkBoxOptions: {
            defaultValue: false
        },
        checkBoxOptions2: {
            defaultValue: true
        },
        btnSubmitOptions: {
            text: isEdition ? "Editar" : "Guardar",
            type: isEdition ? "default" : "success",
            icon: isEdition ? "edit" : "save",
            useSubmitBehavior: true
        },
        btnCloseOptions: {
            icon: "close",
            text: "Cerrar",
            type: "danger",
            onClick: () => cancelPopup()
        },
        phoneEditorOptions: {
            mask: '(X00) 000-0000',
            maskRules: {
                X: /[02-9]/,
            },
            maskInvalidMessage: 'Introduce número válido',
        },
        checkBoxAllSelectedOptions: {
            onValueChanged: () => handleAllpermissionsSelect(colaborador)
        },
        formasPagoOptions: {
            items: formaPagoFilter,
            displayExpr: "formaPagoTipo",
            valueExpr: "formaPagoTipo",
            noDataText: "No hay información",
            placeholder: "--Selecciona--",
            value: formaPagoFilter ? formaPagoFilter.length === 1 ? formaPagoFilter[0].formaPagoTipo : colaborador.historicoSueldo.formaPago : "NOM",
        },
        sueldoIMSSOptions: {
            onValueChanged: () => validacionesFormaPago()
        },
        checkBoxHistoricoOptions: {
            defaultValue: false,
            onValueChanged: () => validacionesFormaPago()
        },
        sueldoBrutoOptions: {
            onValueChanged: () => validacionesFormaPago()
        }
    }

    useEffect(() => {
        validacionesFormaPago()
    }, [validacionesFormaPago]);

    useEffect(() => {
        console.log("colaborador", catalogos)
    }, [catalogos])

    return (
        <div className='card-container formularioPerfil'>
            <form onSubmit={onSumbit}>
                <Form
                    formData={colaborador}
                    colCountByScreen={colCountByScreen}
                    colCount="auto"
                    key="id"
                    scrollingEnabled={true}
                    scrollByContent={true}
                    showValidationSummary={true}
                >
                    {/* Formulario General */}
                    <GroupItem
                        colSpan={2}
                        colCountByScreen={colCountByScreen}
                        cssClass={indexTab !== 0 ? "ocultarInfo" : null}
                    >
                        <Item
                            dataField="nombreColaborador"
                            editorType={"dxTextBox"}
                        >
                            <RequiredRule message="Nombre es obligatorio" />
                            <Label visible={true} text="Nombre" />
                        </Item>
                        <Item
                            dataField="apellidoPaterno"
                            editorType={"dxTextBox"}

                        >
                            <RequiredRule message="Apellido Paterno es obligatorio" />
                            <Label visible={true} text="Apellido Paterno" />
                        </Item>
                        <Item
                            dataField="apellidoMaterno"
                            editorType={"dxTextBox"}
                        >
                            <Label visible={true} text="Apellido Materno" />
                        </Item>
                        <Item
                            dataField="nombrePreferido"
                            editorType={"dxTextBox"}
                        >
                            <Label visible={true} text="Nombre Preferido" />
                        </Item>
                        <Item
                            dataField="fechaIngreso"
                            editorType="dxDateBox"
                            editorOptions={optionsComponents.formatFecha}
                            isRequired={true}
                        >
                            <RequiredRule message="Fecha Ingreso es obligatoria" />
                            <Label visible={true} text="Fecha Ingreso" />
                        </Item>
                        <Item
                            dataField="fechaBaja"
                            editorType="dxDateBox"
                        >
                            <Label visible={true} text="Fecha Baja" />
                        </Item>
                        <Item
                            dataField="jefeId"
                            editorType="dxSelectBox"
                            editorOptions={optionsComponents.empleadosOptions}
                            isRequired={true}
                        >
                            <RequiredRule message="Jefe directo es obligatorio" />
                            <Label visible={true} text="Jefe directo" />
                        </Item>
                        <Item
                            dataField="oficinaId"
                            editorType="dxSelectBox"
                            editorOptions={optionsComponents.oficinasOptions}
                            isRequired={true}
                        >
                            <RequiredRule message="Oficina es obligatoria" />
                            <Label visible={true} text="Oficina" />
                        </Item>
                        <Item
                            dataField="puestoId"
                            editorType="dxSelectBox"
                            editorOptions={optionsComponents.puestosOptions}
                        >
                            <Label visible={true} text="Puesto" />
                        </Item>
                        <Item
                            dataField="departamentoId"
                            editorType="dxSelectBox"
                            editorOptions={optionsComponents.departamentosOptions}
                            isRequired={true}
                        >
                            <RequiredRule message="Departamento es obligatorio" />
                            <Label visible={true} text="Departamento" />
                        </Item>
                        <Item
                            dataField={`tieneGenteACargo`}
                            editorType="dxCheckBox"
                            cssClass="check-divisiones"
                            editorOptions={optionsComponents.checkBoxOptions}
                        >
                            <Label visible={true} text={"¿Tiene Subordinados?"} />
                        </Item>
                    </GroupItem>
                    {/* Formulario IT */}
                    <GroupItem
                        cssClass={indexTab === 1 && formSuperAdmin ? null : "ocultarInfo"}
                        colSpan={2}
                        colCountByScreen={colCountByScreen}
                    >
                        <Item
                            dataField="email"
                            editorType={"dxTextBox"}
                        >
                            <PatternRule
                                message="Estructura Incorrecta"
                                pattern={/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/}
                            />
                            <Label visible={true} text="Email" />
                        </Item>
                        <Item
                            dataField="usuarioCMotion"
                            editorType={"dxTextBox"}
                            editorOptions={optionsComponents.usuarioCOptions}
                        >
                            <Label visible={true} text="Usuario CMotion" />
                        </Item>
                        <Item
                            dataField="contraseniaCMotion"
                            editorType={"dxTextBox"}
                        >
                            {
                                isCmotion && <RequiredRule message="Contraseña CMotion requerido" />
                            }
                            <Label visible={true} text="Contraseña CMotion" />
                        </Item>
                        <Item
                            dataField="nombreMagaya"
                            editorType={"dxTextBox"}
                        >
                            <Label visible={true} text="Nombre Magaya" />
                        </Item>
                        <Item
                            dataField="usuarioMagaya"
                            editorType={"dxTextBox"}
                            editorOptions={optionsComponents.usuarioMOptions}
                        >
                            <Label visible={true} text="Usuario Magaya" />
                        </Item>
                        <Item
                            dataField="contraseniaMagaya"
                            editorType={"dxTextBox"}
                        >
                            {
                                isMagalla && <RequiredRule message="Contraseña Magaya requerida" />
                            }
                            <Label visible={true} text="Contraseña Magaya" />
                        </Item>
                        <Item
                            dataField="extencion"
                            editorType={"dxTextBox"}
                        >
                            <Label visible={true} text="Extensión" />
                        </Item>
                        <Item
                            dataField="telefono"
                            editorOptions={optionsComponents.phoneEditorOptions}
                            editorType={"dxTextBox"}
                        >
                            <Label visible={true} text="Teléfono" />
                        </Item>
                        <Item
                            dataField="whatsapp"
                            editorOptions={optionsComponents.phoneEditorOptions}
                            editorType={"dxTextBox"}
                        >
                            <Label visible={true} text="WhatsApp" />
                        </Item>
                        <GroupItem
                            name={'colaborador_Divisiones'}
                            colCount={3}
                            colSpan={2}
                        >
                            <Label visible={true} text={'Divisiones'} />
                            {
                                catalogos?.divisiones?.map(item => (
                                    <Item
                                        colSpan={1}
                                        key={item.divisionId}
                                        dataField={`colaborador_Divisiones.${item.nombreDivision}`}
                                        editorType="dxCheckBox"
                                        cssClass="check-divisiones"
                                        editorOptions={item.divisionId === 1 ? optionsComponents.checkBoxOptions2 : optionsComponents.checkBoxOptions}
                                    >
                                        <Label visible={true} text={item.nombreDivision} />
                                    </Item>
                                ))
                            }
                        </GroupItem>
                    </GroupItem>

                    {/* Formulario Permisos */}
                    <GroupItem
                        cssClass={indexTab !== 2 ? "ocultarInfo" : null}
                        colCount={3}
                        colSpan={2}
                    >
                        <Item
                            dataField="rolId"
                            editorType="dxSelectBox"
                            editorOptions={optionsComponents.rolesOptions}
                        >
                            <Label visible={true} text="Rol" />
                        </Item>

                        <GroupItem
                            name={'permisos'}
                            colSpan={2}
                            colCount={3}
                        >
                            <Label visible={true} text={'Permisos'} />
                            {
                                catalogos?.permisos?.map(item => (
                                    <Item
                                        colSpan={1}
                                        key={item.permisoId}
                                        dataField={`permisos.${item.permisoDescripcion}`}
                                        editorType="dxCheckBox"
                                        cssClass="check-divisiones"
                                        editorOptions={optionsComponents.checkboxSelectedPermisos}
                                    >
                                        <Label visible={true} text={item.permisoDescripcion} />
                                    </Item>
                                ))
                            }
                            <Item
                                colSpan={1}
                                key={"seleccionarTodos"}
                                dataField={'selectAll'}
                                editorType="dxCheckBox"
                                cssClass="check-divisiones"
                                editorOptions={optionsComponents.checkBoxAllSelectedOptions}
                            >
                                <Label visible={true} text={"Seleccionar Todos"} />
                            </Item>
                        </GroupItem>
                    </GroupItem>
                    {/* Formulario Info Nómina */}
                    <GroupItem
                        name={"infoNomina"}
                        cssClass={indexTab !== 3 ? "ocultarInfo" : null}
                        colCount={2}
                        colSpan={2}
                    >
                        <Item
                            dataField="infoNomina.claveNomilinea"
                            editorType={"dxTextBox"}
                            editorOptions={optionsComponents.infoCOptions}
                        >
                            {
                                isInfo && <RequiredRule message="Clave Nomilinea requerido" />
                            }
                            <PatternRule
                                message="Valor invalido o máximo dos decimales"
                                pattern={/^[0-9]+(.[0-9]{2})?$/}
                            />
                            <Label visible={true} text="Clave Nomilinea" />
                        </Item>
                        <Item
                            dataField="infoNomina.cuenta"
                            editorType={"dxTextBox"}
                            editorOptions={optionsComponents.infoCOptions}
                        >
                            {
                                isInfo && <RequiredRule message="Cuenta requerido" />
                            }
                            <PatternRule
                                message="Valor invalido o máximo dos decimales"
                                pattern={/^[0-9]+(.[0-9]{2})?$/}
                            />
                            <Label visible={true} text="Cuenta" />
                        </Item>
                        <Item
                            dataField="infoNomina.clabe"
                            editorType={"dxTextBox"}
                            editorOptions={optionsComponents.infoCOptions}
                        >
                            {
                                isInfo && <RequiredRule message="Clabe requerido" />
                            }
                            <PatternRule
                                message="La CLABE interbancaria debe ser de 18 digitos"
                                pattern={/^[0-9]{18}$/}
                            />
                            <Label visible={true} text="CLABE" />
                        </Item>
                        <Item
                            dataField="infoNomina.rfc"
                            editorType={"dxTextBox"}
                            editorOptions={optionsComponents.infoCOptions}
                        >
                            <PatternRule
                                message="Incorrecta estructura del RFC (MAYUSCULAS Y 13 o 12 digitos)"
                                pattern={/^[A-Z]{4}[0-9]{6}[A-Z0-9]{2,3}$/}
                            />
                            {
                                isInfo && <RequiredRule message="RFC requerido" />
                            }
                            <Label visible={true} text="RFC" />
                        </Item>
                        <Item
                            colSpan={2}
                            component={() => linea()}
                        />
                    </GroupItem>
                    {/* Formulario Histórico de sueldo */}
                    <GroupItem
                        name={"historicoSueldo"}
                        cssClass={indexTab !== 3 ? "ocultarInfo" : null}
                        colCount={3}
                        colSpan={2}
                    >
                        <Item
                            dataField="historicoSueldo.aplicaDesde"
                            editorType="dxDateBox"
                            editorOptions={optionsComponents.formatFecha}
                            isRequired={true}
                        >
                            <RequiredRule message="Aplica desde es obligatorio" />
                            <Label visible={true} text="Aplica desde" />
                        </Item>
                        <Item
                            dataField="historicoSueldo.sueldoBruto"
                            editorOptions={optionsComponents.sueldoBrutoOptions}
                            editorType={"dxTextBox"}
                        >
                            <Label visible={true} text="Sueldo Mensual" />
                            <RequiredRule message="Sueldo Bruto es obligatorio" />
                            <PatternRule
                                message="Valor invalido o máximo dos decimales"
                                pattern={/^[0-9]+(.[0-9]{2})?$/}
                            />
                        </Item>
                        <Item
                            dataField="historicoSueldo.sobrepasaIMSS"
                            editorOptions={optionsComponents.sueldoIMSSOptions}
                            editorType={"dxTextBox"}
                        >
                            <Label visible={true} text="Sobrepasa" />
                            <RequiredRule message="Sobrepasa Imss es obligatorio" />
                            <PatternRule
                                message="Valor invalido o máximo dos decimales"
                                pattern={/^[0-9]+(.[0-9]{2})?$/}
                            />
                        </Item>
                        <Item
                            dataField="historicoSueldo.comisiona"
                            editorType="dxCheckBox"
                            cssClass="check-comisiona"
                            editorOptions={optionsComponents.checkBoxHistoricoOptions}
                        >
                            <Label visible={true} text="Comisiona" />
                        </Item>
                        <GroupItem
                            colCount={3}
                            colSpan={2}
                        >
                            <Label visible={true} text="Forma de pago"></Label>
                            {
                                catalogos.formasPago.items.map((item, index) => {

                                    const isDisabled = formaPagoFilter.find((value) => value.formaPagoid === item.formaPagoid) ? false : true;

                                    var checked = false;

                                    if (item.formaPagoid === tipoPagoId && !isDisabled) {
                                        checked = true;
                                    }

                                    return (
                                        <div>
                                            <input onChange={() => {
                                                settipoPagoId(item.formaPagoid);
                                            }} disabled={isDisabled} checked={checked} type="radio" name='formPago' value={item.formaPagoid} />{item.formaPagoTipo}
                                        </div>
                                    )
                                })
                            }
                        </GroupItem>
                    </GroupItem>
                    {/*  */}
                    <GroupItem
                        cssClass={indexTab !== 3 ? "ocultarInfo" : "mostrarTablaHistoricoSueldo"}
                        colCount={3}
                        colSpan={2}
                        component={() => indexTab === 3 ? ModalHistorico({ data: colaborador, asignarHistorico }) : <></>}
                    />
                    {/* Botones */}
                    <GroupItem
                        colSpan={1}
                    />
                    <GroupItem
                        colCount={2}
                        cssClass={"toolbar-groupForm"}
                        colCountByScreen={
                            {
                                xs: 2,
                                lg: 2,
                                md: 2,
                                sm: 2
                            }
                        }
                    >
                        <ButtonItem
                            horizontalAlignment="right"
                            verticalAlignment="bottom"
                            buttonOptions={optionsComponents.btnSubmitOptions}
                            cssClass={"btnToolbar-group"}
                        />

                        <ButtonItem
                            horizontalAlignment="right"
                            verticalAlignment="bottom"
                            buttonOptions={optionsComponents.btnCloseOptions}
                            cssClass={"btnToolbar-group"}
                        />
                    </GroupItem>
                </Form>
            </form>
        </div>
    )
}

const linea = () => {
    return (
        <div>
            <hr />
        </div>
    )
}

const colCountByScreen = {
    sm: 1,
    md: 1,
    lg: 2
}

export default FormPerfilColaborador