import { CSSProperties, useMemo } from 'react';
import { useAuth } from 'api/auth';
import { URL_API_GENERAL } from 'consts/Services_URL';
import { useSWRWithToken } from 'hooks/useSWR';
import { IBudget } from 'interfaces/IHome';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { FormatoSinDecimales, FormatText } from 'utils/Formatos';
import { Meses } from 'utils/Global';

interface IDataBudget {
  data: IBudget[];
  error: any;
  loading: boolean;
}

interface IProps {
  className?: string;
  style?: CSSProperties;
}

const Budget = ({ className, style }: IProps) => {

  const { User } = useAuth();
  const { data = [], loading }: IDataBudget =
    useSWRWithToken(`${URL_API_GENERAL}budget?division=${User.divisionSeleccionada}`, 0, User.token!);

  const budgetMensual: IBudget[] = useMemo(() => {
    console.log(data);
    if (!data) return [];
    const budgets: IBudget[] = data.map((budget) => {
      const numMes: number = Meses.findIndex((mes) => mes.toLocaleUpperCase() === budget.mes) || 0;
      return {
        ...budget,
        numMes
      }
    });
    return budgets.sort((a: IBudget, b: IBudget) => a.numMes! - b.numMes!);
  }, [data]);

  const colorPorcentaje: string = useMemo(() => {
    if (!budgetMensual) return "";
    const porcentajeActual: number = budgetMensual.at(1)?.porcentajeMes || 0;
    if (porcentajeActual <= 25) {
      return "#F81F26";
    }
    if (porcentajeActual > 25 && porcentajeActual <= 50) {
      return "orange";
    }
    if (porcentajeActual > 50 && porcentajeActual <= 75) {
      return "yellow";
    }
    return "#53E079";
  }, [budgetMensual]);

  if (loading) {
    return (
      <Stack spacing={1} className={className}>
        <Skeleton variant="text" width={"100%"} />
        <Skeleton variant="rectangular" width={"100%"} height={180} animation='wave' />
      </Stack >
    )
  }

  return (
    <div className={className} style={style}>
      {
        budgetMensual.map(({ mes, porcentajeMes, total, budget }, index) => (
          index === 0 ? (
            <div className='budget-mensual-pasado-card' key={index}>
              <p className='title-mes'>{FormatText(mes || "")}</p>
              <p className='title-operaciones'><strong>{FormatoSinDecimales(total || 0)}</strong><span style={{ fontSize: 20 }}>/{budget}</span> OPS</p>
              <p className='title-cumplimiento'><span>{porcentajeMes}% <i className='fas fa-arrow-up'></i></span> de cumplimiento vs Budget</p>
            </div>
          ) :
            (
              <div className='budget-mensual-actual-card' key={index}>
                <p className='title-mes'>{FormatText(mes || "")}</p>
                <div className='container-operaciones-budget'>
                  <p className='title-operaciones'><strong>{FormatoSinDecimales(total || 0)}</strong><span style={{ fontSize: 20 }}>/{budget}</span> OPS</p>
                  <p className='title-cumplimiento'><span>{porcentajeMes}% <i className='fas fa-arrow-up'></i></span> de cumplimiento vs Budget</p>
                </div>              
                  <div className='porcentaje'><span style={
                    {
                      width: `${porcentajeMes}%`,
                      backgroundColor: colorPorcentaje
                    }}></span>
                  </div>                
              </div>
            )
        ))
      }
    </div>
  )
}

export default Budget