import { ScrollView } from 'devextreme-react'
import { Popup } from 'devextreme-react/popup'
import { memo } from 'react'
import FormSeguimiento from './FormSeguimiento'
import { IndexTab } from 'consts/Tareas'

const ModalSeguimiento = ({
    modalActividadEstatus,
    closePopup,
    tabVisible,
    dataTareasEntidad,
    onEditTarea,
    responsables
}) => {

    const renderTitle = () => {
        return (
            <h3 style={{ margin: "-0.2rem" }}>
                Seguimiento de Tareas
            </h3>
        )
    }

    return (
        <Popup
            visible={modalActividadEstatus}
            width={"70%"}
            height={"auto"}
            maxHeight={"60%"}
            dragEnabled={false}
            closeOnOutsideClick={false}
            showCloseButton={false}
            titleRender={renderTitle}
        >
            <ScrollView >
                <>
                    <FormSeguimiento
                        closePopup={closePopup}
                        indexTab={obtenerIndex(tabVisible)}
                        dataTareasEntidad={dataTareasEntidad}
                        onEditTarea={onEditTarea}
                        responsables={responsables}
                    />
                    
                </>
            </ScrollView>
        </Popup>
    )
}

const obtenerIndex = (tabVisible) => {
    return IndexTab[tabVisible.text]
}

export default memo(ModalSeguimiento)