import MenuFilters from 'components/MenuFilters';
import { NominaPagadaProvider } from 'contexts/NominaPagadaContext'
import { useFiltersNominaPagada } from './hooks/useFiltersNominaPagada';
import { FiltersNominasPagadas, GridNominasPagadas, ModalAcumulado, ModalComisiones } from './Index';
import './styles/estilos.scss';

const NominasPagadas = () => {

  const {
    tiposPago,
    periodos,
    handlerOnTipoPagoChanged,
    opciones,
    columsGrid
  } = useFiltersNominaPagada();

  return (
    <NominaPagadaProvider>
      <div className='container-modulo-th'>
        <MenuFilters>
          <FiltersNominasPagadas
            tiposPago={tiposPago}
            opciones={opciones}
            periodos={periodos}
            handlerOnTipoPagoChanged={handlerOnTipoPagoChanged}
          />
        </MenuFilters>
        <GridNominasPagadas
          columsGrid={columsGrid}
        />
        <ModalAcumulado />
        <ModalComisiones />
      </div>
    </NominaPagadaProvider>
  )
}

export default NominasPagadas