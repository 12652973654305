import { createContext, ReactElement, useState, useMemo, useCallback, useEffect } from 'react';
import { useAuth } from 'api/auth';
import { IFilterChange, IFilterData } from 'components/interfaces/Interfaces';
import { IFiltersPagoNomina, IRemoteTipoPago, IPeriodos, IImpuestosNomina, INomina } from 'interfaces/ITalentoHumano';
import { KEY_TIPO_PAGO, FORMAPAGO_PRETERMINADAS, KEY_PERIODO, MESSAGE_LOADING, MethodSave } from 'consts/TH';
import { NominaService, ReportsService } from 'api/nomina';
import { createNewFilter } from 'utils/Functions';
import { IOnSaved } from 'components/TableGrid';
import { IColumnProps } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';

export interface IContextNomina {
  nominas: INomina[];
  impuestos: IImpuestosNomina;
  periodos: IFilterData;
  filtros: IFiltersPagoNomina;
  loading: boolean;
  columsGrid: IColumnProps[];
  messageLoading: string;
  onHandlerSearchNomina: () => Promise<void>;
  onSelectFilterChange: (e: IFilterChange) => void;
  onCellValueChange: (e: IOnSaved[]) => void;
  onHandlerSaveNominas: (method: MethodSave) => Promise<void>;
}

export const NominaContext = createContext({} as IContextNomina);
const { Provider } = NominaContext;

interface IProps {
  children: ReactElement | ReactElement[];
}

const defaultPeriodos: IFilterData = {
  key: KEY_PERIODO,
  items: [],
  defaultValue: ""
}

export const NominaProvider = ({ children }: IProps) => {
  const { User } = useAuth();
  const [nominas, setNominas] = useState<INomina[]>([]);
  const [impuestos, setImpuestos] = useState<IImpuestosNomina>({ iva: 0, porcentajeFacturacion: 0 });
  const [filtros, setFiltros] = useState<IFiltersPagoNomina>({});
  const [periodos, setPeriodos] = useState<IFilterData>(defaultPeriodos);
  const [loading, setLoading] = useState<boolean>(false);
  const [messageLoading, setMessageLoading] = useState<string>(MESSAGE_LOADING);

  const nominasService = useMemo(() => {
    return NominaService(User);
  }, [User]);

  const reportesService = useMemo(() => {
    return ReportsService(User);
  }, [User])

  const columsGrid = useMemo(() => {
    return nominasService.showColumnsPagoNomina("PAGAR", filtros.tipoPago);
  }, [filtros, nominasService]);

  const onSelectFilterChange = (e: IFilterChange): void => {
    /**LIMPIAR DATOS DATAGRID */
    nominas.length > 0 && setNominas([]);

    const { key, value } = e;

    if (key === KEY_TIPO_PAGO) {
      const tipoPago: IRemoteTipoPago = value;
      const { cMotion_Tipo_Nomina_Descripcion: tipo } = tipoPago;
      const periodos: IPeriodos[] = nominasService.getPeriodos(tipo);
      const ultimoPeriodo: IPeriodos = periodos[periodos.length - 1];
      setFiltros(
        {
          tipoPago: tipoPago,
          formaPago: FORMAPAGO_PRETERMINADAS[tipo] || "NOM",
          periodo: ultimoPeriodo
        });
      setPeriodos(createNewFilter(periodos, ultimoPeriodo, KEY_PERIODO));
      return
    }
    const periodo: IPeriodos = value;
    setFiltros(prev => {
      return {
        ...prev,
        periodo: periodo
      }
    });
    setPeriodos(prev => {
      return {
        ...prev,
        defaultValue: periodo
      }
    });
  };

  const onCellValueChange = (e: IOnSaved[]) => {
    const nominaRow: INomina = e[0].data;
    const { cMotion_Tipo_Nomina_Descripcion } = filtros.tipoPago!;
    /*SI ES BONO NO HACER NADA */
    if (cMotion_Tipo_Nomina_Descripcion === "ESPECIAL") return

    const nominaRecalculada: INomina = nominasService.recalcularVacaciones_Descuento(nominaRow);
    const nuevasNominas = nominas.map((nomina: INomina) => nomina.idColaborador === nominaRow.idColaborador ?
      nominaRecalculada : nomina);
    setNominas(nuevasNominas);
  }

  const onHandlerSaveNominas = async (method: MethodSave): Promise<void> => {
    if (nominas.length === 0) {
      notify("No hay nominas por pagar", "warning", 4000);
      return
    }
    setMessageLoading("Guardando Nominas");
    setLoading(true);

    const { iva, porcentajeFacturacion } = impuestos;

    if (iva === 0 && porcentajeFacturacion === 0) {
      notify("No se pueden pagar los nominas ahora mismo", "error", 4000);
      return
    }
    await nominasService.saveNominasOnBD(nominas, iva, method, async (result) => {
      const { message, paidPeriod, wasSaved } = result;
      notify(message, !wasSaved ? "error" : "success", 4000);

      if (wasSaved && method === "NOMINA") {
        if (filtros.formaPago === "ASIM NOM") {
          await reportesService.asimilados(paidPeriod);
        }
        setNominas([]);
      }
    });
    setLoading(false);
  }

  const onHandlerSearchNomina = useCallback(async (): Promise<void> => {
    if (!filtros.tipoPago) {
      notify("No se ha seleccionado un tipo de pago", "warning", 4000);
      return
    }
    setMessageLoading(MESSAGE_LOADING);
    setLoading(true);
    const { data: existeNomina, error } = await nominasService.verificaPeriodos(filtros.periodo?.periodo!);

    if (error) {
      notify("Ocurrio un error al validar el periodo a pagar", "error", 4000);
      setLoading(false);
      return
    }

    existeNomina && notify("Ya existe una nomina pagada", "warning", 4000);

    let isOk: boolean = false;

    if (!existeNomina) {
      const { cMotion_Tipo_Nomina_Descripcion: tipo } = filtros.tipoPago;
      if (tipo === "AGUINALDO") {
        isOk = await nominasService.getAguinaldos(filtros, (aguinaldos) => {
          setNominas(aguinaldos);
        });
        !isOk && notify("Error al obtener los aguinaldos", "error", 4000);
      }
      if (tipo === "ESPECIAL") {
        isOk = await nominasService.getEspeciales(filtros, (especiales) => {
          setNominas(especiales);
        });
        !isOk && notify("Error al obtener las nominas especiales", "error", 4000);
      }
      if (tipo !== "ESPECIAL" && tipo !== "AGUINALDO") {
        isOk = await nominasService.getNominas(filtros, (nominas) => {
          setNominas(nominas);
        });
        !isOk && notify("Error al obtener las nominas", "error", 4000);
      }
    }
    setLoading(false);
  }, [filtros, nominasService]);

  /**OBTENER LOS IMPUESTOS */
  useEffect(() => {
    const getImpuestos = async (): Promise<void> => {
      const { result, data, error } = await nominasService.getImpuestos();
      if (!result) {
        notify(error, "error", 4000);
        return
      }
      setImpuestos(data as IImpuestosNomina);
    }
    getImpuestos();
  }, [nominasService]);

  useEffect(() => {
    console.log(filtros);
  }, [filtros]);

  const values: IContextNomina =
  {
    nominas,
    impuestos,
    onSelectFilterChange,
    periodos,
    filtros,
    loading,
    columsGrid,
    messageLoading,
    onHandlerSearchNomina,
    onHandlerSaveNominas,
    onCellValueChange
  };

  return (
    <Provider
      value={values}
    >
      {children}
    </Provider>
  )
}

