import { useAuth } from "api/auth";
import { useCallback, useEffect, useState } from "react";
import { IFilterChange, IFilterData, IFilterMultiple } from "components/interfaces/Interfaces";
import { IFiltersAnualClientes, IGetDataClientes } from "interfaces/IClientes";
import { ClientesService, ICatalogosFacturacionClientes } from "api/clientes";
import { RemoteFacturacionAnualAdapter } from "adapters/Clientes";
import { ExportingEvent } from 'devextreme/ui/pivot_grid';
import { onExportingPivot } from 'utils/Functions';

interface IUseFacturacionAnual {
    facturas: IGetDataClientes;
    catalogos: IGetCatalogosFacturacionCliente;
    handlerOnSelectChange: (e: IFilterChange) => void;
    handleFiltrado: () => Promise<void>;
    onExporting: (e: ExportingEvent) => void;
}

export interface IGetCatalogosFacturacionCliente{
    años?: IFilterMultiple;
    moneda?: IFilterData;
    clientes?: IFilterMultiple;
    error?: string | null;
    loading: boolean;
}

const defaultFilters: IFiltersAnualClientes = {
    años: [],
    moneda: "",
    clientes: [],
}

export const useFacturacionAnual = (): IUseFacturacionAnual => {
    const { User } = useAuth();
    const [filters, setFilters] = useState<IFiltersAnualClientes>(defaultFilters);
    const [catalogos, setCatalogos] = useState<IGetCatalogosFacturacionCliente>({ loading: false });
    const [facturas, setFacturas] = useState<IGetDataClientes>({ loading: false });

    const onExporting = useCallback((e: ExportingEvent) => {
        return onExportingPivot(e, "FacturacionAnualClientes", User.divisionSeleccionada!);
    }, [User]);

    const handleFiltrado = useCallback(async () : Promise<void> => {
        setFacturas({loading:true})
        const filtersAdapter: string = JSON.stringify(RemoteFacturacionAnualAdapter(filters))
        const { result, data, error } = await ClientesService(User).getDataFacturacion(filtersAdapter);
        if(!result){
            setFacturas({ error:error, loading:false})
            return
        }
        console.log(data)
        setFacturas({ data:data, loading:false})
    },[filters, User])

    const handlerOnSelectChange = (e:IFilterChange): void =>{
        const {key, value} = e;
        setFilters(prev => {
            return{
                ...prev,
                [key]: value
            }
        })
    }

  /* Obtener los catálogos */
    useEffect(() => {
        const getCatalogos = async (): Promise<void> => {
            setCatalogos({ loading: true });
            const { result, data, error } = await ClientesService(User).getCatalogosFacturacionAnual();
            if (!result) {
                setCatalogos({ error: error, loading: false });
                return
            }
            const filtersCatalogos: ICatalogosFacturacionClientes = data!;
            setCatalogos({ años:filtersCatalogos.años, clientes:filtersCatalogos.clientes, moneda:filtersCatalogos.moneda, loading: false });
        }
        getCatalogos();
    }, [User]);

  /**OBTENER DATA INICIAL */
    useEffect(() => {
        const getInitialData = async (): Promise<void> => {
            setFacturas({ loading: true });
            const filtersAdapter: string = JSON.stringify(RemoteFacturacionAnualAdapter(defaultFilters));
            const { result, data, error } = await ClientesService(User).getDataFacturacion(filtersAdapter);
            if (!result) {
                setFacturas({ error: error, loading: false });
                return
            }
            setFacturas({ data: data, loading: false });
        };
        getInitialData();
    }, [User]);

    return {
        facturas,
        catalogos,
        handlerOnSelectChange,
        handleFiltrado,
        onExporting
    };
};
