import { FilterMultipleGrid, Select } from "components/Index";
import { Button } from 'components/Index';
import PivotClientes from './components/PivotClientes';
import { useFacturacionAnual } from './hooks/useFacturacionAnual';
import { _FieldFacturacionClientes } from 'functions/Facturacion';
import ErrorPage from 'pages/Error';
import MenuFilters from "components/MenuFilters";
import './styles/estilos.scss';
import { useEffect } from "react";

const FacturacionAnual = () => {

  const {
    catalogos,
    facturas,
    handlerOnSelectChange,
    handleFiltrado,
    onExporting
  } = useFacturacionAnual();

  useEffect(() => {
    console.log(facturas);
  }, [facturas])

  const { loading } = facturas;

  const showFilters = (): JSX.Element => {
    const añosFactura = catalogos.años;
    const clientes = catalogos.clientes;
    const moneda = catalogos.moneda;

    return (
      <MenuFilters>
        {
          catalogos.loading ? (
            <p>Cargando...</p>
          ) : (
            <div className='container-filters'>
              <FilterMultipleGrid
                items={añosFactura?.items}
                onChange={handlerOnSelectChange}
                keyFilter={añosFactura?.key}
                selectionState={añosFactura?.selectionState}
                title={añosFactura?.title}
              />
              <FilterMultipleGrid
                items={clientes?.items}
                onChange={handlerOnSelectChange}
                keyFilter={clientes?.key}
                selectionState={clientes?.selectionState}
                title={clientes?.title}

              />
              <Select
                item={moneda}
                key={moneda?.key}
                onChangeSelect={handlerOnSelectChange}
              />
              <Button
                onClick={() => handleFiltrado()}
                text={facturas.loading ? "Filtrando..." : "Filtrar"}
                icon='fas fa-search'
                disabled={loading}
                className="btnFilter"
                width={"100%"}
                iconHover="Claro"
              />
            </div>
          )
        }
      </MenuFilters>
    )
  }

  return (
    <div className='container-clientesModules'>
      {
        showFilters()
      }
      {
        facturas.error ?
          <ErrorPage errorMessage={facturas.error} />
          :
          <PivotClientes
            dataClientes={facturas}
            fields={_FieldFacturacionClientes}
            keyLocalStorage='pivotFacturacionCliente'
            onExporting={onExporting}
          />
      }
    </div>
  )
}

export default FacturacionAnual