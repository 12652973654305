import { UsuarioAdapter } from "adapters/Usuario";
import { AxiosRequestConfig } from "axios";
import { URL_API_LOGIN } from "consts/Services_URL";
import { AuthNotify, KEY_LOCALSTORAGE_USER, KEY_LOCALSTORAGE_DIVISION } from "consts/User";
import { AuthContext, IContext } from "contexts/AuthContext"
import { IRemoteUser, IRemoteUserCredentials, IUser } from "interfaces/IUsuario";
import jwtDecode from "jwt-decode";
import { useContext } from "react"
import { sendDataAsync, getDataAsync } from "./fetch";

const useAuth = (): IContext => {
  return useContext(AuthContext)
}

interface IAuthenticationResponse {
  isOk: boolean;
  data?: IUser;
  error?: any;
}

interface ITokenResponse {
  isOk: boolean;
  token?: {
    user: string;
    expirationMinutes: number;
  }
}
interface IRefreshTokenResponse {
  isOk: boolean;
  tokenRefresh?: string;
}
interface ICheckUser {
  data: IUser,
  isOk: boolean;
}
type Message = "success" | "error" | "warning";

interface INotificacion {
  message: string;
  type: Message;
}

const AuthenticationService = {
  signIn: async (Username: string, Password: string): Promise<IAuthenticationResponse> => {
    try {
      const remoteUserCredentials: IRemoteUserCredentials = { Username, Password };
      const { result, data, error } = await sendDataAsync(`${URL_API_LOGIN}authentication`, remoteUserCredentials, 'POST');
      if (!result) {
        return { isOk: false, error: error }
      }
      const userRemote: IRemoteUser = { ...data };
      const dataUser: IUser = { ...UsuarioAdapter(userRemote), isActive: true };
      localStorage.setItem(KEY_LOCALSTORAGE_USER, JSON.stringify(dataUser));
      const userInfo: IUser = setInfoUser(dataUser);
      localStorage.setItem(KEY_LOCALSTORAGE_DIVISION, userInfo.divisionSeleccionada!);
      return { data: userInfo, isOk: true }
    } catch (err: any) {
      return { isOk: false, error: err }
    }
  },
  checkUser: (): ICheckUser => {
    try {
      if (!localStorage.getItem(KEY_LOCALSTORAGE_USER)) {
        localStorage.clear();
        return { data: { isActive: false }, isOk: false };
      }
      const userStorage: IUser = JSON.parse(localStorage.getItem(KEY_LOCALSTORAGE_USER)!);
      const divisionSeleccionada: string = localStorage.getItem(KEY_LOCALSTORAGE_DIVISION)!;
      const userInfo = { ...setInfoUser(userStorage), divisionSeleccionada };
      return { data: userInfo, isOk: true };
    } catch (err: any) {
      localStorage.clear();
      return { data: { isActive: false }, isOk: false };
    }
  },
  refreshToken: async (guid: string, token: string): Promise<IRefreshTokenResponse> => {
    const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } };
    const { result, data: tokenRefresh } = await getDataAsync(`${URL_API_LOGIN}refresh/${guid}`, headers);
    if (!result) {
      return { isOk: false }
    }
    return { tokenRefresh, isOk: true }
  },
  checkToken: async (token: string): Promise<ITokenResponse> => {
    const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } };
    const { result, data: accessToken } = await getDataAsync(`${URL_API_LOGIN}`, headers);
    if (!result) {
      return { isOk: false }
    }
    return { token: accessToken, isOk: true };
  },
  saveNewToken: (refreshToken: string, callback: (user: IUser) => void): void => {
    const userStorage: IUser = JSON.parse(localStorage.getItem(KEY_LOCALSTORAGE_USER)!);
    const newUser: IUser = { ...userStorage, token: refreshToken };
    localStorage.setItem(KEY_LOCALSTORAGE_USER, JSON.stringify(newUser));
    callback(setInfoUser(newUser));
  },
  showNotificacion: (notificacion: AuthNotify): INotificacion => {
    if (notificacion === AuthNotify.Inactivity) {
      return { message: notificacion, type: "warning" };
    }
    if (notificacion === AuthNotify.InvalidToken) {
      return { message: notificacion, type: "error" };
    }
    if (notificacion === AuthNotify.LogOut) {
      return { message: notificacion, type: "success" };
    }
    return { message: AuthNotify.ErrorAuth, type: "error" };
  }
}

interface IInfoUser {
  divisiones: string;
  permisos: string;
  role: string;
}
const setInfoUser = (user: IUser): IUser => {
  const infoUser: IInfoUser = jwtDecode(user.token!);
  const divisiones: string[] = JSON.parse(infoUser.divisiones);
  const permisos: string[] = JSON.parse(infoUser.permisos);
  return {
    ...user,
    divisiones,
    permisos,
    rol: infoUser.role,
    divisionSeleccionada: divisiones.at(0)
  }
}


export { useAuth, AuthenticationService };